import { IAppScreen } from 'app/shared/model/app-screen.model';
import { IAppEntity } from 'app/shared/model/app-entity.model';
import { IAppAccessConfig } from 'app/shared/model/app-access-config.model';
import { IFunctionality } from 'app/shared/model/functionality.model';
import { IAppFile } from 'app/shared/model/app-file.model';
import { IAppDependency } from 'app/shared/model/app-dependency.model';
import { IJob } from 'app/shared/model/job.model';
import { IAppEndPointController } from 'app/shared/model/app-end-point-controller.model';
import { IAppSetting } from 'app/shared/model/app-setting.model';
import { ICoreFunctionality } from 'app/shared/model/core-functionality.model';
import { IApp } from 'app/shared/model/app.model';
import { ICoreFeature } from 'app/shared/model/core-feature.model';
import { FeatureTemplateType } from 'app/shared/model/enumerations/feature-template-type.model';

export interface IFeature {
  id?: number;
  name?: string | null;
  description?: string | null;
  link?: string | null;
  userId?: number | null;
  type?: FeatureTemplateType | null;
  template?: boolean | null;
  appScreens?: IAppScreen[] | null;
  appEntities?: IAppEntity[] | null;
  appAccessConfigs?: IAppAccessConfig[] | null;
  functionalities?: IFunctionality[] | null;
  appFiles?: IAppFile[] | null;
  appDependencies?: IAppDependency[] | null;
  jobs?: IJob[] | null;
  appEndPointControllers?: IAppEndPointController[] | null;
  appSettings?: IAppSetting[] | null;
  coreFunctionality?: ICoreFunctionality | null;
  app?: IApp | null;
  coreFeature?: ICoreFeature | null;
}

export const defaultValue: Readonly<IFeature> = {
  template: false,
};
