import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Table, Label } from 'reactstrap';
import { Translate, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from '../../../../../app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '../../../../../app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';

import { IAppDomainListener } from '../../../../../app/shared/model/app-domain-listener.model';
import { createEntity, getEntities } from './app-domain-listener.reducer';
import { getEntities as getAppEntities } from '../../entities/app-entity/app-entity.reducer';
import AiSuggestListenerModal from '../productAllFeatures/ai-wizard-prompt';
import { AiGeneratedImage } from '../../shared/assets/svg-icon/icon';
import { suggestListeners } from '../ai-suggestor/ai-suggestor.reducer';

export const AppDomainListener = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { listenerId } = useParams<'listenerId'>();
  const [showModal, setShowModal] = useState(false);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const appDomainListenerList = useAppSelector(state => state.appDomainListener.entities);
  const loading = useAppSelector(state => state.appDomainListener.loading);
  const totalItems = useAppSelector(state => state.appDomainListener.totalItems);
  const appEntities = useAppSelector(state => state.appEntity.entities);
  const app = useAppSelector(state => state.app.entity);
  const [entitiesLoading, setEntitiesLoading] = useState(false);
  const [aiSuggestedListeners, setAiSuggestedListeners] = useState([]);
  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: appId ? `appId.equals=${appId}` : '',
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    // const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    // if (location.search !== endURL) {
    //   navigate(`${location.pathname}${endURL}`);
    // }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort?.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const toggleAiWizard = () => {
    dispatch(getAppEntities({ query: !!app?.id ? `appId.equals=${app?.id}` : '' }));
    setShowModal(!showModal);
    setEntitiesLoading(false);
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const handleOnSaveForSuggested = async selectedJobs => {
    setEntitiesLoading(true);
    for (const selectedJob of selectedJobs) {
      const entity = {
        preQueryListener: false,
        domainEventType: selectedJob.domainEventType,
        description: selectedJob.listenerDescription,
        updateFieldValue: selectedJob.listenerFieldValue,
        listenerClassName: selectedJob.listenerClassName,
        type: selectedJob?.listenerType?.dataType,
        appEntity: appEntities.find(it => it.name.toString() === selectedJob?.listenerAppEntity.toString()),
        appEntityField: appEntities
          .find(it => it.name.toString() === selectedJob?.listenerAppEntity.toString())
          .appEntityFields.find(f => f.id.toString() === '5064'),
        entityRelationships: [],
        app: app,
      };
      await dispatch(createEntity(entity));
    }
    getAllEntities();
    setAiSuggestedListeners([]);
    setEntitiesLoading(false);
  };

  const handleAiSuggest = async userPrompt => {
    setEntitiesLoading(true);
    let entitiesNamesString = appEntities
      .filter(it => it.type != 'Enum')
      .map(items => items.name)
      .join(',');
    let result = await dispatch(
      suggestListeners({
        entities: entitiesNamesString,
        description: userPrompt,
        appDescription: app.description,
      })
    );

    setAiSuggestedListeners(result?.payload?.data?.listenerSuggestors);
    setEntitiesLoading(false);
  };

  return (
    // <div className="inner-content-wrap">
    <div>
      <h2 id="app-domain-listener-heading" data-cy="AppDomainListenerHeading">
        {/* <Translate contentKey="codeconductorApp.appDomainListener.home.title">App Domain Listeners</Translate> */}
        <div className="d-flex justify-content-end" style={{ marginTop: '25px' }}>
          <div className="d-flex justify-content-end wizardbtn-dgn">
            <Button className="me-2" color="refresh" onClick={() => toggleAiWizard()} disabled={loading}>
              <AiGeneratedImage /> Wizard
            </Button>
          </div>
          <Link
            to={`/product/${productId}/app/${appId}/app-domain-listener/new`}
            className="btn btn-edit jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            {/* <Translate contentKey="codeconductorApp.appDomainListener.home.createLabel">Create new App Domain Listener</Translate> */}
            <Label style={{ marginBottom: '0px' }}>Create Domain Listener</Label>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {!loading && appDomainListenerList && appDomainListenerList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                {/* <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="codeconductorApp.appDomainListener.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th> */}
                <th className="hand" onClick={sort('domainEventType')}>
                  <Translate contentKey="codeconductorApp.appDomainListener.domainEventType">Domain Event Type</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('description')}>
                  <Translate contentKey="codeconductorApp.appDomainListener.description">Description</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('updateFieldValue')}>
                  <Translate contentKey="codeconductorApp.appDomainListener.updateFieldValue">Update Field Value</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('listenerClassName')}>
                  <Translate contentKey="codeconductorApp.appDomainListener.listenerClassName">Listener Class Name</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('type')}>
                  <Translate contentKey="codeconductorApp.appDomainListener.type">Type</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="codeconductorApp.appDomainListener.appEntityField">App Entity Field</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                {/* <th>
                  <Translate contentKey="codeconductorApp.appDomainListener.app">App</Translate> <FontAwesomeIcon icon="sort" />
                </th> */}
                <th />
              </tr>
            </thead>
            <tbody>
              {appDomainListenerList.map((appDomainListener, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  {/* <td>
                    <Button tag={Link} to={`/app-domain-listener/${appDomainListener.id}`} color="link" size="sm">
                      {appDomainListener.id}
                    </Button>
                  </td> */}
                  <td>
                    <Translate contentKey={`codeconductorApp.DomainEventType.${appDomainListener.domainEventType}`} />
                  </td>
                  <td>{appDomainListener.description}</td>
                  <td>{appDomainListener.updateFieldValue}</td>
                  <td>{appDomainListener.listenerClassName}</td>
                  <td>
                    <Translate contentKey={`codeconductorApp.DomainListenerType.${appDomainListener.type}`} />
                  </td>
                  <td>
                    {appDomainListener.appEntityField ? (
                      <Link to={`/app-entity-field/${appDomainListener.appEntityField.id}`}>{appDomainListener.appEntityField.name}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  {/* <td>{appDomainListener.app ? <Link to={`/app/${appDomainListener.app.id}`}>{appDomainListener.app.id}</Link> : ''}</td> */}
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/product/${productId}/app/${appId}/app-domain-listener/${appDomainListener.id}/view`}
                        color="primary"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{' '}
                        {/* <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span> */}
                      </Button>
                      <Button
                        tag={Link}
                        to={`/product/${productId}/app/${appId}/app-domain-listener/${appDomainListener.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="warning"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        {/* <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span> */}
                      </Button>
                      <Button
                        tag={Link}
                        to={`/product/${productId}/app/${appId}/app-domain-listener/${appDomainListener.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        {/* <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span> */}
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : loading ? (
          <div className="conductor_loader"></div>
        ) : (
          <div className="alert alert-warning">
            <Translate contentKey="codeconductorApp.appDomainListener.home.notFound">No App Domain Listeners found</Translate>
          </div>
        )}
      </div>
      {showModal && (
        <AiSuggestListenerModal
          aiSuggestLoading={entitiesLoading}
          aiSuggestList={aiSuggestedListeners}
          showAiWizard={showModal}
          toggleAiWizard={toggleAiWizard}
          handleAiSuggest={handleAiSuggest}
          aiSuggestFor="Listeners"
          handleOnSaveForSuggested={handleOnSaveForSuggested}
        />
      )}
      {totalItems ? (
        <div className={appDomainListenerList && appDomainListenerList.length > 0 ? 'footer-pagination' : 'd-none'}>
          <div className="justify-content-end d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-end d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default AppDomainListener;
