export enum BackendFramework {
  SPRING_BOOT = 'SPRING_BOOT',

  RUBY_ON_RAILS = 'RUBY_ON_RAILS',

  QUARKUS = 'QUARKUS',

  LARAVEL = 'LARAVEL',

  ASP_NET_CORE = 'ASP_NET_CORE',

  DJANGO = 'DJANGO',

  EXPRESS = 'EXPRESS',

  CAKE_PHP = 'CAKE_PHP',

  FLASK = 'FLASK',

  NEST_JS = 'NEST_JS',
}
