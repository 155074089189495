import dayjs from 'dayjs';

export interface IJobTask {
  id?: number;
  scheduledRunTime?: string;
  finished?: boolean;
  successful?: boolean;
  executorResponse?: string | null;
  executorLog?: string | null;
}

export const defaultValue: Readonly<IJobTask> = {
  finished: false,
  successful: false,
};
