import React from 'react';
import { Row, Nav, NavItem, NavLink } from 'reactstrap';

export const ScreenListSkeleton = () => {
    const style = {
        background: '#eee',
        margin: '6px',
        height: '32px',
        marginBottom: '10px',
        borderRadius: '10px'
    }

    return (
        <Row className='screen-list'>
            <Nav tabs className="screenSkeleton" style={{ backgroundColor: 'white !important' }}>
                <NavItem style={style} className='screenItem'>
                    <NavLink herf="#" className="skeletonLink">
                        { }
                    </NavLink>
                </NavItem>
                <NavItem style={style} className='screenItem'>
                    <NavLink herf="#" className="skeletonLink">
                        { }
                    </NavLink>
                </NavItem>
                <NavItem style={style} className='screenItem'>
                    <NavLink herf="#" className="skeletonLink">
                        { }
                    </NavLink>
                </NavItem>
                <NavItem style={style} className='screenItem'>
                    <NavLink herf="#" className="skeletonLink">
                        { }
                    </NavLink>
                </NavItem>
                <NavItem style={style} className='screenItem'>
                    <NavLink herf="#" className="skeletonLink">
                        { }
                    </NavLink>
                </NavItem>
            </Nav>
        </Row>
    );
};

export default ScreenListSkeleton;
