import { ILayoutConfig } from '../../shared/model/layout-config.model';
import { IApp } from '../../shared/model/app.model';

export interface IAppLayout {
  id?: number;
  defaultLayout?: boolean | null;
  componentValues?: string | null;
  layoutConfig?: ILayoutConfig | null;
  app?: IApp | null;
}

export const defaultValue: Readonly<IAppLayout> = {
  defaultLayout: false,
};
