import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from '../../../../../app/config/constants';
import { useAppDispatch, useAppSelector } from '../../config/store';

import { getEntity } from './app.reducer';

export const AppDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getEntity(id));
    }
  }, []);

  const appEntity = useAppSelector(state => state.app.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="appDetailsHeading">
          <Translate contentKey="codeconductorApp.app.detail.title">App</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{appEntity.id}</dd>
          <dt>
            <span id="gitRepoPath">
              <Translate contentKey="codeconductorApp.app.gitRepoPath">Git Repo Path</Translate>
            </span>
          </dt>
          <dd>{appEntity.gitRepoPath}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="codeconductorApp.app.name">Name</Translate>
            </span>
          </dt>
          <dd>{appEntity.name}</dd>
          <dt>
            <span id="baseName">
              <Translate contentKey="codeconductorApp.app.baseName">Base Name</Translate>
            </span>
          </dt>
          <dd>{appEntity.baseName}</dd>
          <dt>
            <span id="logo">
              <Translate contentKey="codeconductorApp.app.logo">Logo</Translate>
            </span>
          </dt>
          <dd>{appEntity.logo}</dd>
          <dt>
            <span id="applicationType">
              <Translate contentKey="codeconductorApp.app.applicationType">Application Type</Translate>
            </span>
          </dt>
          <dd>{appEntity.applicationType}</dd>
          <dt>
            <span id="gitRepoPath">
              <Translate contentKey="codeconductorApp.app.gitRepoPath">Git Repo Path</Translate>
            </span>
          </dt>
          <dd>{appEntity.gitRepoPath}</dd>
          <dt>
            <span id="gitRepoId">
              <Translate contentKey="codeconductorApp.app.gitRepoId">Git Repo Id</Translate>
            </span>
          </dt>
          <dd>{appEntity.gitRepoId}</dd>
          <dt>
            <span id="cicdPipeline">
              <Translate contentKey="codeconductorApp.app.cicdPipeline">Cicd Pipeline</Translate>
            </span>
          </dt>
          <dd>{appEntity.cicdPipeline}</dd>
          <dt>
            <span id="userId">
              <Translate contentKey="codeconductorApp.app.userId">User Id</Translate>
            </span>
          </dt>
          <dd>{appEntity.userId}</dd>
          <dt>
            <span id="packageName">
              <Translate contentKey="codeconductorApp.app.packageName">Package Name</Translate>
            </span>
          </dt>
          <dd>{appEntity.packageName}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="codeconductorApp.app.status">Status</Translate>
            </span>
          </dt>
          <dd>{appEntity.status}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="codeconductorApp.app.description">Description</Translate>
            </span>
          </dt>
          <dd>{appEntity.description}</dd>
          <dt>
            <span id="entityFileName">
              <Translate contentKey="codeconductorApp.app.entityFileName">Entity File Name</Translate>
            </span>
          </dt>
          <dd>{appEntity.entityFileName}</dd>
          <dt>
            <span id="commitMsg">
              <Translate contentKey="codeconductorApp.app.commitMsg">Commit Msg</Translate>
            </span>
          </dt>
          <dd>{appEntity.commitMsg}</dd>
          <dt>
            <span id="loader">
              <Translate contentKey="codeconductorApp.app.loader">Loader</Translate>
            </span>
          </dt>
          <dd>{appEntity.loader}</dd>
          <dt>
            <span id="favIcon">
              <Translate contentKey="codeconductorApp.app.favIcon">Fav Icon</Translate>
            </span>
          </dt>
          <dd>{appEntity.favIcon}</dd>
          <dt>
            <Translate contentKey="codeconductorApp.app.product">Product</Translate>
          </dt>
          <dd>{appEntity.product ? appEntity.product.name : ''}</dd>
        </dl>
        <Button tag={Link} to="/app" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/app/${appEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default AppDetail;
