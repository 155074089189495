import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ErrorBoundaryRoutes from '../../../../../app/shared/error/error-boundary-routes';
import ProductAllFeatures from './ProductAllFeatures';
import AppEntityUpdate from './editEntityFunctionality/AppEntityUpdate';
import AppEntityFieldUpdate from './editAppEntityField/AppEntityFieldUpdate';
import AppEntityView from './editEntityFunctionality/AppEntityView';
import EnumMemberUpdate from '../enum-member/enum-member-update';
import AppEntityFieldDetail from '../app-entity-field/app-entity-field-detail';
import AppEntityDeleteDialog from '../app-entity/app-entity-delete-dialog';
import EnumMemberDetail from '../enum-member/enum-member-detail';
import FeatureUpdate from '../../entities/feature/feature-update';
import EntityRelationshipUpdate from '../../entities/entity-relationship/entity-relationship-update';
import AppScreenUpdate from '../../entities/app-screen/app-screen-update';
import AppScreenDetail from '../../entities/app-screen/app-screen-detail';
import DeploymentUpdate from '../../entities/deployment/deployment-update';
import DeploymentDetail from '../../entities/deployment/deployment-detail';
import DependencyUpdate from '../dependency/dependency-update';
import DependencyDetail from '../dependency/dependency-detail';
import DependencyDeleteDialog from '../../entities/dependency/dependency-delete-dialog';
import AppUpdate from '../app/app-update';
import AppDependencyUpdate from '../../entities/app-dependency/app-dependency-update';
import AppDependencyDetail from '../../entities/app-dependency/app-dependency-detail';
import AppDependencyDeleteDialog from '../../entities/app-dependency/app-dependency-delete-dialog';
import JobUpdate from '../../entities/job/job-update';
import JobDetail from '../../entities/job/job-detail';
import AppEndPointControllerUpdate from '../../entities/app-end-point-controller/app-end-point-controller-update';
import AppEndPointControllerDetail from '../../entities/app-end-point-controller/app-end-point-controller-detail';
import AppEndPointUpdate from '../app-end-point/app-end-point-update';
import AppEndPointDetail from '../app-end-point/app-end-point-detail';
import Dependency from '../../entities/dependency/dependency';
import AppDomainListenerUpdate from '../../entities/app-domain-listener/app-domain-listener-update';
import AppDomainListenerDetail from '../../entities/app-domain-listener/app-domain-listener-detail';
import ProductJournalEntryUpdate from '../product-journal-entry/product-journal-entry-update';
import ProductJournalEntryDetail from '../product-journal-entry/product-journal-entry-detail';
import AppFileUpdate from "../app-file/app-file-update";
import AppFileDetail from "../app-file/app-file-detail";
import AppFileDeleteDialog from "../app-file/app-file-delete-dialog";
import AppAiServiceUpdate from "../app-ai-service/app-ai-service-update";
import AppAiServiceDeleteDialog from "../app-ai-service/app-ai-service-delete-dialog";
import AppAiServiceDetail from '../app-ai-service/app-ai-service-detail';
import AppAiToolUpdate from "../app-ai-tool/app-ai-tool-update";
import AppAiToolDetail from "../app-ai-tool/app-ai-tool-detail";
import AppAiToolDeleteDialog from "../app-ai-tool/app-ai-tool-delete-dialog";
import AppChatLanguageModel from '../app-chat-language-model/app-chat-language-model';
import AppChatLanguageModelDetail from '../app-chat-language-model/app-chat-language-model-detail';
import AppChatLanguageModelUpdate from '../app-chat-language-model/app-chat-language-model-update';
import AppChatLanguageModelDeleteDialog from '../app-chat-language-model/app-chat-language-model-delete-dialog';

const ProductAllFeatureRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProductAllFeatures />} />
    <Route path="edit" element={<AppUpdate />} />
    <Route path="feature">
      <Route path="new" element={<FeatureUpdate />} />
      <Route path=":featureId/edit" element={<FeatureUpdate />} />
    </Route>
    <Route path="app-domain-listener">
      <Route path="new" element={<AppDomainListenerUpdate />} />
      <Route path=":listenerId">
        <Route path="edit" element={<AppDomainListenerUpdate />} />
        <Route path="view" element={<AppDomainListenerDetail />} />
      </Route>
    </Route>
    <Route path="product-journal-entry">
      <Route path="new" element={<ProductJournalEntryUpdate />} />
      <Route path=":journalId">
        <Route path="edit" element={<ProductJournalEntryUpdate />} />
        <Route path="view" element={<ProductJournalEntryDetail />} />
      </Route>
    </Route>
    <Route path="job">
      <Route path="new" element={<JobUpdate />} />
      <Route path=":jobId">
        <Route path="edit" element={<JobUpdate />} />
        <Route path="view" element={<JobDetail />} />
      </Route>
    </Route>
    <Route path="controller">
      <Route path="new" element={<AppEndPointControllerUpdate />} />
      <Route path=":endpointControllerId">
        <Route path="edit" element={<AppEndPointControllerUpdate />} />
        <Route path="view" element={<AppEndPointControllerDetail />} />
        <Route path="endpoint">
          <Route path="new" element={<AppEndPointUpdate />} />
          <Route path=":endpointId">
            <Route path="edit" element={<AppEndPointUpdate />} />
            <Route path="view" element={<AppEndPointDetail />} />
          </Route>
        </Route>
      </Route>
    </Route>
    <Route path="app-entity">
      <Route path="new" element={<AppEntityUpdate />} />
      <Route path=":appEntityId">
        <Route path="view" element={<AppEntityView />} />
        <Route path="view/app-entity-field">
          <Route path="new" element={<AppEntityFieldUpdate />} />
          <Route path=":appEntityFieldId/edit" element={<AppEntityFieldUpdate />} />
          <Route path=":appEntityFieldId/view" element={<AppEntityFieldDetail />} />
        </Route>
        <Route path="view/app-entity-enum">
          <Route path="new" element={<EnumMemberUpdate />} />
          <Route path=":appEntityEnumId/edit" element={<EnumMemberUpdate />} />
          <Route path=":appEntityEnumId/view" element={<EnumMemberDetail />} />
        </Route>
        <Route path="edit" element={<AppEntityUpdate />} />
      </Route>
    </Route>
    <Route path="entity-relationship">
      <Route path="new" element={<EntityRelationshipUpdate />} />
      <Route path=":entityRelationshipId/edit" element={<EntityRelationshipUpdate />} />
    </Route>
    <Route path="app-screen">
      <Route path="new" element={<AppScreenUpdate />} />
      <Route path=":appScreenId">
        <Route path="edit" element={<AppScreenUpdate />} />
        <Route path="view" element={<AppScreenDetail />} />
      </Route>
    </Route>
    <Route path="deployment">
      <Route path="new" element={<DeploymentUpdate />} />
      <Route path=":deploymentId">
        <Route path="edit" element={<DeploymentUpdate />} />
        <Route path="view" element={<DeploymentDetail />} />
      </Route>
    </Route>
    <Route path="dependency">
      <Route path="new" element={<DependencyUpdate />} />
      <Route path=":dependencyId">
        <Route path="edit" element={<DependencyUpdate />} />
        <Route path="view" element={<DependencyDetail />} />
        <Route path="delete" element={<DependencyDeleteDialog />} />
      </Route>
    </Route>
    <Route path="app-dependency">
      <Route path="new" element={<AppDependencyUpdate />} />
      <Route path=":appDependencyId">
        <Route path="edit" element={<AppDependencyUpdate />} />
        <Route path="view" element={<AppDependencyDetail />} />
        <Route path="delete" element={<AppDependencyDeleteDialog />} />
      </Route>
    </Route>
    <Route path="app-entity-view/:entityID/:entityFieldId/delete" element={<AppEntityDeleteDialog />} />
    <Route path="dependency-list" element={<Dependency />} />
    <Route path="app-file">
      <Route path="new" element={<AppFileUpdate />} />
      <Route path=":appFileId">
        <Route path="edit" element={<AppFileUpdate />} />
        <Route path="view" element={<AppFileDetail />} />
        <Route path="delete" element={<AppFileDeleteDialog />} />
      </Route>
    </Route>
    <Route path="app-ai-service">
      <Route path="new" element={<AppAiServiceUpdate />} />
      <Route path=":appAiServiceId">
        <Route path="edit" element={<AppAiServiceUpdate />} />
        <Route path="view" element={<AppAiServiceDetail />} />
        <Route path="delete" element={<AppAiServiceDeleteDialog />} />
      </Route>
    </Route>
    <Route path="app-ai-tool">
      <Route path="new" element={<AppAiToolUpdate />} />
      <Route path=":appAiToolId">
        <Route path="edit" element={<AppAiToolUpdate />} />
        <Route path="view" element={<AppAiToolDetail />} />
        <Route path="delete" element={<AppAiToolDeleteDialog />} />
      </Route>
    </Route>
    <Route path="app-chat-language-model">
      <Route path="new" element={<AppChatLanguageModelUpdate />} />
      <Route path=":appChatLanguageModelId">
        <Route path="edit" element={<AppChatLanguageModelUpdate />} />
        <Route path="view" element={<AppChatLanguageModelDetail />} />
        <Route path="delete" element={<AppChatLanguageModelDeleteDialog />} />
      </Route>
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProductAllFeatureRoutes;
