import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '../../config/store';
import { deleteEntity, updateEntity } from './product.reducer';
import { AppStatus } from '../../../../../app/shared/model/enumerations/app-status.model';

export const ProductDeleteDialog = (itemId, showModal) => {
  const dispatch = useAppDispatch();
  const [loadModal, setLoadModal] = useState(false);
  const updateSuccess = useAppSelector(state => state.product.updateSuccess);

  const handleClose = () => {
    itemId.showModal(loadModal);
  };

  useEffect(() => {
    if (updateSuccess) {
      itemId.showModal(loadModal);
    }
  }, [updateSuccess]);

  const confirmDelete = () => {
    // dispatch(deleteEntity(itemId.itemId.id));

    let product = itemId.itemId;
    let update_entity = { ...product, status: 'Deleted' };
    console.log('product ::', updateEntity);
    dispatch(updateEntity(update_entity));
  };

  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose} data-cy="productDeleteDialogHeading">
        <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
      </ModalHeader>
      <ModalBody id="codeconductorApp.product.delete.question">
        <Translate contentKey="codeconductorApp.product.delete.question" interpolate={{ id: itemId.itemId.name }}>
          Are you sure you want to delete this Product?
        </Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button id="jhi-confirm-delete-product" data-cy="entityConfirmDeleteButton" color="danger" onClick={confirmDelete}>
          <FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ProductDeleteDialog;
