import dayjs from 'dayjs';
import { ICoreScreen } from '../../shared/model/core-screen.model';
import { ICoreEntity } from '../../shared/model/core-entity.model';

export interface ICoreScreenComponent {
  id?: number;
  name?: string | null;
  layout?: string | null;
  entityFields?: string | null;
  userId?: number | null;
  layoutTemplate?: string | null;
  lastSyncTimestamp?: string | null;
  coreScreenComponent?: ICoreScreenComponent | null;
  coreScreen?: ICoreScreen | null;
  coreEntity?: ICoreEntity | null;
  filter?: string | null;
  coreScreenComponentParents?: ICoreScreenComponent[] | null;
}

export const defaultValue: Readonly<ICoreScreenComponent> = {};
