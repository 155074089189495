import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, Form, FormGroup, Input, Label, Breadcrumb, BreadcrumbItem, Nav, NavItem, NavLink } from 'reactstrap';
import { Translate, TextFormat, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from '../../../../../../app/config/constants';
import { useAppDispatch, useAppSelector } from '../../../config/store';
import { getEntity } from '../../../entities/app-entity/app-entity.reducer';
import AppEntityField from '../editAppEntityField/AppEntityField';
import EnumMember from '../../../entities/enum-member/enum-member';
import { getEntities as entityFielsMembers } from '../../../entities/enum-member/enum-member.reducer';
import { EntityType } from '../../../../../../app/shared/model/enumerations/entity-type.model';
import { BackArrowIcon } from '../../../shared/assets/svg-icon/icon';
import Relationship from '../relationshipForm/Relationship';

// import { getEntity } from './app-entity.reducer';
// import AppEntityField from '../app-entity-field/app-entity-field';

export const AppEntityView = () => {
  const dispatch = useAppDispatch();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { appEntityId } = useParams<'appEntityId'>();
  const [showTab, setShowTab] = useState(localStorage.getItem('selectedAppEntityViewTab') || 'AppEntityField');
  const app = useAppSelector(state => state.app.entity);
  const productEntity = useAppSelector(state => state.product.entity);
  const navigate = useNavigate();
  useEffect(() => {
    if (!Number(appEntityId)) navigate(-1);
    if (appEntityId !== undefined) {
      dispatch(getEntity(appEntityId));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedAppEntityViewTab', showTab);
  }, [showTab]);

  const appEntityDetails = useAppSelector(state => state.appEntity.entity);

  return (
    <div className="inner-content-wrap adding_spc">
      <Form>
        <Row>
          <Col md={12}>
            {/* <h2 className="row  page-title" data-cy="appEntityDetailsHeading">
              <Translate contentKey="codeconductorApp.appEntity.detail.title">App Entity</Translate>
            </h2> */}
            <Row className="mb-4">
              <Col md="8">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to="/product">Products</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <Link to={`/product/${productEntity.id}`}>{productEntity.name}</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <Link to={`/product/${productEntity.id}/app/${app.id}`}>{app.name}</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>App Entity</BreadcrumbItem>
                </Breadcrumb>
              </Col>
              {/* <Button tag={Link} to={`/product/${productId}/app/${appId}`} replace color="back" data-cy="entityDetailsBackButton">
                <FontAwesomeIcon icon="arrow-left" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button> */}
              <Col md="4" className="justify-content-end d-flex">
                <Link
                  // href=""
                  className="feature-back"
                  to={`/product/${productId}/app/${appId}`}
                  replace
                  color="back"
                  data-cy="entityDetailsBackButton"
                >
                  <BackArrowIcon />
                  <Translate contentKey="entity.action.back">Back</Translate>
                </Link>
              </Col>
            </Row>
            <div className="jh-entity-details row">
              <Col md={6}>
                <FormGroup>
                  <span id="name">
                    <Label contentKey="codeconductorApp.appEntity.name">Name</Label>
                  </span>
                  <Input value={appEntityDetails.name} />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <span id="type">
                    <Label contentKey="codeconductorApp.appEntity.type">Type</Label>
                  </span>
                  <Input value={appEntityDetails.type} />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <span id="type">
                    <Label contentKey="codeconductorApp.feature.detail.title">Feature</Label>
                  </span>
                  <Input value={appEntityDetails?.feature?.name} />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <span id="type">
                    <Label contentKey="codeconductorApp.appEntity.description">Description</Label>
                  </span>
                  <Input value={appEntityDetails.description} />
                </FormGroup>
              </Col>

              {appEntityDetails && appEntityDetails.length > 0 && (
                <Col md={6}>
                  <FormGroup>
                    <Label contentKey="codeconductorApp.appEntity.functionality">Functionality</Label>
                    <Input value={appEntityDetails.functionality ? appEntityDetails.functionality.name : ''} />
                  </FormGroup>
                </Col>
              )}
            </div>
            {/*
            <Col md={4}>
              <Button tag={Link} to={`/product/${productId}/app/${appId}`} replace color="back" data-cy="entityDetailsBackButton">
                <FontAwesomeIcon icon="arrow-left" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              {/* <Button tag={Link} to={`/app-entity/${appEntityEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
            </Col>
            */}
          </Col>
        </Row>
      </Form>
      {appEntityDetails.type === EntityType.Enum ? (
        <EnumMember />
      ) : appEntityDetails.type !== 'Internal' ? (
        <div>
          <Nav tabs className="tab-design">
            <NavItem>
              <NavLink onClick={() => setShowTab('AppEntityField')} active={showTab == 'AppEntityField'}>
                App Entity Fields{' '}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={() => setShowTab('EntityRelationship')} active={showTab == 'EntityRelationship'}>
                Relationships{' '}
              </NavLink>
            </NavItem>
          </Nav>
          {showTab == 'AppEntityField' && (
            <AppEntityField entityName={appEntityDetails.name} entityDescription={appEntityDetails.description} appId={appId} />
          )}
          {showTab == 'EntityRelationship' && <Relationship appEntityFromId={appEntityId} />}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default AppEntityView;
