export enum FrontendFramework {
  REACT = 'REACT',

  VUE = 'VUE',

  SVELTE = 'SVELTE',

  ANGULAR = 'ANGULAR',

  FLUTTER = 'FLUTTER',
}
