import { IAppEntity } from 'app/shared/model/app-entity.model';
import { IAppAccessConfig } from 'app/shared/model/app-access-config.model';
import { IAppScreen } from 'app/shared/model/app-screen.model';
import { IJob } from 'app/shared/model/job.model';
import { IAppEndPointController } from 'app/shared/model/app-end-point-controller.model';
import { IAppDependency } from 'app/shared/model/app-dependency.model';
import { IAppFile } from 'app/shared/model/app-file.model';
import { IApp } from 'app/shared/model/app.model';
import { ICoreFunctionality } from 'app/shared/model/core-functionality.model';
import { IFeature } from 'app/shared/model/feature.model';
import { FeatureTemplateType } from 'app/shared/model/enumerations/feature-template-type.model';

export interface IFunctionality {
  id?: number;
  name?: string | null;
  description?: string | null;
  template?: boolean | null;
  type?: FeatureTemplateType | null;
  appEntities?: IAppEntity[] | null;
  appAccessConfigs?: IAppAccessConfig[] | null;
  appScreens?: IAppScreen[] | null;
  jobs?: IJob[] | null;
  appEndPointControllers?: IAppEndPointController[] | null;
  appDependencies?: IAppDependency[] | null;
  appFiles?: IAppFile[] | null;
  app?: IApp | null;
  coreFunctionality?: ICoreFunctionality | null;
  feature?: IFeature | null;
}

export const defaultValue: Readonly<IFunctionality> = {
  template: false,
};
