import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, Label, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getSortState, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '../../config/store';
import { getEntity as getApp } from '../app/app.reducer';
import { fetchMoreEntities, getEntities as getAppEntities, reset as resetAppEntity } from '../app-entity/app-entity.reducer';
import { RelationshipType } from '../../../../../app/shared/model/enumerations/relationship-type.model';
import { getEntity, updateEntity, createEntity, reset } from './entity-relationship.reducer';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { ITEMS_PER_PAGE, DESC } from '../../../../../app/shared/util/pagination.constants';
import { EntityType } from '../../../../../app/shared/model/enumerations/entity-type.model';
import DynamicSelect from '../../modules/dynamicSelectComponent/DynamicSelect';
import { getEntities as entityFields } from '../app-entity-field/app-entity-field.reducer';
import { BackArrowIcon } from '../../shared/assets/svg-icon/icon';

export const EntityRelationshipUpdate = () => {
  const [isEntityFieldFromDisabled, setIsEntityFieldFromDisabled] = useState(true);
  const [isEntityFieldToDisabled, setIsEntityFieldToDisabled] = useState(true);
  const [selectedEntityFrom, setSelectedEntityFrom] = useState({});
  const [selectedEntityTo, setSelectedEntityTo] = useState({});
  const [entityFieldsFrom, setEntityFieldsFrom] = useState([]);
  const [entityFieldsTo, setEntityFieldsTo] = useState([]);
  const [defaultValues, setDefaultValues] = useState({});
  const [entityFieldIsFrom, setEntityFieldIsFrom] = useState(true);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', DESC), location.search)
  );

  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { entityRelationshipId } = useParams<'entityRelationshipId'>();
  const isNew = entityRelationshipId === undefined;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const app = useAppSelector(state => state.app.entity);
  const appEntities = useAppSelector(state => state.appEntity.entities);
  const appEntitiesFields = useAppSelector(state => state.appEntityField.entities);
  const entityRelationshipEntity = useAppSelector(state => state.entityRelationship.entity);
  const loading = useAppSelector(state => state.entityRelationship.loading);
  const updating = useAppSelector(state => state.entityRelationship.updating);
  const updateSuccess = useAppSelector(state => state.entityRelationship.updateSuccess);
  const relationshipTypeValues = Object.keys(RelationshipType);
  const [entityRelationshipType, setEntityRelationshipType] = useState(RelationshipType.OneToMany);
  const productEntity = useAppSelector(state => state.product.entity);
  const appEntity = useAppSelector(state => state.app.entity);
  const [appEntitiesFromList, setAppEntitiesFromList] = useState();

  useEffect(() => {
    const appEntitiesFromList = appEntities.filter(it => it.type == "Standard");
    setAppEntitiesFromList(appEntitiesFromList);
  }, [appEntities]);

  const handleClose = () => {
    navigate(`/product/${productId}/app/${appId}` + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(entityRelationshipId));
    }
    if (appId !== undefined) {
      dispatch(getApp(appId));
    }
    dispatch(
      getAppEntities({
        page: paginationState.activePage - 1,
        size: 25,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: !!appId ? `appId.equals=${appId}&type.notEquals=${EntityType.Enum}` : '',
      })
    );
    dispatch(resetAppEntity());

    // dispatch(getAppEntities({ query: !!appId ? `appId.equals=${appId}&type.notEquals=ENUM` : '' }));
  }, []);

  useEffect(() => {
    entityFieldIsFrom ? setEntityFieldsFrom(appEntitiesFields) : setEntityFieldsTo(appEntitiesFields);
  }, [appEntitiesFields]);
  useEffect(() => {
    (async () => {
      setEntityFieldIsFrom(true);
      await dispatch(entityFields({ query: `appEntityId.equals=${entityRelationshipEntity.appEntityFrom.id}` }));
      setEntityFieldIsFrom(false);
      await dispatch(entityFields({ query: `appEntityId.equals=${entityRelationshipEntity.appEntityTo.id}` }));
      if (!isNew && entityRelationshipEntity && entityRelationshipEntity.id) {
        setIsEntityFieldFromDisabled(false);
        setIsEntityFieldToDisabled(false);
        setSelectedEntityFrom(entityRelationshipEntity.appEntityFrom);
        setSelectedEntityTo(entityRelationshipEntity.appEntityTo);
        setDefaultValues({
          ...entityRelationshipEntity,
          app: entityRelationshipEntity?.app?.id,
          appEntityFieldTo: entityRelationshipEntity?.appEntityFieldTo?.id,
          appEntityFieldFrom: entityRelationshipEntity?.appEntityFieldFrom?.id,
          appEntityTo: entityRelationshipEntity?.appEntityTo?.id,
          appEntityFrom: entityRelationshipEntity?.appEntityFrom?.id,
          fromSuffix: entityRelationshipEntity?.fromSuffix,
          toSuffix: entityRelationshipEntity?.toSuffix,
        });
        setEntityRelationshipType(entityRelationshipEntity.type);
      }
    })();
  }, [entityRelationshipEntity]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);
  useEffect(() => {}, [defaultValues]);

  const saveEntity = values => {
    const entity = {
      ...entityRelationshipEntity,
      ...values,
      appEntityFrom: selectedEntityFrom,
      appEntityTo: selectedEntityTo,
      app: app,
      appEntityFieldFrom: values.appEntityFieldFrom
        ? entityFieldsFrom.find(it => it.id.toString() === values.appEntityFieldFrom.toString())
        : null,
      appEntityFieldTo: values.appEntityFieldTo ? entityFieldsTo.find(it => it.id.toString() === values.appEntityFieldTo.toString()) : null,
      type: entityRelationshipType,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  // co-related filteration
  const handleEntityFromChange = e => {
    var entity = appEntities.find(it => it.id.toString() === e.value.toString());
    dispatch(entityFields({ query: `appEntityId.equals=${e.value.toString()}` }));
    setSelectedEntityFrom(entity);
    setEntityFieldIsFrom(true);
    // setEntityFieldsFrom(entity && entity.appEntityFields && entity.appEntityFields != null && entity.appEntityFields);
    setIsEntityFieldFromDisabled(false);
  };

  const handleEntityToChange = e => {
    var entity = appEntities.find(it => it.id.toString() === e.value.toString());
    // var entity = appEntities.find(it => it.id.toString() === e.target.value.toString());
    dispatch(entityFields({ query: `appEntityId.equals=${e.value.toString()}` }));
    setSelectedEntityTo(entity);
    // setEntityFieldsTo(entity && entity.appEntityFields && entity.appEntityFields != null && entity.appEntityFields);
    setEntityFieldIsFrom(false);
    setIsEntityFieldToDisabled(false);
  };

  const handleEntityRelationshipTypeChange = e => {
    setEntityRelationshipType(e.target.value);
  };

  return (
    <div className="inner-content-wrap">
      <Row className="justify-content-center">
        {/* <Col md="12">
          <h2
            className="row  page-title"
            id="codeconductorApp.entityRelationship.home.createOrEditLabel"
            data-cy="EntityRelationshipCreateUpdateHeading"
          >
            <Label>{isNew ? `Add` : `Manage`} Entity Relationship</Label>
          </h2>
        </Col> */}
        <Col md="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/product">Products</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={`/product/${productEntity.id}`}>{productEntity.name}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={`/product/${productEntity.id}/app/${appEntity.id}`}>{appEntity.name}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>{isNew ? `Add` : `Manage`} Entity Relationship</BreadcrumbItem>
          </Breadcrumb>
        </Col>
        <Col md="6" className="justify-content-end d-flex">
          <Link
            // href=""
            className="feature-back"
            to={`/product/${productEntity.id}/app/${appEntity.id}`}
            replace
            color="back"
            data-cy="entityDetailsBackButton"
          >
            <BackArrowIcon />
            <Translate contentKey="entity.action.back">Back</Translate>
          </Link>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues} onSubmit={saveEntity} className="row">
              <Row>
                <ValidatedField
                  className="col-md-6"
                  label={translate('codeconductorApp.entityRelationship.type')}
                  id="entity-relationship-type"
                  name="relationshipType"
                  data-cy="relationshipType"
                  type="select"
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                  value={entityRelationshipType}
                  onChange={handleEntityRelationshipTypeChange}
                >
                  {relationshipTypeValues.map(relationshipType => (
                    <option value={RelationshipType[relationshipType]} key={RelationshipType[relationshipType]}>
                      {translate('codeconductorApp.RelationshipType.' + RelationshipType[relationshipType])}
                    </option>
                  ))}
                </ValidatedField>
              </Row>
              {/* <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.entityRelationship.suffix')}
                id="entity-relationship-suffix"
                name="suffix"
                data-cy="suffix"
                type="text"
              /> */}

              {/* <ValidatedField
                onChange={handleEntityFromChange}
                className="col-md-6"
                id="entity-relationship-appEntityFrom"
                name="appEntityFrom"
                data-cy="appEntityFrom"
                label={translate('codeconductorApp.entityRelationship.appEntityFrom')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0">
                  Select One
                </option>
                {appEntities
                  ? appEntities.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField> */}

              <div id="entity-relationship-appEntityFrom" className="col-md-6 mb-3">
                <label className="form-label">{translate('codeconductorApp.entityRelationship.appEntityFrom')}</label>
                <DynamicSelect
                  data-cy="appEntityFrom"
                  changeFxn={handleEntityFromChange}
                  callbackFXN={fetchMoreEntities}
                  paginateState={paginationState}
                  enitityData={appEntitiesFromList?.length > 0 && appEntitiesFromList}
                  callbackFxnProps={{
                    page: paginationState.activePage,
                    size: 25,
                    sort: `${paginationState.sort},${paginationState.order}`,
                    query: !!appId ? `appId.equals=${appId}&type.notEquals=${EntityType.Enum}` : '',
                  }}
                  setPageState={setPaginationState}
                  entity={'appEntity'}
                  name="appEntityFrom"
                  defaultValue={selectedEntityFrom}
                  label={translate('codeconductorApp.entityRelationship.appEntityFrom')}
                  validate={{ required: true }}
                />
              </div>

              {/* <ValidatedField
                onChange={handleEntityToChange}
                className="col-md-6"
                id="entity-relationship-appEntityTo"
                name="appEntityTo"
                data-cy="appEntityTo"
                label={translate('codeconductorApp.entityRelationship.appEntityTo')}
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0">
                  Select One
                </option>
                {appEntities
                  ? appEntities.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField> */}

              <div id="entity-relationship-appEntityTo" className="col-md-6 mb-3">
                <label className="form-label">{translate('codeconductorApp.entityRelationship.appEntityTo')}</label>
                <DynamicSelect
                  data-cy="appEntityTo"
                  changeFxn={handleEntityToChange}
                  callbackFXN={fetchMoreEntities}
                  paginateState={paginationState}
                  enitityData={appEntities}
                  callbackFxnProps={{
                    page: paginationState.activePage,
                    size: 25,
                    sort: `${paginationState.sort},${paginationState.order}`,
                    query: !!appId ? `appId.equals=${appId}&type.notEquals=${EntityType.Enum}` : '',
                  }}
                  setPageState={setPaginationState}
                  entity={'appEntity'}
                  name="appEntityTo"
                  defaultValue={selectedEntityTo}
                  label={translate('codeconductorApp.entityRelationship.appEntityTo')}
                  validate={{ required: true }}
                />
              </div>

              <ValidatedField
                disabled={isEntityFieldFromDisabled}
                className="col-md-6"
                id="entity-relationship-appEntityFieldFrom"
                name="appEntityFieldFrom"
                data-cy="appEntityFieldFrom"
                label={translate('codeconductorApp.entityRelationship.appEntityFieldFrom')}
                type="select"
              >
                <option value="" key="0">
                  No Field Selected
                </option>
                {entityFieldsFrom
                  ? entityFieldsFrom.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                disabled={isEntityFieldToDisabled}
                className="col-md-6"
                id="entity-relationship-appEntityFieldTo"
                name="appEntityFieldTo"
                data-cy="appEntityFieldTo"
                label={translate('codeconductorApp.entityRelationship.appEntityFieldTo')}
                type="select"
              >
                <option value="" key="0">
                  No Field Selected{' '}
                </option>
                {entityFieldsTo
                  ? entityFieldsTo.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                className="col-md-6"
                id="entity-relationship-fromSuffix"
                name="fromSuffix"
                data-cy="fromSuffix"
                label={translate('codeconductorApp.entityRelationship.fromSuffix')}
                type="text"
              />

              <ValidatedField
                className="col-md-6"
                id="entity-relationship-toSuffix"
                name="toSuffix"
                data-cy="toSuffix"
                label={translate('codeconductorApp.entityRelationship.toSuffix')}
                type="text"
              />

              <Col md={'12'}>
                <Button
                  tag={Link}
                  id="cancel-save"
                  data-cy="entityCreateCancelButton"
                  to={`/product/${productId}/app/${appId}`}
                  replace
                  color="back"
                >
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="save" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </Col>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default EntityRelationshipUpdate;
