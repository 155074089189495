import React, { useEffect, useState } from 'react';
import { Accordion, Button, AccordionBody, AccordionHeader, AccordionItem, Badge, Row, Col, UncontrolledTooltip } from 'reactstrap';
import { AiGeneratedImage, InformationIcon, KeyboardIcon, MicroPhoneIcon } from '../../shared/assets/svg-icon/icon';

const ProductFeaturesList = props => {
  // console.log('props', props.AiSuggestFeatures);
  const [open, setOpen] = useState('0');
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const toggle = id => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const handleCheckboxChange = (e, feature) => {
    let isChecked = e.target.checked;
    if (isChecked) {
      setSelectedFeatures(prevFeatures => {
        props.onSelectedFeatureIdChange([...prevFeatures, feature]);
        return [...prevFeatures, feature];
      });
    } else {
      let tempArr = selectedFeatures.filter(obj => obj.productFeatureName != feature.productFeatureName);
      setSelectedFeatures(tempArr);
      props.onSelectedFeatureIdChange(tempArr);
    }
  };

  return (
    <div>
      <Row className="stepmodal">
        <div className="Information">
          <Badge color="primary" className="badgemodal">
            Product Name: <b>{props.app.name}</b>
          </Badge>
          <InformationIcon id={`info-${props.app.id}`} />
          <UncontrolledTooltip placement="top" target={`info-${props.app.id}`}>
            You can edit/update your app/product name any time
          </UncontrolledTooltip>
        </div>
        <Col md={12}>
          <h2 className="title-modal">Features</h2>
          <div className="coll-design">
            <h3>
              <AiGeneratedImage />
              AI Generated
            </h3>

            <Accordion open={open} toggle={toggle}>
              {props.AiSuggestFeatures.length > 0 &&
                props.AiSuggestFeatures.map((feature, index) => (
                  <AccordionItem key={index}>
                    <AccordionHeader targetId={index}>
                      <>
                        <input
                          type="checkbox"
                          onChange={e => {
                            handleCheckboxChange(e, feature);
                          }}
                        />
                        {feature.productFeatureName}
                      </>
                    </AccordionHeader>
                    <AccordionBody accordionId={index}>
                      <div>{feature.productFeatureDescription}</div>
                    </AccordionBody>
                  </AccordionItem>
                ))}
            </Accordion>
          </div>
        </Col>
        {/* <Col md={6} className="micr-btn-span">
          <div className="micr-btn-design">
            <Button color="info phone-design">
              <span>
                {' '}
                <MicroPhoneIcon />
              </span>{' '}
            </Button>
            <Button color="info phone-design" className="design-btn-">
              <span>
                <KeyboardIcon />{' '}
              </span>
            </Button>
          </div>
           <span>Press to fill details</span>{' '}
        </Col> */}
      </Row>
    </div>
  );
};

export default ProductFeaturesList;
