import './dependency.scss';
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { Translate, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from '../../../../../app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '../../../../../app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../config/store';
import DependencyDeleteDialog from './dependency-delete-dialog';
import { IDependency } from '../../../../../app/shared/model/dependency.model';
import { getEntities } from './dependency.reducer';
import SearchModule from '../../../app/shared/component/searchModule/SearchModule';
import { getEntities as getAppDependencies } from '../../entities/app-dependency/app-dependency.reducer';

export const Dependency = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const [deleteId, setDeleteId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const dependencyList = useAppSelector(state => state.dependency.entities);
  const loading = useAppSelector(state => state.dependency.loading);
  const totalItems = useAppSelector(state => state.dependency.totalItems);
  const [query, setQuery] = useState('');
  const appDependencyList = useAppSelector(state => state.appDependency.entities);

  const getAllEntities = (emptyQuery?: any) => {
    const queryValue = emptyQuery !== undefined ? emptyQuery : query;
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: `appId.equals=${appId}${queryValue ? `&packageName.contains=${queryValue}` : ''}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    dispatch(getAppDependencies({ query: `appId.equals=${appId}` }));
  }, []);

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleDelete = dependencyId => {
    setShowModal(true);
    setDeleteId(dependencyId);
  };
  const viewModal = modal => {
    setShowModal(modal);
  };

  const handleSyncList = () => {
    sortEntities();
  };

  return (
    <>
      <div>
        <h2 id="dependency-heading" data-cy="DependencyHeading">
          {/* <Translate contentKey="codeconductorApp.dependency.home.title">Dependencies</Translate> */}
          <Row>
            <Col md={6}>
              <SearchModule setQuery={setQuery} fetchFunction={getAllEntities} query={query} placeHolder="Package Name" />
            </Col>
          </Row>
          {/* <div className="d-flex justify-content-end">
            <Link
              to={`/product/${productId}/app/${appId}/dependency/new`}
              className="btn btn-primary jh-create-entity sss"
              id="jh-create-entity"
              data-cy="entityCreateButton"
            >
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="codeconductorApp.dependency.home.createLabel">Create new Dependency</Translate>
            </Link>
          </div> */}
        </h2>
        <div className="table-responsive">
          {dependencyList && dependencyList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th className="hand" onClick={sort('id')}>
                    <Translate contentKey="codeconductorApp.dependency.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('packageName')}>
                    <Translate contentKey="codeconductorApp.dependency.packageName">Package Name</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('declaration')}>
                    <Translate contentKey="codeconductorApp.dependency.declarations">Declaration</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {dependencyList.map((dependency, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td>
                      <Button tag={Link} to={`/product/${productId}/app/${appId}/dependency/${dependency.id}/view`} color="link" size="sm">
                        {dependency.id}
                      </Button>
                    </td>
                    <td>{dependency.packageName}</td>
                    <td className="multiline">{dependency.declarations}</td>
                    <td className="text-end">
                      <div className="btn-group flex-btn-group-container">
                        <Button
                          tag={Link}
                          to={`/product/${productId}/app/${appId}/dependency/${dependency.id}/view`}
                          color="primary"
                          size="sm"
                          data-cy="entityDetailsButton"
                        >
                          <FontAwesomeIcon icon="eye" />{' '}
                        </Button>
                        <Button
                          tag={Link}
                          to={`/product/${productId}/app/${appId}/dependency/${dependency.id}/edit`}
                          color="warning "
                          size="sm"
                          data-cy="entityEditButton"
                        >
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                        </Button>
                        <Button onClick={() => handleDelete(dependency.id)} color="danger" size="sm" data-cy="entityDeleteButton">
                          <FontAwesomeIcon icon="trash" />{' '}
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="codeconductorApp.dependency.home.notFound">No Dependencies found</Translate>
              </div>
            )
          )}
        </div>
        {totalItems ? (
          <div className={dependencyList && dependencyList.length > 0 ? 'footer-pagination' : 'd-none'}>
            <div className="justify-content-end d-flex pagination-pstn">
              <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
            </div>
            <div className="justify-content-end d-flex pagination-pstn">
              <JhiPagination
                activePage={paginationState.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={paginationState.itemsPerPage}
                totalItems={totalItems}
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {showModal && (
        <>
          {console.log(paginationState)}
          <DependencyDeleteDialog itemId={deleteId} pagination={paginationState} showModal={viewModal} />
        </>
      )}
    </>
  );
};

export default Dependency;
