import { IAppLayout } from '../../shared/model/app-layout.model';

export interface ILayoutConfig {
  id?: number;
  name?: string;
  components?: string | null;
  numberOfColumns?: number | null;
  appLayout?: IAppLayout | null;
}

export const defaultValue: Readonly<ILayoutConfig> = {};
