import { IAppAiService } from 'app/shared/model/app-ai-service.model';
import { IAppAiTool } from 'app/shared/model/app-ai-tool.model';

export interface IAppAiServiceTool {
  id?: number;
  attributeName?: string;
  appAiService?: IAppAiService | null;
  appAiTool?: IAppAiTool | null;
}

export const defaultValue: Readonly<IAppAiServiceTool> = {};
