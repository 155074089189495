import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { getSortState, isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from '../../../../../app/shared/util/date-utils';
import { mapIdList, overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../config/store';

import { IAppEndPointController } from '../../../../../app/shared/model/app-end-point-controller.model';
import { getEntities as getAppEndPointControllers } from '../../../../../app/entities/app-end-point-controller/app-end-point-controller.reducer';
import { IAppEndPoint } from '../../../../../app/shared/model/app-end-point.model';
import { RestMethod } from '../../../../../app/shared/model/enumerations/rest-method.model';
// import { getEntity, updateEntity, createEntity, reset } from '../../../../../app/entities/app-end-point/app-end-point.reducer';
import { getEntity, updateEntity, createEntity, reset } from './app-end-point.reducer';
import { ITEMS_PER_PAGE } from '../../../../../app/shared/util/pagination.constants';

export const AppEndPointUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { endpointControllerId } = useParams<'endpointControllerId'>();
  const { endpointId } = useParams<'endpointId'>();
  const id = endpointId;
  const isNew = id === undefined;

  const appEndPointControllers = useAppSelector(state => state.appEndPointController?.entities);
  const appEndPointEntity = useAppSelector(state => state.appEndPoint?.entity);
  const loading = useAppSelector(state => state.appEndPoint?.loading);
  const updating = useAppSelector(state => state.appEndPoint?.updating);
  const updateSuccess = useAppSelector(state => state.appEndPoint?.updateSuccess);
  const restMethodValues = Object.keys(RestMethod);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const handleClose = () => {
    navigate(`/product/${productId}/app/${appId}/controller/${endpointControllerId}/view`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getAppEndPointControllers({
      // sort: 'id,asc&id.equals=' + endpointControllerId       
      page: paginationState.activePage - 1,
      size: paginationState.itemsPerPage,
      sort: `${paginationState.sort},${paginationState.order}&id.equals=${endpointControllerId}`,
    }));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...appEndPointEntity,
      ...values,
      appEndPointController: appEndPointControllers.find(it => it.id.toString() === endpointControllerId.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
        method: 'GET',
        ...appEndPointEntity,
        appEndPointController: appEndPointEntity?.appEndPointController?.id,
      };

  return (
    <div className="inner-content-wrap">
      <Row className="justify-content-center">
        <Col md="12">
          <h2 id="codeconductorApp.appEndPoint.home.createOrEditLabel" className=" fs-5" data-cy="AppEndPointCreateUpdateHeading">
            <Translate contentKey="codeconductorApp.appEndPoint.home.createOrEditLabel">Create or edit a AppEndPoint</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center ">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm className="spaceLeft" defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  className="col-md-6"
                  name="id"
                  required
                  readOnly
                  id="app-end-point-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appEndPoint.path')}
                id="app-end-point-path"
                name="path"
                data-cy="path"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appEndPoint.method')}
                id="app-end-point-method"
                name="method"
                data-cy="method"
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                {restMethodValues.map(restMethod => (
                  <option value={restMethod} key={restMethod}>
                    {translate('codeconductorApp.RestMethod.' + restMethod)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appEndPoint.description')}
                id="app-end-point-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appEndPoint.pseudoCode')}
                id="app-end-point-pseudoCode"
                name="pseudoCode"
                data-cy="pseudoCode"
                type="text"
              />
              <ValidatedField
                className="col-md-6 ps-0 "
                label={translate('codeconductorApp.appEndPoint.outputsList')}
                id="app-end-point-outputsList"
                name="outputsList"
                data-cy="outputsList"
                check
                type="checkbox"
              />
              {/* <ValidatedField
                id="app-end-point-appEndPointController"
                name="appEndPointController"
                data-cy="appEndPointController"
                label={translate('codeconductorApp.appEndPoint.appEndPointController')}
                type="select"
              >
                <option value="" key="0" />
                {appEndPointControllers
                  ? appEndPointControllers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField> */}
              <Col md={12}>
                <Button
                  tag={Link}
                  id="cancel-save"
                  data-cy="entityCreateCancelButton"
                  to={`/product/${productId}/app/${appId}/controller/${endpointControllerId}/view`}
                  replace
                  color="back"
                >
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="save" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </Col>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AppEndPointUpdate;
