import { ICoreScreenComponent } from '../../shared/model/core-screen-component.model';
import { ICoreFunctionality } from '../../shared/model/core-functionality.model';
import { AppStatus } from '../../../../../app/shared/model/enumerations/app-status.model';

export interface ICoreScreen {
  id?: number;
  title?: string;
  path?: string;
  metadata?: string | null;
  template?: string | null;
  accessRoles?: string | null;
  userId?: number | null;
  description?: string | null;
  status?: AppStatus | null;
  coreScreenComponents?: ICoreScreenComponent[] | null;
  coreFunctionality?: ICoreFunctionality | null;
}

export const defaultValue: Readonly<ICoreScreen> = {};
