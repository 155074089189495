import { IAppEndPointController } from 'app/shared/model/app-end-point-controller.model';
import { IAppDomainListener } from 'app/shared/model/app-domain-listener.model';
import { IAppAiTool } from 'app/shared/model/app-ai-tool.model';
import { IAppAiService } from 'app/shared/model/app-ai-service.model';
import { IApp } from 'app/shared/model/app.model';
import { IFeature } from 'app/shared/model/feature.model';
import { IFunctionality } from 'app/shared/model/functionality.model';
import { FileExtension } from 'app/shared/model/enumerations/file-extension.model';

export interface IAppFile {
  id?: number;
  extension?: FileExtension | null;
  fileName?: string | null;
  fileContents?: string | null;
  filePath?: string | null;
  lastCommitHash?: string | null;
  appEndPointController?: IAppEndPointController | null;
  appDomainListener?: IAppDomainListener | null;
  appAiTool?: IAppAiTool | null;
  appAiService?: IAppAiService | null;
  app?: IApp | null;
  feature?: IFeature | null;
  functionality?: IFunctionality | null;
}

export const defaultValue: Readonly<IAppFile> = {};
