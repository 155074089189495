import React from 'react';
import { faker, fi } from '@faker-js/faker';
export default function fakerData(fields) {
  const [field, value] = fields.split(':');
  // Check by field name
  const fieldName = field?.toLowerCase();
  switch (true) {
    case fieldName.includes('email'):
      return faker.internet.email();
    case fieldName.includes('birthdate'):
      console.log('birthDate', fieldName);
      return formatDate(faker.date.birthdate());

    case fieldName.includes('number') || fieldName.includes('id'):
      return faker.number.int({ max: 1000000 });
    case fieldName.includes('image') || fieldName.includes('picture') || fieldName.includes('profileimage'):
      return <img src={faker.image.people(150, 150, true)} />;
    case fieldName.includes('name'):
      return faker.internet.userName();
    case fieldName.includes('bio') || fieldName.includes('message') || fieldName.includes('description'):
      return faker.lorem.lines(2);
  }
  // // Check by value type
  switch (true) {
    //     }
    case value instanceof Date || !isNaN(Date.parse(value)):
      return formatDate(faker.date.birthdate());

    case value?.toLowerCase() === 'timestamp':
      return formatDate(faker.date.birthdate());

    case value?.toLowerCase() === 'boolean':
      return faker.datatype.boolean() == true ? 'True' : 'False';
    case typeof value === 'number':
      return faker.number.int();
    default:
      return faker.word.sample();
  }

  function formatDate(date) {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options).replace(/ /g, ' ');
  }
}
