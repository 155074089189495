import { ICoreFunctionality } from 'app/shared/model/core-functionality.model';
import { JobType } from 'app/shared/model/enumerations/job-type.model';
import { JobScheduleType } from 'app/shared/model/enumerations/job-schedule-type.model';

export interface ICoreJob {
  id?: number;
  name?: string;
  type?: JobType;
  taskExecutor?: string | null;
  scheduleType?: JobScheduleType | null;
  scheduleConfig?: string | null;
  queueName?: string | null;
  description?: string | null;
  pseudoCode?: string | null;
  coreFunctionality?: ICoreFunctionality | null;
}

export const defaultValue: Readonly<ICoreJob> = {};
