export enum DomainEventType {
  LOAD = 'LOAD',

  INSERT = 'INSERT',

  UPDATE = 'UPDATE',

  DELETE = 'DELETE',

  UPSERT = 'UPSERT',
}
