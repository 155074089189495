import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from '../../../../../app/config/constants';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';

import { getEntity } from './product-journal-entry.reducer';

export const ProductJournalEntryDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { journalId } = useParams<'journalId'>();

  useEffect(() => {
    dispatch(getEntity(journalId));
  }, []);

  const productJournalEntryEntity = useAppSelector(state => state.productJournalEntry.entity);
  return (
    <div className="inner-content-wrap">
      <Row>
        <Col md="8">
          <h2 data-cy="productJournalEntryDetailsHeading" className="d-flex justify-content-between align-items-center fs-5">
            <Translate contentKey="codeconductorApp.productJournalEntry.detail.title">ProductJournalEntry</Translate>
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="id">
                <Translate contentKey="global.field.id">ID</Translate>
              </span>
            </dt>
            <dd>{productJournalEntryEntity.id}</dd>
            <dt>
              <span id="entryType">
                <Translate contentKey="codeconductorApp.productJournalEntry.entryType">Entry Type</Translate>
              </span>
            </dt>
            <dd>{productJournalEntryEntity.entryType}</dd>
            <dt>
              <span id="title">
                <Translate contentKey="codeconductorApp.productJournalEntry.title">Title</Translate>
              </span>
            </dt>
            <dd>{productJournalEntryEntity.title}</dd>
            <dt>
              <span id="details">
                <Translate contentKey="codeconductorApp.productJournalEntry.details">Details</Translate>
              </span>
            </dt>
            <dd>{productJournalEntryEntity.details}</dd>
            <dt>
              <span id="verifiedComplete">
                <Translate contentKey="codeconductorApp.productJournalEntry.verifiedComplete">Verified Complete</Translate>
              </span>
            </dt>
            <dd>{productJournalEntryEntity.verifiedComplete ? 'true' : 'false'}</dd>
            <dt>
              <span id="verifiable">
                <Translate contentKey="codeconductorApp.productJournalEntry.verifiable">Verifiable</Translate>
              </span>
            </dt>
            <dd>{productJournalEntryEntity.verifiable ? 'true' : 'false'}</dd>
            <dt>
              <span id="entryTimestamp">
                <Translate contentKey="codeconductorApp.productJournalEntry.entryTimestamp">Entry Timestamp</Translate>
              </span>
            </dt>
            <dd>
              {productJournalEntryEntity.entryTimestamp ? (
                <TextFormat value={productJournalEntryEntity.entryTimestamp} type="date" format={APP_DATE_FORMAT} />
              ) : null}
            </dd>
            <dt>
              <span id="verificationTimestamp">
                <Translate contentKey="codeconductorApp.productJournalEntry.verificationTimestamp">Verification Timestamp</Translate>
              </span>
            </dt>
            <dd>
              {productJournalEntryEntity.verificationTimestamp ? (
                <TextFormat value={productJournalEntryEntity.verificationTimestamp} type="date" format={APP_DATE_FORMAT} />
              ) : null}
            </dd>
            <dt>
              <span id="relatedAppEntityId">
                <Translate contentKey="codeconductorApp.productJournalEntry.relatedAppEntityId">Related App Entity Id</Translate>
              </span>
            </dt>
            <dd>{productJournalEntryEntity.relatedAppEntityId}</dd>
            <dt>
              <span id="relatedAppEntityRecordId">
                <Translate contentKey="codeconductorApp.productJournalEntry.relatedAppEntityRecordId">
                  Related App Entity Record Id
                </Translate>
              </span>
            </dt>
            <dd>{productJournalEntryEntity.relatedAppEntityRecordId}</dd>
            <dt>
              <span id="ticketIdentifier">
                <Translate contentKey="codeconductorApp.productJournalEntry.ticketIdentifier">Ticket Identifier</Translate>
              </span>
            </dt>
            <dd>{productJournalEntryEntity.ticketIdentifier}</dd>
            <dt>
              <span id="commitIdentifier">
                <Translate contentKey="codeconductorApp.productJournalEntry.commitIdentifier">Commit Identifier</Translate>
              </span>
            </dt>
            <dd>{productJournalEntryEntity.commitIdentifier}</dd>
            <dt>
              <span id="deletedOrRemoved">
                <Translate contentKey="codeconductorApp.productJournalEntry.deletedOrRemoved">Deleted Or Removed</Translate>
              </span>
            </dt>
            <dd>{productJournalEntryEntity.deletedOrRemoved ? 'true' : 'false'}</dd>
            <dt>
              <span id="manualEffortRequired">
                <Translate contentKey="codeconductorApp.productJournalEntry.manualEffortRequired">Manual Effort Required</Translate>
              </span>
            </dt>
            <dd>{productJournalEntryEntity.manualEffortRequired ? 'true' : 'false'}</dd>
            <dt>
              <Translate contentKey="codeconductorApp.productJournalEntry.app">App</Translate>
            </dt>
            <dd>{productJournalEntryEntity.app ? productJournalEntryEntity.app.id : ''}</dd>
            <dt>
              <Translate contentKey="codeconductorApp.productJournalEntry.product">Product</Translate>
            </dt>
            <dd>{productJournalEntryEntity.product ? productJournalEntryEntity.product.id : ''}</dd>
            <dt>
              <Translate contentKey="codeconductorApp.productJournalEntry.appEntity">App Entity</Translate>
            </dt>
            <dd>{productJournalEntryEntity.appEntity ? productJournalEntryEntity.appEntity.id : ''}</dd>
          </dl>
          <Button tag={Link} to={`/product/${productId}/app/${appId}`} replace color="back" data-cy="entityDetailsBackButton">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/product/${productId}/app/${appId}/product-journal-entry/${productJournalEntryEntity.id}/edit`}
            replace
            color="edit"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ProductJournalEntryDetail;
