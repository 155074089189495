import React, { useState, useEffect, useRef } from 'react';
import 'grapesjs/dist/css/grapes.min.css';
import grapesjs from 'grapesjs';
import layouts from './layouts/layouts.tsx';

import { faker } from '@faker-js/faker';
import themeCss from '../../../../../../../../../maincss.json';
import { title } from 'process';
import './grapeEditor.css';

type Props = {
  selectionBuilder?: any;
  selectionLoading?: Boolean;
  previewMessage?: String;
  //   setQuery?: any;
  //   placeHolder?: string;
};

const GrapeEditor = (props: Props) => {
  //   const { setQuery, fetchFunction, placeHolder } = props;
  //   const appEntities = useAppSelector(state => state.appEntity.entities);
  const [cssString, setCssString] = useState('');

  const selectorManagerDefault = {
    selectorManager: {
      appendTo: '.styles-container',
    },
  };
  const traitManagerDefault = {
    traitManager: {
      appendTo: '.traits-container',
    },
  };
  const styleManagerDefault = {
    styleManager: {
      appendTo: '.styles-container',
      sectors: [
        {
          name: 'Dimension',
          open: false,
          // Use built-in properties
          buildProps: ['width', 'min-height', 'padding'],
          // Use `properties` to define/override single property
          properties: [
            {
              // Type of the input,
              // options: integer | radio | select | color | slider | file | composite | stack
              type: 'integer',
              name: 'The width', // Label for the property
              property: 'width', // CSS property (if buildProps contains it will be extended)
              units: ['px', '%'], // Units, available only for 'integer' types
              defaults: 'auto', // Default value
              min: 0, // Min value, available only for 'integer' types
            },
          ],
        },
        {
          name: 'Extra',
          open: false,
          buildProps: ['background-color', 'box-shadow', 'custom-prop'],
          properties: [
            {
              id: 'custom-prop',
              name: 'Custom Label',
              property: 'font-size',
              type: 'select',
              defaults: '32px',
              // List of options, available only for 'select' and 'radio'  types
              options: [
                { value: '12px', name: 'Tiny' },
                { value: '18px', name: 'Medium' },
                { value: '32px', name: 'Big' },
              ],
            },
          ],
        },
      ],
    },
  };
  const panelsDefault = {
    panels: {
      defaults: [
        {
          id: 'panel-devices',
          el: '.panel__devices',
          buttons: [
            {
              id: 'device-desktop',
              label: 'D',
              command: 'set-device-desktop',
              active: true,
              togglable: false,
            },
            {
              id: 'device-mobile',
              label: 'M',
              command: 'set-device-mobile',
              togglable: false,
            },
          ],
        },
        {
          id: 'panel-switcher',
          el: '.panel__switcher',
          buttons: [
            {
              id: 'show-blocks',
              active: true,
              label: 'Blocks',
              command: 'show-blocks',
              // Once activated disable the possibility to turn it off
              togglable: false,
            },
            {
              id: 'show-layers',
              active: true,
              label: 'Layers',
              command: 'show-layers',
              // Once activated disable the possibility to turn it off
              togglable: false,
            },
            {
              id: 'show-style',
              active: true,
              label: 'Styles',
              command: 'show-styles',
              togglable: false,
            },
            {
              id: 'show-traits',
              active: true,
              label: 'Traits',
              command: 'show-traits',
              togglable: false,
            },
          ],
        },
        {
          id: 'layers',
          el: '.panel__right',
          // resizable: {
          //   maxDim: 350,
          //   minDim: 200,
          //   tc: 0, // Top handler
          //   cl: 1, // Left handler
          //   cr: 0, // Right handler
          //   bc: 0, // Bottom handler
          //   keyWidth: 'flex-basis'
          // }
        },
      ],
    },
  };
  const blockManagerDefaults = {
    blockManager: {
      appendTo: '#blocks-screens',
      blocks: [
        {
          id: 'image',
          label: 'Image',
          select: true,
          content: { type: 'image' },
          activate: true,
        },
        {
          id: 'table',
          label: 'table',
          select: true,
          content: { type: 'table' },
          activate: true,
        },
      ],
    },
  };
  const layersManagerDefaults = {
    layerManager: {
      appendTo: '.layers-container',
    },
  };
  const deviceManagerDefault = {
    deviceManager: {
      devices: [
        {
          name: 'Desktop',
          width: '', // default size
        },
        {
          name: 'Mobile',
          width: '320px', // this value will be used on canvas width
          widthMedia: '480px', // this value will be used in CSS @media
        },
      ],
    },
  };
  const grapeEditorConfigDefault = {
    container: '#gjs-screens',
    fromElement: true,
    height: '100%',
    width: '100%',
    storageManager: false,
  };

  const [grapeEditor, setGrapeEditor] = useState<any>();
  const [grapeEditorContent, setGrapeEditorContent] = useState<any>();
  const previousSelectionBuilder = useRef({});
  const [cssFilePath, setCSSFilePath] = useState<string | null>(null);
  const [builder, setBuilder] = useState(props.selectionBuilder);

  const fetchHTMLAndExtractCSS = async () => {
    try {
      // Fetch the HTML file
      const response = await fetch('/content/main.98cdd5b93ce8a47dc5fc.css'); // Adjust the path to your HTML file
      console.log('response', response);
      if (response.ok) {
        // Read the HTML content as text
        const htmlContent = await response.text();

        // Parse the HTML content
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');

        // Find the link element with rel="stylesheet"
        const linkElement = doc.querySelector('link[rel="stylesheet"]');

        // Check if the link element was found
        if (linkElement) {
          // Get the href attribute value
          const href = linkElement.getAttribute('href');

          // Set the CSS file path state
          setCSSFilePath(href);
        }
      } else {
        console.error('Failed to fetch the HTML file');
      }
    } catch (error) {
      console.error('Error fetching HTML file:', error);
    }
  };

  useEffect(() => {
    console.log('after selection console');
    if (props.selectionBuilder.length == 0 && props.previewMessage == undefined) {
      grapeEditor?.Components?.clear();
      let tmp_component = layouts.loadingComponent();
      grapeEditor?.addComponents(tmp_component);
    }
    if (props.selectionBuilder.length == 0 && props.previewMessage != undefined) {
      grapeEditor?.Components?.clear();
      let tmp_component = layouts.messageComponent(props.previewMessage);
      grapeEditor?.addComponents(tmp_component);
    }
    if (props.selectionBuilder.length > 0) {
      grapeEditor?.Components?.clear();
      setBuilder(props.selectionBuilder);
    }
  }, [props.selectionBuilder, props.previewMessage]);

  useEffect(() => {
    console.log('builder', builder);
    if (grapeEditor && builder?.length > 0) {
      setGrapeEditorContent(builder);

      previousSelectionBuilder.current = grapeEditorContent;
      // check if fields are there
      // check if html is previewed ; if yes then remove
      // render relevant template based on layout and sublayout with faker data
      // let tmp_fak = faker;
      // debugger
      // let category = {
      //   title: faker.internet.displayName(),
      // };
      // let fakeData = mocker.addGenerator('faker', faker).schema('user', category, 2).buildSync()
      // debugger;
      builder?.map(obj => {
        let fields = obj?.entityfields?.split(',');
        let relatedEntitiesData = obj?.relatedEntitiesData?.length > 0 && obj.relatedEntitiesData;
        if (obj.layout == 'list' && obj.relatedEntitiesData?.length > 0) {
          // console.log("obj.relatedEntitiesData", obj.relatedEntitiesData);
          obj.relatedEntitiesData.map(item => {
            const relatedFields = item.fields?.split(',');
            relatedFields.map(item => {
              const existingFields = fields?.includes(item);
              if (!existingFields) fields?.push(item);
            });
          });
        }
        let relatedEntitiesDetails = [];
        if (obj.layout == 'details' && obj.relatedEntitiesData?.length > 0) {
          console.log('obj.relatedEntitiesData', obj.relatedEntitiesData);
          obj.relatedEntitiesData.map(item => {
            relatedEntitiesDetails.push({
              entityName: item.relationshipData?.appEntityTo?.name,
              fields: item.fields?.split(','),
              createButtonEnable: item.createButtonEnable,
              createButtonText: item.createButtonText,
              actionComponents: item?.actionComponents,
            });
          });
        }
        // debugger
        if (fields?.length > 0 && obj.list != '') {
          let layoutId = grapeEditor?.Components.getById(`custom-${obj?.layout}-${obj?.name?.toLowerCase()}`);
          let layoutTemplateId = grapeEditor.Components.getById(`custom-${obj?.layout}-${obj?.layoutTemplate}-${obj?.name?.toLowerCase()}`);

          grapeEditor.Components.getById(`custom-header-${obj?.name?.toLowerCase()}`)?.remove();
          if (layoutId) {
            layoutId.remove();
          } else if (layoutTemplateId) {
            layoutTemplateId.remove();
          }
          // grapeEditor.Components.getById(`custom-${obj?.layout}-${obj.layoutTemplate}-${obj?.name?.toLowerCase()}`)?.remove();
          grapeEditor.addComponents(`<h1 id="custom-header-${obj?.name?.toLowerCase().replaceAll(' ', '-')}">${obj?.name}</h1>`);

          const clearEditor = () => {
            grapeEditor.Components.getById(`custom-header-${obj?.name?.toLowerCase()}`)?.remove();
            grapeEditor.Components.getById(`custom-${obj?.layout}-${obj?.name?.toLowerCase()}`)?.remove();
            grapeEditor.Components.getById(`custom-${obj?.layout}-${obj.layoutTemplate}-${obj?.name?.toLowerCase()}`)?.remove();
          };
          const clearButton = document.querySelector(`#deleteapp-${obj.entity}`);
          if (clearButton) {
            clearButton.addEventListener('click', clearEditor);
          }
          // let temp_obj = {};

          // debugger;
          let tmp_component;
          if (obj?.layout == 'list') {
            tmp_component = layouts.listLayout(obj, fields);
          } else if (obj?.layout == 'details') {
            tmp_component = layouts.detailsLayout(obj, fields, relatedEntitiesDetails);
          } else if (obj?.layout == 'form') {
            tmp_component = layouts.formLayout(obj, fields, relatedEntitiesData);
          } else if (obj?.layout == 'map') {
            tmp_component = layouts.mapLayout(obj, fields, relatedEntitiesData);
          }

          if (tmp_component != null && tmp_component != undefined) {
            // console.log('addComponent', tmp_component);
            grapeEditor.addComponents(tmp_component);
          }
          previousSelectionBuilder?.current?.map(curr => {
            let prevEntity = curr.entity;
            let prevLayout = curr.layout;
            let prevEntityLayoutTemplate = curr.layoutTemplate;
            let prevLayoutId = grapeEditor.Components.getById(`custom-${prevLayout}-${curr?.name?.toLowerCase()}`);
            let prevLayoutTemplateId = grapeEditor.Components.getById(
              `custom-${prevLayout}-${prevEntityLayoutTemplate}-${curr?.name?.toLowerCase()}`
            );

            if (
              prevEntity &&
              prevEntity == obj.entity &&
              ((prevEntityLayoutTemplate && prevEntityLayoutTemplate != obj.layoutTemplate) || (prevLayout && prevLayout != obj.layout))
            ) {
              if (prevLayoutId) {
                prevLayoutId.remove();
              } else if (prevLayoutTemplateId) {
                prevLayoutTemplateId.remove();
              }
            }
          });
        }
      });
    }
  }, [builder]);

  useEffect(() => {
    grapeEditor?.Components?.clear();
    console.log('Grape Editor Mount Clear');
  }, [grapeEditor]);

  var elems = document.querySelectorAll('.gjs-selected');
  console.log({ elems });

  useEffect(() => {
    // /home/rbpcadmin/Projects/Conductor-New/codeconductor/build/resources/main/static/index.html
    fetchHTMLAndExtractCSS();
    let editor = grapesjs.init({
      ...grapeEditorConfigDefault,
      ...styleManagerDefault,
      ...selectorManagerDefault,
      ...layersManagerDefaults,
      ...panelsDefault,
      ...blockManagerDefaults,
      ...traitManagerDefault,
      ...deviceManagerDefault,
      canvas: {
        styles: [themeCss.mainCss],
      },
    });

    editor.editor.Panels.addPanel({
      id: 'panel-top',
      el: '.panel__top',
    });
    editor.Panels.addPanel({
      id: 'basic-actions',
      el: '.panel__basic-actions',
      buttons: [
        {
          id: 'visibility',
          active: true, // active by default
          className: 'btn-toggle-borders',
          label: '<u>B</u>',
          command: 'sw-visibility', // Built-in command
        },
        {
          id: 'export',
          className: 'btn-open-export',
          label: 'Exp',
          command: 'export-template',
          context: 'export-template', // For grouping context of buttons from the same panel
        },
        {
          id: 'show-json',
          className: 'btn-show-json',
          label: 'JSON',
          context: 'show-json',
          command(editor) {
            editor.Modal.setTitle('Components JSON')
              .setContent(
                `<textarea style="width:100%; height: 250px;">
                  ${JSON.stringify(editor.getComponents())}
                </textarea>`
              )
              .open();
          },
        },
      ],
    });
    editor.on('run:export-template:before', opts => {
      console.log('Before the command run');
      if (0 /* some condition */) {
        opts.abort = 1;
      }
    });

    // stop the hoverable functionality for wraper components
    const updateAll = model => {
      model.set({ hoverable: false });
      model.get('components').each(childModel => updateAll(childModel));
    };
    updateAll(editor.DomComponents.getWrapper());

    // stop the other drag and drop functionality and also hide the toolbar buttons
    editor.on('component:selected', model => {
      model.set({
        draggable: false,
        droppable: false,
        copyable: false,
        removable: false,
        stylable: false,
        toolbar: [],
      });
    });

    // stop the select related functionality for sub components
    editor.Commands.extend('select-comp', {
      onHovered(em, component) {
        if (component && component.getEl()) {
          if (!editor.getSelected()) {
            editor.select(component);
          } else if (editor.getSelected()?.getId() !== component.getId()) {
            editor.select(component);
          }
        }
      },
    });

    // -----------------------------------------------------------------------

    editor.on('run:export-template', () => console.log('After the command run'));
    editor.on('abort:export-template', () => console.log('Command aborted'));

    editor.Commands.add('show-layers', {
      getRowEl(editor: { getContainer: () => { (): any; new (): any; closest: { (arg0: string): any; new (): any } } }) {
        return editor.getContainer().closest('.editor-row');
      },
      getLayersEl(row: { querySelector: (arg0: string) => any }) {
        return row.querySelector('.layers-container');
      },

      run(editor: any, sender: any) {
        const lmEl = this.getLayersEl(this.getRowEl(editor));
        lmEl.style.display = '';
      },
      stop(editor, sender) {
        const lmEl = this.getLayersEl(this.getRowEl(editor));
        lmEl.style.display = 'none';
      },
    });
    editor.Commands.add('show-blocks', {
      getRowEl(editor) {
        return editor.getContainer().closest('.editor-row');
      },
      getLayersEl(row) {
        return row.querySelector('.blocks-container');
      },

      run(editor, sender) {
        const lmEl = this.getLayersEl(this.getRowEl(editor));
        lmEl.style.display = '';
      },
      stop(editor, sender) {
        const lmEl = this.getLayersEl(this.getRowEl(editor));
        lmEl.style.display = 'none';
      },
    });
    editor.Commands.add('show-styles', {
      getRowEl(editor) {
        return editor.getContainer().closest('.editor-row');
      },
      getStyleEl(row) {
        return row.querySelector('.styles-container');
      },

      run(editor, sender) {
        const smEl = this.getStyleEl(this.getRowEl(editor));
        smEl.style.display = '';
      },
      stop(editor, sender) {
        const smEl = this.getStyleEl(this.getRowEl(editor));
        smEl.style.display = 'none';
      },
    });

    editor.Commands.add('show-traits', {
      getTraitsEl(editor) {
        const row = editor.getContainer().closest('.editor-row');
        return row.querySelector('.traits-container');
      },
      run(editor, sender) {
        this.getTraitsEl(editor).style.display = '';
      },
      stop(editor, sender) {
        this.getTraitsEl(editor).style.display = 'none';
      },
    });

    editor.Commands.add('set-device-desktop', {
      run: editor => editor.setDevice('Desktop'),
    });
    editor.Commands.add('set-device-mobile', {
      run: editor => editor.setDevice('Mobile'),
    });

    setGrapeEditor(editor);
  }, []);

  console.log('data for testing');
  return (
    <>
      <div className="panel__top">
        {/* <div className="panel__basic-actions"></div> */}
        <div className="panel__devices"></div>
        {/* <div className="panel__switcher"></div> */}
      </div>
      <div className="editor-row" style={{ height: '100%' }}>
        <div className="editor-canvas">
          <div id="gjs-screens"></div>
        </div>
        {/* <div className="panel__right">
          <div className="blocks-container">
            <div id="blocks-screens"></div>
          </div>
          <div className="layers-container"></div>
          <div className="styles-container"></div>
          <div className="traits-container"></div>
        </div> */}
      </div>
    </>
  );
};

export default GrapeEditor;
