import { IScreenComponent } from '../../shared/model/screen-component.model';
import { ContentType } from '../../../../../app/shared/model/enumerations/content-type.model';

export interface IScreenContent {
  id?: number;
  type?: ContentType;
  content?: string | null;
  screenComponents?: IScreenComponent[] | null;
}

export const defaultValue: Readonly<IScreenContent> = {};
