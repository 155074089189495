import React, { useEffect, useState } from 'react';
import { Button, Table, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { Translate, ValidatedField } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProductCreatedIcon } from '../../shared/assets/svg-icon/icon';

export const AiSuggestJobsModal = props => {
  const {
    aiSuggestLoading,
    aiSuggestList,
    handleOnSaveForSuggested,
    handleSuggestedDelete,
    handleAiSuggest,
    toggleAiWizard,
    showAiWizard,
    aiSuggestFor,
  } = props;

  const [userPropmt, setUserPropmt] = useState('');
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [step, setStep] = useState(1);

  const handleUserPrompt = event => {
    if (event.target) {
      setUserPropmt(event.target.value);
    } else {
      setUserPropmt('');
    }
  };

  const handleCheckboxChange = (e, job) => {
    let isChecked = e.target.checked;
    if (isChecked == true) {
      setSelectedJobs(prevJobs => [...prevJobs, job]);
    }
    if (isChecked == false) {
      let tempArr = selectedJobs.filter(obj => obj.className !== job.className);
      setSelectedJobs(tempArr);
    }
  };

  function convertClassName(fullClassName) {
    const className = fullClassName.split('.').pop();
    const readableName = className.replace(/([A-Z])/g, ' $1').trim();
    return readableName;
  }

  return (
    <>
      <Modal isOpen={showAiWizard} toggle={() => toggleAiWizard()} className="modal-lg modalDesignNew" centered>
        <ModalHeader
          //  toggle={handleClose}
          data-cy="appEntityFeatureHeading"
        >
          {!aiSuggestLoading && step == 1 && <h2 className="wizard-heading gif_heading">How Would You Like To Modify {aiSuggestFor}?</h2>}
          {!aiSuggestLoading && step == 2 && <h2 className="wizard-heading gif_heading">AI Suggested Jobs: </h2>}
        </ModalHeader>

        <ModalBody id="codeconductorApp.appEntity.feature.select">
          {step == 1 && (
            <>
              <ValidatedField
                className="col-md-12 mb-0 modal-input"
                id="userPrompt"
                name="userPrompt"
                type="textarea"
                data-cy="userPrompt"
                value={userPropmt}
                onChange={handleUserPrompt}
                placeholder="What are you looking for? (Optional)"
                validate={{
                  required: { value: true, message: 'Please enter valid prompt!!' },
                }}
              />
            </>
          )}
          {step == 2 && (
            <>
              {aiSuggestList?.length > 0 ? (
                <div className="table-responsive">
                  <Table responsive>
                    <thead className="table-primary">
                      <tr>
                        <th></th>
                        <th className="hand">
                          <Translate contentKey="codeconductorApp.appEntityField.name">Name</Translate>
                        </th>
                        <th className="hand">
                          <Translate contentKey="codeconductorApp.appEntityField.type">Type</Translate>
                        </th>
                        <th className="hand">
                          <Translate contentKey="codeconductorApp.appEntityField.description">Description</Translate>{' '}
                        </th>
                        {/* <th>
                      <Translate contentKey="codeconductorApp.appEntityField.appEntity">App Entity</Translate>{' '}
                      <FontAwesomeIcon icon="sort" />
                    </th> */}
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {aiSuggestFor == 'Jobs' &&
                        aiSuggestList?.map((job, i) => (
                          <tr key={`entity-${i}`} id={`entity-key-${job.id || job.key}`} data-cy="entityTable">
                            <td>
                              <input
                                type="checkbox"
                                value=""
                                onClick={e => {
                                  handleCheckboxChange(e, job);
                                }}
                              />
                            </td>
                            <td>{convertClassName(job.className)}</td>
                            <td>{job.jobType}</td>
                            <td>{job.jobScheduleType}</td>
                            <td className="text-end">{job.taskExecutor}</td>
                          </tr>
                        ))}
                      {aiSuggestFor == 'Listeners' &&
                        aiSuggestList?.map((listener, i) => (
                          <tr key={`entity-${i}`} id={`entity-key-${listener.id || listener.key}`} data-cy="entityTable">
                            <td>
                              <input
                                type="checkbox"
                                value=""
                                onClick={e => {
                                  handleCheckboxChange(e, listener);
                                }}
                              />
                            </td>
                            <td>{convertClassName(listener.listenerClassName)}</td>
                            <td>{convertClassName(listener.domainEventType)}</td>
                            <td>{listener.listenerDescription}</td>
                            <td className="text-end">{listener.listenerAppEntity}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              ) : aiSuggestLoading ? (
                <div className="d-flex justify-content-center">
                  <div style={{ textAlign: 'center' }}>
                    <h2 className="wizard-heading gif_heading">Looking For {aiSuggestFor}</h2>
                    <img className="modalIimg-dgn" src="themeContent/entitiesloader.gif" />
                  </div>
                </div>
              ) : (
                <div className="alert alert-warning">No AI suggested {aiSuggestFor} Found</div>
              )}
            </>
          )}

          {step == 3 && (
            <>
              <div className="modal-success">
                <ProductCreatedIcon />
                <h2>Your {aiSuggestFor} has been added Successfully.</h2>
                <Button color="save" onClick={() => toggleAiWizard()}>
                  Let's Review
                </Button>
              </div>
            </>
          )}
        </ModalBody>
        {!aiSuggestLoading && step !== 3 && (
          <ModalFooter>
            <Button
              disabled={step == 2 && selectedJobs.length == 0}
              color="save"
              onClick={async () => {
                if (step == 1) {
                  handleAiSuggest(userPropmt);
                  setStep(step => step + 1);
                } else {
                  await handleOnSaveForSuggested(selectedJobs);
                  setStep(step => step + 1);
                }
              }}
            >
              Next
            </Button>
          </ModalFooter>
        )}
      </Modal>
    </>
  );
};

export default AiSuggestJobsModal;
