import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Nav, NavItem, NavLink, Table } from 'reactstrap';
import { Translate, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from '../../../../../app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '../../../../../app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../config/store';
import AppDependencyDeleteDialog from './app-dependency-delete-dialog';
import { IAppDependency } from '../../shared/model/app-dependency.model';
import { getEntities } from './app-dependency.reducer';
import Dependency from '../dependency/dependency';

export const AppDependency = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const [deleteId, setDeleteId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showTab, setShowTab] = useState('AppDependency');

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const appDependencyList = useAppSelector(state => state.appDependency.entities);
  const loading = useAppSelector(state => state.appDependency.loading);
  const totalItems = useAppSelector(state => state.appDependency.totalItems);
  const [query, setQuery] = useState('');

  const getAllEntities = (emptyQuery?: any) => {
    const queryValue = emptyQuery != undefined ? emptyQuery : query;
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: `appId.equals=${appId}${queryValue ? `&packageName.contains=${queryValue}` : ''}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    // const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    // if (location.search !== endURL) {
    //   navigate(`${location.pathname}${endURL}`);
    // }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const handleDelete = dependencyId => {
    setShowModal(true);
    setDeleteId(dependencyId);
  };
  const viewModal = modal => {
    setShowModal(modal);
  };

  return (
    <>
      <div>
        <h2 id="app-dependency-heading" data-cy="AppDependencyHeading" style={{ marginTop: '25px' }}>
          {/*<Translate contentKey="codeconductorApp.appDependency.home.title">App Dependencies</Translate> */}
          <div className="row">
            <div className="col-md-5">
              <Nav tabs className="tab-design dpndency-tab-design">
                <NavItem>
                  <NavLink herf="#" onClick={() => setShowTab('AppDependency')} active={showTab == 'AppDependency'}>
                    App Dependency
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink herf="#" onClick={() => setShowTab('DependencyList')} active={showTab == 'DependencyList'}>
                    Dependency List
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <div className="col-md-7">
              <div className="d-flex justify-content-end">
                {/* <Link
              to={`/product/${productId}/app/${appId}/dependency-list`}
              className="btn btn-primary jh-create-entity me-2"
              id="jh-create-entity"
              data-cy="entityCreateButton"
            >
              &nbsp;
              <Translate contentKey="codeconductorApp.dependency.home.list">Dependency List</Translate>
            </Link> */}
                {showTab == 'DependencyList' && (
                  <Link
                    to={`/product/${productId}/app/${appId}/dependency/new`}
                    className="btn btn-save jh-create-entity"
                    id="jh-create-entity"
                    data-cy="entityCreateButton"
                  >
                    <FontAwesomeIcon icon="plus" />
                    &nbsp;
                    <Translate contentKey="codeconductorApp.dependency.home.createLabel">Add Dependency</Translate>
                  </Link>
                )}
                {showTab == 'AppDependency' && (
                  <Link
                    to={`/product/${productId}/app/${appId}/app-dependency/new`}
                    className="btn btn-save jh-create-entity"
                    id="jh-create-entity"
                    data-cy="entityCreateButton"
                  >
                    <FontAwesomeIcon icon="plus" />
                    &nbsp;
                    <Translate contentKey="codeconductorApp.appDependency.home.createLabel">Add App Dependency</Translate>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </h2>
        {showTab == 'AppDependency' && (
          <>
            <div className="table-responsive">
              {appDependencyList && appDependencyList.length > 0 ? (
                <Table responsive>
                  <thead>
                    <tr>
                      {/* <th className="hand" onClick={sort('id')}>
                      <Translate contentKey="codeconductorApp.appDependency.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                    </th> */}
                      <th className="hand" onClick={sort('version')}>
                        <Translate contentKey="codeconductorApp.appDependency.version">Version</Translate> <FontAwesomeIcon icon="sort" />
                      </th>
                      <th>
                        <Translate contentKey="codeconductorApp.appDependency.dependency">Dependency</Translate>{' '}
                        <FontAwesomeIcon icon="sort" />
                      </th>
                      <th>
                        <Translate contentKey="codeconductorApp.appDependency.app">App</Translate> <FontAwesomeIcon icon="sort" />
                      </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {appDependencyList.map((appDependency, i) => (
                      <tr key={`entity-${i}`} data-cy="entityTable">
                        {/* <td>
                        <Button tag={Link} to={`/product/${productId}/app/${appId}/app-dependency/${appDependency.id}`} color="link" size="sm">
                          {appDependency.id}
                        </Button>
                      </td> */}
                        <td>{appDependency.version ? appDependency.version : '--'}</td>
                        <td>{appDependency.dependency ? appDependency.dependency.packageName : '--'}</td>
                        {/* <td>{appDependency.app ? <Link to={`/product/${productId}/app/${appDependency.app.id}`}>{appDependency.app.id}</Link> : ''}</td> */}
                        <td>{appDependency.app ? appDependency.app.name : '--'}</td>
                        <td className="text-end">
                          <div className="btn-group flex-btn-group-container">
                            <Button
                              tag={Link}
                              to={`/product/${productId}/app/${appId}/app-dependency/${appDependency.id}/view`}
                              color="primary"
                              size="sm"
                              data-cy="entityDetailsButton"
                            >
                              <FontAwesomeIcon icon="eye" />{' '}
                            </Button>
                            <Button
                              tag={Link}
                              to={`/product/${productId}/app/${appId}/app-dependency/${appDependency.id}/edit`}
                              color="warning"
                              size="sm"
                              data-cy="entityEditButton"
                            >
                              <FontAwesomeIcon icon="pencil-alt" />{' '}
                            </Button>
                            <Button onClick={() => handleDelete(appDependency.id)} color="danger" size="sm" data-cy="entityDeleteButton">
                              <FontAwesomeIcon icon="trash" />{' '}
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                !loading && (
                  <div className="alert alert-warning">
                    <Translate contentKey="codeconductorApp.appDependency.home.notFound">No App Dependencies found</Translate>
                  </div>
                )
              )}
            </div>
            {totalItems ? (
              <div className={appDependencyList && appDependencyList.length > 0 ? 'footer-pagination' : 'd-none'}>
                <div className="justify-content-end d-flex pagination-pstn">
                  <JhiItemCount
                    page={paginationState.activePage}
                    total={totalItems}
                    itemsPerPage={paginationState.itemsPerPage}
                    i18nEnabled
                  />
                </div>
                <div className="justify-content-end d-flex pagination-pstn">
                  <JhiPagination
                    activePage={paginationState.activePage}
                    onSelect={handlePagination}
                    maxButtons={5}
                    itemsPerPage={paginationState.itemsPerPage}
                    totalItems={totalItems}
                  />
                </div>
              </div>
            ) : (
              ''
            )}

            {showModal && <AppDependencyDeleteDialog itemId={deleteId} showModal={viewModal} />}
          </>
        )}
        {showTab == 'DependencyList' && (
          <>
            <Dependency />
          </>
        )}
      </div>
    </>
  );
};

export default AppDependency;
