import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ActionComponent from './action-component';
import ActionComponentDetail from './action-component-detail';
import ActionComponentUpdate from './action-component-update';
import ActionComponentDeleteDialog from './action-component-delete-dialog';

const ActionComponentRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ActionComponent />} />
    <Route path="new" element={<ActionComponentUpdate />} />
    <Route path=":id">
      <Route index element={<ActionComponentDetail />} />
      <Route path="edit" element={<ActionComponentUpdate />} />
      <Route path="delete" element={<ActionComponentDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ActionComponentRoutes;
