import { IProduct } from '../../shared/model/product.model';
import { DeploymentType } from '../../../../../app/shared/model/enumerations/deployment-type.model';
import { DeploymentCloud } from '../../../../../app/shared/model/enumerations/deployment-cloud.model';
import { DomainProvider } from '../../../../../app/shared/model/enumerations/domain-provider.model';

export interface IDeployment {
  id?: number;
  enabled?: boolean | null;
  deploymentType?: DeploymentType | null;
  deploymentCloud?: DeploymentCloud | null;
  envName?: string | null;
  domainName?: string | null;
  dbName?: string | null;
  deployedUrl?: string | null;
  subDomain?: string | null;
  userId?: number | null;
  cloudFlareToken?: string | null;
  dbUser?: string | null;
  cloudToken?: string | null;
  dockerRepositoryName?: string | null;
  domainProvider?: DomainProvider | null;
  dbHost?: string | null;
  credentials?: string | null;
  resourceName?: string | null;
  dbPassword?: string | null;
  resourcePath?: string | null;
  branchName?: string | null;
  domainToken?: string | null;
  appsFolders?: string | null;
  domainSecretKey?: string | null;
  appPort?: string | null;
  product?: IProduct | null;
  productid?: number | null;
  appid?: number | null;
  commitId?: number | null;
}

export const defaultValue: Readonly<IDeployment> = {
  enabled: false,
};
