import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from '../../../../../app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '../../../../../app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../config/store';

import { IJob } from '../../../../../app/shared/model/job.model';
import { createEntity, getEntities } from '../../../../../app/entities/job/job.reducer';
import { AiGeneratedImage } from '../../shared/assets/svg-icon/icon';
import AiSuggestJobsModal from '../productAllFeatures/ai-wizard-prompt';
import { suggestJobs } from '../ai-suggestor/ai-suggestor.reducer';
import { getEntities as getAppEntities } from '../../entities/app-entity/app-entity.reducer';
export const Job = () => {
  const dispatch = useAppDispatch();
  const apps = useAppSelector(state => state.app.entities);
  const app = useAppSelector(state => state.app.entity);
  const productEntity = useAppSelector(state => state.product.entity);
  const appEntities = useAppSelector(state => state.appEntity.entities);
  const jobList = useAppSelector(state => state.job?.entities);
  const loading = useAppSelector(state => state.job?.loading);
  const totalItems = useAppSelector(state => state.job?.totalItems);
  const jobEntity = useAppSelector(state => state.job?.entity);

  const location = useLocation();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const navigate = useNavigate();
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );
  const [query, setQuery] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [entitiesLoading, setEntitiesLoading] = useState(false);
  const [aiSuggestedJobs, setAiSuggestedJobs] = useState([]);
  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}&id,asc&appId.equals=${appId}`,
        // sort: 'id,asc&appId.equals=' + appId
      })
    );
  };

  const getJobEntities = (emptyQuery?: any) => {
    const queryValue = emptyQuery != undefined ? emptyQuery : query;
    dispatch(
      getAppEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        // query: `appId.equals=${appId}`,
        query: `appId.equals=${appId}${queryValue ? `&name.contains=${queryValue}` : ''}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    // const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}&appId.equals=${appId}`;
    // if (location.search !== endURL) {
    //   navigate(`${location.pathname}${endURL}`);
    // }
    getJobEntities();
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const toggleAiWizard = () => {
    setShowModal(!showModal);
    setAiSuggestedJobs([]);
  };

  const handleAiSuggest = async userPrompt => {
    setEntitiesLoading(true);
    let entitiesNamesString = appEntities
      .filter(it => it.type != 'Enum')
      .map(items => items.name)
      .join(',');
    let result = await dispatch(
      suggestJobs({
        entities: entitiesNamesString,
        description: userPrompt,
        appDescription: app.description,
      })
    );

    setAiSuggestedJobs(result?.payload?.data?.jobSuggestions);
    setEntitiesLoading(false);
  };

  function convertClassName(fullClassName) {
    // const className = fullClassName.split('.').pop();
    const readableName = fullClassName.replace(/([A-Z])/g, ' $1').trim();
    return readableName;
  }

  const handleOnSaveForSuggested = async selectedJobs => {
    setEntitiesLoading(true);
    for (const selectedJob of selectedJobs) {
      const entity = {
        name: convertClassName(selectedJob.className),
        taskExecutor: selectedJob.taskExecutor,
        scheduleType: selectedJob.jobScheduleType,
        scheduleConfig: selectedJob.scheduleConfig,
        description: selectedJob.description,
        packageName: selectedJob.packageName,
        modelDescription: selectedJob.description,
        app: app,
        type: selectedJob.jobType,
      };
      await dispatch(createEntity(entity));
    }
    getAllEntities();
    setAiSuggestedJobs([]);
    setEntitiesLoading(false);
  };

  return (
    <div>
      <h2 id="job-heading" data-cy="JobHeading" style={{ marginTop: '25px' }}>
        {/* <Translate contentKey="codeconductorApp.job.home.title">Jobs</Translate> */}
        <div className="d-flex justify-content-end">
          {/* <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="codeconductorApp.job.home.refreshListLabel">Refresh List</Translate>
          </Button> */}
          <div className="d-flex justify-content-end wizardbtn-dgn">
            <Button className="me-2" color="refresh" onClick={() => toggleAiWizard()} disabled={loading}>
              <AiGeneratedImage /> Wizard
            </Button>
          </div>
          <Link
            to={`/product/${productId}/app/${appId}/job/new`}
            className="btn btn-edit jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="codeconductorApp.job.home.createLabel">Create new Job</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {!loading && jobList && jobList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                {/* <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="codeconductorApp.job.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th> */}
                <th className="hand" onClick={sort('name')}>
                  <Translate contentKey="codeconductorApp.job.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('description')}>
                  <Translate contentKey="codeconductorApp.job.description">Description</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('type')}>
                  <Translate contentKey="codeconductorApp.job.type">Type</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                {/* <th className="hand" onClick={sort('taskExecutor')}>
                  <Translate contentKey="codeconductorApp.job.taskExecutor">Task Executor</Translate> <FontAwesomeIcon icon="sort" />
              </th> */}
                <th className="hand" onClick={sort('scheduleType')}>
                  <Translate contentKey="codeconductorApp.job.scheduleType">Schedule Type</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('scheduleConfig')}>
                  <Translate contentKey="codeconductorApp.job.scheduleConfig">Schedule Config</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('packageName')}>
                  <Translate contentKey="codeconductorApp.job.packageName">Package Name</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('modelDescription')}>
                  <Translate contentKey="codeconductorApp.job.modelDescription">Model Description</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                {/* <th className="hand" onClick={sort('queueName')}>
                  <Translate contentKey="codeconductorApp.job.queueName">Queue Name</Translate> <FontAwesomeIcon icon="sort" />
                </th> */}

                {/* <th className="hand" onClick={sort('pseudoCode')}>
                  <Translate contentKey="codeconductorApp.job.pseudoCode">Pseudo Code</Translate> <FontAwesomeIcon icon="sort" />
                </th> */}
                {/* <th>
                  <Translate contentKey="codeconductorApp.job.app">App</Translate> <FontAwesomeIcon icon="sort" />
                </th> */}
                <th />
              </tr>
            </thead>
            <tbody>
              {jobList.map((job, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  {/* <td>
                    <Button tag={Link} to={`/product/${productId}/app/${appId}/job/${job.id}`} color="link" size="sm">
                      {job.id}
                    </Button>
                  </td> */}
                  <td>{job.name ? job.name : '--'}</td>
                  <td>{job.description ? job.description : '--'}</td>
                  <td>
                    <Translate contentKey={`codeconductorApp.JobType.${job.type}`} />
                  </td>
                  {/* <td>{job.taskExecutor}</td> */}
                  <td>
                    <Translate contentKey={`codeconductorApp.JobScheduleType.${job.scheduleType}`} />
                  </td>
                  <td>{job.scheduleConfig ? job.scheduleConfig : '--'}</td>
                  <td>{job.packageName ? job.packageName : '--'}</td>
                  <td>{job.modelDescription ? job.modelDescription : '--'}</td>
                  {/* <td>{job.queueName}</td> */}

                  {/* <td>{job.pseudoCode}</td> */}
                  {/* <td>{job.app ? <Link to={`/app/${job.app.id}`}>{job.app.name}</Link> : ''}</td> */}
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/product/${productId}/app/${appId}/job/${job.id}/view`}
                        color="primary"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />
                        <span className="d-none d-md-inline"></span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/product/${productId}/app/${appId}/job/${job.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="warning"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline"></span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/product/${productId}/app/${appId}/job/${job.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline"></span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : loading ? (
          <div className="conductor_loader"></div>
        ) : (
          <div className="alert alert-warning">
            <Translate contentKey="codeconductorApp.job.home.notFound">No Jobs found</Translate>
          </div>
        )}
      </div>

      {showModal && (
        <AiSuggestJobsModal
          aiSuggestLoading={entitiesLoading}
          aiSuggestList={aiSuggestedJobs}
          showAiWizard={showModal}
          toggleAiWizard={toggleAiWizard}
          aiSuggestFor="Jobs"
          handleAiSuggest={handleAiSuggest}
          handleOnSaveForSuggested={handleOnSaveForSuggested}
        />
      )}

      {totalItems ? (
        <div className={jobList && jobList.length > 0 ? 'footer-pagination' : 'd-none'}>
          <div className="justify-content-end d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-end d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Job;
