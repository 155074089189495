import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { Translate, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from '../../../../../app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '../../../../../app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../config/store';

import { IFunctionality } from '../../../../../app/shared/model/functionality.model';
import { getEntities } from '../../../../../app/entities/functionality/functionality.reducer';
import Sidebar from '../../shared/layout/sidebar/Sidebar';

// interface AppDetails {
//   name?: string;
//   id?: string;
// }
// interface ListProps {
//   id?: string | number;
//   description?: string;
//   link?: string;
//   name?: string;
//   app?: [AppDetails];
// }
// interface Props {
//   data: ListProps[];
// }
export const Functionality = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const { id: appid } = useParams<'id'>();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const functionalityList = useAppSelector(state => state.functionality.entities);
  const loading = useAppSelector(state => state.functionality.loading);
  const totalItems = useAppSelector(state => state.functionality.totalItems);

  console.log(functionalityList);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: !!appid ? `appId.equals=${appid}` : '',
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    //console.log({ location });
    // if(appid)
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  return (
    <>
      <div className="inner-content-wrap">
        <h2 id="functionality-heading" data-cy="FunctionalityHeading" className="row mb-4 mt-3">
          <Col md="5">
            <Translate contentKey="codeconductorApp.functionality.home.title">Functionalities</Translate>
          </Col>
          <Col md="7">
            <div className="d-flex justify-content-end">
              <Button className="me-2" color="success" onClick={handleSyncList} disabled={loading}>
                <FontAwesomeIcon icon="sync" spin={loading} />{' '}
                <Translate contentKey="codeconductorApp.functionality.home.refreshListLabel">Refresh List</Translate>
              </Button>
              <Link
                to={'/functionality/new?app='}
                className="btn btn-primary jh-create-entity"
                id="jh-create-entity"
                data-cy="entityCreateButton"
              >
                <FontAwesomeIcon icon="plus" />
                &nbsp;
                <Translate contentKey="codeconductorApp.functionality.home.createLabel">Create new Functionality</Translate>
              </Link>
            </div>
          </Col>
        </h2>
        {functionalityList && functionalityList.length > 0 ? (
          <div>
            <Table responsive>
              <thead className="table-primary">
                <tr>
                  <th className="hand" onClick={sort('id')}>
                    <Translate contentKey="codeconductorApp.functionality.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('name')}>
                    <Translate contentKey="codeconductorApp.functionality.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('description')}>
                    <Translate contentKey="codeconductorApp.functionality.description">Description</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('link')}>
                    <Translate contentKey="codeconductorApp.functionality.link">Link</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    <Translate contentKey="codeconductorApp.functionality.app">App</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    <Translate contentKey="codeconductorApp.functionality.coreFunctionality">Core Functionality</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {functionalityList.map((functionality, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td>
                      <Button tag={Link} to={`/functionality/${functionality.id}`} color="link" size="sm">
                        {functionality.id}
                      </Button>
                    </td>
                    <td>{functionality.name}</td>
                    <td>{functionality.description}</td>
                    <td>{functionality.link}</td>
                    <td>{functionality.app ? <Link to={`/app/${functionality.app.id}`}>{functionality.app.name}</Link> : ''}</td>
                    <td>
                      {functionality.coreFunctionality ? (
                        <Link to={`/core-functionality/${functionality.coreFunctionality.id}`}>{functionality.coreFunctionality.name}</Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td className="text-end">
                      <div className="btn-group flex-btn-group-container">
                        <Button
                          tag={Link}
                          to={`/functionality/${functionality.id}`}
                          color="primary"
                          size="sm"
                          data-cy="entityDetailsButton"
                        >
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button
                          tag={Link}
                          to={`/functionality/${functionality.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          color="warning"
                          size="sm"
                          data-cy="entityEditButton"
                        >
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button
                          tag={Link}
                          to={`/functionality/${functionality.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          color="danger"
                          size="sm"
                          data-cy="entityDeleteButton"
                        >
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="codeconductorApp.functionality.home.notFound">No Functionalities found</Translate>
            </div>
          )
        )}
        {totalItems ? (
          <div className={functionalityList && functionalityList.length > 0 ? '' : 'd-none'}>
            <div className="justify-content-end d-flex">
              <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
            </div>
            <div className="justify-content-end d-flex">
              <JhiPagination
                activePage={paginationState.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={paginationState.itemsPerPage}
                totalItems={totalItems}
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default Functionality;
