import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, Label, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from '../../../../../app/shared/util/date-utils';
import { mapIdList } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../config/store';

import { IProduct } from '../../shared/model/product.model';
import { getEntities as getProducts } from '../../entities/product/product.reducer';
import { IApp } from '../../../../../app/shared/model/app.model';
import { ApplicationType } from '../../../../../app/shared/model/enumerations/application-type.model';
import { AppStatus } from '../../../../../app/shared/model/enumerations/app-status.model';
import { FrontendFramework } from '../../../../../app/shared/model/enumerations/frontend-framework.model';
import { BackendFramework } from '../../../../../app/shared/model/enumerations/backend-framework.model';
import { ProgrammingLanguage } from '../../../../../app/shared/model/enumerations/programming-language.model';

import { getEntity, updateEntity, createEntity, reset } from './app.reducer';

export const AppUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { appId } = useParams<'appId'>();
  const id = appId;
  const { productId } = useParams<'productId'>();
  console.log('productId', productId);
  const isNew = id === undefined;

  const products = useAppSelector(state => state.product.entities);
  const appEntity = useAppSelector(state => state.app.entity);
  const loading = useAppSelector(state => state.app.loading);
  const updating = useAppSelector(state => state.app.updating);
  const updateSuccess = useAppSelector(state => state.app.updateSuccess);
  const applicationTypeValues = Object.keys(ApplicationType);
  const chatGptSearchData = useAppSelector(state => state.chatGptSearch);
  const appStatus = Object.keys(AppStatus);
  const frontendFramework = Object.keys(FrontendFramework);
  const backendFramework = Object.keys(BackendFramework);
  const programmingLanguages = Object.keys(ProgrammingLanguage);
  const appStatusValues = Object.keys(AppStatus);
  const productEntity = useAppSelector(state => state.product.entity);

  const handleClose = () => {
    navigate(`/product/${productId}` + location.search);
  };
  // localStorage.removeItem('selectedTab');

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getProducts({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    console.log('values', values);

    const entity = {
      ...appEntity,
      ...values,
      frontendFramework: values?.frontendFramework ? values?.frontendFramework : null,
      programmingLanguage: values?.programmingLanguage ? values?.programmingLanguage : null,
      backendFramework: values?.backendFramework ? values?.backendFramework : null,
      product: { id: productId },
      // ,products.find(it => it.id === values.product || it.id === productId),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      console.log(entity);
      dispatch(updateEntity(entity));
    }
  };

  // const defaultValues = () =>
  //   isNew
  //     ? {}
  //     : {
  //       applicationType: 'MONOLITH',
  //       ...appEntity,
  //       product: appEntity?.product?.id,
  //     };

  const defaultValues = () => {
    if (isNew) {
      const emptyObj = {}; // Empty object
      if (chatGptSearchData && chatGptSearchData.entity && chatGptSearchData.entity.identifier) {
        emptyObj['name'] = chatGptSearchData.entity.identifier;
      }
      return emptyObj;
    } else {
      const newEntityObj = { ...appEntity };
      if (chatGptSearchData && chatGptSearchData.entity && chatGptSearchData.entity.newValue) {
        newEntityObj.name = chatGptSearchData.entity.newValue;
      }
      console.log(newEntityObj);
      return {
        applicationType: 'Monolith',
        ...newEntityObj,
        product: appEntity?.product?.id,
      };
    }
  };

  const handleBack = () => {
    navigate(`/product/${productId}`);
  };

  return (
    <div className="inner-content-wrap">
      <Row className="justify-content-center">
        {/* <Col md="12">
          <h2 id="codeconductorApp.app.home.createOrEditLabel" data-cy="AppCreateUpdateHeading">
            <Label>{isNew ? `Add` : ` Manage`} App</Label>
          </h2>
        </Col> */}
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/product">Products</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={`/product/${productEntity.id}`}>{productEntity.name}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>{isNew ? `Add` : ` Manage`} App</BreadcrumbItem>
        </Breadcrumb>
      </Row>
      <Row className="justify-content-center">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity} className="row">
              {!isNew
                ? // <ValidatedField
                  // className="col-md-6"
                  //   name="id"
                  //   required
                  //   readOnly
                  //   id="app-id"
                  //   label={translate('global.field.id')}
                  //   validate={{ required: true }}
                  // />
                  ''
                : null}
              <ValidatedField
                className="col-md-12 col-lg-6"
                label={translate('codeconductorApp.app.name')}
                id="app-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                className="col-md-12 col-lg-6"
                label={translate('codeconductorApp.app.baseName')}
                id="app-baseName"
                name="baseName"
                data-cy="baseName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField label={translate('codeconductorApp.app.logo')} id="app-logo" name="logo" data-cy="logo" type="text" />
              <ValidatedField
                className="col-md-12 col-lg-6"
                label={translate('codeconductorApp.app.applicationType')}
                id="app-applicationType"
                name="applicationType"
                data-cy="applicationType"
                type="select"
              >
                {applicationTypeValues.map(applicationType => (
                  <option value={ApplicationType[applicationType]} key={ApplicationType[applicationType]}>
                    {translate('codeconductorApp.ApplicationType.' + ApplicationType[applicationType])}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                className="col-md-12 col-lg-6"
                label={translate('codeconductorApp.app.gitRepoPath')}
                id="app-gitRepoPath"
                name="gitRepoPath"
                data-cy="gitRepoPath"
                type="text"
              />
              {isNew ? (
                <ValidatedField
                  className="col-md-12 col-lg-6"
                  label={translate('codeconductorApp.app.status')}
                  id="app-status"
                  name="status"
                  data-cy="status"
                  type="select"
                  // value={authenticationType}
                  // onChange={handleAuthTypeChange}
                >
                  {appStatus.map(status => (
                    <option value={AppStatus[status]} key={AppStatus[status]}>
                      {AppStatus[status]}
                      {/* {translate('codeconductorApp.AppStatus.' + status)} */}
                    </option>
                  ))}
                </ValidatedField>
              ) : (
                <ValidatedField
                  className="col-md-12 col-lg-6"
                  label={translate('codeconductorApp.app.status')}
                  id="app-status"
                  name="status"
                  data-cy="status"
                  type="select"
                >
                  {appStatus.map(status => (
                    <option value={AppStatus[status]} key={AppStatus[status]}>
                      {AppStatus[status]}
                      {/* {translate('codeconductorApp.AppStatus.' + status)} */}
                    </option>
                  ))}
                </ValidatedField>
              )}
              <ValidatedField
                className="col-md-6 col-lg-6"
                label={translate('codeconductorApp.app.packageName')}
                id="app-packageName"
                name="packageName"
                data-cy="packageName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />

              <ValidatedField
                className="col-md-6 col-lg-3"
                label={translate('codeconductorApp.app.backendFramework')}
                id="app-backendFramework"
                name="backendFramework"
                data-cy="backendFramework"
                type="select"
                // validate={{
                //   required: { value: true, message: translate('entity.validation.required') },
                // }}
              >
                <option value="" key="0">
                  {' '}
                  SELECT FRAMEWORK
                </option>
                {backendFramework.map(frame => (
                  <option value={BackendFramework[frame]} key={BackendFramework[frame]}>
                    {BackendFramework[frame]}
                    {/* {translate('codeconductorApp.AppStatus.' + status)} */}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                className="col-md-6 col-lg-3 top-space"
                label=""
                placeholder="version"
                id="app-backendFrameworkVersion"
                name="backendFrameworkVersion"
                data-cy="backendFrameworkVersion"
                type="text"
                // validate={{
                //   required: { value: true, message: translate('entity.validation.required') },
                // }}
              />

              <ValidatedField
                className="col-md-6 col-lg-3"
                label={translate('codeconductorApp.app.frontendFramework')}
                id="app-frontendFramework"
                name="frontendFramework"
                data-cy="frontendFramework"
                type="select"
                // validate={{
                //   required: { value: true, message: translate('entity.validation.required') },
                // }}
              >
                <option value="" key="0">
                  {' '}
                  SELECT FRAMEWORK
                </option>
                {frontendFramework.map(frame => (
                  <option value={FrontendFramework[frame]} key={FrontendFramework[frame]}>
                    {FrontendFramework[frame]}
                    {/* {translate('codeconductorApp.AppStatus.' + status)} */}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                className="col-md-6 col-lg-3 top-space"
                label=""
                placeholder="version"
                id="app-frontendFrameworkVersion"
                name="frontendFrameworkVersion"
                data-cy="frontendFrameworkVersion"
                type="text"
                // validate={{
                //   required: { value: true, message: translate('entity.validation.required') },
                // }}
              />
              <ValidatedField
                className="col-md-6 col-lg-3"
                label={translate('codeconductorApp.app.programmingLanguage')}
                id="app-programmingLanguage"
                name="programmingLanguage"
                data-cy="programmingLanguage"
                type="select"
                // validate={{
                //   required: { value: true, message: translate('entity.validation.required') },
                // }}
              >
                <option value="" key="0">
                  {' '}
                  Select Framework
                </option>
                {programmingLanguages.map(frame => (
                  <option value={ProgrammingLanguage[frame]} key={ProgrammingLanguage[frame]}>
                    {ProgrammingLanguage[frame]}
                    {/* {translate('codeconductorApp.AppStatus.' + status)} */}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                className="col-md-12 col-lg-3 top-space"
                placeholder="version"
                label=""
                id="app-programmingLanguageVersion"
                name="programmingLanguageVersion"
                data-cy="programmingLanguageVersion"
                type="text"
                // validate={{
                //   required: { value: true, message: translate('entity.validation.required') },
                // }}
              />
              <ValidatedField
                className="col-md-12 col-lg-6"
                label={translate('codeconductorApp.app.functionalRequirements')}
                id="app-functionalRequirements"
                name="functionalRequirements"
                data-cy="functionalRequirements"
                type="textarea"
                />
              <ValidatedField
                className="col-md-12 col-lg-6"
                label={translate('codeconductorApp.app.nonFunctionalRequirements')}
                id="app.nonFunctionalRequirements"
                name="nonFunctionalRequirements"
                data-cy="nonFunctionalRequirements"
                type="textarea"
                />

              <Col md={12}>
                <Button
                  className="m-1"
                  // tag={Link}
                  id="cancel-save"
                  data-cy="entityCreateCancelButton"
                  // to="/app"
                  onClick={handleBack}
                  replace
                  color="back"
                >
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span>
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button className="m-1" color="save" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </Col>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AppUpdate;
