import dayjs from 'dayjs';
import { IApp } from '../../shared/model/app.model';
import { IAppEntity } from '../../shared/model/app-entity.model';
import { IAppEntityField } from '../../shared/model/app-entity-field.model';
import { RelationshipType } from '../../../../../app/shared/model/enumerations/relationship-type.model';

export interface IEntityRelationship {
  id?: number;
  type?: RelationshipType;
  userId?: number | null;
  suffix?: string | null;
  lastSyncTimestamp?: string | null;
  app?: IApp | null;
  appEntityTo?: IAppEntity | null;
  appEntityFieldFrom?: IAppEntityField | null;
  appEntityFrom?: IAppEntity | null;
  appEntityFieldTo?: IAppEntityField | null;
}

export const defaultValue: Readonly<IEntityRelationship> = {};
