import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from '../../../../../app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '../../../../../app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../config/store';

import { IAppEndPointController } from '../../../../../app/shared/model/app-end-point-controller.model';
// import { getEntities } from '../../../../../app/entities/app-end-point-controller/app-end-point-controller.reducer';
import { getEntities } from './app-end-point-controller.reducer';
import AppEndPointControllerDeleteDialog from './app-end-point-controller-delete-dialog';

export const AppEndPointController = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const navigate = useNavigate();
  var [deleteId, setDeleteId] = useState('');
  const [showModal, setShowModal] = useState(false);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const appEndPointControllerList = useAppSelector(state => state.appEndPointController?.entities);
  const loading = useAppSelector(state => state.appEndPointController?.loading);
  const totalItems = useAppSelector(state => state.appEndPointController?.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}&appId.equals=${appId}`,
        // query: ``,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    // const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}&appId.equals=${appId}`;
    // if (location.search !== endURL) {
    //   navigate(`${location.pathname}${endURL}`);
    // }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const handleDelete = appEndPoint_id => {
    setShowModal(true);
    setDeleteId(appEndPoint_id);
    deleteId = appEndPoint_id;
  };

  const viewModal = modal => {
    setShowModal(modal);
  };

  return (
    <div>
      <h2 id="app-end-point-controller-heading" data-cy="AppEndPointControllerHeading" style={{ marginTop: '25px' }}>
        {/* <Translate contentKey="codeconductorApp.appEndPointController.home.title">App End Point Controllers</Translate> */}
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="codeconductorApp.appEndPointController.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link
            to={`/product/${productId}/app/${appId}/controller/new`}
            className="btn jh-create-entity btn-edit"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            {/* <Translate contentKey="codeconductorApp.appEndPointController.home.createLabel"> */}
            Add App Endpoint Controller
            {/* </Translate> */}
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {!loading && appEndPointControllerList && appEndPointControllerList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                {/* <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="codeconductorApp.appEndPointController.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th> */}
                <th className="hand" onClick={sort('packageName')}>
                  <Translate contentKey="codeconductorApp.appEndPointController.packageName">Package Name</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('className')}>
                  <Translate contentKey="codeconductorApp.appEndPointController.className">Class Name</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('description')}>
                  <Translate contentKey="codeconductorApp.appEndPointController.description">Description</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                {/* <th>
                  <Translate contentKey="codeconductorApp.appEndPointController.app">App</Translate> <FontAwesomeIcon icon="sort" />
                </th> */}
                <th />
              </tr>
            </thead>
            <tbody>
              {appEndPointControllerList.map((appEndPointController, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  {/* <td>
                    <Button tag={Link} to={`/app-end-point-controller/${appEndPointController.id}`} color="link" size="sm">
                      {appEndPointController.id}
                    </Button>
                  </td> */}
                  <td>{appEndPointController.packageName ? appEndPointController.packageName : '--'}</td>
                  <td>{appEndPointController.className ? appEndPointController.className : '--'}</td>
                  <td>{appEndPointController.description ? appEndPointController.description : '--'}</td>
                  {/* <td>
                    {appEndPointController.app ? (
                      <Link to={`/app/${appEndPointController.app.id}`}>{appEndPointController.app.name}</Link>
                    ) : (
                      ''
                    )}
                  </td> */}
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/product/${productId}/app/${appId}/controller/${appEndPointController.id}/view`}
                        color="primary"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />
                      </Button>
                      <Button
                        tag={Link}
                        to={`/product/${productId}/app/${appId}/controller/${appEndPointController.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="warning"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />
                      </Button>
                      <Button onClick={() => handleDelete(appEndPointController.id)} color="danger" size="sm" data-cy="entityDeleteButton">
                        <FontAwesomeIcon icon="trash" />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : loading ? (
          <div className="conductor_loader"></div>
        ) : (
          <div className="alert alert-warning">
            <Translate contentKey="codeconductorApp.appEndPointController.home.notFound">No App Controllers found</Translate>
          </div>
        )}
      </div>
      {totalItems ? (
        <div className={appEndPointControllerList && appEndPointControllerList.length > 0 ? 'footer-pagination' : 'd-none'}>
          <div className="justify-content-end d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-end d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
      {showModal && <AppEndPointControllerDeleteDialog endPointId={deleteId} showModal={viewModal} />}
    </div>
  );
};

export default AppEndPointController;
