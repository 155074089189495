import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  ButtonGroup,
  Col,
  Row,
  Container,
  FormGroup,
  Input,
  Spinner,
} from 'reactstrap';
import { Translate, ValidatedForm, ValidatedField, translate, getSortState } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from '../../config/store';
import { getEntities, deleteEntity } from './app-screen.reducer';
import { faXmark, faPlus } from '@fortawesome/free-solid-svg-icons';
import { getEntity, updateEntity, createEntity, reset, getEntities as getScreens } from './app-screen.reducer';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { DESC, ITEMS_PER_PAGE } from '../../../../../app/shared/util/pagination.constants';
import {
  createEntity as createScreenComponent,
  getEntities as getScreenComponents,
  deleteEntity as deleteComponent,
} from '../screen-component/screen-component.reducer';
import { getEntities as getEntityFields } from '../app-entity-field/app-entity-field.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

const AddTabsForm = props => {
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();

  // const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  // const { appScreenId } = useParams<'appScreenId'>();
  // const isNew = appScreenId === undefined;

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', DESC), location.search)
  );

  const { activeComponentData, activeScreenData, screenType } = props;

  const appScreenList = useAppSelector(state => state.appScreen.entities);
  const appScreenComponents = useAppSelector(state => state.screenComponent.entities);
  const screenComponentUpdateSuccess = useAppSelector(state => state.screenComponent.updateSuccess);
  const screenComponent = useAppSelector(state => state.screenComponent.entity);

  const [tempAppScreenList, setTempAppScreenList] = useState([]);
  const [selectedScreen, setSelectedScreen] = useState();
  const [defaultValues, setDefaultValues] = useState([]);
  const [screenComponents, setScreenComponents] = useState([]);
  // const [checkedScreensIds, setCheckedScreensIds] = useState([]);

  useEffect(() => {
    dispatch(
      getScreens({
        page: 0,
        size: 100,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: `appId.equals=${appId}`,
      })
    );
    dispatch(getScreenComponents({ query: `appScreenId.equals=${activeScreenData.id}` }));
  }, []);

  useEffect(() => {
    if (screenComponent || screenComponentUpdateSuccess) {
      dispatch(getScreenComponents({ query: `appScreenId.equals=${activeScreenData.id}` }));
    }
  }, [screenComponent, screenComponentUpdateSuccess]);

  useEffect(() => {
    setScreenComponents(appScreenComponents);
  }, [appScreenComponents]);

  useEffect(() => {
    if (appScreenList?.length > 0) {
      let tempArray = appScreenList.filter(it => it.id != activeScreenData?.id && it?.template != 'tabs');
      if (screenType !== undefined && screenType !== null) setTempAppScreenList(tempArray.filter(it => it.screenType == screenType));
    }
  }, [appScreenList]);

  useEffect(() => {
    if (selectedScreen != (undefined || null)) {
      if (selectedScreen.value == true) {
        const entity = {
          appScreen: { id: activeScreenData.id },
          appScreenviewDetail: selectedScreen.screen,
        };
        dispatch(createScreenComponent(entity));
      }
      if (selectedScreen.value == false) {
        const deleteItem = screenComponents.filter(it => it.appScreenviewDetail?.id == selectedScreen.screen.id);
        dispatch(deleteComponent(deleteItem[0].id));
      }
    }
  }, [selectedScreen]);

  useEffect(() => {
    let checkedScreensIds = [];
    screenComponents &&
      screenComponents?.length > 0 &&
      screenComponents.map(item => {
        const existingItem = checkedScreensIds.filter(it => it == item.appScreenviewDetail?.id);
        if (existingItem != undefined && existingItem.length == 0 && item.appScreenviewDetail?.id != undefined) {
          checkedScreensIds.push(item.appScreenviewDetail?.id);
          setDefaultValues(checkedScreensIds);
        }
      });
  }, [screenComponents]);

  console.log('defaultValues', defaultValues);

  return (
    <Container>
      <Col md="12">
        <Label>App Screens : </Label>
        {screenType == 'MOBILE' && defaultValues.length > 4 && !defaultValues.includes(screen.id) && (
          <Label className="maxScreensAlert">
            <FontAwesomeIcon icon={faCircleExclamation} style={{ color: '#14329d' }} /> You can add maximum 5 screens in mobile bottom bar
          </Label>
        )}
        {tempAppScreenList && tempAppScreenList.length > 0 ? (
          tempAppScreenList.map(screen => {
            return (
              <Row style={{ paddingRight: '0px', margin: '8px 0px' }}>
                <Input
                  className="col-md-2"
                  type="checkbox"
                  style={{ marginLeft: '5px', paddingRight: '0px' }}
                  checked={defaultValues.includes(screen.id)}
                  defaultChecked={selectedScreen?.value}
                  onChange={e => {
                    setSelectedScreen({ screen: screen, value: e.target.checked });
                  }}
                  disabled={screenType == 'MOBILE' && defaultValues.length > 4 && !defaultValues.includes(screen.id)}
                />
                <Label check style={{ paddingRight: '0px' }} className="col-md-10">
                  {screen.title}
                </Label>
              </Row>
            );
          })
        ) : (
          <Spinner size="sm" color="primary">
            Loading...
          </Spinner>
        )}
      </Col>
    </Container>
  );
};

export default AddTabsForm;
