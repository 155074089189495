import { ICoreAppEndPoint } from 'app/shared/model/core-app-end-point.model';
import { ICoreFunctionality } from 'app/shared/model/core-functionality.model';

export interface ICoreAppEndPointController {
  id?: number;
  packageName?: string | null;
  className?: string | null;
  description?: string | null;
  coreAppEndPoints?: ICoreAppEndPoint[] | null;
  coreFunctionality?: ICoreFunctionality | null;
}

export const defaultValue: Readonly<ICoreAppEndPointController> = {};
