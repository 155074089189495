import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from '../../../../../app/config/constants';
import { useAppDispatch, useAppSelector } from '../../config/store';

import { getEntity } from '../../../../../app/entities/app-code-blueprint/app-code-blueprint.reducer';

export const AppCodeBlueprintDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getEntity(id));
    }
  }, []);

  const appCodeBlueprintEntity = useAppSelector(state => state.appCodeBlueprint.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="appCodeBlueprintDetailsHeading">
          <Translate contentKey="codeconductorApp.appCodeBlueprint.detail.title">AppCodeBlueprint</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{appCodeBlueprintEntity.id}</dd>
          <dt>
            <span id="variableValues">
              <Translate contentKey="codeconductorApp.appCodeBlueprint.variableValues">Variable Values</Translate>
            </span>
          </dt>
          <dd>{appCodeBlueprintEntity.variableValues}</dd>
          <dt>
            <Translate contentKey="codeconductorApp.appCodeBlueprint.app">App</Translate>
          </dt>
          <dd>{appCodeBlueprintEntity.app ? appCodeBlueprintEntity.app.name : ''}</dd>
          <dt>
            <Translate contentKey="codeconductorApp.appCodeBlueprint.codeBlueprint">Code Blueprint</Translate>
          </dt>
          <dd>{appCodeBlueprintEntity.codeBlueprint ? appCodeBlueprintEntity.codeBlueprint.name : ''}</dd>
        </dl>
        <Button tag={Link} to="/app-code-blueprint" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/app-code-blueprint/${appCodeBlueprintEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default AppCodeBlueprintDetail;
