import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardLink,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Label,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { Translate, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '../../../../../app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../config/store';
import { getEntities } from './product.reducer';
import { reset, getEntities as getApps } from '../../entities/app/app.reducer';
import './product.scss';
import {
  AiGeneratedImage,
  AllProducts,
  AppsIcon,
  DeleteIcon,
  EditIcon,
  NotPublishIcon,
  ProductsIcon,
  PublishIcon,
  RightArrowIcon,
  TickMarkIcon,
} from '../../shared/assets/svg-icon/icon';
import { publishProduct } from '../customreducers/ustomReducer';
import ProductDeleteDialog from './product-delete-dialog';
import { Icons, toast } from 'react-toastify';
import { AppStatus } from '../../../../../app/shared/model/enumerations/app-status.model';
import SearchModule from '../../shared/component/searchModule/SearchModule';
import ProductModal from './productModal';

export const Product = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  var [deleteId, setDeleteId] = useState({});
  // const [productName, setProductName] = useState("")
  const [showModal, setShowModal] = useState(false);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', DESC), location.search)
  );

  const productList = useAppSelector(state => state.product.entities);
  const loading = useAppSelector(state => state.product.loading);
  const totalItems = useAppSelector(state => state.product.totalItems);
  const updateSuccess = useAppSelector(state => state.product.updateSuccess);
  const appUpdateSuccess = useAppSelector(state => state.app.updateSuccess);
  const [isPublished, setIsPublished] = useState(undefined);
  const [query, setQuery] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const getAllEntities = (emptyQuery?: any) => {
    const queryValue = emptyQuery != undefined ? emptyQuery : query;
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `id,desc`,
        query: queryValue ? `name.contains=${queryValue}&status.notEquals=${AppStatus.Deleted}` : 'status.notEquals=' + AppStatus.Deleted,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
  };

  useEffect(() => {
    // console.log('productList', productList);
  }, [productList]);

  useEffect(() => {
    sortEntities();
    dispatch(reset());
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  useEffect(() => {
    if (updateSuccess && isPublished) {
      toast.success('Product published successfully!!');
      setIsPublished(undefined);
    }
    console.log('product added!!');
    sortEntities();
  }, [updateSuccess, appUpdateSuccess]);

  const toggleProductPopup = () => {
    setModalVisible(!modalVisible);
  };

  const handleDelete = product => {
    setDeleteId(product);
    // setProductName(productName)
    setShowModal(true);
  };

  const publish = productId => {
    dispatch(publishProduct(productId));
    setIsPublished(true);
  };

  const viewModal = modal => {
    setShowModal(modal);
  };

  localStorage.removeItem('selectedTab');

  return (
    <>
      <div className="inner-content-wrap adding_spc">
        {!loading ? (
          <>
            <h2 id="product-heading" data-cy="ProductHeading" className="row g-0 page-title">
              <Col md="5">
                {/* <img src="content/images/product-icon.png" /> */}
                <Translate contentKey="codeconductorApp.product.home.title">Products</Translate>
              </Col>
              <Col md="7">
                {/* <div className="d-flex justify-content-end">
            <Button className="me-2" color="refresh" onClick={handleSyncList} disabled={loading}>
              <FontAwesomeIcon icon="sync" spin={loading} />{' '}
              <Translate contentKey="codeconductorApp.product.home.refreshListLabel">Refresh List</Translate>
            </Button>
          </div> */}
                <div className="d-flex justify-content-end wizardbtn-dgn">
                  <Button className="me-2" color="refresh" onClick={toggleProductPopup} disabled={loading}>
                    <AiGeneratedImage /> Wizard
                  </Button>
                </div>
              </Col>
            </h2>
            <div className="inner_wrap">
              <Row>
                <Col md="8">
                  <SearchModule setQuery={setQuery} fetchFunction={getAllEntities} query={query} placeHolder="Product Name" />
                </Col>
              </Row>
              <Row className="cardLayout">
                <Col md={'12'} Col sm={'12'} Col lg={'4'} Col xl={'3'} className="mb-4">
                  <Card className="newCard">
                    <Link
                      to="/product/new"
                      className="btn btn-create-new jh-create-entity"
                      id="jh-create-entity"
                      data-cy="entityCreateButton"
                    >
                      <CardBody>
                        <ProductsIcon />
                        <br />
                        <Label contentKey="codeconductorApp.product.home.createLabel">Add Product</Label>
                      </CardBody>
                    </Link>
                  </Card>
                </Col>
                {productList && productList.length > 0 ? (
                  <>
                    {productList.map((product, i) => (
                      <Col md={'12'} Col sm={'12'} Col lg={'4'} Col xl={'3'} key={`entity-${i}`} data-cy="entityTable" className="mb-4">
                        <Card>
                          <CardBody className="product-name" onClick={() => navigate(`/product/${product.id}`)}>
                            <div className="static-tittle1">
                              <CardText className="ProductImg mb-0">
                                <span style={{ width: '42px' }}>{product.name}</span>
                              </CardText>
                              <div className="aaa">
                                <CardTitle className="pb-0">{product.name}</CardTitle>
                                {/* <CardSubtitle>28 January 2024</CardSubtitle> */}
                              </div>
                            </div>
                            {/* <div className="static-tittle">
                          <AppsIcon />
                          <CardText className="fw-bolder">Apps</CardText>
                        </div> */}
                          </CardBody>
                          <div className="product-dgn">
                            {product.apps && product.apps.length > 0 ? (
                              <>
                                {product.apps
                                  .filter(app => app.status !== 'Deleted')
                                  .slice(0, 2)
                                  .map(app => (
                                    <CardText className="apps-tittle">
                                      <div className="apps-tittle-inner" onClick={() => navigate(`/product/${product.id}/app/${app.id}`)}>
                                        <AppsIcon />
                                        <span>{app.name}</span>
                                        {/* <CardSubtitle className="date-design">28 January 2024</CardSubtitle> */}
                                      </div>
                                      <div className={app.gitRepoPath ? 'done-status' : 'draft'}>
                                        {app.gitRepoPath ? (
                                          <>
                                            <a className="" href={`https://gitlab.codeconductor.ai/${app.gitRepoPath}`} target="_blank">
                                              <PublishIcon id={`published-${app.id}`} />
                                            </a>

                                            <UncontrolledTooltip placement="top" target={`published-${app.id}`}>
                                              Git Repository Path
                                            </UncontrolledTooltip>
                                          </>
                                        ) : (
                                          <>
                                            <NotPublishIcon id={`draft-${app.id}`} />
                                            <UncontrolledTooltip placement="top" target={`draft-${app.id}`}>
                                              No Code Generated for this App
                                            </UncontrolledTooltip>
                                          </>
                                        )}
                                      </div>
                                    </CardText>
                                  ))}
                              </>
                            ) : (
                              <></>
                            )}
                            {/* </div> */}
                            {product.apps && product.apps.filter(app => app.status !== 'Deleted').length > 2 && (
                              <Link className="text-right" to={`/product/${product.id}`}>
                                View More
                                <RightArrowIcon />
                              </Link>
                            )}
                          </div>

                          <div className="Apps_btn_design">
                            <Link
                              className="btn btn-edit"
                              type="button"
                              data-cy="entityEditButton"
                              to={`/product/${product.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                            >
                              {/* <FontAwesomeIcon icon="pencil-alt" />  */} Manage
                            </Link>
                            <Button onClick={() => publish(product && product.id)} color="publish" className="btn">
                              Setup
                            </Button>
                            <Button
                              className="delete-button delete-design"
                              id={`deleteproduct-${product.id}`}
                              onClick={async () => {
                                handleDelete(product);
                              }}
                            >
                              <DeleteIcon />
                            </Button>
                            <UncontrolledTooltip placement="top" target={`deleteproduct-${product.id}`}>
                              Delete
                            </UncontrolledTooltip>
                          </div>
                        </Card>
                      </Col>
                    ))}
                  </>
                ) : (
                  !loading && (
                    <div className="alert alert-warning">
                      <Translate contentKey="codeconductorApp.product.home.notFound">No Products found</Translate>
                    </div>
                  )
                )}
              </Row>
            </div>
            {totalItems ? (
              <div className={productList && productList.length > 0 ? 'footer-pagination' : 'd-none'}>
                <div className="justify-content-end d-flex">
                  <JhiItemCount
                    page={paginationState.activePage}
                    total={totalItems}
                    itemsPerPage={paginationState.itemsPerPage}
                    i18nEnabled
                  />
                </div>

                <div className="justify-content-end d-flex ">
                  <JhiPagination
                    activePage={paginationState.activePage}
                    onSelect={handlePagination}
                    maxButtons={5}
                    itemsPerPage={paginationState.itemsPerPage}
                    totalItems={totalItems}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
          </>
        ) : (
          <div className="conductor_loader"></div>
        )}
      </div>
      {showModal && <ProductDeleteDialog itemId={deleteId} showModal={viewModal} />}
      {modalVisible && <ProductModal visible={modalVisible} toggleProductPopup={toggleProductPopup} />}
    </>
  );
};

export default Product;
