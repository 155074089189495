import { IProduct } from '../../shared/model/product.model';

export interface IEmailConfiguration {
  id?: number;
  fromEmail?: string | null;
  username?: string | null;
  password?: string | null;
  host?: string | null;
  port?: number | null;
  userId?: number | null;
  product?: IProduct | null;
}

export const defaultValue: Readonly<IEmailConfiguration> = {};
