export enum ProductJournalEntryType {
  FEATURE_UPDATE = 'FEATURE_UPDATE',

  ENTITY_UPDATE = 'ENTITY_UPDATE',

  CODE_GENERATION = 'CODE_GENERATION',

  METADATA_UPDATE = 'METADATA_UPDATE',

  RELEASE_READY = 'RELEASE_READY',

  DEPLOYMENT = 'DEPLOYMENT',

  USER_UPDATE = 'USER_UPDATE',

  PRODUCT_UPDATE = 'PRODUCT_UPDATE',

  ENTITY_FIELD_UPDATE = 'ENTITY_FIELD_UPDATE',

  SCREEN_UPDATE = 'SCREEN_UPDATE',

  DEPENDENCY_UPDATE = 'DEPENDENCY_UPDATE',

  MOBILE_SCREEN_UPDATE = 'MOBILE_SCREEN_UPDATE',
}
