import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, Label, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from '../../../../../app/shared/util/date-utils';
import { mapIdList } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../config/store';

import { IApp } from '../../../../../app/shared/model/app.model';
import { getEntities as getApps } from '../../../../../app/entities/app/app.reducer';
import { IAppEndPointController } from '../../../../../app/shared/model/app-end-point-controller.model';
// import {
//   getEntity,
//   updateEntity,
//   createEntity,
//   reset,
// } from '../../../../../app/entities/app-end-point-controller/app-end-point-controller.reducer';
import { getEntity, updateEntity, createEntity, reset, } from './app-end-point-controller.reducer';

export const AppEndPointControllerUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { endpointControllerId } = useParams<'endpointControllerId'>();
  const id = endpointControllerId;
  const isNew = id === undefined;

  const apps = useAppSelector(state => state.app?.entities);
  const appEndPointControllerEntity = useAppSelector(state => state.appEndPointController?.entity);
  const loading = useAppSelector(state => state.appEndPointController?.loading);
  const updating = useAppSelector(state => state.appEndPointController?.updating);
  const updateSuccess = useAppSelector(state => state.appEndPointController?.updateSuccess);
  const productEntity = useAppSelector(state => state.product.entity);
  const appEntity = useAppSelector(state => state.app.entity);

  const handleClose = () => {
    navigate(`/product/${productId}/app/${appId}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getApps({ sort: 'id,asc&id.equals=' + appId }));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);  

  const saveEntity = values => {
    const entity = {
      ...appEndPointControllerEntity,
      ...values,
      app: apps.find(it => it.id.toString() === appId.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...appEndPointControllerEntity,
          app: appEndPointControllerEntity?.app?.id,
        };

  return (
    <div className="inner-content-wrap">
      <Row className="justify-content-center">
        {/* <Col md="12">
          <h2 id="codeconductorApp.appEndPointController.home.createOrEditLabel" data-cy="AppEndPointControllerCreateUpdateHeading">
            <Label>{isNew ? `Add` : ` Manage`} App Endpoint Controller</Label>
          </h2>
        </Col> */}
        <Col md="12">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/product">Products</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={`/product/${productEntity.id}`}>{productEntity.name}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={`/product/${productEntity.id}/app/${appEntity.id}`}>{appEntity.name}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>{isNew ? `Add` : `Manage`} App Endpoint Controller</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  className="col-md-6"
                  name="id"
                  required
                  readOnly
                  id="app-end-point-controller-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appEndPointController.packageName')}
                id="app-end-point-controller-packageName"
                name="packageName"
                data-cy="packageName"
                type="text"
                defaultValue={appEntity?.packageName ? appEntity?.packageName: ''}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appEndPointController.className')}
                id="app-end-point-controller-className"
                name="className"
                data-cy="className"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appEndPointController.description')}
                id="app-end-point-controller-description"
                name="description"
                data-cy="description"
                type="text"
              />
              {/* <ValidatedField
                id="app-end-point-controller-app"
                name="app"
                data-cy="app"
                label={translate('codeconductorApp.appEndPointController.app')}
                type="select"
              >
                <option value="" key="0" />
                {apps
                  ? apps.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField> */}
              <Col md={12}>
                <Button
                  tag={Link}
                  id="cancel-save"
                  data-cy="entityCreateCancelButton"
                  to={`/product/${productId}/app/${appId}`}
                  replace
                  color="back"
                >
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="save" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </Col>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AppEndPointControllerUpdate;
