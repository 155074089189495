import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  CardTitle,
  Col,
  Row,
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Table,
  CardSubtitle,
  CardImg,
} from 'reactstrap';
import {
  NotPublishIcon,
  ProductsIcon,
  DropdownAppIcon,
  ManageDAppIcon,
  GitAppIcon,
  DeleteIcon,
  PublishIcon,
} from '../../shared/assets/svg-icon/icon';
import { Translate, getSortState, JhiPagination, JhiItemCount, isNumber } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCode } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '../../../../../app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../config/store';
import { getEntities, updateEntities, reset, getLastCommits } from './app.reducer';
import { toast } from 'react-toastify';
import { EditIcon, JavaIcon, PythonIcon, ReactJsIcon, SpringIcon } from '../../../app/shared/assets/svg-icon/icon';
import { deployProduct } from '../customreducers/ustomReducer';
import AppDeleteDialog from './app-delete-dialog';
import { AppStatus } from '../../../../../app/shared/model/enumerations/app-status.model';
import AppGenerateDialog from './app-generate-dialog';
import { getEntities as getDeploymentEntities } from '../deployment/deployment.reducer';
import DeploymentTab from '../productAllFeatures/deployTab/DeploymentTab';
import { deployEntity } from '../../entities/deployment/deployment.reducer';
import { keysIn } from 'lodash';

import SearchModule from '../../../app/shared/component/searchModule/SearchModule';

export const App = () => {
  const dispatch = useAppDispatch();
  const { id: productId } = useParams<'id'>();
  const { productid } = useParams<'productid'>();
  const { appId } = useParams<'appId'>();
  const location = useLocation();
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState({});
  const [generateId, setGenerateId] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showGenerateModal, setShowGenerateModal] = useState(false);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', DESC), location.search)
  );

  const appList = useAppSelector(state => state.app.entities);
  const loading = useAppSelector(state => state.app.loading);
  const totalItems = useAppSelector(state => state.app.totalItems);
  const updateSuccess = useAppSelector(state => state.app.updateSuccess);
  const deployUpdating = useAppSelector(state => state.deployment.updating);
  const productEntity = useAppSelector(state => state.product.entity);
  const app = useAppSelector(state => state.app.entity);
  const deploymentList = useAppSelector(state => state.deployment.entities);
  const [query, setQuery] = useState('');
  const [appListDeploymentList, setAppListDeploymentList] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState('');
  const [envDropdownOpen, setEnvDropdownOpen] = useState('');
  const [commitList, setCommitList] = useState([]);
  const [commitsLoading, setCommitsLoading] = useState(false);

  const [dropdownOpenApp, setDropdownOpenApp] = useState('');

  useEffect(() => {
    if (!productId) navigate('/product');
  });

  const getAllEntities = (emptyQuery?: any) => {
    const queryValue = emptyQuery != undefined ? emptyQuery : query;
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `id,desc`,
        query: `productId.equals=${productId}&status.notEquals=${AppStatus.Deleted}${queryValue ? `&name.contains=${queryValue}` : ''}`,
      })
    );
  };

  const handleDeploymentEntity = async (appId, deploymentId, commitId) => {
    // let productid = Number(productId);
    // let deployId = index;
    // let deploymentEntity = deploymentList[deployId];
    const deployment = {
      id: deploymentId,
      appid: appId,
      commitId: commitId,
    };
    const { payload } = await dispatch(deployEntity(deployment));
    setEnvDropdownOpen('');
    setDropdownOpen('');
    if (payload) {
      let deploymentData = payload['data'];
      if (deploymentData?.statusCodeValue === 200) {
        toast.success('Deployment in Progess.....');
      } else {
        toast.error(deploymentData?.body);
      }
    }
  };

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  useEffect(() => {
    if (appList.length > 0 && !loading) {
      setAppListDeploymentList(appList);
      getAllDeploymentEntities();
    } else {
      setAppListDeploymentList([]);
    }
  }, [appList, loading]);

  const handleCommitList = async (id) => {
    setCommitsLoading(true)
    const { payload } = await dispatch(getLastCommits(id));
    const data = payload.data;
    setCommitsLoading(false);
    setCommitList(data);
  }

  useEffect(() => {
    if(deploymentList?.length > 0){
      handleCommitList(deploymentList[0].id);
    }
  }, [deploymentList]);

  // const updateEntity = async id => {
  //   try {
  //     const data = await dispatch(updateEntities({ id, fileName: 'updateEntities' }));
  //     if (data) toast('App Updated Successfully..!!!');
  //     await navigate('/product');
  //   } catch (error) {
  //     if (error) toast('Unable to update app. Please try again...');
  //   } finally {
  //   }
  // };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  useEffect(() => {
    if (productId) {
      getAllEntities();
    }
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);
  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleDelete = entity => {
    setShowModal(true);
    setDeleteId(entity);
  };
  const handleGenerateApp = entity => {
    setShowGenerateModal(true);
    setGenerateId(entity);
  };

  const closeModal = val => {
    setShowModal(val);
  };
  const closeGenerateModal = val => {
    setShowGenerateModal(val);
  };

  localStorage.removeItem('selectedTab');

  const getAllDeploymentEntities = async () => {
    if (appList.length > 0) {
      let tempAppList = [...appList];

      const promises = tempAppList.map(async (items: any) => {
        const { payload } = await dispatch(
          getDeploymentEntities({
            page: paginationState.activePage - 1,
            size: paginationState.itemsPerPage,
            sort: `${paginationState.sort},${paginationState.order}`,
            query: `appId.equals=${parseInt(items.id)}`,
          })
        );

        return { ...items, deploymentList: payload['data'] };
      });

      const updatedAppList = await Promise.all(promises);
      setAppListDeploymentList(updatedAppList);
    }
  };

  const toggle = appId => {
    if (appId == dropdownOpen) {
      setDropdownOpen('');
    } else {
    setDropdownOpen(appId);
    }
  };

  const toggleDeployment = deploymentId => {
    // if (deploymentId == dropdownOpen) {
    //   setEnvDropdownOpen('');
    // } else {
    setEnvDropdownOpen(deploymentId);
    // }
  }

  const toggleApp = appId => {
    if (appId == dropdownOpenApp) {
      setDropdownOpenApp('');
    } else {
      setDropdownOpenApp(appId);
    }
    // setDropdownOpenApp(prevState => !prevState);
  };

  return (
    <>
      <div>
        {!loading ? (
          <>
            <Row>
              <Col md="12" col lg="8">
                <SearchModule setQuery={setQuery} fetchFunction={getAllEntities} query={query} placeHolder="App Name" />
              </Col>
              {/* <Col md="12" col lg="5">
                <div className="justify-content-end d-flex">
                  <Button className="me-2" tag={Link} to={`/product/${productEntity.id}/edit`} replace color="edit">
                    <FontAwesomeIcon icon="pencil-alt" /> <span>Manage</span>
                  </Button>
                </div>
              </Col> */}
            </Row>
            <Row className="cardLayout">
              <Col md={'6'} sm={'12'} lg={'4'} Col xl={'3'} className="mb-3">
                <Card className="newCard prodDetailsCard">
                  <Link
                    to={`/product/${productId}/app/new`}
                    className="btn btn-create-new jh-create-entity"
                    id="jh-create-entity"
                    data-cy="entityCreateButton"
                  >
                    <CardBody>
                      <ProductsIcon />
                      <Translate contentKey="codeconductorApp.app.home.createLabel">Add App</Translate>
                    </CardBody>
                  </Link>
                </Card>
              </Col>
              {appListDeploymentList && appListDeploymentList.length > 0
                ? appListDeploymentList.map((app, i) => (
                  <Col md={'6'} sm={'12'} lg={'4'} xl={'3'} className="mb-3" key={`entity-${i}`} data-cy="entityTable">
                    <Card className="prodDetailsCard">
                      <CardBody
                        style={{ paddingBottom: '0px' }}
                        key={`entity-${i}`}
                        data-cy="entityTable"
                        className="clickable-row"
                        onClick={() => navigate(`/product/${productId}/app/${app.id}`)}
                      >
                        <div className="static-tittle1">
                          <CardText className="ProductImg mb-0">
                            <span style={{ width: '42px' }}>{app.name}</span>
                          </CardText>
                          <div className="aaa">
                            <CardTitle className="pb-0 card-de">{app.name}</CardTitle>
                            {/* <CardSubtitle>28 January 2024</CardSubtitle> */}
                          </div>
                        </div>
                        <div className="technologies-list mt-3">
                          {appListDeploymentList.programmingLanguage ? (
                            <>
                              <div id="java-icon">
                                <JavaIcon />
                              </div>
                              <UncontrolledTooltip placement="top" target={`java-icon`}>
                                {appListDeploymentList.programmingLanguage}
                              </UncontrolledTooltip>
                            </>
                          ) : (
                            <>
                              <div id="java-icon">
                                <JavaIcon />
                              </div>
                              <UncontrolledTooltip placement="top" target={`java-icon`}>
                                Java
                              </UncontrolledTooltip>
                            </>
                          )}
                          {appListDeploymentList.frontendFramework ? (
                            <>
                              <div id="react-icon">
                                <SpringIcon />
                              </div>
                              <UncontrolledTooltip placement="top" target={`react-icon`}>
                                {appListDeploymentList.frontendFramework}
                              </UncontrolledTooltip>
                            </>
                          ) : (
                            <>
                              <div id="react-icon">
                                <SpringIcon />
                              </div>
                              <UncontrolledTooltip placement="top" target={`react-icon`}>
                                React
                              </UncontrolledTooltip>
                            </>
                          )}
                          {/* <div id="spring-icon">
                          <SpringIcon />
                        </div>
                        <div id="python-icon">
                          <PythonIcon />
                        </div> */}
                        </div>
                        <div className="application-type mt-3">
                          <div>Application Type: {app.applicationType}</div>
                        </div>
                      </CardBody>

                      <div className="floating-buttons">
                        {!app.gitRepoPath ? (
                          <div className="draft">
                            <NotPublishIcon id={`draft-${app.id}`} />
                            <UncontrolledTooltip placement="top" target={`draft-${app.id}`}>
                              No code generated for this app
                            </UncontrolledTooltip>
                          </div>
                        ) : (
                          <div className="done-status">
                            <PublishIcon id={`published-${app.id}`} />
                            <UncontrolledTooltip placement="top" target={`published-${app.id}`}>
                              Code Generated
                            </UncontrolledTooltip>
                          </div>
                        )}

                        {/* {productEntity && productEntity.gitDescriptorsRepo ? (
                          <a
                            className="repo-btn btn"
                            href={`https://gitlab.redblink.net/${productEntity.gitDescriptorsRepo}`}
                            target="_blank"
                          >
                            <FontAwesomeIcon icon={faFileCode} />
                          </a>
                        ) : null}
                        <a
                          className="manage-button btn"
                          id={`manageapp-${app.id}`}
                          href={`/product/${productId}/app/${app.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        >
                          <FontAwesomeIcon icon="pencil-alt" />
                        </a>
                        <UncontrolledTooltip placement="top" target={`manageapp-${app.id}`}>
                          Manage
                        </UncontrolledTooltip>

                        <Button
                          className="del-button"
                          id={`deleteapp-${app.id}`}
                          onClick={e => {
                            handleDelete(app);
                          }}
                        >
                          <FontAwesomeIcon icon="trash" />
                        </Button>
                        <UncontrolledTooltip placement="top" target={`deleteapp-${app.id}`}>
                          Delete
                        </UncontrolledTooltip> */}
                      </div>

                      <div className="Apps_btn_design">
                        <Button onClick={() => handleGenerateApp(app.product.id)} color="manage">
                          {/* <FontAwesomeIcon icon="server" />  */}
                          Generate
                        </Button>
                        {/* <Button onClick={() => dispatch(deployProduct(app.product.id))} color="manage">
                          <FontAwesomeIcon icon="server" /> Deploy
                        </Button> */}

                        <div>
                          {app && app.deploymentList != undefined ? (
                            app?.deploymentList && app?.deploymentList.length > 0 ? (
                              <Dropdown
                                className="deplot-drop"
                                id="deploy-dropdown"
                                isOpen={app.id == dropdownOpen}
                                toggle={() => {
                                  toggle(app.id);
                                }}
                              >
                                <DropdownToggle caret color="primary">
                                  {/* <UncontrolledTooltip placement='right' target={`deploy-dropdown`}>Check environments</UncontrolledTooltip> */}
                                  Deploy
                                </DropdownToggle>
                                <DropdownMenu>
                                  {app?.deploymentList?.map((deployment, index) =>
                                  {
                                    let dditems = []
                                    let headeritem = < DropdownItem
                                    header
                                    id={`deployment-${deployment.envName}`}
                                    key={index}
                                    style={{ padding: '2px 8px', background: 'transparent' }}
                                  >
                                    {deployment['envName']}
                                  </DropdownItem>
                                  let clickitems = commitList ? commitList.map((commit, index) =>{
                                      return <DropdownItem
                                        key={index}
                                        onClick={e => {
                                          handleDeploymentEntity(app.id, deployment.id, commit.commitId);
                                        }}
                                      >
                                        {commit.commitTitle}
                                      </DropdownItem>}
                                    ) : []
                                    dditems = [headeritem, clickitems];                                    
                                   return dditems;
                                  }
                                  )}
                                </DropdownMenu>
                              </Dropdown>
                            ) : (
                              <Button id="deploy-btn" className="btn-publish">
                                <UncontrolledTooltip placement="bottom" target={`deploy-btn`}>
                                  No deployments exist
                                </UncontrolledTooltip>
                                Deploy
                              </Button>
                            )
                          ) : (
                            <div style={{ padding: '7px 21px', width: '95px', textAlign: 'center' }}>
                              <Spinner size="sm" color="primary">
                                Loading...
                              </Spinner>
                            </div>
                          )}
                        </div>

                        {/* <CardImg alt="Card image cap" src="themeContent/dot.svg" top width={15} height={15} /> */}
                        {/* <Dropdown isOpen={dropdownOpenApp}  toggle={toggleApp}> */}
                        <Dropdown
                          // id="deploy-dropdown"

                          isOpen={app.id == dropdownOpenApp}
                          toggle={() => {
                            toggleApp(app.id);
                          }}
                        >
                          <div className="dropdown-new">
                            <DropdownToggle>
                              <DropdownAppIcon />
                            </DropdownToggle>
                          </div>
                          <DropdownMenu className="app-dropdown">
                            <DropdownItem>
                              <a
                                className="manage-button "
                                id={`manageapp-${app.id}`}
                                href={`/product/${productId}/app/${app.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                              >
                                <ManageDAppIcon />
                                Manage
                              </a>
                            </DropdownItem>
                            <DropdownItem
                              className="manage-button "
                              onClick={e => {
                                handleDelete(app);
                              }}
                            >
                              <DeleteIcon />
                              Delete
                            </DropdownItem>
                            {app.gitRepoPath ? (
                              <DropdownItem>
                                <a className="manage-button" href={`https://gitlab.codeconductor.ai/${app.gitRepoPath}`} target="_blank">
                                  <GitAppIcon />
                                  Git Link
                                </a>
                              </DropdownItem>
                            ) : (
                              ''
                            )}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </Card>
                  </Col>
                ))
                : !loading && (
                  <Col md={'3'} key={`entity-noappscol`} data-cy="entityTable">
                    <Card className="noDataCard">
                      <span className="noDataTitle">
                        <h5> No Apps found</h5>
                      </span>
                    </Card>
                  </Col>
                )}
              {/* <Col md={'6'}>
              <Card>
                <CardBody>
                  <CardTitle>
                    <CardText>
                    <span>
                      <h5>ENVIRONMENTS</h5>
                    </span>
                    </CardText>
                  </CardTitle>
                  <div>                  
                  <div>
                  {deploymentList && deploymentList.length > 0 ? (
                    <Table responsive>
                      <thead className="table-primary">
                        <tr>
                          <th className="hand" onClick={sort('deploymentType')}>
                            <Translate contentKey="codeconductorApp.deployment.deploymentType">Deployment Type</Translate>
                            <FontAwesomeIcon icon="sort" />
                          </th>
                          <th className="hand" onClick={sort('envName')}>
                            <Translate contentKey="codeconductorApp.deployment.envName">Environment Name</Translate>
                            <FontAwesomeIcon icon="sort" />
                          </th>
                          <th className="hand" onClick={sort('resourceName')}>
                            <Translate contentKey="codeconductorApp.deployment.resourceName">Resource Name</Translate>
                            <FontAwesomeIcon icon="sort" />
                          </th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {deploymentList.map((deployment, i) => (
                          <tr key={`entity-${i}`} data-cy="entityTable">
                            <td>{deployment.deploymentType}</td>
                            <td>{deployment.envName}</td>
                            <td>{deployment.resourceName}</td>

                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    loading ? (<div className="conductor_loader"></div>) : (
                      <div className="alert alert-warning">
                        <Translate contentKey="codeconductorApp.deployment.home.notFound">No Deployments found</Translate>
                      </div>
                    )
                  )}
                </div>
                
                </div>
                </CardBody>
              </Card>
            </Col> */}
            </Row>

            {totalItems ? (
              <div className={appList && appList.length > 0 ? 'footer-pagination' : 'd-none'}>
                {/* <div className="justify-content-center d-flex">
              <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
            </div> */}
                <div className="justify-content-end d-flex">
                  <JhiItemCount
                    page={paginationState.activePage}
                    total={totalItems}
                    itemsPerPage={paginationState.itemsPerPage}
                    i18nEnabled
                  />
                </div>
                <div className="justify-content-end d-flex  pagination-pstn">
                  <JhiPagination
                    activePage={paginationState.activePage}
                    onSelect={handlePagination}
                    maxButtons={5}
                    itemsPerPage={paginationState.itemsPerPage}
                    totalItems={totalItems}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
          </>
        ) : (
          <>
            <div className="conductor_loader"></div>
          </>
        )}
      </div >
      {showModal && <AppDeleteDialog itemId={deleteId} closeModal={closeModal} />
      }
      {showGenerateModal && <AppGenerateDialog generateId={generateId} closeGenerateModal={closeGenerateModal} />}
    </>
  );
};

export default App;
