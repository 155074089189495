import dayjs from 'dayjs';
import { ICoreEntity } from '../../shared/model/core-entity.model';

export interface ICoreEnumMember {
  id?: number;
  name?: string | null;
  lastSyncTimestamp?: string | null;
  description?: string | null;
  coreEntity?: ICoreEntity | null;
}

export const defaultValue: Readonly<ICoreEnumMember> = {};
