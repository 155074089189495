import React from 'react';
import { Button, Table, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const AiSuggestEntityFieldsModal = props => {
  const {
    aiSuggestLoading,
    aiSuggestEntityFieldsList,
    handleOnSaveForSuggested,
    handleCloseAiEntityFieldsModal,
    handleSuggestedDelete,
    entityName,
  } = props;
  //   const location = useLocation();
  //   const { productId } = useParams<'productId'>();
  //   const { appId } = useParams<'appId'>();
  //   const { appEntityId } = useParams<'appEntityId'>();
  const titleUpperCase = str => {
    let splitStr = str.split(' ').map(item => item.charAt(0).toUpperCase() + item.substring(1));
    return splitStr.join(' ');
  };
  return (
    <>
      <Modal isOpen className="modal-lg" centered>
        <ModalHeader
          //  toggle={handleClose}
          data-cy="appEntityFeatureHeading"
        >
          AI suggested entity fields for {titleUpperCase(entityName)}
        </ModalHeader>

        <ModalBody id="codeconductorApp.appEntity.feature.select">
          {aiSuggestEntityFieldsList.length > 0 ? (
            <div className="table-responsive">
              <Table responsive>
                <thead className="table-primary">
                  <tr>
                    <th className="hand">
                      <Translate contentKey="codeconductorApp.appEntityField.name">Name</Translate>
                    </th>
                    <th className="hand">
                      <Translate contentKey="codeconductorApp.appEntityField.type">Type</Translate>
                    </th>
                    <th className="hand">
                      <Translate contentKey="codeconductorApp.appEntityField.description">Description</Translate>{' '}
                    </th>
                    {/* <th>
                      <Translate contentKey="codeconductorApp.appEntityField.appEntity">App Entity</Translate>{' '}
                      <FontAwesomeIcon icon="sort" />
                    </th> */}
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {aiSuggestEntityFieldsList.map((appEntityField, i) => (
                    <tr key={`entity-${i}`} id={`entity-key-${appEntityField.id || appEntityField.key}`} data-cy="entityTable">
                      <td>{appEntityField.entityFieldName}</td>
                      <td>{appEntityField.entityFieldType.dataType}</td>
                      <td>{appEntityField.entityFieldDescription}</td>
                      <td className="text-end">
                        <div className="btn-group flex-btn-group-container">
                          <Button
                            onClick={e => {
                              handleOnSaveForSuggested(appEntityField);
                            }}
                            color="primary"
                            size="sm"
                            data-cy="entityEditButton"
                          >
                            <FontAwesomeIcon icon="save" />
                          </Button>
                          <Button
                            onClick={() => handleSuggestedDelete(appEntityField)}
                            color="danger"
                            size="sm"
                            data-cy="entityDeleteButton"
                          >
                            <FontAwesomeIcon icon="trash" />
                            <span className="d-none">
                              <Translate contentKey="entity.action.delete">Delete</Translate>
                            </span>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : aiSuggestLoading ? (
            <div className="d-flex justify-content-center">
              <div style={{ textAlign: 'center' }}>
                <Spinner className="sm-5" color="primary">
                  Loading...
                </Spinner>
              </div>
            </div>
          ) : (
            <div className="alert alert-warning">No AI suggested Entity Fields Found</div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCloseAiEntityFieldsModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AiSuggestEntityFieldsModal;
