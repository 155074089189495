import { IApp } from 'app/shared/model/app.model';
import { IAppAiService } from 'app/shared/model/app-ai-service.model';
import { EmbeddingStoreType } from 'app/shared/model/enumerations/embedding-store-type.model';

export interface IAppEmbeddingStore {
  id?: number;
  type?: EmbeddingStoreType | null;
  app?: IApp | null;
  appAiServices?: IAppAiService[] | null;
}

export const defaultValue: Readonly<IAppEmbeddingStore> = {};
