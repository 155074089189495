import dayjs from 'dayjs';
import { IApp } from '../../shared/model/app.model';

export interface IVaultConfig {
  id?: number;
  vaultToken?: string;
  secretPath?: string | null;
  policyName?: string | null;
  profile?: string | null;
  configValues?: string | null;
  userId?: number | null;
  tokenIssueDate?: string | null;
  app?: IApp | null;
}

export const defaultValue: Readonly<IVaultConfig> = {};
