import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './action-component.reducer';

export const ActionComponentDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const actionComponentEntity = useAppSelector(state => state.actionComponent.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="actionComponentDetailsHeading">
          <Translate contentKey="codeconductorApp.actionComponent.detail.title">ActionComponent</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{actionComponentEntity.id}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="codeconductorApp.actionComponent.title">Title</Translate>
            </span>
            <UncontrolledTooltip target="title">
              <Translate contentKey="codeconductorApp.actionComponent.help.title" />
            </UncontrolledTooltip>
          </dt>
          <dd>{actionComponentEntity.title}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="codeconductorApp.actionComponent.description">Description</Translate>
            </span>
            <UncontrolledTooltip target="description">
              <Translate contentKey="codeconductorApp.actionComponent.help.description" />
            </UncontrolledTooltip>
          </dt>
          <dd>{actionComponentEntity.description}</dd>
          <dt>
            <span id="redirectTo">
              <Translate contentKey="codeconductorApp.actionComponent.redirectTo">Redirect To</Translate>
            </span>
            <UncontrolledTooltip target="redirectTo">
              <Translate contentKey="codeconductorApp.actionComponent.help.redirectTo" />
            </UncontrolledTooltip>
          </dt>
          <dd>{actionComponentEntity.redirectTo}</dd>
          <dt>
            <span id="redirectUrl">
              <Translate contentKey="codeconductorApp.actionComponent.redirectUrl">Redirect Url</Translate>
            </span>
            <UncontrolledTooltip target="redirectUrl">
              <Translate contentKey="codeconductorApp.actionComponent.help.redirectUrl" />
            </UncontrolledTooltip>
          </dt>
          <dd>{actionComponentEntity.redirectUrl}</dd>
          <dt>
            <Translate contentKey="codeconductorApp.actionComponent.appEndPoint">App End Point</Translate>
          </dt>
          <dd>{actionComponentEntity.appEndPoint ? actionComponentEntity.appEndPoint.id : ''}</dd>
          <dt>
            <Translate contentKey="codeconductorApp.actionComponent.appScreen">App Screen</Translate>
          </dt>
          <dd>{actionComponentEntity.appScreen ? actionComponentEntity.appScreen.id : ''}</dd>
          <dt>
            <Translate contentKey="codeconductorApp.actionComponent.screenComponent">Screen Component</Translate>
          </dt>
          <dd>{actionComponentEntity.screenComponent ? actionComponentEntity.screenComponent.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/action-component" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/action-component/${actionComponentEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ActionComponentDetail;
