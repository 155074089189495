import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, UncontrolledTooltip } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IAppEndPoint } from 'app/shared/model/app-end-point.model';
import { getEntities as getAppEndPoints } from 'app/entities/app-end-point/app-end-point.reducer';
import { IAppScreen } from 'app/shared/model/app-screen.model';
import { getEntities as getAppScreens } from 'app/entities/app-screen/app-screen.reducer';
import { IScreenComponent } from 'app/shared/model/screen-component.model';
import { getEntities as getScreenComponents } from 'app/entities/screen-component/screen-component.reducer';
import { IActionComponent } from 'app/shared/model/action-component.model';
import { getEntity, updateEntity, createEntity, reset } from './action-component.reducer';

export const ActionComponentUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const appEndPoints = useAppSelector(state => state.appEndPoint.entities);
  const appScreens = useAppSelector(state => state.appScreen.entities);
  const screenComponents = useAppSelector(state => state.screenComponent.entities);
  const actionComponentEntity = useAppSelector(state => state.actionComponent.entity);
  const loading = useAppSelector(state => state.actionComponent.loading);
  const updating = useAppSelector(state => state.actionComponent.updating);
  const updateSuccess = useAppSelector(state => state.actionComponent.updateSuccess);

  const handleClose = () => {
    navigate('/action-component' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getAppEndPoints({}));
    dispatch(getAppScreens({}));
    dispatch(getScreenComponents({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...actionComponentEntity,
      ...values,
      appEndPoint: appEndPoints.find(it => it.id.toString() === values.appEndPoint.toString()),
      appScreen: appScreens.find(it => it.id.toString() === values.appScreen.toString()),
      screenComponent: screenComponents.find(it => it.id.toString() === values.screenComponent.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...actionComponentEntity,
          appEndPoint: actionComponentEntity?.appEndPoint?.id,
          appScreen: actionComponentEntity?.appScreen?.id,
          screenComponent: actionComponentEntity?.screenComponent?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="codeconductorApp.actionComponent.home.createOrEditLabel" data-cy="ActionComponentCreateUpdateHeading">
            <Translate contentKey="codeconductorApp.actionComponent.home.createOrEditLabel">Create or edit a ActionComponent</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="action-component-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('codeconductorApp.actionComponent.title')}
                id="action-component-title"
                name="title"
                data-cy="title"
                type="text"
              />
              <UncontrolledTooltip target="titleLabel">
                <Translate contentKey="codeconductorApp.actionComponent.help.title" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('codeconductorApp.actionComponent.description')}
                id="action-component-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <UncontrolledTooltip target="descriptionLabel">
                <Translate contentKey="codeconductorApp.actionComponent.help.description" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('codeconductorApp.actionComponent.redirectTo')}
                id="action-component-redirectTo"
                name="redirectTo"
                data-cy="redirectTo"
                type="text"
              />
              <UncontrolledTooltip target="redirectToLabel">
                <Translate contentKey="codeconductorApp.actionComponent.help.redirectTo" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('codeconductorApp.actionComponent.redirectUrl')}
                id="action-component-redirectUrl"
                name="redirectUrl"
                data-cy="redirectUrl"
                type="text"
              />
              <UncontrolledTooltip target="redirectUrlLabel">
                <Translate contentKey="codeconductorApp.actionComponent.help.redirectUrl" />
              </UncontrolledTooltip>
              <ValidatedField
                id="action-component-appEndPoint"
                name="appEndPoint"
                data-cy="appEndPoint"
                label={translate('codeconductorApp.actionComponent.appEndPoint')}
                type="select"
              >
                <option value="" key="0" />
                {appEndPoints
                  ? appEndPoints.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="action-component-appScreen"
                name="appScreen"
                data-cy="appScreen"
                label={translate('codeconductorApp.actionComponent.appScreen')}
                type="select"
              >
                <option value="" key="0" />
                {appScreens
                  ? appScreens.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="action-component-screenComponent"
                name="screenComponent"
                data-cy="screenComponent"
                label={translate('codeconductorApp.actionComponent.screenComponent')}
                type="select"
              >
                <option value="" key="0" />
                {screenComponents
                  ? screenComponents.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/action-component" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ActionComponentUpdate;
