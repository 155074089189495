import dayjs from 'dayjs';
import { IApp } from '../../shared/model/app.model';

export interface ITeamMember {
  id?: number;
  username?: string;
  email?: string | null;
  role?: string | null;
  activated?: boolean | null;
  lastSyncTimestamp?: string | null;
  userId?: number | null;
  app?: IApp | null;
}

export const defaultValue: Readonly<ITeamMember> = {
  activated: false,
};
