import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { IApp } from 'app/shared/model/app.model';
import { IProduct } from 'app/shared/model/product.model';
import { IAppEntity } from 'app/shared/model/app-entity.model';
import { ProductJournalEntryType } from 'app/shared/model/enumerations/product-journal-entry-type.model';

export interface IProductJournalEntry {
  id?: number;
  entryType?: ProductJournalEntryType;
  title?: string | null;
  details?: string | null;
  verifiedComplete?: boolean | null;
  verifiable?: boolean | null;
  entryTimestamp?: string | null;
  verificationTimestamp?: string | null;
  relatedAppEntityId?: number | null;
  relatedAppEntityRecordId?: number | null;
  ticketIdentifier?: string | null;
  commitIdentifier?: string | null;
  deletedOrRemoved?: boolean | null;
  manualEffortRequired?: boolean | null;
  user?: IUser | null;
  app?: IApp | null;
  product?: IProduct | null;
  appEntity?: IAppEntity | null;
}

export const defaultValue: Readonly<IProductJournalEntry> = {
  verifiedComplete: false,
  verifiable: false,
  deletedOrRemoved: false,
  manualEffortRequired: false,
};
