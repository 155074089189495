import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from '../../../../../app/config/constants';
import { useAppDispatch, useAppSelector } from '../../config/store';

import { getEntity } from '../../../../../app/entities/team-member/team-member.reducer';

export const TeamMemberDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getEntity(id));
    }
  }, []);

  const teamMemberEntity = useAppSelector(state => state.teamMember.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="teamMemberDetailsHeading">
          <Translate contentKey="codeconductorApp.teamMember.detail.title">TeamMember</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{teamMemberEntity.id}</dd>
          <dt>
            <span id="username">
              <Translate contentKey="codeconductorApp.teamMember.username">Username</Translate>
            </span>
          </dt>
          <dd>{teamMemberEntity.username}</dd>
          <dt>
            <span id="email">
              <Translate contentKey="codeconductorApp.teamMember.email">Email</Translate>
            </span>
          </dt>
          <dd>{teamMemberEntity.email}</dd>
          <dt>
            <span id="role">
              <Translate contentKey="codeconductorApp.teamMember.role">Role</Translate>
            </span>
          </dt>
          <dd>{teamMemberEntity.role}</dd>
          <dt>
            <span id="activated">
              <Translate contentKey="codeconductorApp.teamMember.activated">Activated</Translate>
            </span>
          </dt>
          <dd>{teamMemberEntity.activated ? 'true' : 'false'}</dd>
          <dt>
            <span id="lastSyncTimestamp">
              <Translate contentKey="codeconductorApp.teamMember.lastSyncTimestamp">Last Sync Timestamp</Translate>
            </span>
          </dt>
          <dd>
            {teamMemberEntity.lastSyncTimestamp ? (
              <TextFormat value={teamMemberEntity.lastSyncTimestamp} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="codeconductorApp.teamMember.app">App</Translate>
          </dt>
          <dd>{teamMemberEntity.app ? teamMemberEntity.app.name : ''}</dd>
        </dl>
        <Button tag={Link} to="/team-member" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/team-member/${teamMemberEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default TeamMemberDetail;
