import { useAppDispatch, useAppSelector } from '../../../config/store';
import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Row } from 'reactstrap';
import { SearchIcon } from '../../assets/svg-icon/icon';

type Props = {
  fetchFunction?: any;
  setQuery?: any;
  placeHolder?: string;
};

const SearchModule = (props: Props) => {
  const { setQuery, fetchFunction, placeHolder, query } = props;
  const appEntities = useAppSelector(state => state.appEntity.entities);
  const [reset, setReset] = useState('');

  const handleQuery = e => {
    setQuery(e.target.value);
    if (!e.target.value) {
      fetchFunction(e.target.value);
    }
  };

  const handleKeyPress = async e => {
    if (e.key === 'Enter') {
      await fetchFunction();
    }
  };

  const handleSearch = async () => {
    await fetchFunction();
  };

  const handleReset = async () => {
    setReset('reset');
    setQuery('');
  };

  useEffect(() => {
    if (reset == 'reset') {
      fetchFunction();
    }
    setReset('');
  }, [query]);

  return (
    <Row className="product_srch mb-3">
      <Col md={8}>
        <div className=" search-position">
          <Input
            key="search-input"
            type="text"
            onChange={handleQuery}
            placeholder={placeHolder ? `Search ${placeHolder}` : ''}
            onKeyDown={handleKeyPress}
            value={query}
          />
          <SearchIcon />
        </div>
      </Col>
      <Col md={4} style={{ padding: 0 }}>
        <Button size="md" className="btn-height" onClick={handleSearch} color="save">
          Search
        </Button>
        <Button size="md" style={{ marginLeft: '5px' }} className="btn-height" onClick={handleReset} color="publish">
          Reset
        </Button>
      </Col>
    </Row>
  );
};

export default SearchModule;
