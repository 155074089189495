import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';

const initialState: EntityState<any> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: {},
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

// Actions
export const extractUserIntentContext = createAsyncThunk(
  'userIntentContext/fetch_entity',
  async (userPrompt: string) => {
    const requestUrl = `api/ai/userintent?userPrompt=${userPrompt}`;
    const result = axios.post<any>(requestUrl).then(function (response) {
      return response;
    });
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice
export const ChatGptSlice = createEntitySlice({
  name: 'searchData',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(extractUserIntentContext.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(extractUserIntentContext), state => {
        state.loading = true;
      });
  },
});

export const { reset } = ChatGptSlice.actions;

// Reducer
export default ChatGptSlice.reducer;
