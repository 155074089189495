import React from 'react';
import { faker, fi } from '@faker-js/faker';
import {
  Row,
  Table,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  Button,
  UncontrolledTooltip,
  Label,
  FormGroup,
  Input,
} from 'reactstrap';
import { renderToString } from 'react-dom/server';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppsIcon, DeleteIcon, NotPublishIcon, PublishIcon } from '../../../assets/svg-icon/icon';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import fakerData from './faker-data';
// import './layouts.css';

interface Layouts {
  listLayout: Function;
  detailsLayout: Function;
  formLayout: Function;
  mapLayout: Function;
  loadingComponent: Function;
  messageComponent: Function;
  camelCaseToWords: Function;
  fakerDataType: Function;
}

const layouts: Layouts = {
  loadingComponent: () => {
    return renderToString(<div className="conductor_loader"></div>);
  },
  messageComponent: message => {
    return renderToString(
      <div className="previewMessage">
        <Label>{message}</Label>
      </div>
    );
  },
  camelCaseToWords: str => {
    const word = str?.includes(':') ? str?.substring(0, str.indexOf(':')) : str;
    return word
      ?.match(/^[a-z]+|[A-Z][a-z]*/g)
      ?.map(function (x) {
        return x[0].toUpperCase() + x.substr(1).toLowerCase();
      })
      .join(' ');
  },
  listLayout: (obj, fields) => {
    // console.log("@@@@@", obj, fields);
    // console.log('fields', fields);
    let faker_data = [...Array(10)].map(asd => {
      return faker.helpers.multiple(() => {
        let temp_obj = {};
        fields.forEach(key => {
          // console.log('fakerData(key)', fakerData(key));
          temp_obj[key] = fakerData(key);
        });
        // console.log("temp_obj", temp_obj);
        return temp_obj;
      })[0];
    });

    let temp_obj_details = {};
    let faker_data_details = faker.helpers.multiple(
      () => {
        fields.forEach(key => {
          temp_obj_details[key] = faker.lorem.words(20);
        });
        return temp_obj_details;
      },
      { count: 1 }
    );

    let template;
    if (obj && obj.layoutTemplate == 'table') {
      template = renderToString(
        // <div className='inner-content-wrap'>
        <Row
          className="my-component inner-content-wrap"
          id={'custom-' + obj.layout + '-' + obj.layoutTemplate + '-' + obj.name?.toLowerCase().replaceAll(' ', '-')}
        >
          <Col>
            <Table>
              <tr>
                {fields.map(field => (
                  <th>{field.length > 0 && field != null && layouts.camelCaseToWords(field)}</th>
                ))}
                {/* {obj.screenComponentDetails?.id ||
                  obj.appScreenviewDetail?.id ||
                  obj.screenComponentEdit?.id ||
                  obj.appScreenviewEdit?.id ? ( */}
                <th>Actions</th>
                {/* ) : (
                  ''
                )} */}
              </tr>
              {faker_data.map(fake_obj => {
                return (
                  <tr>
                    {Object.keys(fake_obj).map(fake_obj_key => {
                      return <td>{fake_obj[fake_obj_key]}</td>;
                    })}
                    <td>
                      {(obj.screenComponentDetails?.id && obj.appScreenViewType == 'Component') ||
                      (obj.appScreenviewDetail?.id && obj.appScreenViewType == 'Screen') ? (
                        <Button color="manage" className="btn btn-info">
                          Details
                        </Button>
                      ) : (
                        ''
                      )}
                      &nbsp;
                      {(obj.screenComponentEdit?.id && obj.appScreenEditType == 'Component') ||
                      (obj.appScreenviewEdit?.id && obj.appScreenEditType == 'Screen') ? (
                        <Button color="manage" className="btn btn-primary">
                          Edit
                        </Button>
                      ) : (
                        ''
                      )}
                      {obj?.actionComponents?.map(button => {
                        return <Button>{button.title}</Button>;
                      })}
                    </td>
                  </tr>
                );
              })}
            </Table>
          </Col>
        </Row>
        // </div>
      );
    } else if (obj && obj.layoutTemplate == 'card') {
      template = renderToString(
        <Row
          className="my-component inner-content-wrap cardLayout"
          id={'custom-' + obj.layout + '-' + obj.layoutTemplate + '-' + obj.name?.toLowerCase()}
        >
          {faker_data.map(fake_obj => {
            return (
              <Col
                md={'12'}
                Col
                sm={'12'}
                Col
                lg={'4'}
                Col
                xl={'3'}
                className="mb-4"
                // style={{ paddingBottom: '20px' }}
              >
                <Card className="prodDetailsCard">
                  <CardBody
                    // className="clickable-row"
                    className="product-name"
                  >
                    <Row className="static-tittle1">
                      <CardText className="ProductImg mb-0">
                        {faker.random.alpha({ count: 1, casing: 'upper', bannedChars: [''] })}
                      </CardText>
                      <CardTitle className="pb-0">
                        <CardText>{faker.commerce.productName()}</CardText>
                      </CardTitle>
                    </Row>
                  </CardBody>
                  <Row className="product-dgn">
                    <CardText className="apps-tittle">
                      {/* {fields.slice(0, 2).map(fields => { */}
                      {/* return ( */}
                      <div className="apps-tittle-inner">
                        {/* <AppsIcon /> */}
                        {/* <span>{fields.length > 0 && layouts.camelCaseToWords(fields)}</span> */}
                        <span>{faker.commerce.productDescription()}</span>
                      </div>
                      {/* ); */}
                      {/* })} */}
                    </CardText>
                  </Row>

                  {/* <CardFooter> */}
                  <div className="Apps_btn_design">
                    <Button color="manage" className="btn btn-info">
                      Details
                    </Button>
                    {/* <div className="Apps_btn_design"> */}
                    <Button color="manage" className="btn btn-primary" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon="pencil-alt" style={{ height: '16px', color: 'white', paddingRight: '10px' }} />
                      Edit
                    </Button>
                    <Button className="delete-button delete-design">
                      <DeleteIcon />
                    </Button>
                    <UncontrolledTooltip placement="top" target={`deleteproduct`}>
                      Delete
                    </UncontrolledTooltip>
                    {obj?.actionComponents?.map(button => {
                      return <Button>{button.title}</Button>;
                    })}
                  </div>
                  {/* </CardFooter> */}
                </Card>
              </Col>
            );
          })}
        </Row>
        // </div>
      );
    } else if (obj && obj.layoutTemplate == 'sidebar') {
      template = `<div class="my-component" id="custom-${obj.layout}-${obj.layoutTemplate}-${obj.name?.toLowerCase()}">
          <table>
              <tr>
              ${fields.map(field => `<th>${field}</th>`).join('')}
              </tr>
              ${faker_data
                .map(fake_obj => {
                  return `<tr>${Object.keys(fake_obj)
                    .map(fake_obj_key => `<td>${fake_obj[fake_obj_key]}</td>`)
                    .join('')}</tr>`;
                })
                .join('')}
          </table></div>`;
    }
    // console.log("listLayout", template);
    return template;
  },
  detailsLayout: (obj, fields, relatedEntitiesDetails) => {
    // console.log("@@@@@details", obj, fields);
    let temp_obj_details = {};
    let related_temp_obj_details = {};
    let faker_data_details = faker.helpers.multiple(
      () => {
        fields.forEach(key => {
          // temp_obj_details[key] = faker.lorem.words(1);
          temp_obj_details[key] = fakerData(key);
        });
        return temp_obj_details;
      },
      { count: 1 }
    );
    let related_faker_data_details = fields =>
      faker.helpers.multiple(
        () => {
          fields.forEach(key => {
            related_temp_obj_details[key] = fakerData(key);
          });
          return related_temp_obj_details;
        },
        { count: 1 }
      );
    let template;
    // console.log("relatedEntitiesDetails", relatedEntitiesDetails);

    template = renderToString(
      <Row class="my-component inner-content-wrap" id={`custom-${obj.layout}-${obj.name?.toLowerCase()}`}>
        <Col md="12">
          <Row className="justify-content-end">
            <Col md="4" className="detail-btn-col" style={{ display: 'flex', justifyContent: 'flexEnd' }}>
              <Button className="btn btn-primary">
                <FontAwesomeIcon icon="pencil-alt" style={{ height: '16px', paddingRight: '10px' }} />{' '}
                <span className="d-none d-md-inline">
                  <Label className="label">Edit</Label>
                </span>
              </Button>
              {/* </Col> */}
              {/* <Col md="2"> */}
              <Button className="btn btn-info" style={{ marginLeft: '4px' }}>
                <FontAwesomeIcon icon="arrow-left" style={{ height: '16px', paddingRight: '10px' }} />{' '}
                <span className="d-none d-md-inline">
                  <Label className="label">Back</Label>
                </span>
              </Button>
              {obj?.actionComponents?.map(button => {
                return <Button>{button.title}</Button>;
              })}
            </Col>
          </Row>
          {/* <Row> */}
          {fields.map((field, fieldIndex) => (
            <Row key={fieldIndex}>
              <Col md="3">
                <Label className="fieldName">{field.length > 0 && layouts.camelCaseToWords(field)}:</Label>
              </Col>
              <Col md="9">
                {faker_data_details.map((fake_obj, fakeObjIndex) => {
                  const fieldData = fake_obj[field];
                  // console.log('Field:', field);
                  // console.log('Field Data:', fieldData);

                  return (
                    <div key={fakeObjIndex}>
                      {fieldData && typeof fieldData === 'object' && fieldData.type === 'img' ? (
                        <img src={fieldData.props.src} alt={layouts.camelCaseToWords(field)} />
                      ) : (
                        <div>{fieldData}</div>
                      )}
                    </div>
                  );
                })}
              </Col>
            </Row>
          ))}
          {relatedEntitiesDetails?.length > 0 &&
            relatedEntitiesDetails.map(item => (
              <div>
                {item?.fields != '' && item?.fields != undefined ? (
                  <div>
                    <Label className="relatedEntityName">{layouts.camelCaseToWords(item.entityName)}:</Label>
                    <div style={{ background: '#eee', margin: '8px 4px', padding: '4px 8px' }}>
                      <Row style={{ justifyContent: 'flex-end' }}>
                        {item?.actionComponents &&
                          item?.actionComponents.length > 0 &&
                          item?.actionComponents.map(button => (
                            <Col className="createButton">
                              <Button className="btn btn-primary">
                                <span className="d-none d-md-inline">
                                  <Label className="label">{button.title}</Label>
                                </span>
                              </Button>
                            </Col>
                          ))}
                      </Row>
                      {item.fields.map((field, fieldIndex) => (
                        <Row key={fieldIndex}>
                          <Col md="3">
                            <Label className="fieldName">{field.length > 0 && layouts.camelCaseToWords(field)}:</Label>
                          </Col>
                          <Col md="9">
                            {related_faker_data_details(item.fields).map((fake_obj, fakeObjIndex) => {
                              const fieldData = fake_obj[field];
                              return (
                                <div key={fakeObjIndex}>
                                  {fieldData && typeof fieldData === 'object' && fieldData.type === 'img' ? (
                                    <img src={fieldData.props.src} alt={layouts.camelCaseToWords(field)} />
                                  ) : (
                                    <div>{fieldData}</div>
                                  )}
                                </div>
                              );
                            })}
                          </Col>
                        </Row>
                      ))}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            ))}
          {/* </Row> */}
        </Col>
      </Row>
    );
    return template;
  },
  formLayout: (obj, fields, relatedEntitiesData) => {
    // console.log("@@@@@form", obj, fields);
    let temp_obj = {};
    let faker_data = faker.helpers.multiple(
      () => {
        fields.forEach(key => {
          temp_obj[key] = fakerData(key);
        });
        // console.log("temp_obj", temp_obj);
        return temp_obj;
      },
      { count: 10 }
    );
    let faker_data_relatedEntitiesData = faker.helpers.multiple(
      () => {
        let temp_obj = {};
        relatedEntitiesData.length > 0 &&
          relatedEntitiesData?.map(item => {
            item.fields?.split(',').forEach(key => {
              temp_obj[key.substring(0, key.indexOf(':'))] = fakerData(key);
            });
          });
        return temp_obj;
      },
      { count: 6 }
    );
    // let relate
    // console.log('faker data relatedEntitiesData@@ :: ', faker_data_relatedEntitiesData);
    let template;
    template = renderToString(
      <Row class="my-component inner-content-wrap" id={`custom-${obj.layout}-${obj.name?.toLowerCase()}`}>
        {fields.map(field => (
          <FormGroup>
            <Label className="label">{field.length > 0 && layouts.camelCaseToWords(field)}</Label>
            <Input type="text" value={field.length > 0 && layouts.camelCaseToWords(field)} />
          </FormGroup>
        ))}
        {relatedEntitiesData.length > 0 &&
          relatedEntitiesData?.map(item => (
            <>
              {item.relationshipData?.type == 'ManyToOne' || item.relationshipData?.type == 'OneToOne' ? (
                <FormGroup>
                  <ValidatedField
                    label={item.relationshipData?.appEntityTo?.name}
                    id="app-status"
                    name="status"
                    data-cy="status"
                    type="select"
                  >
                    {faker_data_relatedEntitiesData.map(fake_obj =>
                      Object.keys(fake_obj).map(fake_obj_key => {
                        return (
                          <option value={fake_obj[0]} key={fake_obj[0]}>
                            {fake_obj[fake_obj_key]}
                          </option>
                        );
                      })
                    )}
                  </ValidatedField>
                </FormGroup>
              ) : item.relationshipData?.type == 'OneToMany' || item.relationshipData?.type == 'ManyToMany' ? (
                <FormGroup>
                  <Label>{item.relationshipData?.appEntityTo?.name}</Label>
                  {faker_data_relatedEntitiesData.map(fake_obj => (
                    <div style={{ display: 'block' }}>
                      <Input type="checkbox" style={{ marginLeft: '5px', marginRight: '5px' }} />
                      <Label check style={{ marginRight: '10px' }}>
                        {Object.keys(fake_obj).map(fake_obj_key => {
                          return <td>{fake_obj[fake_obj_key].substring(0, 4)}</td>;
                        })}
                      </Label>
                    </div>
                  ))}
                </FormGroup>
              ) : (
                ''
              )}
              {item.relationshipFromData && (
                <FormGroup>
                  <ValidatedField
                    label={item.relationshipFromData?.appEntityFrom?.name}
                    id="app-status"
                    name="status"
                    data-cy="status"
                    type="select"
                  >
                    {faker_data_relatedEntitiesData.map(fake_obj =>
                      Object.keys(fake_obj).map(fake_obj_key => {
                        return (
                          <option value={fake_obj[0]} key={fake_obj[0]}>
                            {fake_obj[fake_obj_key]}
                          </option>
                        );
                      })
                    )}
                  </ValidatedField>
                </FormGroup>
              )}
            </>
          ))}

        <Col md={4}>
          <Button color="save" id="save-entity" type="submit">
            <FontAwesomeIcon icon="save" style={{ height: '16px', paddingRight: '10px' }} />
            &nbsp;
            <Label className="label">Save</Label>
          </Button>
        </Col>
      </Row>
    );
    return template;
  },
  mapLayout: obj => {
    // console.log("@@@@@map", obj);
    return renderToString(
      // <div className="mapLayout">
      //   <div className="mapDiv">
      //     <Label>Map Box</Label>
      //     <p>{`${obj.mapW}(w) * ${obj.mapH}(h)`}</p>
      //     <p>{`${obj.lat}(lat) * ${obj.lang}(lang)`}</p>
      //   </div>
      // </div>

      <div className="gmap_canvas" style={{ height: '694px !important' }}>
        <iframe
          width="100%"
          height="100%"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=mohali&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ></iframe>
      </div>
    );
  },
};

export default layouts;
