import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col, FormGroup, Label, Input, Form } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from '../../../../../app/config/constants';
import { useAppDispatch, useAppSelector } from '../../config/store';

import { getEntity } from '../../../../../app/entities/app-end-point-controller/app-end-point-controller.reducer';
import AppEndPoint from '../app-end-point/app-end-point';

export const AppEndPointControllerDetail = () => {
  const dispatch = useAppDispatch();

  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { endpointControllerId } = useParams<'endpointControllerId'>();
  const id = endpointControllerId;

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getEntity(id));
    }
  }, []);

  const appEndPointControllerEntity = useAppSelector(state => state.appEndPointController.entity);
  return (
    <div className="inner-content-wrap adding_spc">
      <Row style={{ marginBottom: '20px' }}>
        <Col md="12">
          <h2 data-cy="appEndPointControllerDetailsHeading" className=" fs-5">
            <Translate contentKey="codeconductorApp.appEndPointController.detail.title">AppEndPointController</Translate>
          </h2>
          <div className="jh-entity-details" style={{ marginTop: '30px' }}>
            {/* <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{appEndPointControllerEntity.id}</dd> */}
            <Col md={6}>
              <FormGroup>
                <span id="packageName">
                  <Label contentKey="codeconductorApp.appEndPointController.packageName">Package Name</Label>
                </span>
                <Input value={appEndPointControllerEntity.packageName} disabled />
              </FormGroup>
            </Col>
            {/* <Col md="6">
          <dt>
            <span id="packageName">
              <Translate contentKey="codeconductorApp.appEndPointController.packageName">Package Name</Translate>
            </span>
          </dt>
          <dd>{appEndPointControllerEntity.packageName}</dd>
          </Col> */}
            <Col md="6">
              <FormGroup>
                <span id="className">
                  <Label contentKey="codeconductorApp.appEndPointController.className">Class Name</Label>
                </span>
                <Input value={appEndPointControllerEntity.className} disabled />
              </FormGroup>
              {/* <dt>
            <span id="className">
              <Translate contentKey="codeconductorApp.appEndPointController.className">Class Name</Translate>
            </span>
          </dt>
          <dd>{appEndPointControllerEntity.className}</dd> */}
            </Col>
            <Col md="6">
              <FormGroup>
                <span id="description">
                  <Label contentKey="codeconductorApp.appEndPointController.description">Description</Label>
                </span>
                <Input value={appEndPointControllerEntity.description} disabled />
              </FormGroup>
              {/* <dt>
            <span id="description">
              <Translate contentKey="codeconductorApp.appEndPointController.description">Description</Translate>
            </span>
          </dt>
          <dd>{appEndPointControllerEntity.description}</dd> */}
            </Col>
            <Col md="6">
              <FormGroup>
                <span id="app">
                  <Label contentKey="codeconductorApp.appEndPointController.app">App</Label>
                </span>
                <Input value={appEndPointControllerEntity.app ? appEndPointControllerEntity.app.name : ''} disabled />
              </FormGroup>
              {/* <dt>
            <Translate contentKey="codeconductorApp.appEndPointController.app">App</Translate>
          </dt>
          <dd>{appEndPointControllerEntity.app ? appEndPointControllerEntity.app.name : ''}</dd> */}
            </Col>
          </div>
          <Button tag={Link} to={`/product/${productId}/app/${appId}`} replace color="back" data-cy="entityDetailsBackButton">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          {/* <Button tag={Link} 
        to={`/product/${productId}/app/${appId}/controller/${endpointControllerId}/edit`}
        replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button> */}
        </Col>
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Col md={12}>
          <AppEndPoint />
        </Col>
      </Row>
    </div>
  );
};

export default AppEndPointControllerDetail;
