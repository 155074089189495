import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, Label } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from '../../../../../app/shared/util/date-utils';
import { mapIdList } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../config/store';

import { IAppEntity } from '../../shared/model/app-entity.model';
import { getEntity as getAppEntity } from '../app-entity/app-entity.reducer';
// import { getEntities as getAppEntities } from 'app/entities/app-entity/app-entity.reducer';
import { IEnumMember } from '../../shared/model/enum-member.model';
import { getEntity, updateEntity, createEntity, reset } from './enum-member.reducer';

export const EnumMemberUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { appEntityId } = useParams<'appEntityId'>();
  const { appEntityEnumId } = useParams<'appEntityEnumId'>();
  const { enumId } = useParams<'enumId'>();
  const isNew = appEntityEnumId === undefined;

  const appEntity = useAppSelector(state => state.appEntity.entity);
  const enumMemberEntity = useAppSelector(state => state.enumMember.entity);
  const loading = useAppSelector(state => state.enumMember.loading);
  const updating = useAppSelector(state => state.enumMember.updating);
  const updateSuccess = useAppSelector(state => state.enumMember.updateSuccess);

  const handleClose = () => {
    navigate(`/product/${productId}/app/${appId}/app-entity/${appEntityId}/view` + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(appEntityEnumId));
    }
    if (appEntityId !== undefined) {
      dispatch(getAppEntity(appEntityId));
    }
    // dispatch(getAppEntities({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    let entity = {
      ...enumMemberEntity,
      ...values,
      appEntity: { id: appEntity.id },
    };
    entity = { ...entity, name: entity.name[0].toUpperCase() + entity.name.substr(1, entity.name.length) };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...enumMemberEntity,
          appEntity: enumMemberEntity?.appEntity?.id,
        };

  return (
    <div className="inner-content-wrap">
      <Row className="justify-content-center">
        <Col md="12">
          <h2 className="row  page-title" id="codeconductorApp.enumMember.home.createOrEditLabel" data-cy="EnumMemberCreateUpdateHeading">
            <Label>{isNew ? 'Add' : 'Manage'} Enum member</Label>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity} className="row">
              {/* {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="enum-member-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null} */}
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.enumMember.name')}
                id="enum-member-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.enumMember.description')}
                id="enum-member-description"
                name="description"
                data-cy="description"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              {/* <ValidatedField
              className="col-md-6"
                label={translate('codeconductorApp.enumMember.lastSyncTimestamp')}
                id="enum-member-lastSyncTimestamp"
                name="lastSyncTimestamp"
                data-cy="lastSyncTimestamp"
                type="date"
              /> */}
              {/* <ValidatedField
              className="col-md-6"
                id="enum-member-appEntity"
                name="appEntity"
                data-cy="appEntity"
                label={translate('codeconductorApp.enumMember.appEntity')}
                type="select"
              >
                {appEntities
                  ? appEntities.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>  */}
              <Col md={'12'}>
                <Button
                  tag={Link}
                  id="cancel-save"
                  data-cy="entityCreateCancelButton"
                  to={`/product/${productId}/app/${appId}/app-entity/${appEntityId}/view`}
                  replace
                  color="back"
                >
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="save" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </Col>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default EnumMemberUpdate;
