import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from '../../shared/reducers/reducer.utils';

const initialState: EntityState<any> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: {},
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const maxRetry = 3;

const filterResults = async (reqType, currentResult, userPrompt, thunkAPI, retryCount = 0) => {
  switch (reqType) {
    case 'entityFields':
      if (currentResult.data.length == 0 || currentResult.data.some(feature => /Feature\s+\d+/.test(feature.name))) {
        if (retryCount < maxRetry) {
          const suggestEntityRelationshipAction = await thunkAPI.dispatch(
            suggestEntityFields({ ...userPrompt, retryCount: retryCount + 1 })
          );
          return suggestEntityRelationshipAction.payload;
        } else {
          console.error('Maximum retries reached for suggesting entity relationships.');
          return currentResult;
        }
      } else {
        return currentResult;
      }
      break;

    case 'featureEntities':
      if (currentResult.data.length == 0) {
        if (retryCount < maxRetry) {
          const suggestEntityRelationshipAction = await thunkAPI.dispatch(
            suggestFeatureEntities({ ...userPrompt, retryCount: retryCount + 1 })
          );
          return suggestEntityRelationshipAction.payload;
        } else {
          console.error('Maximum retries reached for suggesting entity relationships.');
          return currentResult;
        }
      } else {
        return currentResult;
      }
      break;

    case 'productFeature':
      if (currentResult.data.length == 0 || currentResult.data.some(feature => /Feature\s+\d+/.test(feature.name))) {
        if (retryCount < maxRetry) {
          const suggestEntityRelationshipAction = await thunkAPI.dispatch(
            suggestProductFeature({ ...userPrompt, retryCount: retryCount + 1 })
          );
          return suggestEntityRelationshipAction.payload;
        } else {
          console.error('Maximum retries reached for suggesting entity relationships.');
          return currentResult;
        }
      } else {
        return currentResult;
      }
      break;

    case 'entityRelationship':
      if (currentResult.data.length == 0 || currentResult.data.some(data => data.relationshipType.toLowerCase() === 'string')) {
        if (retryCount < maxRetry) {
          const suggestEntityRelationshipAction = await thunkAPI.dispatch(
            suggestEntityRelationship({ ...userPrompt, retryCount: retryCount + 1 })
          );
          return suggestEntityRelationshipAction.payload;
        } else {
          console.error('Maximum retries reached for suggesting entity relationships.');
          return currentResult;
        }
      } else {
        return currentResult;
      }

      break;

    case 'jobs':
      if (currentResult.data.jobSuggestions.length == 0) {
        if (retryCount < maxRetry) {
          const suggestJobsAction = await thunkAPI.dispatch(suggestJobs({ ...userPrompt, retryCount: retryCount + 1 }));
          return suggestJobsAction.payload;
        } else {
          console.error('Maximum retries reached for suggesting jobs.');
          return currentResult;
        }
      } else {
        return currentResult;
      }

      break;

    case 'listeners':
      if (currentResult?.data?.listenerSuggestors.length == 0) {
        if (retryCount < maxRetry) {
          const suggestListenersAction = await thunkAPI.dispatch(suggestListeners({ ...userPrompt, retryCount: retryCount + 1 }));
          return suggestListenersAction.payload;
        } else {
          console.error('Maximum retries reached for suggesting listeners.');
          return currentResult;
        }
      } else {
        return currentResult;
      }

      break;

    case 'customCase':
      if (currentResult && currentResult.customCase) {
        return currentResult.customCase;
      }
      break;

    default:
      return currentResult;
  }
};

export const suggestEntityFields = createAsyncThunk(
  '/ai/suggest/entityfields',
  async (userPrompt: any, thunkAPI) => {
    const { retryCount = 1 } = userPrompt; // Extract retry count from userPrompt or default to 0
    const requestUrl = `api/ai/suggest/entityfields?entityName=${userPrompt.entityName}&entityDescription=${userPrompt.entityDescription}&appId=${userPrompt.appId}&appEntities=${userPrompt.appEntities}`;
    const result = await axios.post<any>(requestUrl).then(function (response) {
      return response;
    });
    let tempResult = await filterResults('entityFields', result, userPrompt, thunkAPI, retryCount);
    return tempResult;
  },
  { serializeError: serializeAxiosError }
);

export const suggestFeatureEntities = createAsyncThunk(
  '/ai/suggest/featureentities',
  async (userPrompt: any, thunkAPI) => {
    const { retryCount = 1 } = userPrompt; // Extract retry count from userPrompt or default to 0
    const requestUrl = `api/ai/suggest/featureentities?productName=${userPrompt.productName}&productDescription=${userPrompt.productDescription}&featureName=${userPrompt.featureName}&featureDescription=${userPrompt.featureDescription}&appId=${userPrompt.appId}`;
    const result = await axios.post<any>(requestUrl).then(function (response) {
      return response;
    });
    let tempResult = await filterResults('featureEntities', result, userPrompt, thunkAPI, retryCount);
    return tempResult;
  },
  { serializeError: serializeAxiosError }
);

export const suggestProductFeature = createAsyncThunk(
  '/ai/suggest/productfeatures',
  async (userPrompt: any, thunkAPI) => {
    const { retryCount = 1 } = userPrompt; // Extract retry count from userPrompt or default to 0
    const requestUrl = `api/ai/suggest/productfeatures?productName=${userPrompt.productName}&productDescription=${userPrompt.productDescription}&appId=${userPrompt.appId}`;
    const result = await axios.post<any>(requestUrl).then(function (response) {
      return response;
    });
    let tempResult = await filterResults('productFeature', result, userPrompt, thunkAPI, retryCount);
    return tempResult;
  },
  { serializeError: serializeAxiosError }
);

export const suggestEntityRelationship = createAsyncThunk(
  '/ai/suggest/entityrelationship',
  async (userPrompt, thunkAPI) => {
    const { retryCount = 1 } = userPrompt; // Extract retry count from userPrompt or default to 0
    const requestUrl = `api/ai/suggest/entityrelationship?entityName=${userPrompt.entityNames}&appId=${userPrompt.appId}&descriptionPrompt=${userPrompt.descriptionPrompt}`;
    const result = await axios.post(requestUrl);
    let tempResult = await filterResults('entityRelationship', result, userPrompt, thunkAPI, retryCount);
    return tempResult;
  },
  { serializeError: serializeAxiosError }
);

export const suggestAppScreens = createAsyncThunk(
  '/ai/suggest/appscreens',
  async (userPrompt: any) => {
    const requestUrl = `api/ai/suggest/appscreens?productName=${userPrompt.productName}&productDescription=${userPrompt.productDescription}&appId=${userPrompt.appId}&userPrompt=${userPrompt.userPrompt}`;
    const result = axios.post<any>(requestUrl).then(function (response) {
      return response;
    });
    return result;
  },
  { serializeError: serializeAxiosError }
);
export const suggestScreenComponents = createAsyncThunk(
  '/ai/suggest/screencomponents',
  async (userPrompt: any) => {
    const requestUrl = `api/ai/suggest/screencomponents?productName=${userPrompt.productName}&pageName=${userPrompt.pageName}&appId=${userPrompt.appId}`;
    const result = axios.post<any>(requestUrl).then(function (response) {
      return response;
    });
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const suggestJobs = createAsyncThunk(
  '/ai/suggest/jobs',
  async (userPrompt: any, thunkAPI) => {
    const { retryCount = 1 } = userPrompt; // Extract retry count from userPrompt or default to 0
    const requestUrl = `api/ai/suggest/jobs?description=${userPrompt.description}&entities=${userPrompt.entities}&appDescription=${userPrompt.appDescription}`;
    const result = await axios.post<any>(requestUrl).then(function (response) {
      return response;
    });
    let tempResult = await filterResults('jobs', result, userPrompt, thunkAPI, retryCount);
    return tempResult;
  },
  { serializeError: serializeAxiosError }
);

export const suggestListeners = createAsyncThunk(
  '/ai/suggest/listeners',
  async (userPrompt: any, thunkAPI) => {
    const { retryCount = 1 } = userPrompt; // Extract retry count from userPrompt or default to 0
    const requestUrl = `api/ai/suggest/listeners?description=${userPrompt.description}&entities=${userPrompt.entities}&appDescription=${userPrompt.appDescription}`;
    const result = await axios.post<any>(requestUrl).then(function (response) {
      return response;
    });
    let tempResult = await filterResults('listeners', result, userPrompt, thunkAPI, retryCount);
    return tempResult;
  },
  { serializeError: serializeAxiosError }
);

// slice
export const AiSuggestor = createEntitySlice({
  name: 'aiSuggestor',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(suggestEntityFields.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addCase(suggestFeatureEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addCase(suggestProductFeature.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addCase(suggestEntityRelationship.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
        action.payload && action.payload.length > 0 ? action.payload.data : {};
      })
      .addCase(suggestAppScreens.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addCase(suggestScreenComponents.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addCase(suggestJobs.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addCase(suggestListeners.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addMatcher(
        isFulfilled(
          suggestEntityFields,
          suggestFeatureEntities,
          suggestProductFeature,
          suggestEntityRelationship,
          suggestAppScreens,
          suggestScreenComponents,
          suggestJobs,
          suggestListeners
        ),
        (state, action) => {
          state.updating = false;
          state.loading = false;
          state.updateSuccess = true;
          state.entities = action.payload.data;
        }
      )
      .addMatcher(
        isPending(
          suggestEntityFields,
          suggestFeatureEntities,
          suggestProductFeature,
          suggestEntityRelationship,
          suggestAppScreens,
          suggestScreenComponents,
          suggestJobs,
          suggestListeners
        ),
        state => {
          state.loading = true;
        }
      );
  },
});

export const { reset } = AiSuggestor.actions;

// Reducer
export default AiSuggestor.reducer;
