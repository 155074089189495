import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button, Col, Row, Spinner, Table, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Translate, getSortState, JhiPagination, JhiItemCount, ValidatedField } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DESC, ITEMS_PER_PAGE, SORT } from '../../../../../app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../config/store';
import { getEntities, createEntity as createAppFeature } from '../feature/feature.reducer';
import FeatureDeleteDialog from '../feature/feature-delete-dialog';
import SearchModule from '../../../app/shared/component/searchModule/SearchModule';
import { suggestProductFeature } from '../../entities/ai-suggestor/ai-suggestor.reducer';
import { getEntities as getApps } from '../../entities/app/app.reducer';
import { getEntity as getProduct } from '../../entities/product/product.reducer';

import { getEntities as getCoreFunctionalities } from '../../../../../app/entities/core-functionality/core-functionality.reducer';
import ProductFeaturesList from './AiSuggestFeaturesModal';
import AiWizardSuggestor from './AiWizardSuggestor';
import ProductModal from '../product/productModal';
import { AiGeneratedImage } from '../../shared/assets/svg-icon/icon';

export const AppFeature = props => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  var [deleteId, setDeleteId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', DESC), location.search)
  );

  console.log('appId', appId);

  const apps = useAppSelector(state => state.app.entities);
  const coreFunctionalities = useAppSelector(state => state.coreFunctionality.entities);
  const featureEntity = useAppSelector(state => state.feature.entity);
  const productEntity = useAppSelector(state => state.product.entity);
  const features = useAppSelector(state => state.feature.entities);
  const loading = useAppSelector(state => state.feature.loading);
  const totalItems = useAppSelector(state => state.feature.totalItems);
  const [query, setQuery] = useState('');
  const [aiSuggestLoading, setAiSuggestLoading] = useState<boolean>(false);
  const [aiSuggestFeaturesList, setAiSuggestFeaturesList] = useState([]);
  const [aiSuggestFeaturesModal, setAiSuggestFeaturesModal] = useState(false);
  const [showAiSuggestModal, setShowAiSuggestModal] = useState(false);
  const [allFeaturesSaved, setAllFeaturesSaved] = useState(false);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [userPrompt, setUserPrompt] = useState('');
  const [showAiModal, setShowAiModal] = useState(false);

  const handleSelectedFeatureIds = features => {
    setSelectedFeatures(features);
  };
  useEffect(() => {
    dispatch(getApps({ query: `id.equals=${appId}` }));
    dispatch(getCoreFunctionalities({}));
    if (productId !== undefined) {
      dispatch(getProduct(productId));
    }
  }, []);

  const getAllEntities = (emptyQuery?: any) => {
    const queryValue = emptyQuery != undefined ? emptyQuery : query;
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: `appId.equals=${appId}${queryValue ? `&name.contains=${queryValue}` : ''}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
  };

  const handleUserPrompt = event => {
    if (event.target) {
      setUserPrompt(event.target.value);
    } else {
      setUserPrompt('');
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const handleDelete = feature_id => {
    setShowModal(true);
    setDeleteId(feature_id);
    deleteId = feature_id;
  };

  const viewAiModal = () => {
    setShowAiModal(!showAiModal);
  };

  const viewModal = () => {
    console.log(aiSuggestFeaturesModal, 'aiSuggestFeaturesModal');
    setShowModal(!showModal);
  };

  const toggle = () => {
    setShowAiModal(!showAiModal);
    setUserPrompt('');
  };

  const toggleAiWizardPopup = () => {
    setShowAiSuggestModal(!showAiSuggestModal);
  };

  const handleAiSuggest = async () => {
    setAiSuggestLoading(true);
    setModalVisible(!modalVisible);
    setAiSuggestFeaturesModal(true);
    const productDetails = {
      productName: productEntity?.name,
      productDescription: userPrompt.length > 0 ? userPrompt : productEntity?.description,
      appId: appId,
    };
    // console.log("productDetails", productDetails);
    const { payload } = await dispatch(suggestProductFeature(productDetails));
    let result = payload['data'];

    let temp_result = [];
    if (payload['data']) {
      result.map(ele => {
        temp_result.push({ ...ele });
      });
    }

    let temp_AiSuggestEntities = temp_result.filter(function (array_el) {
      return (
        features.filter(function (anotherOne_el) {
          return anotherOne_el?.name?.toLowerCase() == array_el?.productFeatureName?.toLowerCase();
        }).length == 0
      );
    });
    setAiSuggestFeaturesList(temp_AiSuggestEntities);
    setAiSuggestLoading(false);
    setUserPrompt('');
  };

  const handleOnSaveForSuggested = async data => {
    let entity = {
      // ...featureEntity,
      ...data,
      app: apps.find(it => it.id == appId),
      coreFeature: coreFunctionalities.find(it => it.id === data.coreFeature),
      name: data?.productFeatureName,
      description: data?.productFeatureDescription,
    };
    // if (data?.type != null) {
    //   entity = { ...entity, type: null };
    // }

    const { payload } = await dispatch(createAppFeature(entity));

    if (payload['data']) {
      let tempAiSuggestFeature = aiSuggestFeaturesList.filter(item => item?.productFeatureName != payload['data']?.name);

      sortEntities();
      setAiSuggestFeaturesList(tempAiSuggestFeature);
      if (tempAiSuggestFeature.length == 0) {
        setAllFeaturesSaved(true);
      }
    }
  };

  const toggleProductPopup = () => {
    console.log('modalVisible', modalVisible);
    setModalVisible(!modalVisible);
    setAiSuggestFeaturesModal(false);
    setShowAiModal(false);
  };

  const handleSuggestedDelete = data => {
    let filterAiSuggestEntities = aiSuggestFeaturesList.filter(item => item.productFeatureName != data?.productFeatureName);
    setAiSuggestFeaturesList(filterAiSuggestEntities);
  };

  const handleAiSuggestorModal = () => {
    setAiSuggestFeaturesModal(false);
    setAiSuggestFeaturesList([]);
    setShowAiSuggestModal(true);
  };

  const handleCloseAiFeaturesModal = () => {
    setAiSuggestFeaturesModal(false);
    setAiSuggestFeaturesList([]);
  };
  return (
    <>
      <div className="inner-content-wrap" id="feature">
        <h2 id="feature-heading" data-cy="FeatureHeading" className=" mb-4 mt-3">
          <Row>
            <Col md="8">
              <SearchModule setQuery={setQuery} fetchFunction={getAllEntities} query={query} placeHolder="Feature Name" />
            </Col>
            <Col md="4">
              <div className="d-flex justify-content-end">
                {/* <Button className="me-2" color="refresh" onClick={handleSyncList} disabled={loading}>
                <FontAwesomeIcon icon="sync" spin={loading} />{' '}
                <Translate contentKey="codeconductorApp.feature.home.refreshListLabel">Refresh List</Translate>
              </Button> */}
                <div className="d-flex justify-content-end wizardbtn-dgn">
                  <Button className="me-2" color="refresh" onClick={() => viewAiModal()} disabled={loading}>
                    <AiGeneratedImage /> Wizard
                  </Button>
                </div>
                <Link
                  to={`/product/${productId}/app/${appId}/feature/new`}
                  className="btn btn-save jh-create-entity"
                  id="jh-create-entity"
                  data-cy="entityCreateButton"
                >
                  <FontAwesomeIcon icon="plus" />
                  &nbsp;
                  <Translate contentKey="codeconductorApp.feature.home.createLabel">Create new Feature</Translate>
                </Link>
              </div>
            </Col>
          </Row>
        </h2>
        {!loading && features && features.length > 0 ? (
          <div className="table-responsive">
            <Table>
              <thead className="table-primary">
                <tr>
                  <th className="hand">
                    <Translate contentKey="codeconductorApp.feature.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand">
                    <Translate contentKey="codeconductorApp.feature.description">Description</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  {/*<th className="hand">*/}
                  {/*  <Translate contentKey="codeconductorApp.feature.link">Link</Translate> <FontAwesomeIcon icon="sort" />*/}
                  {/*</th>*/}
                  <th />
                </tr>
              </thead>
              <tbody>
                {features.map((feature, i) => (
                  <tr key={`feature-${i}`} id={`feature-key-${feature.id || feature.key}`} data-cy="entityTable">
                    <td>{feature.name}</td>
                    <td>{feature.description ? feature.description : '--'}</td>
                    {/*<td>{feature.link ? feature.link : '--'}</td>*/}
                    <td className="text-end">
                      {/* <div className="btn-group flex-btn-group-container"> */}
                      {/* <Button tag={Link} to={`/feature/${appid}/${feature.id}/view`} color="primary" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button> */}

                      <div className="btn-group flex-btn-group-container">
                        <Button
                          tag={Link}
                          to={`/product/${productId}/app/${appId}/feature/${feature.id}/edit`}
                          color="warning"
                          size="sm"
                          data-cy="entityEditButton"
                        >
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button onClick={() => handleDelete(feature.id)} color="danger" size="sm" data-cy="entityDeleteButton">
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>

                      {/* </div> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : loading ? (
          <div className="conductor_loader"></div>
        ) : (
          <div className="alert alert-warning">
            <Translate contentKey="codeconductorApp.feature.home.notFound">No Features found</Translate>
          </div>
        )}
        {totalItems ? (
          <div className={features && features.length > 0 ? 'footer-pagination' : 'd-none'}>
            <div className="justify-content-end d-flex pagination-pstn">
              <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
            </div>
            <div className="justify-content-end d-flex">
              <JhiPagination
                activePage={paginationState.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={paginationState.itemsPerPage}
                totalItems={totalItems}
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {showModal && <FeatureDeleteDialog itemId={deleteId} showModal={viewModal} />}
      {showAiModal && !aiSuggestFeaturesModal && (
        <Modal isOpen={showAiModal} toggle={toggle} className="modal-lg" className="modalDesignNew" centered>
          <ModalHeader>How would you like to modify the features?</ModalHeader>
          <ModalBody>
            <ValidatedField
              className="col-md-12 mb-0 modal-input"
              id="features-prompt"
              name="prompt"
              data-cy="prompt"
              type="textarea"
              value={userPrompt}
              onChange={handleUserPrompt}
              placeholder="What are you looking for? (Optional)"
              validate={{
                required: { value: true, message: 'Please enter valid prompt!!' },
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="save" onClick={handleAiSuggest}>
              Next
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {aiSuggestFeaturesModal && (
        <ProductModal
          visible={modalVisible}
          toggleProductPopup={toggleProductPopup}
          activeStep={2}
          activeTab="Features"
          app={apps.find(it => it.id == appId)}
          productEntity={productEntity}
          aiSuggestLoading={aiSuggestLoading}
          aiSuggestFeatures={aiSuggestFeaturesList}
        />
      )}

      {showAiSuggestModal && <AiWizardSuggestor visible={showAiSuggestModal} Step="Features" toggleAiWizardPopup={toggleAiWizardPopup} />}
    </>
  );
};

export default AppFeature;
