import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from '../../../../../app/shared/util/date-utils';
import { mapIdList } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../config/store';

import { IApp } from '../../shared/model/app.model';
import { getEntities as getApps } from '../../entities/app/app.reducer';
import { ICodeBlueprint } from '../../shared/model/code-blueprint.model';
import { getEntities as getCodeBlueprints } from '../../../../../app/entities/code-blueprint/code-blueprint.reducer';
import { IAppCodeBlueprint } from '../../shared/model/app-code-blueprint.model';
import { getEntity, updateEntity, createEntity, reset } from '../../../../../app/entities/app-code-blueprint/app-code-blueprint.reducer';

export const AppCodeBlueprintUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const apps = useAppSelector(state => state.app.entities);
  const codeBlueprints = useAppSelector(state => state.codeBlueprint.entities);
  const appCodeBlueprintEntity = useAppSelector(state => state.appCodeBlueprint.entity);
  const loading = useAppSelector(state => state.appCodeBlueprint.loading);
  const updating = useAppSelector(state => state.appCodeBlueprint.updating);
  const updateSuccess = useAppSelector(state => state.appCodeBlueprint.updateSuccess);

  const handleClose = () => {
    navigate('/app-code-blueprint' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getApps({}));
    dispatch(getCodeBlueprints({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...appCodeBlueprintEntity,
      ...values,
      app: apps.find(it => it.id.toString() === values.app.toString()),
      codeBlueprint: codeBlueprints.find(it => it.id.toString() === values.codeBlueprint.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...appCodeBlueprintEntity,
          app: appCodeBlueprintEntity?.app?.id,
          codeBlueprint: appCodeBlueprintEntity?.codeBlueprint?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="codeconductorApp.appCodeBlueprint.home.createOrEditLabel" data-cy="AppCodeBlueprintCreateUpdateHeading">
            <Translate contentKey="codeconductorApp.appCodeBlueprint.home.createOrEditLabel">Create or edit a AppCodeBlueprint</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="app-code-blueprint-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('codeconductorApp.appCodeBlueprint.variableValues')}
                id="app-code-blueprint-variableValues"
                name="variableValues"
                data-cy="variableValues"
                type="text"
              />
              <ValidatedField
                id="app-code-blueprint-app"
                name="app"
                data-cy="app"
                label={translate('codeconductorApp.appCodeBlueprint.app')}
                type="select"
              >
                <option value="" key="0" />
                {apps
                  ? apps.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="app-code-blueprint-codeBlueprint"
                name="codeBlueprint"
                data-cy="codeBlueprint"
                label={translate('codeconductorApp.appCodeBlueprint.codeBlueprint')}
                type="select"
              >
                <option value="" key="0" />
                {codeBlueprints
                  ? codeBlueprints.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/app-code-blueprint" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AppCodeBlueprintUpdate;
