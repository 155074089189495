import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, Label, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from '../../../../../app/shared/util/date-utils';
import { mapIdList } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../config/store';

import { IAppEntity } from '../../shared/model/app-entity.model';
import { getEntities as getAppEntities } from '../app-entity/app-entity.reducer';
import { IAppEntityField } from '../../shared/model/app-entity-field.model';
import { getEntity, updateEntity, createEntity, reset } from './app-entity-field.reducer';

export const AppEntityFieldUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;
  const app = useAppSelector(state => state.app.entity);
  const appEntities = useAppSelector(state => state.appEntity.entities);
  const appEntityFieldEntity = useAppSelector(state => state.appEntityField.entity);
  const loading = useAppSelector(state => state.appEntityField.loading);
  const updating = useAppSelector(state => state.appEntityField.updating);
  const updateSuccess = useAppSelector(state => state.appEntityField.updateSuccess);
  const productEntity = useAppSelector(state => state.product.entity);
  const appEntity = useAppSelector(state => state.appEntity.entity);
  const handleClose = () => {
    navigate('/app-entity-field' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getAppEntities({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...appEntityFieldEntity,
      ...values,
      appEntity: appEntities.find(it => it.id.toString() === values.appEntity.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...appEntityFieldEntity,
          appEntity: appEntityFieldEntity?.appEntity?.id,
        };

  return (
    <div className="inner-content-wrap">
      <Row className="justify-content-center">
        {/* <Col md="12">
          <h2
            className="row  page-title"
            id="codeconductorApp.appEntityField.home.createOrEditLabel"
            data-cy="AppEntityFieldCreateUpdateHeading"
          >
            <Label>{isNew ? `Add` : ` Manage`} Entity Field</Label>
          </h2>
        </Col> */}
        <Col md="12">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/product">Products</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={`/product/${productEntity.id}`}>{productEntity.name}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={`/product/${productEntity.id}/app/${app.id}`}>{app.name}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>{isNew ? `Add` : `Manage`} App Entity</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity} className="row">
              {!isNew
                ? // <ValidatedField
                  //   name="id"
                  //   required
                  //   readOnly
                  //   id="app-entity-field-id"
                  //   label={translate('global.field.id')}
                  //   validate={{ required: true }}
                  // />
                  ''
                : null}
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appEntityField.name')}
                id="app-entity-field-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appEntityField.type')}
                id="app-entity-field-type"
                name="type"
                data-cy="type"
                type="text"
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appEntityField.isRequired')}
                id="app-entity-field-isRequired"
                name="isRequired"
                data-cy="isRequired"
                check
                type="checkbox"
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appEntityField.description')}
                id="app-entity-field-description"
                name="description"
                data-cy="description"
                type="text"
              />
              {/* <ValidatedField
              className='col-md-6'
                label={translate('codeconductorApp.appEntityField.lastSyncTimestamp')}
                id="app-entity-field-lastSyncTimestamp"
                name="lastSyncTimestamp"
                data-cy="lastSyncTimestamp"
                type="date"
              /> */}
              <ValidatedField
                className="col-md-6"
                id="app-entity-field-appEntity"
                name="appEntity"
                data-cy="appEntity"
                label={translate('codeconductorApp.appEntityField.appEntity')}
                type="select"
              >
                <option value="" key="0" />
                {appEntities
                  ? appEntities.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Col md="12">
                <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/app-entity-field" replace color="back">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="save" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </Col>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AppEntityFieldUpdate;
