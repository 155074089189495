import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getSortState, isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from '../../../../../app/shared/util/date-utils';
import { mapIdList, overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';

import { IAppEntityField } from '../../../../../app/shared/model/app-entity-field.model';
// import { getEntities as getAppEntityFields } from '../../../../../app/entities/app-entity-field/app-entity-field.reducer';
import { getEntities as getAppEntityFields } from '../app-entity-field/app-entity-field.reducer';
import { IApp } from '../../../../../app/shared/model/app.model';
import { getEntities as getApps } from '../../../../../app/entities/app/app.reducer';
import { IAppDomainListener } from '../../../../../app/shared/model/app-domain-listener.model';
import { DomainEventType } from '../../../../../app/shared/model/enumerations/domain-event-type.model';
import { DomainListenerType } from '../../../../../app/shared/model/enumerations/domain-listener-type.model';
import { getEntity, updateEntity, createEntity, reset } from './app-domain-listener.reducer';
import { ITEMS_PER_PAGE } from '../../../../../app/shared/util/pagination.constants';
import { getEntities as getAppEntities } from '../app-entity/app-entity.reducer';
import { getEntities as getRelationships } from '../entity-relationship/entity-relationship.reducer';

export const AppDomainListenerUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { listenerId } = useParams<'listenerId'>();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const params = useParams();
  const isNew = listenerId === undefined;

  const appEntityFieldsList = useAppSelector(state => state.appEntityField.entities);
  const appEntities = useAppSelector(state => state.appEntity.entities);
  const appRelationshipList = useAppSelector(state => state.entityRelationship.entities);
  const relationshipLoading = useAppSelector(state => state.entityRelationship.loading);
  const fieldsLoading = useAppSelector(state => state.appEntityField.loading);
  const apps = useAppSelector(state => state.app.entities);
  const appDomainListenerEntity = useAppSelector(state => state.appDomainListener.entity);
  const loading = useAppSelector(state => state.appDomainListener.loading);
  const updating = useAppSelector(state => state.appDomainListener.updating);
  const updateSuccess = useAppSelector(state => state.appDomainListener.updateSuccess);
  const productEntity = useAppSelector(state => state.product.entity);
  const appEntity = useAppSelector(state => state.app.entity);
  const domainEventTypeValues = Object.keys(DomainEventType);
  const domainListenerTypeValues = Object.keys(DomainListenerType);
  const [selectedEntity, setSelectedEntity] = useState<any>();
  const [appEntityFields, setAppEntityFields] = useState();
  const [appRelationship, setAppRelationship] = useState();
  const [listenerType, setListenerType] = useState(domainListenerTypeValues[0]);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const handleClose = () => {
    navigate(`/product/${productId}/app/${appId}` + location.search);
  };

  useEffect(() => {
    setAppEntityFields(appEntityFieldsList);
  }, [appEntityFieldsList]);

  useEffect(() => {
    setAppRelationship(appRelationshipList);
  }, [appRelationshipList]);

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(listenerId));
    }

    // dispatch(getAppEntityFields({
    //   page: paginationState.activePage - 1,
    //   size: paginationState.itemsPerPage,
    //   sort: `${paginationState.sort},${paginationState.order}`,
    //   query: appId ? `appId.equals=${appId}` : '',
    // }));
    dispatch(
      getApps({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: productId ? `productId.equals=${productId}` : '',
      })
    );
    dispatch(
      getAppEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: appId ? `appId.equals=${appId}` : '',
      })
    );
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    // console.log('values', values);
    const entity = {
      ...appDomainListenerEntity,
      ...values,
      appEntity: appEntities.find(it => it.id.toString() == selectedEntity.toString()),
      appEntityField: appEntityFields?.find(it => it.id.toString() === values?.appEntityField?.toString()),
      app: { id: appId?.toString() },
      entityRelationships: appRelationship?.filter(it => it.id.toString() == values?.entityRelationships?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const handleEntitySelect = e => {
    // console.log('valueEntity', e.target.value);
    setSelectedEntity(e.target.value);
  };

  useEffect(() => {
    if (selectedEntity != undefined) {
      dispatch(
        getAppEntityFields({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: selectedEntity ? `appEntityId.equals=${selectedEntity}` : '',
        })
      );
    }
    if (selectedEntity != undefined) {
      dispatch(
        getRelationships({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: appId ? `appId.equals=${appId}${selectedEntity ? `&appEntityFromId.equals=${Number(selectedEntity)}` : ''}` : '',
        })
      );
    }
  }, [selectedEntity]);

  const defaultValues = () =>
    isNew
      ? {}
      : {
          domainEventType: 'LOAD',
          type: 'EAGER_LOAD_COLLECTIONS',
          ...appDomainListenerEntity,
          appEntityField: appDomainListenerEntity?.appEntityField?.id,
          app: appDomainListenerEntity?.app?.id,
        };

  return (
    <div className="inner-content-wrap">
      {/* <div > */}
      <Row className="justify-content-center">
        <Col md="12">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/product">Products</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={`/product/${productEntity.id}`}>{productEntity.name}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={`/product/${productEntity.id}/app/${appEntity.id}`}>{appEntity.name}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>{isNew ? `Add` : `Manage`} Listener</BreadcrumbItem>
          </Breadcrumb>
        </Col>
        {/* <Col md="8">
          <h2 id="codeconductorApp.appDomainListener.home.createOrEditLabel" data-cy="AppDomainListenerCreateUpdateHeading">
            <Translate contentKey="codeconductorApp.appDomainListener.home.createOrEditLabel">Create or edit a AppDomainListener</Translate>
          </h2>
        </Col> */}
      </Row>
      <Row className="justify-content-center">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity} className="row">
              {!isNew ? (
                <ValidatedField
                  className="col-md-6"
                  name="id"
                  required
                  readOnly
                  id="app-domain-listener-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appDomainListener.domainEventType')}
                id="app-domain-listener-domainEventType"
                name="domainEventType"
                data-cy="domainEventType"
                type="select"
              >
                {domainEventTypeValues.map(domainEventType => (
                  <option value={domainEventType} key={domainEventType}>
                    {translate('codeconductorApp.DomainEventType.' + domainEventType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appDomainListener.description')}
                id="app-domain-listener-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appDomainListener.updateFieldValue')}
                id="app-domain-listener-updateFieldValue"
                name="updateFieldValue"
                data-cy="updateFieldValue"
                type="text"
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appDomainListener.listenerClassName')}
                id="app-domain-listener-listenerClassName"
                name="listenerClassName"
                data-cy="listenerClassName"
                type="text"
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appDomainListener.type')}
                id="app-domain-listener-type"
                name="type"
                data-cy="type"
                type="select"
                defaultValue={domainListenerTypeValues[0]}
                onChange={e => setListenerType(e.target.value)}
              >
                {domainListenerTypeValues.map(domainListenerType => (
                  <option value={domainListenerType} key={domainListenerType}>
                    {translate('codeconductorApp.DomainListenerType.' + domainListenerType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                className="col-md-6"
                id="app-domain-listener-appEntities"
                name="appEntity"
                data-cy="appEntities"
                // label={translate('codeconductorApp.appDomainListener.appEntities')}
                label="App Entities"
                type="select"
                // value={selectedEntity}
                onClick={e => handleEntitySelect(e)}
              >
                <option value="" key="0" />
                {appEntities
                  ? appEntities.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              {!fieldsLoading &&
                selectedEntity != undefined &&
                appEntityFields &&
                appEntityFields.length > 0 &&
                listenerType == 'SET_FIELD_VALUE' && (
                  <ValidatedField
                    className="col-md-6"
                    id="app-domain-listener-appEntityField"
                    name="appEntityField"
                    data-cy="appEntityField"
                    label={translate('codeconductorApp.appDomainListener.appEntityField')}
                    type="select"
                  >
                    <option value="" key="0" />
                    {appEntityFields
                      ? appEntityFields.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.name}
                          </option>
                        ))
                      : null}
                  </ValidatedField>
                )}
              {!relationshipLoading &&
                selectedEntity != undefined &&
                appRelationship &&
                appRelationship.length > 0 &&
                listenerType == 'EAGER_LOAD_COLLECTIONS' && (
                  <ValidatedField
                    className="col-md-6"
                    id="app-domain-listener-appEntity"
                    name="entityRelationships"
                    data-cy="entity-relationship"
                    // label={translate('codeconductorApp.appDomainListener.entity-relationship')}
                    label="Relationships"
                    type="select"
                    // onClick={(e) => handleEntitySelect(e)}
                  >
                    <option value="" key="0" />
                    {appRelationship
                      ? appRelationship.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {`${otherEntity.type}-${otherEntity.appEntityTo.name}-${
                              otherEntity?.appEntityFieldTo ? otherEntity.appEntityFieldTo : ''
                            }`}
                          </option>
                        ))
                      : null}
                  </ValidatedField>
                )}
              {/* <ValidatedField
                id="app-domain-listener-app"
                name="app"
                data-cy="app"
                label={translate('codeconductorApp.appDomainListener.app')}
                type="select"
              >
                <option value="" key="0" />
                {apps
                  ? apps.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField> */}
              <Col md={12}>
                <Button
                  tag={Link}
                  id="cancel-save"
                  data-cy="entityCreateCancelButton"
                  to={`/product/${productId}/app/${appId}`}
                  replace
                  color="back"
                >
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="save" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </Col>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AppDomainListenerUpdate;
