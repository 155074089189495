import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '../../config/store';
import { getEntity } from './product.reducer';
// import { getEntities as getAppEntities } from '../app/app.reducer';
import App from '../app/app';
import '../../app.scss';
import { AppStatus } from '../../../../../app/shared/model/enumerations/app-status.model';
import { BackArrowIcon } from '../../shared/assets/svg-icon/icon';

export const ProductDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const productEntity = useAppSelector(state => state.product.entity);

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getEntity(id));
    }
  }, []);

  // const handleSyncList = () => {
  //   dispatch(
  //     getAppEntities({
  //       sort: `id,desc`,
  //       query: `productId.equals=${id}`,
  //     })
  //   );
  // };
  useEffect(() => {
    if (productEntity?.status == AppStatus.Deleted) {
      navigate('/product');
    }
  }, [productEntity]);

  return (
    <div className="inner-content-wrap adding_spc">
      <h2 id="product-heading" data-cy="ProductHeading" className="row g-0 page-title">
        <Col md="12" col lg="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/product">Product</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>{productEntity.name}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
        {/* <Col md="5">
          {productEntity.name && <span className="productImg">{(productEntity.name || '').split('')[0]}</span>}
          {productEntity.name}
        </Col> */}
        <Col md="12" col lg="6">
          <div className="d-flex justify-content-end">
            <Link className="me-2 feature-back" tag={Link} to="/product" replace color="back" data-cy="entityDetailsBackButton">
              <BackArrowIcon />
              <Translate contentKey="entity.action.back">Back</Translate>
            </Link>

            {/* &nbsp;
            <Button className="me-2" color="refresh" onClick={handleSyncList} disabled={appLoading}>
              <FontAwesomeIcon icon="sync" spin={appLoading} />{' '}
              <Translate contentKey="codeconductorApp.app.home.refreshListLabel">Refresh List</Translate>
            </Button> */}
          </div>
        </Col>
      </h2>
      <App />
    </div>
  );
};

export default ProductDetail;
