import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, Label, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../config/store';

import { getEntities as getApps } from '../app/app.reducer';

import { getEntities as getCoreFunctionalities } from '../../../../../app/entities/core-functionality/core-functionality.reducer';

import { getEntity, updateEntity, createEntity, reset, getEntities } from './feature.reducer';

export const FeatureUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { featureId } = useParams<'featureId'>();

  const isNew = featureId === undefined;
  const params = new URLSearchParams(location.search).get('app');
  const Location = useLocation();
  const apps = useAppSelector(state => state.app.entities);
  const coreFunctionalities = useAppSelector(state => state.coreFunctionality.entities);
  const featureEntity = useAppSelector(state => state.feature.entity);
  const loading = useAppSelector(state => state.feature.loading);
  const updating = useAppSelector(state => state.feature.updating);
  const updateSuccess = useAppSelector(state => state.feature.updateSuccess);
  const chatGptSearchData = useAppSelector(state => state.chatGptSearch);
  const productEntity = useAppSelector(state => state.product.entity);
  const appEntity = useAppSelector(state => state.app.entity);
  const handleClose = () => {
    navigate(`/product/${productId}/app/${appId}` + location.search);
  };
  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(featureId));
    }

    dispatch(getApps({ query: `id.equals=${appId}` }));
    dispatch(getCoreFunctionalities({}));
  }, []);

  useEffect(() => {
    console.log('Location', Location);
  }, [Location]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    // console.log("featureValues:::::", apps.find(it => it.id.toString() === values.app.toString()))
    const entity = {
      ...featureEntity,
      ...values,
      app: apps.find(it => it.id == appId),
      coreFeature: coreFunctionalities.find(it => it.id === values.coreFeature),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () => {
    if (isNew) {
      {
      }
      // return chatGptSearchData?.entity?.identifier ? { name: chatGptSearchData.entity.identifier } : {};
    } else {
      return {
        ...featureEntity,
        app: featureEntity?.app?.id,
        coreFeature: featureEntity?.coreFeature?.id,
      };
    }
  };

  return (
    <div className="inner-content-wrap">
      {/* <Row className="justify-content-center">
        <Col md="12">
          <h2 className="row  page-title" id="codeconductorApp.feature.home.createOrEditLabel" data-cy="FeatureCreateUpdateHeading">
            <Label>{isNew ? `Add` : `Manage`} Feature </Label>
          </h2>
        </Col>
      </Row> */}
      <Col md="12">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/product">Products</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={`/product/${productEntity.id}`}>{productEntity.name}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={`/product/${productEntity.id}/app/${appEntity.id}`}>{appEntity.name}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{isNew ? `Add` : `Manage`} Feature</BreadcrumbItem>
        </Breadcrumb>
      </Col>
      <Row className="justify-content-center">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity} className="row">
              {!isNew
                ? // <ValidatedField
                  //   className="col-md-6"
                  //   name="id"
                  //   required
                  //   readOnly
                  //   id="feature-id"
                  //   label={translate('global.field.id')}
                  //   validate={{ required: true }}
                  // />
                  ''
                : null}
              <ValidatedField
                className="col-md-12 col-lg-6"
                label={translate('codeconductorApp.feature.name')}
                id="feature-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                className="col-md-12 col-lg-6"
                label={translate('codeconductorApp.feature.description')}
                id="feature-description"
                name="description"
                data-cy="description"
                type="text"
              />
              {/*<ValidatedField*/}
              {/*  className="col-md-12 col-lg-6"*/}
              {/*  label={translate('codeconductorApp.feature.link')}*/}
              {/*  id="feature-link"*/}
              {/*  name="link"*/}
              {/*  data-cy="link"*/}
              {/*  type="text"*/}
              {/*/>*/}
              {/* <ValidatedField
               className="col-md-6"
                id="feature-app"
                name="app"
                data-cy="app"
                label={translate('codeconductorApp.feature.app')}
                type="select"
                value={apps.name}
              > */}
              {/* <option value="" key="0" /> */}
              {/* {apps
                  ? apps.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id} selected={otherEntity.id == 1 ? true : false}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField> */}

              {/* <ValidatedField
               className="col-md-6"
                id="feature-coreFeature"
                name="coreFeature"
                data-cy="coreFeature"
                label={translate('codeconductorApp.feature.coreFeature')}
                type="select"
              >
                <option value="" key="0" />
                {coreFunctionalities
                  ? coreFunctionalities.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField> */}
              <Col md={'12'}>
                <Button
                  tag={Link}
                  id="cancel-save"
                  data-cy="entityCreateCancelButton"
                  to={`/product/${productId}/app/${appId}`}
                  replace
                  color="back"
                >
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span>
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="save" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </Col>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default FeatureUpdate;
