import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from '../../../../../app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from '../../shared/reducers/reducer.utils';
import { IDeployment, defaultValue } from '../../shared/model/deployment.model';

const initialState: EntityState<IDeployment> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/deployments';
const deploymentUrl = 'api/extsetup/deployment';

// Actions

export const getEntities = createAsyncThunk('deployment/fetch_entity_list', async ({ page, size, sort, query }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()} ${
    query ? `&${query}` : ''
  }`;
  return axios.get<IDeployment[]>(requestUrl);
});

export const createDefaultDeploymentCreds = createAsyncThunk(
  'deployment/fetch_entity',
  async (productId: string | number) => {
    return axios.post(`api/setup/setUpCredential/${productId}`);
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  'deployment/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IDeployment>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'deployment/create_entity',
  async (entity: IDeployment, thunkAPI) => {
    console.log(entity);
    const result = await axios.post<IDeployment>(apiUrl, cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'deployment/update_entity',
  async (entity: IDeployment, thunkAPI) => {
    const result = await axios.put<IDeployment>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deployEntity = createAsyncThunk(
  'deployment/deploy_entity',
  async (entity: IDeployment, thunkAPI) => {
    try {
      const result = await axios.put<IDeployment>(`${deploymentUrl}/${entity.appid}/${entity.id}/${entity.commitId}`, cleanEntity(entity));
      // console.log(result);
      // result? alert("Deployed Successfully!"): alert("Deployment failed");
      // thunkAPI.dispatch(getEntities({}));
      return result;
    } catch (error) {
      console.log(error.message);
    }
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'deployment/partial_update_entity',
  async (entity: IDeployment, thunkAPI) => {
    const result = await axios.patch<IDeployment>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'deployment/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IDeployment>(requestUrl);
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const DeploymentSlice = createEntitySlice({
  name: 'deployment',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity, deployEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, deployEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = DeploymentSlice.actions;

// Reducer
export default DeploymentSlice.reducer;
