import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Table, Nav, NavLink, NavItem } from 'reactstrap';
import { byteSize, Translate, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from '../../../../../app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '../../../../../app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';

import { IAppAiService } from '../../../../../app/shared/model/app-ai-service.model';
import { getEntities } from './app-ai-service.reducer';
import AppAiTool from '../app-ai-tool/app-ai-tool';
import AppChatLanguageModel from '../app-chat-language-model/app-chat-language-model';
import AiSuggestJobsModal from '../productAllFeatures/ai-wizard-prompt';
import { AiGeneratedImage } from '../../shared/assets/svg-icon/icon';

export const AppAiService = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const [showTab, setShowTab] = useState('AIServices');

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const appAiServiceList = useAppSelector(state => state.appAiService.entities);
  const loading = useAppSelector(state => state.appAiService.loading);
  const totalItems = useAppSelector(state => state.appAiService.totalItems);
  const [showModal, setShowModal] = useState(false);
  const [entitiesLoading, setEntitiesLoading] = useState(false);

  const toggleAiWizard = () => {
    setShowModal(!showModal);
    setEntitiesLoading(false);
  };

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}&appId.equals=${appId}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    // if (location.search !== endURL) {
    //   navigate(`${location.pathname}${endURL}`);
    // }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const handleAiSuggest = () => {
    setShowModal(true);
    setEntitiesLoading(true);
  };

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  return (
    <div>
      <h2 className="mb-4 mt-3" id="app-ai-service-heading" data-cy="AppAiServiceHeading">
        {/* <Translate contentKey="codeconductorApp.appAiService.home.title">App Ai Services</Translate> */}
        <div className="row">
          <div className="col-md-8">
            <Nav tabs className="tab-design aiSetup-tab-design">
              <NavItem>
                <NavLink herf="#" onClick={() => setShowTab('AIServices')} active={showTab == 'AIServices'}>
                  AI Services
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink herf="#" onClick={() => setShowTab('AITools')} active={showTab == 'AITools'}>
                  AI Tools
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink herf="#" onClick={() => setShowTab('ChatLanguageModel')} active={showTab == 'ChatLanguageModel'}>
                  Chat Language Model
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <div className="col-md-4">
            <div className="d-flex justify-content-end">
              {/* <div className="d-flex justify-content-end wizardbtn-dgn">
                <Button className="me-2" color="refresh" onClick={() => toggleAiWizard()} disabled={loading}>
                  <AiGeneratedImage /> Wizard
                </Button>
              </div> */}
              {showTab == 'AIServices' && (
                <Link
                  to={`/product/${productId}/app/${appId}/app-ai-service/new`}
                  className="btn btn-save jh-create-entity"
                  id="jh-create-entity"
                  data-cy="entityCreateButton"
                >
                  <FontAwesomeIcon icon="plus" />
                  &nbsp;
                  <Translate contentKey="codeconductorApp.appAiService.home.createLabel">Ai Service</Translate>
                </Link>
              )}
              {showTab == 'AITools' && (
                <Link
                  to={`/product/${productId}/app/${appId}/app-ai-tool/new`}
                  className="btn btn-save jh-create-entity"
                  id="jh-create-entity"
                  data-cy="entityCreateButton"
                >
                  <FontAwesomeIcon icon="plus" />
                  &nbsp;
                  <Translate contentKey="codeconductorApp.appAiTool.home.createLabel">Ai Tool</Translate>
                </Link>
              )}
              {showTab == 'ChatLanguageModel' && (
                <Link
                  to={`/product/${productId}/app/${appId}/app-chat-language-model/new`}
                  className="btn btn-save jh-create-entity"
                  id="jh-create-entity"
                  data-cy="entityCreateButton"
                >
                  <FontAwesomeIcon icon="plus" />
                  &nbsp;
                  <Translate contentKey="codeconductorApp.appChatLanguageModel.home.createLabel">
                    Create new App Chat Language Model
                  </Translate>
                </Link>
              )}
            </div>
          </div>
        </div>
      </h2>
      {showTab == 'AIServices' && (
        <>
          <div className="table-responsive">
            {!loading && appAiServiceList && appAiServiceList.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    {/* <th className="hand" onClick={sort('id')}>
                      <Translate contentKey="codeconductorApp.appAiService.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                    </th> */}
                    <th className="hand" onClick={sort('name')}>
                      <Translate contentKey="codeconductorApp.appAiService.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand" onClick={sort('className')}>
                      <Translate contentKey="codeconductorApp.appAiService.className">Class Name</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand" onClick={sort('systemMessage')}>
                      <Translate contentKey="codeconductorApp.appAiService.systemMessage">System Message</Translate>{' '}
                      <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand" onClick={sort('returnType')}>
                      <Translate contentKey="codeconductorApp.appAiService.returnType">Return Type</Translate>{' '}
                      <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand" onClick={sort('parameters')}>
                      <Translate contentKey="codeconductorApp.appAiService.parameters">Parameters</Translate>{' '}
                      <FontAwesomeIcon icon="sort" />
                    </th>
                    <th>
                      <Translate contentKey="codeconductorApp.appAiService.appFile">App File</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th>
                      <Translate contentKey="codeconductorApp.appAiService.appChatLanguageModel">App Chat Language Model</Translate>{' '}
                      <FontAwesomeIcon icon="sort" />
                    </th>
                    {/* <th>
                      <Translate contentKey="codeconductorApp.appAiService.appEmbeddingStore">App Embedding Store</Translate>{' '}
                      <FontAwesomeIcon icon="sort" />
                    </th>
                    <th>
                      <Translate contentKey="codeconductorApp.appAiService.app">App</Translate> <FontAwesomeIcon icon="sort" />
                    </th> */}
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {appAiServiceList.map((appAiService, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable">
                      {/* <td>
                        <Button tag={Link} to={`/product/${productId}/app/${appId}/app-ai-service/${appAiService.id}/view`} color="link" size="sm">
                          {appAiService.id}
                        </Button>
                      </td> */}
                      <td>{appAiService.name}</td>
                      <td>{appAiService.className}</td>
                      <td>{appAiService.systemMessage}</td>
                      <td>{appAiService.returnType}</td>
                      <td>{appAiService.parameters}</td>
                      <td>
                        {appAiService.appFile ? (
                          <Link to={`/product/${productId}/app/${appId}/app-file/${appAiService.appFile.id}/view`}>
                            {appAiService.appFile.fileName}
                          </Link>
                        ) : (
                          ''
                        )}
                      </td>
                      <td>
                        {appAiService.appChatLanguageModel ? (
                          <Link
                            to={`/product/${productId}/app/${appId}/app-chat-language-model/${appAiService.appChatLanguageModel.id}/view`}
                          >
                            {appAiService.appChatLanguageModel.modelName}
                          </Link>
                        ) : (
                          ''
                        )}
                      </td>
                      {/* <td>
                        {appAiService.appEmbeddingStore ? (
                          <Link to={`/product/${productId}/app/${appId}/app-embedding-store/${appAiService.appEmbeddingStore.id}/view`}>{appAiService.appEmbeddingStore.id}</Link>
                        ) : (
                          ''
                        )}
                      </td>
                      <td>{appAiService.app ? <Link to={`/product/${productId}/app/${appAiService.app.id}`}>{appAiService.app.id}</Link> : ''}</td> */}
                      <td className="text-end">
                        <div className="btn-group flex-btn-group-container">
                          <Button
                            tag={Link}
                            to={`/product/${productId}/app/${appId}/app-ai-service/${appAiService.id}/view`}
                            color="primary"
                            size="sm"
                            data-cy="entityDetailsButton"
                          >
                            <FontAwesomeIcon icon="eye" />{' '}
                          </Button>
                          <Button
                            tag={Link}
                            to={`/product/${productId}/app/${appId}/app-ai-service/${appAiService.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                            color="warning"
                            size="sm"
                            data-cy="entityEditButton"
                          >
                            <FontAwesomeIcon icon="pencil-alt" />{' '}
                          </Button>
                          <Button
                            tag={Link}
                            to={`/product/${productId}/app/${appId}/app-ai-service/${appAiService.id}/delete`}
                            color="danger"
                            size="sm"
                            data-cy="entityDeleteButton"
                          >
                            <FontAwesomeIcon icon="trash" />{' '}
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : loading ? (
              <div className="conductor_loader"></div>
            ) : (
              <div className="alert alert-warning">
                <Translate contentKey="codeconductorApp.appAiService.home.notFound">No App Ai Services found</Translate>
              </div>
            )}
          </div>
          {totalItems ? (
            <div className={appAiServiceList && appAiServiceList.length > 0 ? 'footer-pagination' : 'd-none'}>
              <div className="justify-content-end d-flex">
                <JhiItemCount
                  page={paginationState.activePage}
                  total={totalItems}
                  itemsPerPage={paginationState.itemsPerPage}
                  i18nEnabled
                />
              </div>
              <div className="justify-content-end d-flex">
                <JhiPagination
                  activePage={paginationState.activePage}
                  onSelect={handlePagination}
                  maxButtons={5}
                  itemsPerPage={paginationState.itemsPerPage}
                  totalItems={totalItems}
                />
              </div>
            </div>
          ) : (
            ''
          )}
        </>
      )}
      {showTab == 'AITools' && (
        <>
          <AppAiTool />
        </>
      )}
      {showTab == 'ChatLanguageModel' && (
        <>
          <AppChatLanguageModel />
        </>
      )}

      {showModal && (
        <AiSuggestJobsModal
          aiSuggestLoading={entitiesLoading}
          aiSuggestList={[]}
          showAiWizard={showModal}
          toggleAiWizard={toggleAiWizard}
          handleAiSuggest={handleAiSuggest}
          aiSuggestFor="Ai Service"
        />
      )}
    </div>
  );
};

export default AppAiService;
