import { ICoreAppEndPointController } from 'app/shared/model/core-app-end-point-controller.model';
import { RestMethod } from 'app/shared/model/enumerations/rest-method.model';

export interface ICoreAppEndPoint {
  id?: number;
  path?: string;
  method?: RestMethod;
  description?: string | null;
  pseudoCode?: string | null;
  outputsList?: boolean | null;
  coreAppEndPointController?: ICoreAppEndPointController | null;
}

export const defaultValue: Readonly<ICoreAppEndPoint> = {
  outputsList: false,
};
