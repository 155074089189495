import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, Label, Input, FormGroup, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getSortState, log, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../config/store';
import { getEntities as getProductUsers } from '../../../../../app/entities/product-user/product-user.reducer';
import { Authentication } from '../../../../../app/shared/model/enumerations/authentication.model';
import { AppStatus } from '../../../../../app/shared/model/enumerations/app-status.model';
import { getEntity, updateEntity, createEntity, reset, copyCoreFunctionality } from './product.reducer';
import { createEntity as createEntityApp, reset as resetAppStates } from '../app/app.reducer';
import { toNumber, values } from 'lodash';
import { ITEMS_PER_PAGE } from '../../../../../app/shared/util/pagination.constants';
import { getTemplateFeatures } from '../../../../../app/entities/feature/feature.reducer';
import { getEntities as getFunctionalityEntities } from '../functionality/functionality.reducer';
import { ApplicationType } from '../../../../../app/shared/model/enumerations/application-type.model';
import { createVaultUser } from '../vault-config/vault-config.reducer';
import { createDefaultDeploymentCreds } from '../deployment/deployment.reducer';
import { BackArrowIcon } from '../../shared/assets/svg-icon/icon';

export const ProductUpdate = () => {
  const [productUpdated, setProductUpdated] = useState(false);
  const [gitGroupPath, setGitGroupPath] = useState('');
  const [selectedCoreFunctionalityIds, setSelectedCoreFunctionalityIds] = useState([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const appEntity = useAppSelector(state => state.app.entity);
  const productUsers = useAppSelector(state => state.productUser.entities);
  const productEntity = useAppSelector(state => state.product.entity);
  const loading = useAppSelector(state => state.product.loading);
  const updating = useAppSelector(state => state.product.updating);
  // const productUpdated = useAppSelector(state => state.product.updateSuccess);
  const updateSuccess = useAppSelector(state => state.app.updateSuccess);
  const coreFunctionalityList = useAppSelector(state => state.feature.entities);
  const functionalityList = useAppSelector(state => state.functionality.entities);
  const authenticationValues = Object.keys(Authentication);
  let appStatus = Object.keys(AppStatus);
  appStatus.splice(appStatus.indexOf('DELETED'), 1);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );
  const applicationTypeValues = Object.keys(ApplicationType);

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
    dispatch(getProductUsers({}));
  }, []);

  useEffect(() => {
    dispatch(getTemplateFeatures({}));
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    if (!isNew && productEntity && productEntity.id && productEntity.apps) {
      var appIds = '';
      productEntity.apps.map((app: { id: any }) => {
        appIds = appIds + `appId.in=${app.id}&`;
      });
      dispatch(
        getFunctionalityEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: `${appIds}coreFunctionalityId.specified=true`,
        })
      );
    }
  }, [productEntity]);

  useEffect(() => {
    if (!isNew && functionalityList) {
      var selectedCoreFuncIdsTemp = [];
      functionalityList.map(func => {
        selectedCoreFuncIdsTemp.push(func.coreFunctionality && func.coreFunctionality.id);
      });
      setSelectedCoreFunctionalityIds(selectedCoreFuncIdsTemp);
    }
  }, [functionalityList]);

  const handleClose = () => {
    navigate('/product' + location.search);
  };

  useEffect(() => {
    if (updateSuccess || productUpdated) {
      handleClose();
    }
    return () => {
      dispatch(reset());
      dispatch(resetAppStates());
    };
  }, [updateSuccess, productUpdated]);

  const defaultValues = () =>
    isNew
      ? {}
      : {
          // authenticationType: 'JWT',
          ...productEntity,
          product: productEntity?.product?.id,
        };

  useEffect(() => {
    if (isNew && appEntity && appEntity.id && appEntity.name) {
      //create vault user for app
      dispatch(createVaultUser(appEntity.id));
    }
  }, [appEntity]);

  const saveEntity = async values => {
    let productID = undefined;
    try {
      values['coreFunctionaltyIds'] = selectedCoreFunctionalityIds;

      if (!values['authenticationType']) {
        values['authenticationType'] = 'Jwt';
      } else {
        values['authenticationType'] = values.authenticationType;
      }

      if (!values['gitGroupPath']) {
        values['gitGroupPath'] = values.name;
      } else {
        values['gitGroupPath'] = values.gitGroupPath;
      }

      const entity = {
        ...productEntity,
        ...values,
      };

      if (isNew) {
        productID = await dispatch(createEntity(entity));
      } else {
        productID = await dispatch(updateEntity(entity));
      }
    } catch (error) {
    } finally {
      if (productID && isNew) {
        var appValues = {
          name: productID?.payload?.data?.name,
          baseName: productID?.payload?.data?.name,
          applicationType: 'Monolith',
          product: productID?.payload?.data,
          status: AppStatus.New,
        };
        // appValues.status = NEW
        //create app
        await dispatch(createEntityApp(appValues));

        //add default deployement config
        await dispatch(createDefaultDeploymentCreds(productID?.payload?.data?.id));
      }
      if (productID && !isNew) {
        setProductUpdated(true);
      }
      //add functionality
      if (productID && selectedCoreFunctionalityIds.length > 0) {
        await dispatch(
          copyCoreFunctionality({ productId: productID?.payload?.data?.id, coreFunctionalityId: selectedCoreFunctionalityIds })
        );
      }
    }
  };

  const handleProductNameChange = event => {
    const value = event.target.value;
    setGitGroupPath(value);
  };

  const handleGitGroupPathChange = event => {
    const value = event.target.value;
    setGitGroupPath(value);
  };

  const handleCoreFunctionalityChange = e => {
    var selectedCoreFunctionalityIdsTemp = selectedCoreFunctionalityIds;
    if (e.target.value) {
      if (selectedCoreFunctionalityIdsTemp.indexOf(Number(e.target.value)) > -1) {
        selectedCoreFunctionalityIdsTemp.splice(selectedCoreFunctionalityIdsTemp.indexOf(Number(e.target.value)), 1);
      } else {
        selectedCoreFunctionalityIdsTemp.push(e.target.value);
      }
      setSelectedCoreFunctionalityIds(selectedCoreFunctionalityIdsTemp.map(toNumber));
    }
  };
  console.log({ selectedCoreFunctionalityIds });
  return (
    <>
      <div className="inner-content-wrap">
        <Row className="justify-content-center">
          <Col md="6">
            {/* <h2 className="row  page-title" id="codeconductorApp.product.home.createOrEditLabel" data-cy="ProductCreateUpdateHeading">
              <Label>{isNew ? `Add a` : `Manage`} Product</Label>
            </h2> */}
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/product">Products</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>{isNew ? `Add a` : `Manage`} Product</BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col md="6" className="justify-content-end d-flex">
            <Link
              // href=""
              className="feature-back"
              to={`/product/`}
              replace
              color="back"
              data-cy="entityDetailsBackButton"
            >
              <BackArrowIcon />
              <Translate contentKey="entity.action.back">Back</Translate>
            </Link>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="12">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <>
                <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity} className="row">
                  {isNew ? (
                    <ValidatedField
                      className="col-md-12 col-lg-6"
                      label={translate('codeconductorApp.product.name')}
                      id="product-name"
                      name="name"
                      data-cy="name"
                      type="text"
                      // value={productName}
                      onChange={handleProductNameChange}
                      validate={{
                        required: { value: true, message: translate('entity.validation.required') },
                      }}
                    />
                  ) : (
                    <ValidatedField
                      className="col-md-12 col-lg-6"
                      label={translate('codeconductorApp.product.name')}
                      id="product-name"
                      name="name"
                      data-cy="name"
                      type="text"
                      validate={{
                        required: { value: true, message: translate('entity.validation.required') },
                      }}
                    />
                  )}
                  {/* {isNew ? ( */}
                  <div className="col-md-12 col-lg-6">
                    <Label className="form-label">Features</Label>
                    <Row className="g-0 mt-1">
                      {coreFunctionalityList
                        ? coreFunctionalityList.map(coreFunctionality => (
                            <FormGroup check className="col-md-4 col-lg-4">
                              <Input
                                value={coreFunctionality.id}
                                onChange={handleCoreFunctionalityChange}
                                type="checkbox"
                                checked={selectedCoreFunctionalityIds && selectedCoreFunctionalityIds.includes(coreFunctionality.id)}
                              />
                              <Label check>{coreFunctionality.name}</Label>
                            </FormGroup>
                          ))
                        : null}
                    </Row>
                  </div>
                  {/* ) : null} */}

                  <ValidatedField
                    className="col-md-12 col-lg-6"
                    label={translate('codeconductorApp.app.applicationType')}
                    id="app-applicationType"
                    name="applicationType"
                    data-cy="applicationType"
                    type="select"
                    defaultValue={'monolith'}
                    hidden
                  >
                    {applicationTypeValues.map(applicationType => (
                      <option value={ApplicationType[applicationType]} key={ApplicationType[applicationType]}>
                        {translate('codeconductorApp.ApplicationType.' + ApplicationType[applicationType])}
                      </option>
                    ))}
                  </ValidatedField>

                  {isNew ? (
                    <ValidatedField
                      className="col-md-12 col-lg-6"
                      label={translate('codeconductorApp.product.authenticationType')}
                      id="product-authenticationType"
                      name="authenticationType"
                      data-cy="authenticationType"
                      type="select"
                      // value={authenticationType}
                      // onChange={handleAuthTypeChange}
                    >
                      {authenticationValues.map(authentication => (
                        <option value={Authentication[authentication]} key={Authentication[authentication]}>
                          {translate('codeconductorApp.Authentication.' + Authentication[authentication])}
                        </option>
                      ))}
                    </ValidatedField>
                  ) : (
                    <ValidatedField
                      className="col-md-12 col-lg-6"
                      label={translate('codeconductorApp.product.authenticationType')}
                      id="product-authenticationType"
                      name="authenticationType"
                      data-cy="authenticationType"
                      type="select"
                    >
                      {authenticationValues.map(authentication => (
                        <option value={Authentication[authentication]} key={Authentication[authentication]}>
                          {translate('codeconductorApp.Authentication.' + Authentication[authentication])}
                        </option>
                      ))}
                    </ValidatedField>
                  )}

                  {isNew ? (
                    <ValidatedField
                      className="col-md-12 col-lg-6"
                      label={translate('codeconductorApp.product.status')}
                      id="product-status"
                      name="status"
                      data-cy="status"
                      type="select"
                      // value={authenticationType}
                      // onChange={handleAuthTypeChange}
                    >
                      {appStatus.map(status => (
                        <option value={status} key={status}>
                          {status}
                          {/* {translate('codeconductorApp.AppStatus.' + status)} */}
                        </option>
                      ))}
                    </ValidatedField>
                  ) : (
                    <ValidatedField
                      className="col-md-12 col-lg-6"
                      label={translate('codeconductorApp.product.status')}
                      id="product-status"
                      name="status"
                      data-cy="status"
                      type="select"
                    >
                      {appStatus.map(status => (
                        <option value={status} key={status}>
                          {status}
                          {/* {translate('codeconductorApp.AppStatus.' + status)} */}
                        </option>
                      ))}
                    </ValidatedField>
                  )}

                  <div></div>
                  {isNew ? (
                    <ValidatedField
                      className="col-md-12 col-lg-6"
                      label={translate('codeconductorApp.product.gitGroupPath')}
                      id="product-gitGroupPath"
                      name="gitGroupPath"
                      data-cy="gitGroupPath"
                      value={gitGroupPath}
                      onChange={handleGitGroupPathChange}
                    />
                  ) : (
                    <ValidatedField
                      className="col-md-12 col-lg-6"
                      label={translate('codeconductorApp.product.gitGroupPath')}
                      id="product-gitGroupPath"
                      name="gitGroupPath"
                      data-cy="gitGroupPath"
                    />
                  )}
                  <ValidatedField
                    className="col-md-12 col-lg-6"
                    label={translate('codeconductorApp.product.description')}
                    id="product-description"
                    name="description"
                    data-cy="description"
                    type="text"
                  />
                  <ValidatedField
                    className="col-md-12 col-lg-6"
                    label={translate('codeconductorApp.product.notes')}
                    id="product-notes"
                    name="notes"
                    data-cy="notes"
                    type="textarea"
                  />
                  <ValidatedField
                    className="col-md-12 col-lg-6"
                    label={translate('codeconductorApp.product.functionalRequirements')}
                    id="product-functionalRequirements"
                    name="functionalRequirements"
                    data-cy="functionalRequirements"
                    type="textarea"
                  />
                  <ValidatedField
                    className="col-md-12 col-lg-6"
                    label={translate('codeconductorApp.product.nonFunctionalRequirements')}
                    id="product-nonFunctionalRequirements"
                    name="nonFunctionalRequirements"
                    data-cy="nonFunctionalRequirements"
                    type="textarea"
                  />

                  <Row>
                    <Col md={12}>
                      {/* <Button
                        className="m-1"
                        id="cancel-save"
                        data-cy="entityCreateCancelButton"
                        to="/product"
                        tag={Link}
                        replace
                        color="back"
                      >
                        <FontAwesomeIcon icon="arrow-left" />
                        &nbsp;
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.back">Back</Translate>
                        </span>
                      </Button> */}
                      &nbsp;
                      <Button
                        className="m-1"
                        color="save"
                        id="save-entity"
                        data-cy="entityCreateSaveButton"
                        type="submit"
                        disabled={updating}
                      >
                        <FontAwesomeIcon icon="save" />
                        &nbsp;
                        <Translate contentKey="entity.action.save">Save</Translate>
                      </Button>
                    </Col>
                  </Row>
                </ValidatedForm>
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ProductUpdate;
