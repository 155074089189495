import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { Translate, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from '../../../../../../app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '../../../../../../app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from '../../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../../config/store';

import { IAppEntityField } from '../../../shared/model/app-entity-field.model';
import { getEntities, createEntity as createAppEntityField } from '../../../entities/app-entity-field/app-entity-field.reducer';
import AppEntityFieldDeleteDialog from '../../../entities/app-entity-field/app-entity-field-delete-dialog';

import { suggestEntityFields } from '../../ai-suggestor/ai-suggestor.reducer';
import { getEntity as getAppEntity } from '../../../entities/app-entity/app-entity.reducer';
import AiSuggestEntityFieldsModal from './AiSuggestEntityFieldsModal';
import Relationship from '../relationshipForm/Relationship';
// import { getEntities as getTypes } from 'app/entities/type/type.reducer';
// import { getEntities } from './app-entity-field.reducer';

export const AppEntityField = props => {
  console.log(props, 'propssss');
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { appEntityId } = useParams<'appEntityId'>();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );
  var [deleteId, setDeleteId] = useState('');
  const [showModal, setShowModal] = useState(false);

  const appEntityFields = useAppSelector(state => state.appEntityField.entities);
  const loading = useAppSelector(state => state.appEntityField.loading);
  const totalItems = useAppSelector(state => state.appEntityField.totalItems);
  const appEntity = useAppSelector(state => state.appEntity.entity);

  const [appEntityFieldList, setAppEntityFieldList] = useState<any>();
  const [aiSuggestLoading, setAiSuggestLoading] = useState<boolean>(false);
  const [aiSuggestEntityFieldsList, setAiSuggestEntityFieldsList] = useState([]);
  const [aiSuggestEntityFieldsModal, setAiSuggestEntityFieldsModal] = useState(false);

  useEffect(() => {
    if (appEntityId !== undefined) {
      dispatch(getAppEntity(appEntityId));
    }
  }, []);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: `appEntityId.equals=${appEntityId}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const handleDelete = functionality_id => {
    setShowModal(true);
    setDeleteId(functionality_id);
    deleteId = functionality_id;
  };

  const viewModal = modal => {
    setShowModal(modal);
  };
  const handleAiSuggest = async () => {
    setAiSuggestLoading(true);
    setAiSuggestEntityFieldsModal(true);

    const { payload } = await dispatch(suggestEntityFields(props));
    let result = payload['data'];
    let temp_result = [];
    result.map(ele => {
      temp_result.push({ ...ele });
    });

    let temp_AiSuggestEntities = temp_result.filter(function (array_el) {
      return (
        appEntityFields.filter(function (anotherOne_el) {
          return anotherOne_el.name == array_el.entityFieldName;
        }).length == 0
      );
    });
    setAiSuggestLoading(false);
    setAiSuggestEntityFieldsList(temp_AiSuggestEntities);
  };
  const handleCloseAiEntityFieldsModal = () => {
    setAiSuggestEntityFieldsModal(false);
    setAiSuggestEntityFieldsList([]);
  };

  const handleOnSaveForSuggested = async data => {
    const entityFields = {
      ...data,
      appEntity: appEntity,
      name: data?.entityFieldName,
      description: data?.entityFieldDescription,
      type: data?.entityFieldType?.dataType,
    };

    const { payload } = await dispatch(createAppEntityField(entityFields));

    let temp_AiSuggestEntities = aiSuggestEntityFieldsList.filter(item => item.entityFieldName != payload['data'].name);

    if (payload['data']) {
      sortEntities();
      setAiSuggestEntityFieldsList(temp_AiSuggestEntities);
    }
    if (temp_AiSuggestEntities.length == 0) {
      setAiSuggestEntityFieldsModal(false);
    }
  };

  const handleSuggestedDelete = data => {
    let filterAiSuggestEntityFields = aiSuggestEntityFieldsList.filter(item => item.entityFieldName != data.entityFieldName);
    setAiSuggestEntityFieldsList(filterAiSuggestEntityFields);
  };

  return (
    <>
      <div className="inner-content-wrap">
        <h2 className="row mb-4 mt-3" id="app-entity-field-heading" data-cy="AppEntityFieldHeading">
          <div className="d-flex justify-content-between align-items-center fs-5">
            <Translate contentKey="codeconductorApp.appEntityField.home.title">App Entity Fields</Translate>
            <div className="d-flex justify-content-end">
              <Button className="me-2" color="refresh" onClick={handleAiSuggest} disabled={loading}>
                AI Suggest
              </Button>
              <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
                <FontAwesomeIcon icon="sync" spin={loading} />{' '}
                <Translate contentKey="codeconductorApp.appEntityField.home.refreshListLabel">Refresh List</Translate>
              </Button>
              <Link
                to={`/product/${productId}/app/${appId}/app-entity/${appEntityId}/view/app-entity-field/new`}
                className="btn btn-save jh-create-entity"
                id="jh-create-entity"
                data-cy="entityCreateButton"
              >
                <FontAwesomeIcon icon="plus" />
                &nbsp;
                <Translate contentKey="codeconductorApp.appEntityField.home.createLabel">Add Entity Field</Translate>
              </Link>
            </div>
          </div>
        </h2>
        {!loading && appEntityFields && appEntityFields.length > 0 ? (
          <div className="table-responsive">
            <Table responsive>
              <thead className="table-primary">
                <tr>
                  <th className="hand" onClick={sort('name')}>
                    <Translate contentKey="codeconductorApp.appEntityField.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('type')}>
                    <Translate contentKey="codeconductorApp.appEntityField.type">Type</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('isRequired')}>
                    <Translate contentKey="codeconductorApp.appEntityField.isRequired">Required</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('description')}>
                    <Translate contentKey="codeconductorApp.appEntityField.description">Description</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  {/* <th>
                    <Translate contentKey="codeconductorApp.appEntityField.appEntity">App Entity</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th> */}
                  <th />
                </tr>
              </thead>
              <tbody>
                {appEntityFields.map((appEntityField, i) => (
                  <tr key={`entity-${i}`} id={`entity-key-${appEntityField.id || appEntityField.key}`} data-cy="entityTable">
                    <td>{appEntityField.name}</td>
                    <td>{appEntityField.type ? appEntityField.type : '--'}</td>
                    <td>{appEntityField.isRequired ? 'true' : 'false'}</td>
                    <td>{appEntityField.description ? appEntityField.description : '--'}</td>
                    <td className="text-end">
                      <div className="btn-group flex-btn-group-container">
                        {/* <Button
                          tag={Link}
                          to={`/product-feature/${id}/app-entity-view/${appEntityId}/${appEntityField.id}`}
                          color="info"
                          size="sm"
                          data-cy="entityDetailsButton"
                        >
                          <FontAwesomeIcon icon="eye" />{' '}
                        </Button> */}
                        <Button
                          tag={Link}
                          to={`/product/${productId}/app/${appId}/app-entity/${appEntityId}/view/app-entity-field/${appEntityField.id}/edit`}
                          color="primary"
                          size="sm"
                          data-cy="entityEditButton"
                        >
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                        </Button>
                        <Button
                          // tag={Link}
                          // to={`/product-feature/${id}/app-entity-view/${appEntityId}/${appEntityField.id}/delete`}
                          onClick={() => handleDelete(appEntityField.id)}
                          color="danger"
                          size="sm"
                          data-cy="entityDeleteButton"
                        >
                          <FontAwesomeIcon icon="trash" />{' '}
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : loading ? (
          <div className="conductor_loader"></div>
        ) : (
          <div className="alert alert-warning">
            <Translate contentKey="codeconductorApp.appEntityField.home.notFound">No App Entity Fields found</Translate>
          </div>
        )}
        {totalItems ? (
          <div className={appEntityFields && appEntityFields.length > 0 ? 'footer-pagination' : 'd-none'}>
            <div className="justify-content-end d-flex">
              <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
            </div>
            <div className="justify-content-end d-flex">
              <JhiPagination
                activePage={paginationState.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={paginationState.itemsPerPage}
                totalItems={totalItems}
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {showModal && <AppEntityFieldDeleteDialog itemId={deleteId} showModal={viewModal} />}
      {(aiSuggestLoading || aiSuggestEntityFieldsModal) && (
        // <AppEntityDeleteDialog/>
        <AiSuggestEntityFieldsModal
          entityName={props.entityName}
          aiSuggestLoading={aiSuggestLoading}
          aiSuggestEntityFieldsList={aiSuggestEntityFieldsList}
          handleAiSuggest={handleAiSuggest}
          handleCloseAiEntityFieldsModal={handleCloseAiEntityFieldsModal}
          handleOnSaveForSuggested={handleOnSaveForSuggested}
          handleSuggestedDelete={handleSuggestedDelete}
        />
      )}
    </>
  );
};

export default AppEntityField;
