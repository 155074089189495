import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col, Label, Input, FormGroup } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from '../../../../../app/config/constants';
import { useAppDispatch, useAppSelector } from '../../config/store';

import { getEntity } from './entity-relationship.reducer';

export const EntityRelationshipDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();
  const { appId } = useParams<'appId'>();

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getEntity(id));
    }
  }, []);

  const entityRelationshipEntity = useAppSelector(state => state.entityRelationship.entity);
  return (
    <div className="inner-content-wrap">
      <Row>
        <Col md="12">
          <h2 className="row  page-title" data-cy="entityRelationshipDetailsHeading">
            <Translate contentKey="codeconductorApp.entityRelationship.detail.title">EntityRelationship</Translate>
          </h2>
          <dl className="jh-entity-details">
            {/* <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt> */}
            {/* <dd>{entityRelationshipEntity.id}</dd> */}
            <Col md={4}>
              <FormGroup className="row">
                <span id="type">
                  <Label contentKey="codeconductorApp.entityRelationship.type">Type</Label>
                </span>
                <Input value={entityRelationshipEntity.type} />
              </FormGroup>
            </Col>
            {/* <Col md = {4}>
          <FormGroup className='row'>
            <span id="erFrom">
              <Label contentKey="codeconductorApp.entityRelationship.erFrom">Er From</Label>
            </span>
            <Input value={entityRelationshipEntity.erFrom}/>
            </FormGroup>
          </Col> */}
            {/* <Col md = {4}>
          <FormGroup className='row' >
            <span id="erTo">
              <Label contentKey="codeconductorApp.entityRelationship.erTo">Er To</Label>
            </span>
            <Input value={entityRelationshipEntity.erTo}/>
            </FormGroup>
          </Col> */}
            {/* 
          <Col md = {4}>
          <FormGroup className='row' >
            <Label contentKey="codeconductorApp.entityRelationshzip.app">App</Label>
            <Input value={entityRelationshipEntity.app ? entityRelationshipEntity.app.name : ''}/>
            </FormGroup>
          </Col> */}

            <Col md={4}>
              <FormGroup className="row">
                <Label contentKey="codeconductorApp.entityRelationship.appEntityFieldTo">Entity From</Label>
                <Input value={entityRelationshipEntity.appEntityFrom ? entityRelationshipEntity.appEntityFrom.name : ''} />
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup className="row">
                <Label contentKey="codeconductorApp.entityRelationship.appEntityTo"> Entity To</Label>
                <Input value={entityRelationshipEntity.appEntityTo ? entityRelationshipEntity.appEntityTo.name : ''} />
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup className="row">
                <Translate contentKey="codeconductorApp.entityRelationship.appEntityFieldFrom">App Entity Field From</Translate>
                <Input value={entityRelationshipEntity.appEntityFieldFrom ? entityRelationshipEntity.appEntityFieldFrom.name : ''} />
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup className="row">
                <Label contentKey="codeconductorApp.entityRelationship.appEntityFieldTo">Entity Field To</Label>
                <Input value={entityRelationshipEntity.appEntityFieldTo ? entityRelationshipEntity.appEntityFieldTo.name : ''} />
              </FormGroup>
            </Col>
          </dl>
          <Button tag={Link} to={`/product-feature/${appId}`} replace color="info" data-cy="entityDetailsBackButton">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          {/* <Button tag={Link} to={`/entity-relationship/${entityRelationshipEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button> */}
        </Col>
      </Row>
    </div>
  );
};

export default EntityRelationshipDetail;
