import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate, ValidatedField } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '../../config/store';
import { updateEntities } from './app.reducer';
import { toast } from 'react-toastify';

export const AppGenerateDialog = ({ generateId, closeGenerateModal }) => {
  const dispatch = useAppDispatch();
  const updateSuccess = useAppSelector(state => state.app.updateSuccess);
  const [commitMessage, setCommitMessage] = useState('');

  const handleClose = () => {
    closeGenerateModal(false);
  };
  const commitMessageChange = e => {
    setCommitMessage(e.target.value);
  };

  const generateApp = async () => {
    try {
      const appCommitMessage = { id: generateId, commitMessage: commitMessage ? commitMessage : 'UPDATE' };
      const data = await dispatch(updateEntities(appCommitMessage));
      if (data.payload) {
        toast('App Updated Successfully..!!!');
      }
      handleClose();
    } catch (error) {
      if (error) toast('Unable to update app. Please try again...');
      handleClose();
    }
  };

  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose} data-cy="appGenerateDialogHeading">
        Commit message
      </ModalHeader>
      <ModalBody id="codeconductorApp.app.generate.question">
        <ValidatedField
          className="col-md-12"
          id="commitmessage"
          name="commitmessage"
          data-cy="commitmessage"
          type="textarea"
          onChange={commitMessageChange}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="save" onClick={generateApp}>
          Generate
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AppGenerateDialog;
