import { IAppEndPoint } from 'app/shared/model/app-end-point.model';
import { IAppScreen } from 'app/shared/model/app-screen.model';
import { IScreenComponent } from 'app/shared/model/screen-component.model';

export interface IActionComponent {
  id?: number;
  title?: string | null;
  description?: string | null;
  redirectTo?: string | null;
  redirectUrl?: string | null;
  appEndPoint?: IAppEndPoint | null;
  appScreen?: IAppScreen | null;
  screenComponent?: IScreenComponent | null;
}

export const defaultValue: Readonly<IActionComponent> = {};
