import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, Breadcrumb, BreadcrumbItem, Label } from 'reactstrap';
import { getSortState, isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from '../../../../../app/shared/util/date-utils';
import { mapIdList, overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';

import { IAppFile } from '../../../../../app/shared/model/app-file.model';
import { getEntities as getAppFiles } from '../../../../../app/entities/app-file/app-file.reducer';
import { IAppChatLanguageModel } from '../../../../../app/shared/model/app-chat-language-model.model';
import { getEntities as getAppChatLanguageModels } from '../../../../../app/entities/app-chat-language-model/app-chat-language-model.reducer';
import { IAppEmbeddingStore } from '../../../../../app/shared/model/app-embedding-store.model';
import { getEntities as getAppEmbeddingStores } from '../../../../../app/entities/app-embedding-store/app-embedding-store.reducer';
import { IApp } from '../../../../../app/shared/model/app.model';
import { getEntities as getApps } from '../../../../../app/entities/app/app.reducer';
import { IAppAiService } from '../../../../../app/shared/model/app-ai-service.model';
import { getEntity, updateEntity, createEntity, reset } from './app-ai-service.reducer';
import { ITEMS_PER_PAGE } from '../../../../../app/shared/util/pagination.constants';
import { getEntities as getAiTools } from '../app-ai-tool/app-ai-tool.reducer';
import { getEntities as getAiServiceTool, createEntity as createServiceTool } from '../app-ai-service-tool/app-ai-service-tool.reducer';

export const AppAiServiceUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { appAiServiceId } = useParams<'appAiServiceId'>();
  const isNew = appAiServiceId === undefined;

  const productEntity = useAppSelector(state => state.product.entity);
  const app = useAppSelector(state => state.app.entity);
  const appFiles = useAppSelector(state => state.appFile.entities);
  const appChatLanguageModels = useAppSelector(state => state.appChatLanguageModel.entities);
  const appEmbeddingStores = useAppSelector(state => state.appEmbeddingStore.entities);
  const apps = useAppSelector(state => state.app.entities);
  const appAiServiceEntity = useAppSelector(state => state.appAiService.entity);
  const loading = useAppSelector(state => state.appAiService.loading);
  const updating = useAppSelector(state => state.appAiService.updating);
  const updateSuccess = useAppSelector(state => state.appAiService.updateSuccess);
  const appAiToolList = useAppSelector(state => state.appAiTool.entities);
  // const appAiServiceToolList = useAppSelector(state => state.appAiServiceTool.entities);

  const [selectedServiceTool, setSelectedServiceTool] = useState();
  const [toolsArr, setToolsArr] = useState([]);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const handleClose = () => {
    navigate(`/product/${productId}/app/${appId}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(appAiServiceId));
    }

    dispatch(
      getAiTools({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}&appId.equals=${appId}`,
      })
    );
    dispatch(
      getAiServiceTool({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}&appId.equals=${appId}`,
      })
    );
    dispatch(
      getAppFiles({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}&appId.equals=${appId}`,
      })
    );
    dispatch(
      getAppChatLanguageModels({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}&appId.equals=${appId}`,
      })
    );
    // dispatch(getAppEmbeddingStores({}));
    // dispatch(getApps({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const handleSelectAiTool = e => {
    if (e.target.checked) {
      const existingItem = toolsArr?.includes(e.target.value);
      if (!existingItem) {
        toolsArr?.push(e.target.value);
        setSelectedServiceTool(toolsArr);
      }
    } else if (!e.target.checked) {
      const existingItem = toolsArr?.includes(e.target.value);
      if (existingItem) {
        const arr = toolsArr.filter(it => it !== e.target.value);
        setToolsArr(arr);
        setSelectedServiceTool(arr);
      }
    }
  };

  const saveEntity = async values => {
    const entity = {
      ...appAiServiceEntity,
      ...values,
      appFile: appFiles.find(it => it.id.toString() === values.appFile.toString()),
      appChatLanguageModel: appChatLanguageModels.find(it => it.id.toString() === values.appChatLanguageModel.toString()),
      appEmbeddingStore: appEmbeddingStores.find(it => it.id.toString() === values.appEmbeddingStore.toString()),
      app: app,
    };

    if (isNew) {
      const { payload } = await dispatch(createEntity(entity));

      await Promise.all(selectedServiceTool).then(values => {
        values.map(item => {
          const serviceToolEntity = {
            appAiService: payload.data,
            appAiTool: appAiToolList.find(it => it.id.toString() == item.toString()),
            attributeName: 'gyiugs',
          };
          dispatch(createServiceTool(serviceToolEntity));
        });
      });
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...appAiServiceEntity,
          appFile: appAiServiceEntity?.appFile?.id,
          appChatLanguageModel: appAiServiceEntity?.appChatLanguageModel?.id,
          appEmbeddingStore: appAiServiceEntity?.appEmbeddingStore?.id,
          app: appAiServiceEntity?.app?.id,
        };

  return (
    <div className="inner-content-wrap">
      <Row className="justify-content-center">
        {/* <Col md="8">
          <h2 id="codeconductorApp.appAiService.home.createOrEditLabel" data-cy="AppAiServiceCreateUpdateHeading">
            <Translate contentKey="codeconductorApp.appAiService.home.createOrEditLabel">Create or edit a AppAiService</Translate>
          </h2>
        </Col> */}
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/product">Products</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={`/product/${productEntity.id}`}>{productEntity.name}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={`/product/${productEntity.id}/app/${app.id}`}>{app.name}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active> AI Services</BreadcrumbItem>
        </Breadcrumb>
      </Row>
      <Row className="justify-content-center">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity} className="row">
              {/* {!isNew ? (
                <ValidatedField
                  className="col-md-6"
                  name="id"
                  required
                  readOnly
                  id="app-ai-service-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null} */}
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appAiService.name')}
                id="app-ai-service-name"
                name="name"
                data-cy="name"
                type="text"
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appAiService.className')}
                id="app-ai-service-className"
                name="className"
                data-cy="className"
                type="text"
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appAiService.systemMessage')}
                id="app-ai-service-systemMessage"
                name="systemMessage"
                data-cy="systemMessage"
                type="textarea"
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appAiService.returnType')}
                id="app-ai-service-returnType"
                name="returnType"
                data-cy="returnType"
                type="text"
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appAiService.parameters')}
                id="app-ai-service-parameters"
                name="parameters"
                data-cy="parameters"
                type="text"
              />
              <ValidatedField
                className="col-md-6"
                id="app-ai-service-appFile"
                name="appFile"
                data-cy="appFile"
                label={translate('codeconductorApp.appAiService.appFile')}
                type="select"
              >
                <option value="" key="0" />
                {appFiles
                  ? appFiles.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.fileName}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                className="col-md-6"
                id="app-ai-service-appChatLanguageModel"
                name="appChatLanguageModel"
                data-cy="appChatLanguageModel"
                label={translate('codeconductorApp.appAiService.appChatLanguageModel')}
                type="select"
              >
                <option value="" key="0" />
                {appChatLanguageModels
                  ? appChatLanguageModels.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.modelName}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Col md="6">
                <Label>Select AI Tools</Label>
                <Row>
                  {appAiToolList.map(item => (
                    <ValidatedField
                      className="col"
                      label={item.name}
                      id={item.id}
                      name={item.name}
                      check
                      type="checkbox"
                      value={item.id}
                      onChange={e => handleSelectAiTool(e)}
                    />
                  ))}
                </Row>
              </Col>
              {/* <ValidatedField
                className="col-md-6"
                id="app-ai-service-appEmbeddingStore"
                name="appEmbeddingStore"
                data-cy="appEmbeddingStore"
                label={translate('codeconductorApp.appAiService.appEmbeddingStore')}
                type="select"
              >
                <option value="" key="0" />
                {appEmbeddingStores
                  ? appEmbeddingStores.map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.id}
                    </option>
                  ))
                  : null}
              </ValidatedField> */}
              {/* <ValidatedField
                className="col-md-6"
                id="app-ai-service-app"
                name="app"
                data-cy="app"
                label={translate('codeconductorApp.appAiService.app')}
                type="select"
              >
                <option value="" key="0" />
                {apps
                  ? apps.map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.id}
                    </option>
                  ))
                  : null}
              </ValidatedField> */}
              <Col md="12">
                <Button
                  tag={Link}
                  id="cancel-save"
                  data-cy="entityCreateCancelButton"
                  to={`/product/${productId}/app/${appId}`}
                  replace
                  color="back"
                >
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="save" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </Col>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AppAiServiceUpdate;
