import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from '../../../../../app/config/constants';
import { useAppDispatch, useAppSelector } from '../../config/store';

import { getEntity } from '../../../../../app/entities/product-user/product-user.reducer';

export const ProductUserDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getEntity(id));
    }
  }, []);

  const productUserEntity = useAppSelector(state => state.productUser.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="productUserDetailsHeading">
          <Translate contentKey="codeconductorApp.productUser.detail.title">ProductUser</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{productUserEntity.id}</dd>
          <dt>
            <Translate contentKey="codeconductorApp.productUser.internalUser">Internal User</Translate>
          </dt>
          <dd>{productUserEntity.internalUser ? productUserEntity.internalUser.login : ''}</dd>
          <dt>
            <Translate contentKey="codeconductorApp.productUser.product">Product</Translate>
          </dt>
          <dd>
            {productUserEntity.products
              ? productUserEntity.products.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.id}</a>
                    {productUserEntity.products && i === productUserEntity.products.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
        </dl>
        <Button tag={Link} to="/product-user" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/product-user/${productUserEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ProductUserDetail;
