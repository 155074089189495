import { IAppAccessConfig } from '../../shared/model/app-access-config.model';
import { IAppScreen } from '../../shared/model/app-screen.model';
import { IEntityRelationship } from '../../shared/model/entity-relationship.model';
import { IAppCodeBlueprint } from '../../shared/model/app-code-blueprint.model';
import { IFunctionality } from '../../shared/model/functionality.model';
import { IAppEntity } from '../../shared/model/app-entity.model';
import { IAppEndPointController } from '../../shared/model/app-end-point-controller.model';
import { IJob } from '../../shared/model/job.model';
import { IFeature } from '../../shared/model/feature.model';
import { IVaultConfig } from '../../shared/model/vault-config.model';
import { ITeamMember } from '../../shared/model/team-member.model';
import { IAppLayout } from '../../shared/model/app-layout.model';
import { IProduct } from '../../shared/model/product.model';
import { ApplicationType } from '../../../../../app/shared/model/enumerations/application-type.model';
import { AppStatus } from '../../../../../app/shared/model/enumerations/app-status.model';

export interface IApp {
  id?: number;
  gitRepoId?: number | null;
  name?: string;
  baseName?: string;
  applicationType?: any;
  gitRepoPath?: string | null;
  description?: string | null;
  cicdPipeline?: string | null;
  userId?: number | null;
  logo?: string | null;
  packageName?: string | null;
  status?: AppStatus | null;
  loader?: string | null;
  favIcon?: string | null;
  appAccessConfigs?: IAppAccessConfig[] | null;
  appScreens?: IAppScreen[] | null;
  appEndPointControllers?: IAppEndPointController[] | null;
  entityRelationships?: IEntityRelationship[] | null;
  appCodeBlueprints?: IAppCodeBlueprint[] | null;
  functionalities?: IFunctionality[] | null;
  appEntities?: IAppEntity[] | null;
  vaultConfigs?: IVaultConfig[] | null;
  teamMembers?: ITeamMember[] | null;
  appLayouts?: IAppLayout[] | null;
  product?: IProduct | null;
  jobs?: IJob[] | null;
  features?: IFeature[] | null;
}

export const defaultValue: Readonly<IApp> = {};
