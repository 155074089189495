import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ProductCreatedIcon } from '../../shared/assets/svg-icon/icon';
import { useAppDispatch, useAppSelector } from '../../config/store';
import { createEntity as createAppEntity, getEntities as getAppEntities } from '../../entities/app-entity/app-entity.reducer';
import { createEntity as createAppEntityField, getEntities as getAppEntityFields } from '../app-entity-field/app-entity-field.reducer';
import {
  suggestAppScreens,
  suggestEntityFields,
  suggestEntityRelationship,
  suggestFeatureEntities,
} from '../ai-suggestor/ai-suggestor.reducer';
import { toast } from 'react-toastify';
import { createEntity as createRelationshipEntity } from '../../entities/entity-relationship/entity-relationship.reducer';
import { getEntities as getAppEntityRelationships } from '../../entities/entity-relationship/entity-relationship.reducer';
import { createEntity as createAppScreen } from '../../entities/app-screen/app-screen.reducer';
import { createEntity as createScreenComponent } from '../screen-component/screen-component.reducer';

const AiWizardSuggestor = props => {
  const product = useAppSelector(state => state.product.entity);
  const coreFunctionalities = useAppSelector(state => state.coreFunctionality.entities);
  const app = useAppSelector(state => state.app.entity);

  const dispatch = useAppDispatch();
  const features = useAppSelector(state => state.feature.entities);
  const [modal, setModal] = useState(props.visible);
  const toggle = () => {
    props.toggleAiWizardPopup();
  };
  const [productEntities, setProductEntities] = useState({});
  const [entitiesLoading, setEntitiesLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const Step = props.Step;

  useEffect(() => {
    createEntities(Step);
  }, [Step]);

  const createEntities = async Step => {
    // Start loading state
    setLoadingText('Figuring out the needed data model');
    setEntitiesLoading(true);
    try {
      if (features && features.length > 0) {
        let existingAppEntities = await dispatch(getAppEntities({ query: `appId.equals=${app.id}` }));
        let entitiesCreated = existingAppEntities?.payload?.data;
        console.log('entitiesCreated', entitiesCreated);

        for (const feature of features) {
          const appFeatureEntity = {
            productName: product?.name,
            productDescription: product?.description,
            featureName: feature?.name,
            featureDescription: feature?.description,
            appId: app.id,
          };
          // Suggest and create feature entities
          await handleFeatureEntities(appFeatureEntity, entitiesCreated, feature);
        }

        // Suggest and create entity fields
        let appEntities = await dispatch(getAppEntities({ query: `appId.equals=${app.id}` }));
        setProductEntities(appEntities);
        await handleEntityFields(appEntities);

        // Suggest and create entity relationships
        await handleEntityRelationships(appEntities);
        await handleAppScreens(product);
        let relationships = await dispatch(getAppEntityRelationships({ query: `appId.equals=${app.id}` }));
        if (appEntities.payload.data.length > 0 && relationships.payload.data.length > 0) {
          //   setCurrentStep(prevStep => prevStep + 1);
        } else {
          //   setButtonTxt('Retry');
          toast('AI is busy at the moment, please retry again!!');
          setEntitiesLoading(false);
        }
      }
    } catch (error) {
      console.error('Error creating entities:', error);
      //   setButtonTxt('Retry');
      toast('AI is busy at the moment, please retry again!!');
      setEntitiesLoading(false);
    } finally {
      // Stop loading state
      setLoadingText('');
      setEntitiesLoading(false);
    }
  };

  const handleFeatureEntities = async (appFeatureEntity, entitiesCreated, feature) => {
    const { payload } = await dispatch(suggestFeatureEntities(appFeatureEntity));
    let result = payload['data'];
    let temp_result = result ? result.map(ele => ({ ...ele })) : [];
    // Filter out entities that already exist
    const AiEntitiesList = temp_result.filter(
      arrayEl =>
        !entitiesCreated.some(anotherOneEl => anotherOneEl?.featureEntityName?.toLowerCase() === arrayEl?.featureEntityName?.toLowerCase())
    );

    // Create app entities
    for (const appEntity of AiEntitiesList) {
      const entity = {
        ...appEntity,
        type: 'Standard',
        app: app,
        feature,
        name: appEntity?.featureEntityName,
        description: appEntity?.featureEntityDescription,
      };

      if (appEntity.featureEntityName.toLowerCase() !== 'user' && appEntity.featureEntityName.toLowerCase() !== 'users') {
        let createdAppEntity = await dispatch(createAppEntity(entity));
        // entitiesCreated.push(createdAppEntity.payload.data);
        if (Object.isFrozen(entitiesCreated)) {
          // If the array is frozen, create a new array with the new object
          entitiesCreated = [...entitiesCreated, createdAppEntity.payload.data];
        } else {
          // If the array is not frozen, push the new object directly
          entitiesCreated.push(createdAppEntity.payload.data);
        }
      }
    }
  };

  const handleEntityFields = async entitiesResult => {
    setLoadingText(`Now working on each Entity's Entity Fields`);
    if (entitiesResult?.payload?.data) {
      for (const entity of entitiesResult.payload.data) {
        const suggestEntityField = {
          entityName: entity.name,
          entityDescription: entity.description,
        };

        const results = await dispatch(suggestEntityFields(suggestEntityField));
        if (results?.payload?.data) {
          for (const appEntityField of results.payload.data) {
            if (appEntityField?.entityFieldName?.toLowerCase() !== 'id' && entity.type.toLowerCase() == 'standard') {
              const entityFields = {
                ...appEntityField,
                appEntity: entity,
                name: appEntityField?.entityFieldName,
                description: appEntityField?.entityFieldDescription,
                type: appEntityField?.entityFieldType?.dataType,
              };

              await dispatch(createAppEntityField(entityFields));
            }
          }
        }
      }
    }
  };

  const handleEntityRelationships = async entitiesResult => {
    setLoadingText('Working On Relationships');
    if (entitiesResult?.payload?.data) {
      let entitiesNamesString = entitiesResult.payload.data.map(items => items.name).join(',');
      const entityDetails = {
        entityNames: entitiesNamesString,
      };
      const { payload } = await dispatch(suggestEntityRelationship(entityDetails));
      let result = payload['data'];
      let aiSuggestedRel = [];
      if (payload['data']) {
        result.map(ele => {
          let typeSplit = ' ';
          if (ele?.relationshipType?.includes('-')) {
            typeSplit = '-';
          }

          if (ele?.fromEntity != null && ele?.toEntity != null && ele?.relationshipType != null) {
            let updateType = ele?.relationshipType
              ?.split(typeSplit)
              .map(item => item.charAt(0).toUpperCase() + item.substring(1))
              .join('');
            ele = { ...ele, relationshipType: updateType };
            aiSuggestedRel.push({ ...ele });
          }
        });
      }
      aiSuggestedRel.map(async data => {
        let entityRelationship = {
          type: data?.relationshipType,
          app: app,
          appEntityTo: entitiesResult.payload.data.find(item => item.name == data?.toEntity),
          appEntityFrom: entitiesResult.payload.data.find(item => item.name == data?.fromEntity),
        };
        if (entityRelationship.appEntityTo && entityRelationship.appEntityFrom) {
          await dispatch(createRelationshipEntity(entityRelationship));
        }
      });
    }
  };

  const handleAppScreens = async product => {
    setLoadingText('Finalizing the App Screens');
    const productDetails = {
      productName: product?.name,
      productDescription: product?.description,
    };
    const appScreensResult = await dispatch(suggestAppScreens(productDetails));
    if (appScreensResult?.payload?.data) {
      for (const appScreen of appScreensResult.payload.data) {
        let appScreenPayload = {
          ...appScreen,
          app: app,
          accessRoles: appScreen?.accessRole,
        };
        const appScreenEntity = await dispatch(createAppScreen(appScreenPayload));
        if (appScreenEntity?.payload?.data) {
          let appEntities = await dispatch(getAppEntities({ query: `appId.equals=${app.id}` }));
          if (appEntities?.payload?.data) {
            let suggestedAppEntity = appEntities.payload.data.find(it => it.name == appScreen?.screenComponentSuggestion?.entity);
            let appEntityFields;
            if (suggestedAppEntity?.id) {
              appEntityFields = await dispatch(getAppEntityFields({ query: `appEntityId.equals=${suggestedAppEntity?.id}` }));
            }
            let suggestedAppEntityFieldsString = '';
            appEntityFields?.payload?.data.map((field, index) => {
              if (index !== appEntityFields.length - 1) {
                suggestedAppEntityFieldsString = suggestedAppEntityFieldsString + field.name + ':' + field.type + ',';
              } else {
                suggestedAppEntityFieldsString = suggestedAppEntityFieldsString + field.name + ':' + field.type;
              }
            });
            let screenComponentPayload = {
              name: appScreen?.title,
              layout: appScreen?.screenComponentSuggestion?.layout,
              layoutTemplate: appScreen?.screenComponentSuggestion?.layoutTemplate,
              appEntity: suggestedAppEntity,
              appScreen: appScreenEntity?.payload?.data,
              entityFields: suggestedAppEntityFieldsString,
            };
            await dispatch(createScreenComponent(screenComponentPayload));
          }
        }
      }
    }
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className="modalDesignNew">
        <ModalHeader></ModalHeader>
        <ModalBody>
          {entitiesLoading == true ? (
            <>
              <h2 className="wizard-heading gif_heading">{loadingText}</h2>
              <img className="modalIimg-dgn" src="themeContent/entitiesloader.gif" />
            </>
          ) : (
            <>
              <div className="modal-success">
                <ProductCreatedIcon />
                <h2>Your Product has been Created Successfully</h2>
                <Button href={`product/${product.id}/app/${app.id}`}>Let's Review</Button>
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </div>
  );
};

export default AiWizardSuggestor;
