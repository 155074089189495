import { IAppEntity } from '../../shared/model/app-entity.model';
import { IAppScreen } from '../../shared/model/app-screen.model';
import { IAppAccessConfig } from '../../shared/model/app-access-config.model';
import { ICoreFunctionality } from '../../shared/model/core-functionality.model';
import { IApp } from '../../shared/model/app.model';
import { FunctionalityType } from '../../../../../app/shared/model/enumerations/functionality-type.model';

export interface IFeature {
  id?: number;
  name?: string;
  description?: string | null;
  link?: string | null;
  userId?: number | null;
  type?: FunctionalityType | null;
  appEntities?: IAppEntity[] | null;
  appScreens?: IAppScreen[] | null;
  appAccessConfigs?: IAppAccessConfig[] | null;
  coreFunctionality?: ICoreFunctionality | null;
  app?: IApp | null;
}

export const defaultValue: Readonly<IFeature> = {};
