import CustomRoutes from './modules/CustomRoutes/CustomRoutes';
import CreateAppWizard from './modules/create-app-wizard/create-app-wizard';
import EntityWizard from '../app/modules/entity-wizard/entity-wizard';
import ActiveDeployments from '../app/modules/active-deployments/active-deployments';
import React, { useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';
import Loadable from 'react-loadable';

import LoginRedirect from '../../../app/modules/login/login-redirect';
import Activate from '../app/modules/account/activate/activate';
import PasswordResetInit from './modules/account/password-reset/init/password-reset-init';
import PasswordResetFinish from './modules/account/password-reset/finish/password-reset-finish';
import Logout from './modules/login/logout';
import Home from './modules/home/home';
import EntitiesRoutes from './entities/routes';
import PrivateRoute from '../../../app/shared/auth/private-route';
import ErrorBoundaryRoutes from '../../../app/shared/error/error-boundary-routes';
import PageNotFound from '../../../app/shared/error/page-not-found';
import { AUTHORITIES } from '../../../app/config/constants';
import Login from './modules/login/login';
import { getEntity as getProductEntity } from './entities/product/product.reducer';
import { getEntity as getAppEntity } from './entities/app/app.reducer';
import { useAppDispatch, useAppSelector } from './config/store';

const loading = <div>loading ...</div>;

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ './modules/account'),
  loading: () => loading,
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ '../../../app/modules/administration'),
  loading: () => loading,
});

const AppRoutes = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const productEntity = useAppSelector(state => state.product.entity);
  const appEntity = useAppSelector(state => state.app.entity);
  useEffect(() => {
    (async () => {
      const productIdRegex = /\/product\/(\d+)/;
      const appIdRegex = /\/app\/(\d+)/;

      const productIdMatch = location.pathname.match(productIdRegex);
      const appIdMatch = location.pathname.match(appIdRegex);

      const productId = productIdMatch ? productIdMatch[1] : null;
      const appId = appIdMatch ? appIdMatch[1] : null;

      if (appId && Object.keys(appEntity).length === 0) {
        // console.log('appEntity', appEntity);
        await dispatch(getAppEntity(appId));
      }

      if (productId && Object.keys(productEntity).length === 0) {
        // console.log('productEntity', productEntity);
        await dispatch(getProductEntity(productId));
      }
    })();
  }, [location.pathname]);

  return (
    <div className={`view-routes`}>
      <ErrorBoundaryRoutes>
        <Route index element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="account">
          <Route
            path="*"
            element={
              <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.USER]}>
                <Account />
              </PrivateRoute>
            }
          />
          <Route path="activate" element={<Activate />} />
          <Route path="reset">
            <Route path="request" element={<PasswordResetInit />} />
            <Route path="finish" element={<PasswordResetFinish />} />
          </Route>
        </Route>
        <Route
          path="admin/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
              <Admin />
            </PrivateRoute>
          }
        />
        <Route path="oauth2/authorization/oidc" element={<LoginRedirect />} />
        <Route
          path="*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
              <EntitiesRoutes />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </ErrorBoundaryRoutes>
    </div>
  );
};

export default AppRoutes;
