import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button, Col, Input, Row, Table, FormGroup, Label } from 'reactstrap';
import { Translate, getSortState, JhiPagination, JhiItemCount, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '../../../../../../app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from '../../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../../config/store';
import { getEntities, createEntity } from '../../../entities/entity-relationship/entity-relationship.reducer';
import EntityRelationshipDeleteDialog from '../../../entities/entity-relationship/entity-relationship-delete-dialog';
import { getEntities as getAppEntities } from '../../../entities/app-entity/app-entity.reducer';
import { suggestEntityRelationship } from '../../../entities/ai-suggestor/ai-suggestor.reducer';
import AiSuggestEntityRelationshipModal from './AiSuggestEntityRelationshipModal';
import { getEntities as getApps } from '../../../entities/app/app.reducer';
import { getEntity as getProduct } from '../../../entities/product/product.reducer';

import GraphComponent from '../../../shared/component/erDiagrams/nomnol.tsx';
import { AiGeneratedImage } from '../../../shared/assets/svg-icon/icon.tsx';
import ProductModal from '../../product/productModal.tsx';

export const Relationship = props => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();

  var [deleteId, setDeleteId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', DESC), location.search)
  );
  const { appEntityFromId } = props;
  const [entityFrom, setEntityFrom] = useState(appEntityFromId);
  const [entityTo, setEntityTo] = useState('');
  const appEntities = useAppSelector(state => state.appEntity.entities);
  const apps = useAppSelector(state => state.app.entities);
  const productEntity = useAppSelector(state => state.product.entity);
  const entityRelationshipList = useAppSelector(state => state.entityRelationship.entities);
  const loading = useAppSelector(state => state.entityRelationship.loading);
  const totalItems = useAppSelector(state => state.entityRelationship.totalItems);
  const [aiSuggestLoading, setAiSuggestLoading] = useState<boolean>(false);
  const [aiSuggestEntityRelationshipList, setAiSuggestEntityRelationshipList] = useState([]);
  const [aiSuggestEntityRelationshipModal, setAiSuggestEntityRelationshipModal] = useState(false);
  const [appEntitiesNames, setAppEntitiesNames] = useState('');
  const [reset, setReset] = useState('');
  const [showGraph, setShowGraph] = useState(false);
  const [aiSuggestFeaturesModal, setAiSuggestFeaturesModal] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  // const [engine, setEngine] = useState<any>();

  const getAllEntities = () => {
    dispatch(getApps({ query: `id.equals=${appId}` }));
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: !!appId
          ? `appId.equals=${appId}${entityFrom ? `&appEntityFromId.equals=${Number(entityFrom)}` : ''}${
              entityTo ? `&appEntityToId.equals=${Number(entityTo)}` : ''
            }`
          : '',
      })
    );
    if (productId !== undefined) {
      dispatch(getProduct(productId));
    }
  };

  const sortEntities = () => {
    getAllEntities();
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const openSuggestModal = () => {
    setAiSuggestEntityRelationshipModal(true);
  };

  const handleDelete = functionality_id => {
    setShowModal(true);
    setDeleteId(functionality_id);
    deleteId = functionality_id;
  };

  const viewModal = modal => {
    setShowModal(modal);
  };

  const handleAiSuggest = async prompt => {
    setAiSuggestLoading(true);
    console.log('prompt', prompt);
    let entitiesNamesString = appEntities
      .filter(it => it.type != 'Enum')
      .map(items => items.name)
      .join(',');

    const entityDetails = {
      entityNames: entitiesNamesString,
      appId: appId,
      descriptionPrompt: prompt,
    };
    console.log('productDetails', appId);
    const { payload } = await dispatch(suggestEntityRelationship(entityDetails));
    let result = payload['data'];

    let temp_result = [];
    if (payload['data']) {
      result.map(ele => {
        let typeSplit = ' ';
        if (ele?.relationshipType?.includes('-')) {
          typeSplit = '-';
        }

        if (ele?.fromEntity != null && ele?.toEntity != null && ele?.relationshipType != null) {
          let updateType = ele?.relationshipType
            ?.split(typeSplit)
            .map(item => item.charAt(0).toUpperCase() + item.substring(1))
            .join('');
          ele = { ...ele, relationshipType: updateType };
          temp_result.push({ ...ele });
        }
      });
    }

    let temp_AiSuggestEntityRelation = temp_result.filter(function (array_el) {
      return (
        entityRelationshipList.filter(function (anotherOne_el) {
          return anotherOne_el?.appEntityFrom?.name == array_el?.fromEntity && anotherOne_el?.appEntityTo?.name == array_el?.toEntity;
        }).length == 0
      );
    });

    setAiSuggestLoading(false);
    setAiSuggestEntityRelationshipList(temp_AiSuggestEntityRelation);
  };

  const toggleProductPopup = () => {
    setModalVisible(!modalVisible);
    setAiSuggestFeaturesModal(false);
    setShowModal(false);
  };

  const handleOnSaveForSuggested = async selectedRelationships => {
    for (const selectedRelationship of selectedRelationships) {
      let entityRelationship = {
        // ...featureEntity,
        type: selectedRelationship?.relationshipType,
        app: apps.find(it => it.id == appId),
        appEntityTo: appEntities.find(item => item.name == selectedRelationship?.toEntity),
        appEntityFrom: appEntities.find(item => item.name == selectedRelationship?.fromEntity),
      };

      const { payload } = await dispatch(createEntity(entityRelationship));

      if (payload['data']) {
        let tempAiSuggestFeature = aiSuggestEntityRelationshipList.filter(
          item => !(item?.fromEntity == selectedRelationship?.fromEntity && item?.toEntity == selectedRelationship?.toEntity)
        );

        sortEntities();
        setAiSuggestEntityRelationshipList(tempAiSuggestFeature);
        if (tempAiSuggestFeature.length == 0) {
          setAiSuggestEntityRelationshipModal(false);
        }
      }
    }

    setModalVisible(true);
    setAiSuggestEntityRelationshipModal(false);
  };

  const closeRelationshipModal = () => {
    setAiSuggestEntityRelationshipModal(!aiSuggestEntityRelationshipModal);
  };

  const handleSuggestedDelete = data => {
    let filterAiSuggestEntities = aiSuggestEntityRelationshipList.filter(
      item => !(item?.fromEntity == data?.fromEntity && item?.toEntity == data?.toEntity)
    );
    setAiSuggestEntityRelationshipList(filterAiSuggestEntities);
  };

  const handleCloseAiEntityRelationshipModal = () => {
    setAiSuggestEntityRelationshipModal(false);
    setAiSuggestEntityRelationshipList([]);
  };

  const handleReset = async () => {
    setReset('reset');
    setEntityFrom('');
    setEntityTo('');
  };

  useEffect(() => {
    if (reset == 'reset') {
      getAllEntities();
    }
    setReset('');
  }, [entityFrom, entityTo]);

  return (
    <>
      <div className="inner-content-wrap">
        <h2 id="entity-relationship-heading" data-cy="EntityRelationshipHeading" className=" mb-4 mt-3">
          <Row>
            <Col md={7}>
              <Row>
                <SearchModule id={'fromEntitySelect'} value={entityFrom} handleChange={setEntityFrom} placeHolder="App Entity From" />
                <SearchModule
                  id={'toEntitySelect'}
                  value={entityTo}
                  handleChange={setEntityTo}
                  placeHolder="App Entity To"
                  wrapperCss={{ paddingLeft: 0 }}
                />
                <Col md={4} style={{ padding: 0 }}>
                  <Button className="btn-save" color="save" onClick={sortEntities}>
                    Search
                  </Button>
                  <Button className="btn-save" color="publish" style={{ marginLeft: '5px' }} onClick={handleReset}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md={5} className="d-flex justify-content-end">
              {/* <Button className="me-2" color="refresh" onClick={handleSyncList} disabled={loading}>
                <FontAwesomeIcon icon="sync" spin={loading} />{' '}
                <Translate contentKey="codeconductorApp.entityRelationship.home.refreshListLabel">Refresh List</Translate>
              </Button> */}
              <Button className="me-2" color="refresh" onClick={openSuggestModal} disabled={loading}>
                <AiGeneratedImage /> Wizard
              </Button>
              <Link
                to={`/product/${productId}/app/${appId}/entity-relationship/new`}
                className="btn btn-save jh-create-entity"
                id="jh-create-entity"
                data-cy="entityCreateButton"
              >
                <FontAwesomeIcon icon="plus" />
                &nbsp;
                <Translate contentKey="codeconductorApp.entityRelationship.home.createLabel">Create new Entity Relationship</Translate>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="d-flex justify-content-end mt-3 align-items-center" style={{ fontSize: '17px' }}>
              <FormGroup switch style={{ margin: '0' }}>
                <Input
                  type="switch"
                  checked={showGraph}
                  onClick={() => {
                    setShowGraph(!showGraph);
                  }}
                />
                <Label check style={{ marginTop: '2px' }}>
                  Show Entity Diagram
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </h2>
        {!showGraph ? (
          <>
            {!loading && entityRelationshipList && entityRelationshipList.length > 0 ? (
              <div className="table-responsive">
                <Table>
                  <thead className="table-primary">
                    <tr>
                      <th className="hand" onClick={sort('type')}>
                        <Translate contentKey="codeconductorApp.entityRelationship.type">Type</Translate> <FontAwesomeIcon icon="sort" />
                      </th>
                      <th onClick={sort('appEntityFrom')}>
                        <Translate contentKey="codeconductorApp.entityRelationship.appEntityFrom">Entity from</Translate>{' '}
                        <FontAwesomeIcon icon="sort" />
                      </th>
                      <th onClick={sort('appEntityTo')}>
                        <Translate contentKey="codeconductorApp.entityRelationship.appEntityTo">Entity To</Translate>{' '}
                        <FontAwesomeIcon icon="sort" />
                      </th>
                      <th onClick={sort('appEntityFieldFrom')}>
                        <Translate contentKey="codeconductorApp.entityRelationship.appEntityFieldFrom">Entity Field From</Translate>{' '}
                        <FontAwesomeIcon icon="sort" />
                      </th>
                      <th onClick={sort('appEntityFieldTo')}>
                        <Translate contentKey="codeconductorApp.entityRelationship.appEntityFieldTo">Entity Field To</Translate>{' '}
                        <FontAwesomeIcon icon="sort" />
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {entityRelationshipList.map((entityRelationship, i) => (
                      <tr key={`entity-${i}`} data-cy="entityTable">
                        <td>{entityRelationship?.type}</td>
                        <td>{entityRelationship?.appEntityFrom?.name ? entityRelationship?.appEntityFrom?.name : '--'}</td>
                        <td>{entityRelationship?.appEntityTo?.name ? entityRelationship?.appEntityTo?.name : '--'}</td>
                        <td>
                          {entityRelationship?.appEntityFieldFrom ? (
                            <Link
                              style={{ cursor: 'auto', textDecoration: 'none', fontWeight: 'normal', color: '#000' }}
                              to={`/product/${productId}/app/${appId}`}
                            >
                              {entityRelationship?.appEntityFieldFrom?.name}
                            </Link>
                          ) : (
                            '--'
                          )}
                        </td>
                        <td>
                          {entityRelationship.appEntityFieldTo ? (
                            <Link
                              style={{ cursor: 'auto', textDecoration: 'none', fontWeight: 'normal', color: '#000' }}
                              to={`/product/${productId}/app/${appId}`}
                            >
                              {entityRelationship?.appEntityFieldTo?.name}
                            </Link>
                          ) : (
                            '--'
                          )}
                        </td>
                        <td className="text-end">
                          <div className="btn-group flex-btn-group-container">
                            {/* <Button
                          tag={Link}
                          to={`/entity-relationship/${appid}/${entityRelationship.id}/view`}
                          color="primary"
                          size="sm"
                          data-cy="entityDetailsButton"
                        >
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button> */}
                            <Button
                              tag={Link}
                              to={`/product/${productId}/app/${appId}/entity-relationship/${entityRelationship.id}/edit`}
                              color="warning"
                              size="sm"
                              data-cy="entityEditButton"
                            >
                              <FontAwesomeIcon icon="pencil-alt" />{' '}
                              <span className="d-none">
                                <Translate contentKey="entity.action.edit">Edit</Translate>
                              </span>
                            </Button>
                            <Button
                              onClick={() => handleDelete(entityRelationship.id)}
                              color="danger"
                              size="sm"
                              data-cy="entityDeleteButton"
                            >
                              <FontAwesomeIcon icon="trash" />{' '}
                              <span className="d-none">
                                <Translate contentKey="entity.action.delete">Delete</Translate>
                              </span>
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                {/* <div className='testing'><CanvasWidget engine={engine} /></div> */}
              </div>
            ) : loading ? (
              <div className="conductor_loader"></div>
            ) : (
              <div className="alert alert-warning">
                <Translate contentKey="codeconductorApp.entityRelationship.home.notFound">No Entity Relationships found</Translate>
              </div>
            )}
            {totalItems ? (
              <div className={entityRelationshipList && entityRelationshipList.length > 0 ? 'footer-pagination' : 'd-none'}>
                <div className="justify-content-end d-flex pagination-pstn">
                  <JhiItemCount
                    page={paginationState.activePage}
                    total={totalItems}
                    itemsPerPage={paginationState.itemsPerPage}
                    i18nEnabled
                  />
                </div>
                <div className="justify-content-end d-flex pagination-pstn">
                  <JhiPagination
                    activePage={paginationState.activePage}
                    onSelect={handlePagination}
                    maxButtons={5}
                    itemsPerPage={paginationState.itemsPerPage}
                    totalItems={totalItems}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
          </>
        ) : (
          <div className="er-diagram" style={{ height: '100%', width: '100%' }}>
            {entityRelationshipList && entityRelationshipList.length > 0 ? (
              <GraphComponent entityRelationships={entityRelationshipList} />
            ) : (
              ''
            )}
          </div>
        )}
      </div>
      {showModal && <EntityRelationshipDeleteDialog itemId={deleteId} showModal={viewModal} />}
      {(aiSuggestLoading || aiSuggestEntityRelationshipModal) && !modalVisible && (
        // <AppEntityDeleteDialog/>
        <AiSuggestEntityRelationshipModal
          aiSuggestLoading={aiSuggestLoading}
          aiSuggestEntityRelationshipList={aiSuggestEntityRelationshipList}
          handleAiSuggest={handleAiSuggest}
          handleCloseAiEntityRelationshipModal={handleCloseAiEntityRelationshipModal}
          handleOnSaveForSuggested={handleOnSaveForSuggested}
          handleSuggestedDelete={handleSuggestedDelete}
          productEntity={productEntity}
          modalVisible={aiSuggestEntityRelationshipModal}
          closeRelationshipModal={closeRelationshipModal}
        />
      )}

      {modalVisible && (
        <ProductModal
          visible={modalVisible}
          toggleProductPopup={toggleProductPopup}
          activeStep={3}
          activeTab="Relationships"
          app={apps.find(it => it.id == appId)}
          productEntity={productEntity}
        />
      )}
    </>
  );
};

export default Relationship;

type Props = {
  handleChange: any;
  placeHolder: string;
  value: any;
  style?: any;
  id: string;
  wrapperCss?: any;
};
const SearchModule = (props: Props) => {
  const { handleChange, value, placeHolder, style, id, wrapperCss } = props;
  const dispatch = useAppDispatch();

  const appEntities = useAppSelector(state => state.appEntity.entities);

  const { appId } = useParams<'appId'>();

  useEffect(() => {
    dispatch(
      getAppEntities({
        page: 0,
        size: 500,
        sort: `id,desc`,
        // query: `appId.equals=${appId}`,
        query: `appId.equals=${appId}`,
      })
    );
  }, []);

  const handleSelectChange = e => {
    handleChange(e.target.value);
  };

  return (
    <Col md={4} style={wrapperCss}>
      <Input key="relationship-search" value={value} id={id} name="select" type="select" onChange={handleSelectChange}>
        <option value={''}>{placeHolder}</option>
        {appEntities.map(item => {
          return (
            <option value={item.id} key={item.name}>
              {item.name}
            </option>
          );
        })}
      </Input>
    </Col>
  );
};
