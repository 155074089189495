import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from '../../../../../app/shared/util/date-utils';
import { mapIdList } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../config/store';

import { IFunctionality } from '../../../../../app/shared/model/functionality.model';
import { getEntities as getFunctionalities } from '../../entities/functionality/functionality.reducer';
import { IApp } from '../../../../../app/shared/model/app.model';
import { getEntities as getApps } from '../../entities/app/app.reducer';
import { IAppAccessConfig } from '../../shared/model/app-access-config.model';
import { getEntity, updateEntity, createEntity, reset } from '../../../../../app/entities/app-access-config/app-access-config.reducer';

export const AppAccessConfigUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const functionalities = useAppSelector(state => state.functionality.entities);
  const apps = useAppSelector(state => state.app.entities);
  const appAccessConfigEntity = useAppSelector(state => state.appAccessConfig.entity);
  const loading = useAppSelector(state => state.appAccessConfig.loading);
  const updating = useAppSelector(state => state.appAccessConfig.updating);
  const updateSuccess = useAppSelector(state => state.appAccessConfig.updateSuccess);

  const handleClose = () => {
    navigate('/app-access-config' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getFunctionalities({}));
    dispatch(getApps({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...appAccessConfigEntity,
      ...values,
      functionality: functionalities.find(it => it.id.toString() === values.functionality.toString()),
      app: apps.find(it => it.id.toString() === values.app.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...appAccessConfigEntity,
          functionality: appAccessConfigEntity?.functionality?.id,
          app: appAccessConfigEntity?.app?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="codeconductorApp.appAccessConfig.home.createOrEditLabel" data-cy="AppAccessConfigCreateUpdateHeading">
            <Translate contentKey="codeconductorApp.appAccessConfig.home.createOrEditLabel">Create or edit a AppAccessConfig</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="app-access-config-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('codeconductorApp.appAccessConfig.pathPattern')}
                id="app-access-config-pathPattern"
                name="pathPattern"
                data-cy="pathPattern"
                type="text"
              />
              <ValidatedField
                label={translate('codeconductorApp.appAccessConfig.reason')}
                id="app-access-config-reason"
                name="reason"
                data-cy="reason"
                type="text"
              />
              <ValidatedField
                label={translate('codeconductorApp.appAccessConfig.accessRoles')}
                id="app-access-config-accessRoles"
                name="accessRoles"
                data-cy="accessRoles"
                type="text"
              />
              <ValidatedField
                label={translate('codeconductorApp.appAccessConfig.enabled')}
                id="app-access-config-enabled"
                name="enabled"
                data-cy="enabled"
                check
                type="checkbox"
              />
              <ValidatedField
                id="app-access-config-functionality"
                name="functionality"
                data-cy="functionality"
                label={translate('codeconductorApp.appAccessConfig.functionality')}
                type="select"
              >
                <option value="" key="0" />
                {functionalities
                  ? functionalities.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="app-access-config-app"
                name="app"
                data-cy="app"
                label={translate('codeconductorApp.appAccessConfig.app')}
                type="select"
              >
                <option value="" key="0" />
                {apps
                  ? apps.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/app-access-config" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AppAccessConfigUpdate;
