import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, Label } from 'reactstrap';
import { getSortState, isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from '../../../../../app/shared/util/date-utils';
import { mapIdList, overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';

import { IApp } from '../../../../../app/shared/model/app.model';
import { getEntities as getApps } from '../../../../../app/entities/app/app.reducer';
import { IProduct } from '../../../../../app/shared/model/product.model';
import { getEntities as getProducts } from '../../../../../app/entities/product/product.reducer';
import { IAppEntity } from '../../../../../app/shared/model/app-entity.model';
import { getEntities as getAppEntities } from '../../../../../app/entities/app-entity/app-entity.reducer';
import { IProductJournalEntry } from '../../../../../app/shared/model/product-journal-entry.model';
import { ProductJournalEntryType } from '../../../../../app/shared/model/enumerations/product-journal-entry-type.model';
import { getEntity, updateEntity, createEntity, reset } from './product-journal-entry.reducer';
import { DESC, ITEMS_PER_PAGE } from '../../../../../app/shared/util/pagination.constants';

export const ProductJournalEntryUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { journalId } = useParams<'journalId'>();
  const isNew = id === undefined;

  const apps = useAppSelector(state => state.app.entities);
  const products = useAppSelector(state => state.product.entities);
  const appEntities = useAppSelector(state => state.appEntity.entities);
  const productJournalEntryEntity = useAppSelector(state => state.productJournalEntry.entity);
  const loading = useAppSelector(state => state.productJournalEntry.loading);
  const updating = useAppSelector(state => state.productJournalEntry.updating);
  const updateSuccess = useAppSelector(state => state.productJournalEntry.updateSuccess);
  const productJournalEntryTypeValues = Object.keys(ProductJournalEntryType);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', DESC), location.search)
  );

  const handleClose = () => {
    // navigate('/product-journal-entry' + location.search);
    navigate(`/product/${productId}/app/${appId}` + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(journalId));
    }

    dispatch(getApps({}));
    dispatch(getProducts({}));
    dispatch(
      getAppEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: !!appId ? `appId.equals=${appId}` : '',
      })
    );
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  console.log('InnUpdate');

  const saveEntity = values => {
    values.entryTimestamp = convertDateTimeToServer(values.entryTimestamp);
    values.verificationTimestamp = convertDateTimeToServer(values.verificationTimestamp);

    const entity = {
      ...productJournalEntryEntity,
      ...values,
      app: apps.find(it => it.id.toString() === appId?.toString()),
      product: products.find(it => it.id.toString() === productId?.toString()),
      appEntity: appEntities.find(it => it.id.toString() === values.appEntity.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          entryTimestamp: displayDefaultDateTime(),
          verificationTimestamp: displayDefaultDateTime(),
        }
      : {
          entryType: 'FEATURE_UPDATE',
          ...productJournalEntryEntity,
          entryTimestamp: convertDateTimeFromServer(productJournalEntryEntity.entryTimestamp),
          verificationTimestamp: convertDateTimeFromServer(productJournalEntryEntity.verificationTimestamp),
          app: productJournalEntryEntity?.app?.id,
          product: productJournalEntryEntity?.product?.id,
          appEntity: productJournalEntryEntity?.appEntity?.id,
        };

  return (
    <div className="inner-content-wrap">
      <Row className="justify-content-center">
        <Col md="12">
          <h2
            className="fs-5"
            id="codeconductorApp.productJournalEntry.home.createOrEditLabel"
            data-cy="ProductJournalEntryCreateUpdateHeading"
          >
            {/* <Translate contentKey="codeconductorApp.productJournalEntry.home.createOrEditLabel">
              {isNew ? 'Create Journal' : 'Edit Journal'}
            </Translate> */}
            <Label>{isNew ? 'Create Journal' : 'Edit Journal'}</Label>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity} className="row">
              {!isNew ? (
                <ValidatedField
                  className="col-md-6"
                  name="id"
                  required
                  readOnly
                  id="product-journal-entry-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.productJournalEntry.entryType')}
                id="product-journal-entry-entryType"
                name="entryType"
                data-cy="entryType"
                type="select"
              >
                {productJournalEntryTypeValues.map(productJournalEntryType => (
                  <option value={productJournalEntryType} key={productJournalEntryType}>
                    {translate('codeconductorApp.ProductJournalEntryType.' + productJournalEntryType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.productJournalEntry.title')}
                id="product-journal-entry-title"
                name="title"
                data-cy="title"
                type="text"
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.productJournalEntry.details')}
                id="product-journal-entry-details"
                name="details"
                data-cy="details"
                type="textarea"
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.productJournalEntry.entryTimestamp')}
                id="product-journal-entry-entryTimestamp"
                name="entryTimestamp"
                data-cy="entryTimestamp"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.productJournalEntry.verificationTimestamp')}
                id="product-journal-entry-verificationTimestamp"
                name="verificationTimestamp"
                data-cy="verificationTimestamp"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.productJournalEntry.relatedAppEntityId')}
                id="product-journal-entry-relatedAppEntityId"
                name="relatedAppEntityId"
                data-cy="relatedAppEntityId"
                type="text"
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.productJournalEntry.relatedAppEntityRecordId')}
                id="product-journal-entry-relatedAppEntityRecordId"
                name="relatedAppEntityRecordId"
                data-cy="relatedAppEntityRecordId"
                type="text"
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.productJournalEntry.ticketIdentifier')}
                id="product-journal-entry-ticketIdentifier"
                name="ticketIdentifier"
                data-cy="ticketIdentifier"
                type="text"
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.productJournalEntry.commitIdentifier')}
                id="product-journal-entry-commitIdentifier"
                name="commitIdentifier"
                data-cy="commitIdentifier"
                type="text"
              />
              {/* <ValidatedField
                className='col-md-3'
                label={translate('codeconductorApp.productJournalEntry.deletedOrRemoved')}
                id="product-journal-entry-deletedOrRemoved"
                name="deletedOrRemoved"
                data-cy="deletedOrRemoved"
                check
                type="checkbox"
              /> */}
              <ValidatedField
                className="col-md-6"
                id="product-journal-entry-appEntity"
                name="appEntity"
                data-cy="appEntity"
                label={translate('codeconductorApp.productJournalEntry.appEntity')}
                type="select"
              >
                <option value="" key="0" />
                {appEntities
                  ? appEntities.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                className="col-md-3"
                label={translate('codeconductorApp.productJournalEntry.manualEffortRequired')}
                id="product-journal-entry-manualEffortRequired"
                name="manualEffortRequired"
                data-cy="manualEffortRequired"
                check
                type="checkbox"
              />
              <ValidatedField
                className="col-md-3"
                label={translate('codeconductorApp.productJournalEntry.verifiedComplete')}
                id="product-journal-entry-verifiedComplete"
                name="verifiedComplete"
                data-cy="verifiedComplete"
                check
                type="checkbox"
              />
              <ValidatedField
                className="col-md-3"
                label={translate('codeconductorApp.productJournalEntry.verifiable')}
                id="product-journal-entry-verifiable"
                name="verifiable"
                data-cy="verifiable"
                check
                type="checkbox"
              />
              {/* <ValidatedField
                id="product-journal-entry-app"
                name="app"
                data-cy="app"
                label={translate('codeconductorApp.productJournalEntry.app')}
                type="select"
              >
                <option value="" key="0" />
                {apps
                  ? apps.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField> */}
              {/* <ValidatedField
                id="product-journal-entry-product"
                name="product"
                data-cy="product"
                label={translate('codeconductorApp.productJournalEntry.product')}
                type="select"
              >
                <option value="" key="0" />
                {products
                  ? products.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField> */}
              <Col md={12} style={{ marginTop: '20px' }}>
                <Button
                  tag={Link}
                  id="cancel-save"
                  data-cy="entityCreateCancelButton"
                  to={`/product/${productId}/app/${appId}`}
                  replace
                  color="back"
                >
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="save" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </Col>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ProductJournalEntryUpdate;
