import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, Col } from 'reactstrap';
import { AiSpark, AssistedSetup, MicroPhoneIcon, ProductCreatedIcon, QuestionMark } from '../../shared/assets/svg-icon/icon';
import { useAppDispatch, useAppSelector } from '../../config/store';
import { useNavigate, useParams } from 'react-router-dom';
import { createEntity, extractUserIntentContext, copyCoreFunctionality } from './product.reducer';
import { createEntity as createEntityApp } from '../app/app.reducer';
import { AppStatus } from '../../../../../app/shared/model/enumerations/app-status.model';
import { getEntities as getAppFeatures, createEntity as createAppFeature } from '../feature/feature.reducer';
import ProductFeaturesList from './product-Features';
import { createEntity as createAppEntity, getEntities as getAppEntities } from '../../entities/app-entity/app-entity.reducer';
import { createEntity as createAppEntityField, getEntities as getAppEntityFields } from '../app-entity-field/app-entity-field.reducer';
import {
  suggestAppScreens,
  suggestEntityFields,
  suggestEntityRelationship,
  suggestFeatureEntities,
  suggestProductFeature,
} from '../ai-suggestor/ai-suggestor.reducer';
import { toast } from 'react-toastify';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { Authentication } from '../../../../../app/shared/model/enumerations/authentication.model';
import { ApplicationType } from '../../../../../app/shared/model/enumerations/application-type.model';
import { createEntity as createRelationshipEntity } from '../../entities/entity-relationship/entity-relationship.reducer';
import { createVaultUser } from '../vault-config/vault-config.reducer';
import { createDefaultDeploymentCreds } from '../deployment/deployment.reducer';
import { getEntities as getAppEntityRelationships } from '../../entities/entity-relationship/entity-relationship.reducer';
import { createEntity as createAppScreen } from '../../entities/app-screen/app-screen.reducer';
import { createEntity as createScreenComponent } from '../screen-component/screen-component.reducer';
import { getTemplateFeatures } from '../../../../../app/entities/feature/feature.reducer';

const ProductModal = props => {
  const productEntity = useAppSelector(state => state.product.entity);
  const coreFunctionalities = useAppSelector(state => state.coreFunctionality.entities);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const features = useAppSelector(state => state.feature.entities);
  const entities = useAppSelector(state => state.appEntity.entities);
  const [modal, setModal] = useState(props.visible);
  const toggle = () => {
    props.toggleProductPopup();
  };

  const [currentStep, setCurrentStep] = props.activeStep ? useState(props.activeStep) : useState(1);
  const [currentApp, setCurrentApp] = props.app ? useState(props.app) : useState({});
  const [currentProduct, setCurrentProduct] = props.productEntity ? useState(props.productEntity) : useState({});
  const [productFeatures, setProductFeatures] = useState([]);
  const [productEntities, setProductEntities] = useState({});
  const [entitiesLoading, setEntitiesLoading] =
    props.activeStep && (props.activeTab !== 'Entities' || props.activeTab !== 'Relationships') ? useState(true) : useState(false);
  const [loadingText, setLoadingText] = props.activeTab == 'Entities' ? useState('Adding Selected Entities') : useState('');
  const [userPrompt, setUserPropmt] = useState('');
  const [enableNextBtn, setEnableNextBtn] = props.activeStep ? useState(true) : useState(false);
  const [buttonTxt, setButtonTxt] = useState('Next');
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [latestFeatures, setLatestFeatures] = useState([]);
  const [continueFeaturesWizard, setContinueFeaturesWizard] = props.activeStep ? useState(false) : useState(true);
  const [continueEntitiesWizard, setContinueEntitiesWizard] = props.activeStep == 'Entities' ? useState(false) : useState(true);
  const handleSelectedFeatureIds = features => {
    setSelectedFeatures(features);
  };

  useEffect(() => {
    setModal(props.visible);
  }, [props.visible]);

  useEffect(() => {
    setProductFeatures(props.aiSuggestFeatures);
    // if (props.aiSuggestFeatures && props.aiSuggestFeatures.length > 0) {
    setEntitiesLoading(props.aiSuggestLoading);
  }, [props.aiSuggestFeatures, props.aiSuggestLoading]);

  useEffect(() => {
    if (props.activeTab == 'Features') {
      setLoadingText('Figuring out the needed Data Models');
    }

    if (props.activeTab == 'Relationships' || props.activeTab == 'Entities') {
      setEntitiesLoading(false);
    }

    if (props.activeTab == 'Screen') {
      handleAppScreens(productEntity);
      setEntitiesLoading(true);
    }
  }, [props.activeTab]);

  useEffect(() => {
    setEntitiesLoading(props.aiSuggestLoading);
  }, [props.aiSuggestLoading]);

  useEffect(() => {
    console.log('currentStep', currentStep);
  }, [currentStep]);

  const createProductApp = async () => {
    setEntitiesLoading(true);
    let productContext;
    var count = 0;
    try {
      productContext = await dispatch(extractUserIntentContext(userPrompt));
      count = count + 1;
    } catch (error) {
    } finally {
      if (
        productContext &&
        productContext.payload &&
        productContext.payload.action &&
        (productContext.payload.identifier || productContext.payload.appName)
      ) {
        handleAiResponse(productContext.payload, userPrompt);
      } else if (count === 1) {
        productContext = await dispatch(extractUserIntentContext(userPrompt));
        if (
          productContext &&
          productContext.payload &&
          productContext.payload.action &&
          (productContext.payload.identifier || productContext.payload.appName)
        ) {
          handleAiResponse(productContext.payload);
        } else {
          setEntitiesLoading(false);
          toast('Hey!! I am not able to understand what you want to do, can you please explain a bit more...');
        }
      } else {
        setEntitiesLoading(false);
        toast('Hey!! I am not able to understand what you want to do, can you please explain a bit more...');
      }
    }
  };

  const handleUserPrompt = event => {
    if (event.target) {
      setUserPropmt(event.target.value);
      if (event.target.value !== '') {
        setEnableNextBtn(true);
      } else {
        setEnableNextBtn(false);
      }
    } else {
      setUserPropmt('');
    }
  };

  const createProduct = async (productContext, userPrompt) => {
    setLoadingText('Creating Product');
    try {
      let appName = productContext && productContext.appName ? productContext.appName : productContext.identifier;
      if (!appName) {
        toast('Please enter a valid app name!!');
      }
      // Create product
      const productValues = {
        name: appName,
        authenticationType: Authentication.Jwt,
        gitGroupPath: appName,
        status: AppStatus.New,
        description: userPrompt,
      };
      let product = await dispatch(createEntity({ ...productValues }));
      setCurrentProduct(product.payload.data);
      return product;
    } catch (error) {
      // Handle error
      toast('Unable to create product at the moment. Please try again.');
    }
  };

  const createApp = async (product, userPrompt) => {
    setLoadingText('Your App now has an awesome name : ' + product?.payload?.data?.name);
    try {
      const appValues = {
        name: product?.payload?.data?.name,
        baseName: product?.payload?.data?.name,
        applicationType: ApplicationType.Monolith,
        product: product?.payload?.data,
        status: AppStatus.New,
        functionalRequirements: userPrompt,
      };
      let appObj = await dispatch(createEntityApp(appValues));
      setCurrentApp(appObj.payload.data);
      return appObj;
    } catch (error) {
      // Handle error
      toast('Unable to create app at the moment. Please try again.');
    }
  };

  const suggestProductFeatures = async productDetails => {
    setLoadingText(`Reviewing the Features required by the application "${productDetails.productName}"`);
    let features;
    let attempts = 0;
    const { payload } = await dispatch(suggestProductFeature(productDetails));
    features = payload['data'];
    return features;
  };

  const createAppFeatures = async (features, app) => {
    try {
      setEntitiesLoading(true);
      let aiSuggestedFeatures = [];
      if (features && features.length > 0) {
        await Promise.all(
          features.map(async data => {
            let featureEntity = {
              ...data,
              app: app,
              name: data?.productFeatureName,
              description: data?.productFeatureDescription,
            };
            // Clear the type field if it's not needed
            // if (data?.type != null) {
            //   featureEntity = { ...featureEntity, type: null };
            // }
            // Remove keywords like "feature", "features", "Feature" from the feature name
            if (
              data?.productFeatureName.includes('feature') ||
              data?.productFeatureName.includes('features') ||
              data?.productFeatureName.includes('Feature')
            ) {
              let featureName = data?.productFeatureName.replace(/(?:^|\s)(?:feature|features?)(?::|\s)?/gi, '');
              featureEntity = { ...featureEntity, name: featureName };
            }
            let savedFeature = await dispatch(createAppFeature(featureEntity));
            console.log('savedFeature', savedFeature);
            aiSuggestedFeatures.push(savedFeature.payload.data.id);
          })
        );
      }
      // setLoadingText('');
      return aiSuggestedFeatures;
      // return true;
    } catch (e) {
      console.log('F3@t#3 3x7 :: ', e);
      setEntitiesLoading(false);
      return false;
    }
  };

  const handleAiResponse = async (productContext, userPrompt) => {
    try {
      let product;
      let appObj;
      if (Object.keys(currentProduct).length === 0) {
        product = await createProduct(productContext, userPrompt);
      }
      if (Object.keys(currentApp).length === 0) {
        appObj = await createApp(product, userPrompt);
      }

      if (appObj && product) {
        await dispatch(createVaultUser(appObj.payload.data.id));
        await dispatch(createDefaultDeploymentCreds(product?.payload?.data?.id));
        // let coreFunctionalities = await dispatch(getTemplateFeatures({}));

        // // coreFunctionality.payload.data
        // if (coreFunctionalities?.payload?.data && coreFunctionalities.payload.data.length > 0) {
        //   coreFunctionalities.payload.data.map(async coreFunctionality => {
        //     if (coreFunctionality.name.toLowerCase() == 'user management') {
        //       await dispatch(copyCoreFunctionality({ productId: product?.payload?.data?.id, coreFunctionalityId: [coreFunctionality.id] }));
        //     }
        //   });
        // }
      }

      let appID = currentApp.id ? currentApp.id : appObj.payload.data.id;
      // await dispatch(getAppFeatures({ query: `appId.equals=${appID}` }));
      const productDetails = {
        productName: currentProduct.id ? currentProduct.name : product?.payload?.data?.name,
        productDescription: currentProduct.id ? currentProduct.description : product?.payload?.data?.description,
        appId: currentApp?.id ? currentApp?.id : appObj?.payload?.data?.id,
      };
      setEntitiesLoading(true);
      const Aifeatures = await suggestProductFeatures(productDetails);
      const includesFeatureKeyword = Aifeatures.some(feature => /Feature\s+\d+/.test(feature.name));
      if (Aifeatures && Aifeatures.length > 0 && !includesFeatureKeyword) {
        // let app = currentApp.id ? currentApp : appObj.payload.data;
        setProductFeatures(Aifeatures);
        // await createAppFeatures(Aifeatures, app);
      } else {
        toast('AI is busy at the moment, please retry again!!');
        setEntitiesLoading(false);
        setButtonTxt('Retry');
        return;
      }

      // const { payload } = await dispatch(getEntities({ query: `appId.equals=${appID}` }));
      // setProductFeatures(payload['data']);
      setCurrentStep(prevStep => prevStep + 1);
      setEntitiesLoading(false);
      setButtonTxt('Next');
    } catch (error) {
      setButtonTxt('Retry');
      toast('AI is busy at the moment, please retry again!!');
      setEntitiesLoading(false);
    }
  };

  const createSelectedFeatures = async () => {
    // Start loading state
    setLoadingText('Thank you!! Your input helps');
    setEntitiesLoading(true);

    let selectedFeatureresult = await createAppFeatures(selectedFeatures, currentApp);
    let selectedFeatureGetresult;
    if (selectedFeatureresult) {
      selectedFeatureGetresult = await dispatch(getAppFeatures({ query: `appId.equals=${currentApp.id}` }));
    }
    console.log(selectedFeatureresult, 'selectedFeatureresult');
    console.log(selectedFeatureGetresult, 'selectedFeatureGetresult');

    // let featuresList = selectedFeatureGetresult?.payload?.data || [];
    let featuresList = selectedFeatureGetresult?.payload?.data.filter(item => selectedFeatureresult.includes(item.id));
    console.log('selected featuresList', featuresList);
    setLatestFeatures(featuresList);
    if (continueFeaturesWizard) {
      await createEntitiesAiSuggested(featuresList);
    } else {
      setCurrentStep(prevStep => prevStep + 1);
    }
    setLoadingText('');
    setEntitiesLoading(false);
  };

  const createEntitiesAiSuggested = async featuresList => {
    try {
      if (featuresList && featuresList.length > 0) {
        await createEntitiesForFeatures(featuresList);
      }
    } catch (error) {
      console.error('Error creating entities:', error);
      setButtonTxt('Retry');
      toast('AI is busy at the moment, please retry again!!');
      setEntitiesLoading(false);
    } finally {
      // Stop loading state
      setLoadingText('');
      setEntitiesLoading(false);
      setCurrentStep(prevStep => prevStep + 1);
    }
  };

  const createEntitiesForFeatures = async savedfeatures => {
    // debugger;
    setEntitiesLoading(true);
    setLoadingText('Figuring out the needed Data Models');
    let entitiesCreated = [];
    let appEntities;
    if (
      (Object.keys(productEntities).length === 0 ||
        (productEntities.hasOwnProperty('payload') &&
          productEntities.payload.hasOwnProperty('data') &&
          productEntities.payload.data.length === 0)) &&
      props.activeStep !== 'Entities'
    ) {
      for (const feature of savedfeatures) {
        const appFeatureEntity = {
          productName: currentProduct?.name,
          productDescription: currentProduct?.description,
          featureName: feature?.name,
          featureDescription: feature?.description,
          appId: currentApp?.id,
        };
        // Suggest and create feature entities
        await handleFeatureEntities(appFeatureEntity, entitiesCreated, feature);
        // Suggest and create entity fields
        appEntities = await dispatch(getAppEntities({ query: `featureId.equals=${feature.id}` }));
        await handleEntityFields(appEntities);
      }
    }

    appEntities = await dispatch(getAppEntities({ query: `appId.equals=${currentApp.id}` }));
    if (props.activeTab == 'Entities') {
      console.log(props.savedEntities, 'props.savedEntities');

      await handleEntityFields(appEntities);
    }
    setProductEntities(appEntities);

    // Suggest and create entity relationships
    await handleEntityRelationships(appEntities);
    await handleAppScreens(currentProduct);
    let relationships = await dispatch(getAppEntityRelationships({ query: `appId.equals=${currentApp.id}` }));
    if (relationships.payload.data.length > 0) {
      if (props.activeTab !== 'Features') {
        setCurrentStep(prevStep => prevStep + 1);
      }
      setEntitiesLoading(false);
    } else {
      await handleEntityRelationships(appEntities);
      if (props.activeTab !== 'Features') {
        setCurrentStep(prevStep => prevStep + 1);
      }
      setEntitiesLoading(false);
    }
  };

  const handleFeatureEntities = async (appFeatureEntity, entitiesCreated, feature) => {
    let existingAppEntities = await dispatch(getAppEntities({ query: `appId.equals=${currentApp.id}` }));
    const { payload } = await dispatch(suggestFeatureEntities(appFeatureEntity));
    // Filter out entities that already exist
    let result = payload['data'];
    let temp_result = [];
    result.map(ele => {
      let temp_obj = { ...ele, key: `${ele.featureEntityName}-${Date.now()}`, feature: feature };
      temp_result.push(temp_obj);
    });

    let temp_AiSuggestEntities = temp_result.filter(function (array_el) {
      return (
        existingAppEntities?.payload?.data?.filter(function (anotherOne_el) {
          return anotherOne_el.name == array_el.featureEntityName;
        }).length == 0
      );
    });

    // Create app entities
    for (const appEntity of temp_AiSuggestEntities) {
      const entity = {
        ...appEntity,
        type: 'Standard',
        app: currentApp,
        feature,
        name: appEntity?.featureEntityName,
        description: appEntity?.featureEntityDescription,
      };

      if (appEntity.featureEntityName.toLowerCase() !== 'user' && appEntity.featureEntityName.toLowerCase() !== 'users') {
        await dispatch(createAppEntity(entity));
        entitiesCreated.push(appEntity);
      }
    }
  };

  const handleEntityFields = async entitiesResult => {
    if (entitiesResult?.payload?.data) {
      let featuresList = props.savedEntities ? entitiesResult?.payload?.data.filter(item => props.savedEntities.includes(item.id)) : [];
      console.log('featuresList', featuresList);
      let suggestedEntitiesSaved = props.savedEntities?.length > 0 ? featuresList : entitiesResult.payload.data;
      for (const entity of suggestedEntitiesSaved) {
        setLoadingText(`Now working on getting Fields for Model ${entity.name}`);
        // selectedFeatures.filter(obj => obj.productFeatureName != feature.productFeatureName);
        // if(exists){
        let suggestedEntitiesNameArr = [];
        suggestedEntitiesSaved?.length > 0 &&
          suggestedEntitiesSaved.map(item => {
            suggestedEntitiesNameArr.push(item.name);
          });
        const suggestEntityField = {
          entityName: entity.name,
          entityDescription: entity.description,
          appId: currentApp?.id,
          appEntities: suggestedEntitiesNameArr,
        };
        const existingAppEntitiesFields = await dispatch(getAppEntityFields({ query: `appEntityId.equals=${entity?.id}` }));
        const { payload } = await dispatch(suggestEntityFields(suggestEntityField));
        let result = payload['data'];
        let temp_result = [];
        result.map(ele => {
          temp_result.push({ ...ele });
        });

        let temp_AiSuggestEntities = temp_result.filter(function (array_el) {
          return (
            existingAppEntitiesFields?.payload.data?.filter(function (anotherOne_el) {
              return anotherOne_el.name == array_el.entityFieldName;
            }).length == 0
          );
        });
        if (temp_AiSuggestEntities) {
          for (const appEntityField of temp_AiSuggestEntities) {
            if (appEntityField?.entityFieldName?.toLowerCase() !== 'id' && entity.type.toLowerCase() == 'standard') {
              const entityFields = {
                ...appEntityField,
                appEntity: entity,
                name: appEntityField?.entityFieldName,
                description: appEntityField?.entityFieldDescription,
                type: appEntityField?.entityFieldType?.dataType,
              };

              await dispatch(createAppEntityField(entityFields));
            }
          }
        }
        // }
      }
    }
  };

  const handleEntityRelationships = async entitiesResult => {
    setLoadingText('Working On Relationships');
    if (entitiesResult?.payload?.data) {
      let entitiesNamesString = entitiesResult.payload.data
        .filter(it => it.type != 'Enum')
        .map(items => items.name)
        .join(',');
      const entityDetails = {
        entityNames: entitiesNamesString,
        appId: currentApp?.id,
      };
      const { payload } = await dispatch(suggestEntityRelationship(entityDetails));

      let aiSuggestedRel = [];
      if (payload && payload['data']) {
        let result = payload['data'];
        result.map(ele => {
          let typeSplit = ' ';
          if (ele?.relationshipType?.includes('-')) {
            typeSplit = '-';
          }

          if (ele?.fromEntity != null && ele?.toEntity != null && ele?.relationshipType != null) {
            let updateType = ele?.relationshipType
              ?.split(typeSplit)
              .map(item => item.charAt(0).toUpperCase() + item.substring(1))
              .join('');
            ele = { ...ele, relationshipType: updateType };
            aiSuggestedRel.push({ ...ele });
          }
        });
      }
      aiSuggestedRel.map(async data => {
        let entityRelationship = {
          type: data?.relationshipType,
          app: currentApp,
          appEntityTo: entitiesResult.payload.data.find(item => item.name == data?.toEntity),
          appEntityFrom: entitiesResult.payload.data.find(item => item.name == data?.fromEntity),
        };
        if (entityRelationship.appEntityTo && entityRelationship.appEntityFrom) {
          await dispatch(createRelationshipEntity(entityRelationship));
        }
      });
    }
  };

  const handleAppScreens = async currentProduct => {
    setLoadingText('Finalizing the App Screens');
    const productDetails = {
      productName: currentProduct?.name,
      productDescription: currentProduct?.description,
      appId: currentApp?.id,
    };
    const appScreensResult = await dispatch(suggestAppScreens(productDetails));
    if (appScreensResult?.payload?.data) {
      for (const appScreen of appScreensResult.payload.data) {
        let appScreenPayload = {
          ...appScreen,
          app: currentApp,
          accessRoles: appScreen?.accessRole,
        };
        const appScreenEntity = await dispatch(createAppScreen(appScreenPayload));
        if (appScreenEntity?.payload?.data) {
          let appEntities = await dispatch(getAppEntities({ query: `appId.equals=${currentApp.id}` }));
          if (appEntities?.payload?.data) {
            let suggestedAppEntity = appEntities.payload.data.find(it => it.name == appScreen?.screenComponentSuggestion?.entity);
            let appEntityFields;
            if (suggestedAppEntity?.id) {
              appEntityFields = await dispatch(getAppEntityFields({ query: `appEntityId.equals=${suggestedAppEntity?.id}` }));
            }
            let suggestedAppEntityFieldsString = [];
            appEntityFields?.payload?.data.map((field, index) => {
              const existingItem = suggestedAppEntityFieldsString.includes(`${field.name}:${field.type}`);
              if (!existingItem) suggestedAppEntityFieldsString.push(`${field.name}:${field.type}`);
            });
            let screenComponentPayload = {
              name: appScreen?.title,
              layout: appScreen?.screenComponentSuggestion?.layout,
              layoutTemplate: appScreen?.screenComponentSuggestion?.layoutTemplate,
              appEntity: suggestedAppEntity,
              appScreen: appScreenEntity?.payload?.data,
              entityFields: suggestedAppEntityFieldsString.toString(),
            };
            await dispatch(createScreenComponent(screenComponentPayload));
          }
        }
      }
    }
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={() => !entitiesLoading && toggle()} className="modalDesignNew">
        {currentStep === 1 && entitiesLoading !== true && (
          <ModalHeader>
            Let's Begin <br></br>
            <span>Describe your product</span>
          </ModalHeader>
        )}
        <ModalBody>
          {entitiesLoading == true ? (
            <>
              <h2 className="wizard-heading gif_heading">{loadingText}</h2>
              <img className="modalIimg-dgn" src="themeContent/entitiesloader.gif" />
            </>
          ) : (
            <>
              {currentStep === 1 && (
                <>
                  <ValidatedForm defaultValues={{}} onSubmit={} className="w-100 d-flex gap-3">
                    <ValidatedField
                      className="col-md-12 mb-0 modal-input"
                      id="userPrompt"
                      name="userPrompt"
                      type="textarea"
                      data-cy="userPrompt"
                      value={userPrompt}
                      onChange={handleUserPrompt}
                      placeholder="Build a simple to do list for a family so organize their chores."
                      validate={{
                        required: { value: true, message: 'Please enter valid prompt!!' },
                      }}
                    />
                  </ValidatedForm>
                </>
              )}

              {currentStep === 2 && productFeatures.length > 0 && (
                <>
                  <ProductFeaturesList
                    AiSuggestFeatures={productFeatures}
                    app={currentApp}
                    onSelectedFeatureIdChange={handleSelectedFeatureIds}
                  />
                </>
              )}
              {currentStep === 3 && (
                <>
                  <div className="modal-success">
                    <ProductCreatedIcon />
                    <h2>Selected {props.activeTab ? props.activeTab : 'Entities'} Added Successfully</h2>
                    <Button color="back" className="me-2" onClick={() => toggle()}>
                      Close
                    </Button>
                    <Button
                      color="save"
                      onClick={async () => {
                        if (props.activeTab == 'Features') {
                          createEntitiesAiSuggested(latestFeatures);
                        } else if (props.activeTab == 'Relationships') {
                          setEntitiesLoading(true);
                          await handleAppScreens(currentProduct);
                          setCurrentStep(prevStep => prevStep + 1);
                          setEntitiesLoading(false);
                        } else {
                          createEntitiesForFeatures(latestFeatures);
                        }
                      }}
                    >
                      Continue with the Wizard
                    </Button>
                  </div>
                </>
              )}
              {currentStep === 4 && (
                <>
                  <div className="modal-success">
                    <ProductCreatedIcon />
                    <h2>Your Product has been {props.activeStep ? 'Updated' : 'Created'} Successfully</h2>
                    <Button href={`product/${currentProduct.id}/app/${currentApp.id}`}>Let's Review</Button>
                  </div>
                </>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {currentStep <= 2 ? (
            <>
              {entitiesLoading == true ? (
                ''
              ) : (
                <Button
                  disabled={!enableNextBtn || (currentStep == 2 && selectedFeatures.length == 0)}
                  className="btn btn-edit"
                  onClick={() => {
                    if (currentStep === 1) {
                      createProductApp();
                    } else {
                      createSelectedFeatures();
                    }
                  }}
                >
                  {buttonTxt}
                </Button>
              )}
            </>
          ) : (
            ''
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ProductModal;
