import dayjs from 'dayjs';
import { IEntityRelationship } from '../../shared/model/entity-relationship.model';
import { IScreenComponent } from '../../shared/model/screen-component.model';
import { IAppEntityField } from '../../shared/model/app-entity-field.model';
import { IProductUserRoleAccess } from '../../shared/model/product-user-role-access.model';
import { IEnumMember } from '../../shared/model/enum-member.model';
import { IApp } from '../../shared/model/app.model';
import { IFeature } from '../../shared/model/feature.model';
import { IFunctionality } from '../../shared/model/functionality.model';
import { ICoreEntity } from '../../shared/model/core-entity.model';
import { EntityType } from '../../../../../app/shared/model/enumerations/entity-type.model';

export interface IAppEntity {
  id?: number;
  name?: string;
  lastSyncTimestamp?: string | null;
  type?: EntityType;
  userId?: number | null;
  description?: string | null;
  entityRelationshipTos?: IEntityRelationship[] | null;
  screenComponents?: IScreenComponent[] | null;
  appEntityFields?: IAppEntityField[] | null;
  productUserRoleAccesses?: IProductUserRoleAccess[] | null;
  entityRelationshipFroms?: IEntityRelationship[] | null;
  enumMembers?: IEnumMember[] | null;
  feature?: IFeature | null;
  app?: IApp | null;
  functionality?: IFunctionality | null;
  coreEntity?: ICoreEntity | null;
}

export const defaultValue: Readonly<IAppEntity> = {};
