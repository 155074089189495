import { IActionComponent } from 'app/shared/model/action-component.model';
import { IAppEndPointController } from 'app/shared/model/app-end-point-controller.model';
import { RestMethod } from 'app/shared/model/enumerations/rest-method.model';

export interface IAppEndPoint {
  id?: number;
  path?: string | null;
  method?: RestMethod | null;
  description?: string | null;
  pseudoCode?: string | null;
  outputsList?: boolean | null;
  paramType?: string | null;
  paramName?: string | null;
  paramTypes?: string | null;
  paramNames?: string | null;
  outputType?: string | null;
  functionName?: string | null;
  paramInPath?: boolean | null;
  injectedObjects?: string | null;
  typesToImport?: string | null;
  actionComponent?: IActionComponent | null;
  appEndPointController?: IAppEndPointController | null;
}

export const defaultValue: Readonly<IAppEndPoint> = {
  outputsList: false,
  paramInPath: false,
};
