import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Col, Container, Row, Spinner, FormGroup, Input } from 'reactstrap';
import { Translate, ValidatedForm, ValidatedField, translate, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from '../../config/store';
import { getEntities, deleteEntity, getEntity as getAppScreenEntity } from './app-screen.reducer';
import {
  getEntities as getScreenComponents,
  createEntity,
  updateEntity,
  getEntity,
  partialUpdateEntity,
} from '../screen-component/screen-component.reducer';
import { deleteEntity as deleteComponent } from '../screen-component/screen-component.reducer';
import DynamicSelect from '../../modules/dynamicSelectComponent/DynamicSelect';
import { faXmark, faPlus } from '@fortawesome/free-solid-svg-icons';
import { AppScreenEditType } from '../../shared/model/enumerations/app-screen-edit-type.model';
import { AppScreenViewType } from '../../shared/model/enumerations/app-screen-view-type.model';
import { FormActionType } from '../../../../../app/shared/model/enumerations/form-action-type.model';
import { getEntities as getEntityFields } from '../app-entity-field/app-entity-field.reducer';
import { getEntities as getRelatedEntities } from '../entity-relationship/entity-relationship.reducer';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { DESC, ITEMS_PER_PAGE } from '../../../../../app/shared/util/pagination.constants';
import { getEntity as getAppEntity } from '../app-entity/app-entity.reducer';
import { DeleteIcon } from '../../shared/assets/svg-icon/icon';
import { ButtonActionType } from '../../../../../app/shared/model/enumerations/button-action-type.model';
import { getEntities as getEndPointControllers } from '../app-end-point-controller/app-end-point-controller.reducer';
import { getEntities as getEndpoints } from '../app-end-point/app-end-point.reducer';
import ActionButtons from './action-buttons';
import { createEntity as createActionComponent } from '../../../../../app/entities/action-component/action-component.reducer';

export const ComponentUpdate = props => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  // const { id } = useParams<'id'>();
  const { appId } = useParams<'appId'>();
  const { productId } = useParams<'productId'>();
  const { appScreenId, showModal, componentId, componentData } = props;
  const [loadModal, setLoadModal] = useState(false);
  const isNew = componentId === undefined;

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', DESC), location.search)
  );
  const buttonActionTypeValues = Object.keys(ButtonActionType);

  const appScreens = useAppSelector(state => state.appScreen.entities);
  const updateSuccess = useAppSelector(state => state.screenComponent.updateSuccess);
  const loadingComponent = useAppSelector(state => state.screenComponent.loading);
  const appScreenComponents = useAppSelector(state => state.screenComponent.entities);
  const loadingFields = useAppSelector(state => state.appEntityField.loading);
  const screenComponent = useAppSelector(state => state.screenComponent.entity);
  const updating = useAppSelector(state => state.screenComponent.updating);
  const appEntityFieldEntities = useAppSelector(state => state.appEntityField.entities);
  const relationshipList = useAppSelector(state => state.entityRelationship.entities);
  const appEntities = useAppSelector(state => state.appEntity.entities);
  const appEntitiesLoading = useAppSelector(state => state.appEntity.loading);
  const appEndPointControllerList = useAppSelector(state => state.appEndPointController.entities);

  const [activeComponentData, setActiveComponentData] = useState(componentData);
  const [appEntityList, setAppEntityList] = useState();
  const [entityLayout, setEntityLayout] = useState();
  const [subLayoutList, setSubLayoutList] = useState(['card', 'table']);
  const [subLayout, setSubLayout] = useState();
  const [defaultSubLayout, setDefaultSubLayout] = useState();
  const [selectEntityField, setSelectEntityField] = useState([]);
  const [entityField, setEntityField] = useState([]);
  // const [selectedItem, setSelectedItem] = useState([]);
  const [filterEntityData, setFilterEntityData] = useState();
  const [filterEntityText, setFilterEntityText] = useState();
  const [filterRelatedEntity, setFilterRelatedEntity] = useState();
  const [filterData, setFilterData] = useState();
  const [selectedRelatedEntityId, setSelectedRelatedEntityId] = useState();
  const [formAction, setFormAction] = useState();
  const [redirectScreen, setRedirectScreen] = useState();
  const [formSuccessMessage, setFormSuccessMessage] = useState();
  const [formCustomEndpoint, setFormCustomEndpoint] = useState();
  const [relatedScreenComponents, setRelatedScreenComponents] = useState();
  const [selectRelatedEntityField, setSelectRelatedEntityField] = useState();
  const [relatedComponent, setRelatedComponent] = useState();
  const [mapW, setMapW] = useState();
  const [mapH, setMapH] = useState();
  const [lat, setLat] = useState();
  const [lang, setLang] = useState();
  const [searchEnable, setSearchEnable] = useState();
  const [searchFields, setSearchFields] = useState();
  const [sortEnable, setSortEnable] = useState();
  const [sortFields, setSortFields] = useState([]);
  const [filterEnable, setFilterEnable] = useState();
  const [filterFields, setFilterFields] = useState([]);
  const [activeComponentId, setActiveComponentId] = useState();
  // const [addCreateButton, setAddCreateButton] = useState();
  // const [buttonRedirect, setButtonRedirect] = useState();
  // const [createButtonText, setCreateButtonText] = useState();
  const [relatedEntitiesList, setRelatedEntitiesList] = useState();
  const [endPointList, setEndPointList] = useState();

  const appEntityId = componentData?.appEntity?.id;
  let defaultFilterValue;

  const fieldsStyle = {
    padding: '2px 12px',
    minHeight: '28px',
    fontSize: '14px',
    fontWeight: '400',
    color: '#8c9296',
    borderRadius: '16px',
  };

  useEffect(() => {
    // console.log("componentData@@", componentData);
    componentData != undefined ? setActiveComponentData(componentData) : '';
    if (activeComponentId != componentData.id) {
      setActiveComponentId(componentData.id);
    }
  }, [componentData]);

  useEffect(() => {
    appEntityId != undefined && dispatch(getEntityFields({ query: 'appEntityId.equals=' + appEntityId }));
  }, [activeComponentId]);

  useEffect(() => {
    if (loadingFields == true) {
      setEntityField([]);
    } else if (loadingFields == false && activeComponentData?.entityFields) {
      setEntityField(activeComponentData?.entityFields?.split(','));
    }
  }, [loadingFields]);

  // console.log("activeComponentData", activeComponentData);

  useEffect(() => {
    if (appEntities?.length > 0) {
      const filteredEntities = appEntities.filter(it => it.type == 'Standard');
      setAppEntityList(filteredEntities);
    }
  }, [appEntities]);

  useEffect(() => {
    dispatch(
      getRelatedEntities({
        query: appId ? `appId.equals=${appId}` : '',
        // ${appEntityId ? `&appEntityFromId.equals=${Number(appEntityId)}` : ''}
      })
    );
    if (activeComponentData != undefined && Object.entries(activeComponentData).length !== 0) {
      dispatch(getEntityFields({ query: 'appEntityId.equals=' + appEntityId }));
      {
        activeComponentData?.appScreen?.template == '2-col' ? subLayoutList.push('sidebar') : '';
      }
      setDefaultSubLayout(activeComponentData.layoutTemplate);
      if (activeComponentData?.filter != (undefined || null)) {
        defaultFilterValue = JSON.parse(activeComponentData?.filter);
        if (defaultFilterValue?.id) {
          setFilterEntityData({ filterField: 'id' });
          setFilterEntityText({ filterId: defaultFilterValue?.id });
        } else if (defaultFilterValue?.relatedentity) {
          setFilterEntityData({ filterField: 'relatedentity' });
          setFilterRelatedEntity({ relatedEntity: defaultFilterValue?.relatedentity });
        }
      }
      // setNavigateViewScreenType({ linkScreen: activeComponentData?.appScreenViewType });
    }
    dispatch(
      getEndPointControllers({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}&appId.equals=${appId}`,
      })
    );
  }, []);

  useEffect(() => {
    let endpointControllersIds = [];
    let endPoints = [];
    if (appEndPointControllerList?.length > 0) {
      appEndPointControllerList.map(item => {
        endpointControllersIds.push(item.id);
      });
    }

    Promise.all(endpointControllersIds).then(async values => {
      values?.length > 0 &&
        values.map(async it => {
          const { payload } = await dispatch(
            getEndpoints({
              page: paginationState.activePage - 1,
              size: paginationState.itemsPerPage,
              sort: `${paginationState.sort},${paginationState.order}&appEndPointControllerId.equals=${it}`,
            })
          );
          payload?.data?.map(item => {
            endPoints.push(item);
          });
          setEndPointList(endPoints);
        });
    });
  }, [appEndPointControllerList]);

  useEffect(() => {
    if (relationshipList?.length > 0) {
      setRelatedEntitiesList(relationshipList.filter(it => it.appEntityFrom?.id == appEntityId || it.appEntityTo?.id == appEntityId));
      // setRelatedEntitiesFromList(relationshipList.filter(it => it.appEntityTo?.id == appEntityId));
    }
  }, [relationshipList]);

  useEffect(() => {
    setFilterData(defaultFilterValue);
  }, [defaultFilterValue]);

  useEffect(() => {
    // setEntityField([]);
    setSelectEntityField([]);
    setSortFields([]);
    setFilterFields([]);
    // setEntityField(activeComponentData?.entityFields?.split(','));
    if (activeComponentData?.entityFields?.length > 0) {
      setSelectEntityField(activeComponentData?.entityFields?.split(','));
    }
    if (activeComponentData?.sortFields?.length > 0) {
      setSortFields(activeComponentData?.sortFields?.split(','));
    }
    if (activeComponentData?.filterFields?.length > 0) {
      setFilterFields(activeComponentData?.filterFields?.split(','));
    }
    // appEntityId != undefined && dispatch(getEntityFields({ query: 'appEntityId.equals=' + appEntityId }));
  }, [activeComponentData]);

  // console.log('entityField', entityField);
  // console.log("selectEntityField", selectEntityField);

  useEffect(() => {
    screenComponent != undefined ? setDefaultSubLayout(screenComponent.layoutTemplate) : '';
  }, [screenComponent]);

  useEffect(() => {
    if (appScreenComponents?.length > 0 && !appEntitiesLoading) {
      const components = appScreenComponents?.filter(
        it => it.screenComponentParent?.id == activeComponentData?.id && it.screenComponentParent != null
      );
      setRelatedScreenComponents(components);
    }
  }, [appScreenComponents, activeComponentData]);

  useEffect(() => {
    {
      appEntityId != undefined
        ? dispatch(
            getRelatedEntities({
              query: appId ? `appId.equals=${appId}` : '',
            })
          )
        : '';
    }
  }, [appEntityId]);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: 0,
        size: 100,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: `appId.equals=${appId}`,
      })
    );
  };

  useEffect(() => {
    if (updateSuccess == true && appScreenId != undefined) {
      dispatch(getScreenComponents({ query: 'appScreenId.equals=' + appScreenId }));
      getAllEntities();
    }
  }, [updateSuccess]);

  const actionAdded = () => {
    console.log('Helloooooo');
    dispatch(getScreenComponents({ query: 'appScreenId.equals=' + appScreenId }));
    getAllEntities();
  };

  const removeEntity = async id => {
    await dispatch(deleteComponent(id));
    dispatch(getScreenComponents({ query: 'appScreenId.equals=' + appScreenId }));
  };

  const handleSelectedCheckbox = async data => {
    if (data.checked) {
      const existingField = selectEntityField?.includes(`${data.field.name}:${data.field.type}`);
      if (!existingField) selectEntityField?.push(`${data.field.name}:${data.field.type}`);
      await dispatch(updateEntity({ ...activeComponentData, entityFields: selectEntityField.toString() }));
    } else if (data.checked == false) {
      const existingField = selectEntityField?.includes(`${data.field.name}:${data.field.type}`);
      if (existingField) {
        const arr = selectEntityField.filter(it => it != `${data.field.name}:${data.field.type}`);
        await dispatch(updateEntity({ ...activeComponentData, entityFields: arr.toString() }));
      }
    }
  };

  const handleSortFields = async data => {
    if (data.checked) {
      const existingField = sortFields?.includes(`${data.field.name}:${data.field.type}`);
      if (!existingField) sortFields?.push(`${data.field.name}:${data.field.type}`);
      await dispatch(updateEntity({ ...activeComponentData, sortFields: sortFields.toString() }));
    } else if (data.checked == false) {
      const existingField = sortFields?.includes(`${data.field.name}:${data.field.type}`);
      if (existingField) {
        const arr = sortFields.filter(it => it != `${data.field.name}:${data.field.type}`);
        await dispatch(updateEntity({ ...activeComponentData, sortFields: arr.toString() }));
      }
    }
  };

  const handleFilterFields = async data => {
    if (data.checked) {
      const existingField = filterFields?.includes(`${data.field.name}:${data.field.type}`);
      if (!existingField) filterFields?.push(`${data.field.name}:${data.field.type}`);
      await dispatch(updateEntity({ ...activeComponentData, filterFields: filterFields.toString() }));
    } else if (data.checked == false) {
      const existingField = filterFields?.includes(`${data.field.name}:${data.field.type}`);
      if (existingField) {
        const arr = filterFields.filter(it => it != `${data.field.name}:${data.field.type}`);
        await dispatch(updateEntity({ ...activeComponentData, filterFields: arr.toString() }));
      }
    }
  };

  useEffect(() => {
    if (selectRelatedEntityField != undefined && relatedComponent != undefined) {
      const relatedComponentEntity = {
        ...relatedComponent,
        entityFields: selectRelatedEntityField?.toString(),
      };
      dispatch(updateEntity(relatedComponentEntity));
    }
  }, [selectRelatedEntityField]);

  const handleAddCreateButton = (e, related_entity) => {
    let relatedComponentEntity;
    if (e.target.checked == true) {
      relatedComponentEntity = {
        ...related_entity,
        createButtonEnable: true,
      };
    } else if (e.target.checked == false) {
      relatedComponentEntity = {
        ...related_entity,
        createButtonEnable: false,
      };
    }
    dispatch(updateEntity(relatedComponentEntity));
  };

  useEffect(() => {
    let data = {};
    if (filterEntityData?.filterField == 'id') data = { id: filterEntityText?.filterId };
    else if (filterEntityData?.filterField == 'relatedentity') data = { relatedentity: filterRelatedEntity?.relatedEntity };
    setFilterData(data);
  }, [filterEntityData, filterEntityText, filterRelatedEntity]);

  let entity;
  useEffect(() => {
    entity = {
      ...activeComponentData,
    };
    // console.log("updateInnnn", entity);
    if (entityLayout != undefined) entity = { ...entity, layout: entityLayout.layout };
    if (subLayout != undefined) entity = { ...entity, layoutTemplate: subLayout.sub_layout };
    // Filter Type OR (Text/RelatedEntity) Change
    if (filterEntityData != undefined) entity = { ...entity, filter: JSON.stringify(filterEntityData.filterField) };
    if (filterEntityText != undefined) entity = { ...entity, filter: JSON.stringify(filterData) };
    if (filterRelatedEntity != undefined) entity = { ...entity, filter: JSON.stringify(filterData) };
    //Form Actions
    if (formAction != undefined) entity = { ...entity, formAction: formAction.action };
    if (redirectScreen != undefined) entity = { ...entity, appScreenformSuccessRedirectScreen: redirectScreen };
    if (formSuccessMessage != undefined) entity = { ...entity, formSuccessMessage: formSuccessMessage.message };
    if (formCustomEndpoint != undefined) entity = { ...entity, formCustomEndpoint: formCustomEndpoint };

    // console.log("updateEntity@@@@", entity);
    dispatch(updateEntity(entity));
  }, [entityLayout, subLayout, filterEntityData, filterData, formAction, redirectScreen, formSuccessMessage, formCustomEndpoint]);

  const handleNavigateView = async args => {
    let entity = { ...activeComponentData };
    // console.log('args.type', args);
    if (args.type != undefined) {
      entity = { ...entity, appScreenViewType: args.type, screenComponentDetails: null, appScreenviewDetail: null };
    } else if (args.componentId) {
      const component = appScreenComponents.find(it => it.id == args.componentId);
      entity = { ...entity, screenComponentDetails: component, appScreenviewDetail: null };
    } else if (args.screenId) {
      const screen = appScreens.find(it => it.id == args.screenId);
      entity = { ...entity, screenComponentDetails: null, appScreenviewDetail: screen };
    }
    dispatch(updateEntity(entity));
  };

  const handleNavigateEdit = async args => {
    let entity = { ...activeComponentData };
    if (args.type != undefined) {
      entity = { ...entity, appScreenEditType: args.type, screenComponentEdit: null, appScreenviewEdit: null };
    } else if (args.componentId) {
      const component = appScreenComponents.find(it => it.id == args.componentId);
      entity = { ...entity, screenComponentEdit: component, appScreenviewEdit: null };
    } else if (args.screenId) {
      const screen = appScreens.find(it => it.id == args.screenId);
      entity = { ...entity, screenComponentEdit: null, appScreenviewEdit: screen };
    }
    dispatch(updateEntity(entity));
  };

  useEffect(() => {
    if (selectedRelatedEntityId != undefined) {
      const appEntityToValue = relatedEntitiesList?.filter(it => it.appEntityTo?.id.toString() == selectedRelatedEntityId?.id.toString());
      let appEntityFromValue;
      if (appEntityToValue.length == 0) {
        appEntityFromValue = relatedEntitiesList?.filter(it => it.appEntityFrom?.id.toString() == selectedRelatedEntityId?.id.toString());
      }
      const componentEntity = {
        appEntity: appEntityToValue?.length > 0 ? appEntityToValue[0].appEntityTo : appEntityFromValue[0].appEntityFrom,
        appScreen: { id: appScreenId },
        screenComponentParent: activeComponentData,
      };
      dispatch(createEntity(componentEntity));
    }
  }, [selectedRelatedEntityId]);

  useEffect(() => {
    entity = {
      ...activeComponentData,
    };
    if (mapW != undefined) entity = { ...entity, mapW: mapW };
    if (mapH != undefined) entity = { ...entity, mapH: mapH };
    if (lat != undefined) entity = { ...entity, lat: lat };
    if (lang != undefined) entity = { ...entity, lang: lang };
    // console.log("entity mapW && mapH", entity);
    if (mapW != undefined || mapH != undefined || lat != undefined || lang != undefined) {
      dispatch(updateEntity(entity));
    }
  }, [mapW, mapH, lat, lang]);

  useEffect(() => {
    entity = {
      ...activeComponentData,
    };
    if (searchEnable != undefined) entity = { ...entity, searchEnable: searchEnable };
    if (searchFields != undefined) entity = { ...entity, searchFields: searchFields };
    if (sortEnable != undefined) entity = { ...entity, sortEnable: sortEnable };
    if (filterEnable != undefined) entity = { ...entity, filterEnable: filterEnable };
    if (searchEnable != undefined || searchFields != undefined || sortEnable != undefined || filterEnable != undefined) {
      dispatch(updateEntity(entity));
    }
  }, [searchEnable, searchFields, sortEnable, filterEnable]);

  const removeRelatedEntity = id => {
    dispatch(deleteComponent(id));
  };

  return (
    <div className="componentBuildForm">
      {activeComponentData && activeComponentData != undefined && (
        <div
          // key={index}
          className="right-scroll"
        >
          <h5 className="entityTitle">
            {' '}
            Entity Name : {activeComponentData?.appEntity?.name}{' '}
            <Button
              className="del-entityScreen deleteComponent"
              id={`deleteapp-${activeComponentData?.appEntity?.id}`}
              onClick={e => {
                removeEntity(activeComponentData?.id);
                showModal(false);
              }}
            >
              <DeleteIcon />
            </Button>
          </h5>
          <Row className="layoutDesign-sction bordr-section">
            <Col md={12}>
              <ValidatedField
                name={`layout-${activeComponentData.name}`}
                type="select"
                className="mb-0"
                style={fieldsStyle}
                // className="component-fields"
                label="Layout :"
                value={activeComponentData.layout}
                // defaultValue={activeComponentData.layout}
                required
                onChange={e => {
                  setEntityLayout({
                    layout: e.target.value,
                  });
                }}
              >
                <option value="">Select One</option>
                <option value="list">List</option>
                <option value="details">Details</option>
                <option value="form">Form</option>
                <option value="map">Map</option>
              </ValidatedField>
            </Col>
            {entityLayout == 'map' ||
              (activeComponentData.layout == 'map' && (
                <Col md={12}>
                  <ValidatedField
                    label={translate('codeconductorApp.screenComponent.mapW')}
                    id="screen-component-mapW"
                    name="mapW"
                    data-cy="mapW"
                    className="mb-0"
                    type="text"
                    // style={fieldsStyle}
                    value={activeComponentData.mapW}
                    onChange={e => {
                      setMapW(e.target.value);
                    }}
                  />
                  <ValidatedField
                    label={translate('codeconductorApp.screenComponent.mapH')}
                    id="screen-component-mapH"
                    name="mapH"
                    data-cy="mapH"
                    className="mb-0"
                    type="text"
                    // style={fieldsStyle}
                    value={activeComponentData.mapH}
                    onChange={e => {
                      setMapH(e.target.value);
                    }}
                  />
                  <ValidatedField
                    label={translate('codeconductorApp.screenComponent.lat')}
                    id="screen-component-lat"
                    name="lat"
                    data-cy="lat"
                    className="mb-0"
                    type="text"
                    // style={fieldsStyle}
                    value={activeComponentData.lat}
                    onChange={e => {
                      setLat(e.target.value);
                    }}
                  />
                  <ValidatedField
                    label={translate('codeconductorApp.screenComponent.lang')}
                    id="screen-component-lang"
                    name="lang"
                    data-cy="lang"
                    className="mb-0"
                    type="text"
                    // style={fieldsStyle}
                    value={activeComponentData.lang}
                    onChange={e => {
                      setLang(e.target.value);
                    }}
                  />
                </Col>
              ))}
            <Col md={12} className="mt-2">
              {/* checkboxes with values "sidebar", "card", "table"*/}
              {activeComponentData && !loadingFields && activeComponentData.layout === 'list' ? (
                <>
                  <Label>Sub Layout : </Label>
                  <FormGroup className="mb-0" key={entityLayout ? entityLayout.screen_id : activeComponentData?.appScreen?.id}>
                    {subLayoutList.map((sub, index) => {
                      return (
                        <>
                          <Input
                            key={index}
                            name={sub}
                            type="radio"
                            className="mx-1"
                            // checked={defaultSubLayout == sub}
                            defaultChecked={activeComponentData.layoutTemplate == sub}
                            // value={sub}
                            id={`sub-layout-${sub}`}
                            onChange={e => {
                              setSubLayout({
                                sub_layout: sub,
                              });
                            }}
                          />
                          <Label check className="me-2 fs-12 fw-500">
                            {sub}
                          </Label>
                        </>
                      );
                    })}
                  </FormGroup>
                </>
              ) : (
                ''
              )}
            </Col>
          </Row>
          {activeComponentData.layout != 'map' && (
            <Row className="field-sction bordr-section">
              <Label>Select Fields : </Label>
              {/* {activeComponentData && !loadingFields && */}
              <Col md={12} className="field-flex">
                {activeComponentData &&
                  !loadingFields &&
                  appEntityFieldEntities?.length > 0 &&
                  appEntityFieldEntities.map(field => {
                    return (
                      <Row>
                        <Col md={12}>
                          <Input
                            type="checkbox"
                            style={{ marginRight: '5px' }}
                            // checked={activeComponentData?.entityFields?.includes(`${field.name}:${field.type}`)}
                            // defaultChecked={entityField?.includes(`${field.name}:${field.type}`)}
                            defaultChecked={activeComponentData?.entityFields?.split(',').includes(`${field.name}:${field.type}`)}
                            onChange={e =>
                              handleSelectedCheckbox({
                                checked: e.target.checked,
                                field: field,
                              })
                            }
                          />
                          <Label className="fw-500 fs-12 mb-1" check style={{ marginRight: '10px' }}>
                            {field.name}
                          </Label>
                        </Col>
                      </Row>
                    );
                  })}
                {loadingFields && appEntityFieldEntities?.length == 0 && (
                  <Spinner size="sm" color="primary">
                    Loading...
                  </Spinner>
                )}
              </Col>
              {/* } */}
            </Row>
          )}
          {activeComponentData.layout != 'map' && (
            <Row className="layoutDesign-sction bordr-section">
              <Label>Filter on: </Label>
              <Col md={12}>
                <ValidatedField
                  className="filter-space"
                  name={`filter-id-${activeComponentData.name}`}
                  type="select"
                  style={fieldsStyle}
                  // value={filterEntityData?.filterField}
                  defaultValue={
                    filterEntityData?.filterField == 'id' ? 'id' : filterEntityData?.filterField == 'relatedentity' ? 'relatedentity' : ''
                  }
                  onChange={e => {
                    setFilterEntityData({
                      filterField: e.target.value,
                    });
                  }}
                >
                  <option value="">Select One</option>
                  <option value="id">Id</option>
                  <option value="relatedentity">Related Entity</option>
                </ValidatedField>
              </Col>
              <Col md={12} className="field-sction bordr-section">
                {filterEntityData && filterEntityData.filterField === 'id' && (
                  <ValidatedField
                    name="id"
                    type="text"
                    className="mb-0"
                    defaultValue={filterEntityText?.filterId}
                    style={fieldsStyle}
                    onChange={e =>
                      setFilterEntityText({
                        filterId: e.target.value,
                      })
                    }
                  ></ValidatedField>
                )}
                {activeComponentData.layout == 'details' && (
                  <ActionButtons
                    relatedEntity={activeComponentData}
                    buttonActionTypeValues={buttonActionTypeValues}
                    actionAdded={actionAdded}
                    appScreenId={appScreenId}
                    appId={appId}
                    activeComponentData={activeComponentData}
                    appScreens={appScreens}
                    endPointList={endPointList}
                  />
                )}
                {filterEntityData &&
                  filterEntityData.filterField === 'relatedentity' &&
                  (relatedEntitiesList?.length > 0 ? (
                    <ValidatedField
                      name={`filter-relatedentity-${filterEntityData.filterField}`}
                      type="select"
                      className="mb-0"
                      style={fieldsStyle}
                      defaultValue={filterRelatedEntity?.relatedEntity}
                      onChange={e => {
                        setFilterRelatedEntity({
                          relatedEntity: e.target.value,
                        });
                      }}
                    >
                      <option value="">Select One</option>
                      {relatedEntitiesList &&
                        relatedEntitiesList.length > 0 &&
                        relatedEntitiesList.map(item => <option value={item?.appEntityTo?.name}>{item?.appEntityTo?.name}</option>)}
                    </ValidatedField>
                  ) : (
                    relatedEntitiesList.length == 0 && <p>No Related Entity</p>
                  ))}
              </Col>
            </Row>
          )}
          {activeComponentData?.layout === 'list' && (
            <div>
              <Row className="layoutDesign-sction bordr-section">
                <Col md={12} className="mt-2">
                  {activeComponentData && !loadingFields ? (
                    <>
                      <Col md={12} className="field-flex">
                        <Input
                          // key={index}
                          name="searchEnable"
                          type="checkbox"
                          style={{ marginRight: '5px' }}
                          className="mx-1"
                          defaultChecked={activeComponentData?.searchEnable}
                          onChange={e => setSearchEnable(e.target.checked)}
                        />
                        <Label className="fw-500 fs-12 mb-1" check style={{ marginRight: '10px' }}>
                          Add Search Bar
                        </Label>
                      </Col>
                    </>
                  ) : (
                    ''
                  )}
                </Col>
                {activeComponentData?.searchEnable && (
                  <Col md={12} className="mt-2">
                    <Label>Search on field:</Label>
                    <FormGroup className="mb-0" key={entityLayout ? entityLayout.screen_id : activeComponentData?.appScreen?.id}>
                      <Col md={12} className="field-flex">
                        {activeComponentData &&
                          !loadingFields &&
                          appEntityFieldEntities?.length > 0 &&
                          appEntityFieldEntities.map(field => {
                            return (
                              <Row>
                                <Col md={12}>
                                  <Input
                                    // key={index}
                                    name={field}
                                    type="radio"
                                    style={{ marginRight: '5px' }}
                                    className="mx-1"
                                    defaultChecked={activeComponentData?.searchFields?.split(',').includes(`${field.name}:${field.type}`)}
                                    onChange={e => {
                                      if (e.target.checked) setSearchFields(`${field.name}:${field.type}`);
                                    }}
                                  />
                                  <Label className="fw-500 fs-12 mb-1" check style={{ marginRight: '10px' }}>
                                    {field.name}
                                  </Label>
                                </Col>
                              </Row>
                            );
                          })}
                        {loadingFields && appEntityFieldEntities?.length == 0 && (
                          <Spinner size="sm" color="primary">
                            Loading...
                          </Spinner>
                        )}
                      </Col>
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <Row className="layoutDesign-sction bordr-section">
                <Col md={12} className="mt-2">
                  {activeComponentData && !loadingFields ? (
                    <>
                      <Col md={12} className="field-flex">
                        <Input
                          // key={index}
                          name="sortEnable"
                          type="checkbox"
                          style={{ marginRight: '5px' }}
                          className="mx-1"
                          defaultChecked={activeComponentData?.sortEnable}
                          onChange={e => setSortEnable(e.target.checked)}
                        />
                        <Label className="fw-500 fs-12 mb-1" check style={{ marginRight: '10px' }}>
                          Enable Sorting
                        </Label>
                      </Col>
                    </>
                  ) : (
                    ''
                  )}
                </Col>
                {activeComponentData?.sortEnable && (
                  <Col md={12} className="mt-2">
                    <Label>Add sort to fields:</Label>
                    <FormGroup className="mb-0" key={entityLayout ? entityLayout.screen_id : activeComponentData?.appScreen?.id}>
                      <Col md={12} className="field-flex">
                        {activeComponentData &&
                          !loadingFields &&
                          appEntityFieldEntities?.length > 0 &&
                          appEntityFieldEntities.map(field => {
                            return (
                              <Row>
                                <Col md={12}>
                                  <Input
                                    // key={index}
                                    name={field}
                                    type="checkbox"
                                    style={{ marginRight: '5px' }}
                                    className="mx-1"
                                    defaultChecked={activeComponentData?.sortFields?.split(',').includes(`${field.name}:${field.type}`)}
                                    onChange={e =>
                                      handleSortFields({
                                        checked: e.target.checked,
                                        field: field,
                                      })
                                    }
                                  />
                                  <Label className="fw-500 fs-12 mb-1" check style={{ marginRight: '10px' }}>
                                    {field.name}
                                  </Label>
                                </Col>
                              </Row>
                            );
                          })}
                        {loadingFields && appEntityFieldEntities?.length == 0 && (
                          <Spinner size="sm" color="primary">
                            Loading...
                          </Spinner>
                        )}
                      </Col>
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <Row className="layoutDesign-sction bordr-section">
                <Col md={12} className="mt-2">
                  {activeComponentData && !loadingFields ? (
                    <>
                      <Col md={12} className="field-flex">
                        <Input
                          // key={index}
                          name="filterEnable"
                          type="checkbox"
                          style={{ marginRight: '5px' }}
                          className="mx-1"
                          defaultChecked={activeComponentData?.filterEnable}
                          onChange={e => setFilterEnable(e.target.checked)}
                        />
                        <Label className="fw-500 fs-12 mb-1" check style={{ marginRight: '10px' }}>
                          Enable Filter
                        </Label>
                      </Col>
                    </>
                  ) : (
                    ''
                  )}
                </Col>
                {activeComponentData?.filterEnable && (
                  <Col md={12} className="mt-2">
                    <Label>Add filter to fields:</Label>
                    <FormGroup className="mb-0" key={entityLayout ? entityLayout.screen_id : activeComponentData?.appScreen?.id}>
                      <Col md={12} className="field-flex">
                        {activeComponentData &&
                          !loadingFields &&
                          appEntityFieldEntities?.length > 0 &&
                          appEntityFieldEntities.map(field => {
                            return (
                              <Row>
                                <Col md={12}>
                                  <Input
                                    // key={index}
                                    name={field}
                                    type="checkbox"
                                    style={{ marginRight: '5px' }}
                                    className="mx-1"
                                    defaultChecked={activeComponentData?.filterFields?.split(',').includes(`${field.name}:${field.type}`)}
                                    onChange={e =>
                                      handleFilterFields({
                                        checked: e.target.checked,
                                        field: field,
                                      })
                                    }
                                  />
                                  <Label className="fw-500 fs-12 mb-1" check style={{ marginRight: '10px' }}>
                                    {field.name}
                                  </Label>
                                </Col>
                              </Row>
                            );
                          })}
                        {loadingFields && appEntityFieldEntities?.length == 0 && (
                          <Spinner size="sm" color="primary">
                            Loading...
                          </Spinner>
                        )}
                      </Col>
                    </FormGroup>
                  </Col>
                )}
              </Row>
            </div>
          )}
          {activeComponentData && activeComponentData?.id && activeComponentData.layout == 'list' && (
            <Row className="layoutDesign-sction bordr-section">
              <Col md={12}>
                <Label>Navigate View Screen: </Label>
                {activeComponentData && (
                  <Row>
                    <ValidatedField
                      name={`navigate-${activeComponentData.name}`}
                      type="select"
                      style={fieldsStyle}
                      value={activeComponentData.appScreenViewType ? activeComponentData.appScreenViewType : ''}
                      // defaultValue={navigateViewDefaultValue?.type}
                      onChange={e => {
                        if (e.target.value != '') handleNavigateView({ type: e.target.value });
                      }}
                    >
                      <option value="">Select One</option>
                      <option value={AppScreenViewType.Component}>On Screen</option>
                      <option value={AppScreenViewType.Screen}>To Screen</option>
                    </ValidatedField>
                    {activeComponentData?.appScreenViewType == AppScreenViewType.Component && appScreenComponents ? (
                      <ValidatedField
                        name={`navigate-${activeComponentData.entity_id}`}
                        type="select"
                        style={fieldsStyle}
                        value={activeComponentData.screenComponentDetails?.id}
                        // defaultValue={navigateViewDefaultValue?.component?.id}
                        onChange={e => handleNavigateView({ componentId: e.target.value })}
                      >
                        <option value="">Select One</option>
                        {activeComponentData &&
                          appScreenComponents.length > 0 &&
                          appScreenComponents.map(component => {
                            return (
                              <option value={component.id} key={component.id}>
                                {component.name}-{component.layout}
                              </option>
                            );
                          })}
                      </ValidatedField>
                    ) : (
                      <Col md="12">
                        {activeComponentData?.appScreenViewType == AppScreenViewType.Screen && appScreens && (
                          <ValidatedField
                            name={`navigate-${activeComponentData.name}`}
                            type="select"
                            style={fieldsStyle}
                            value={activeComponentData.appScreenviewDetail?.id}
                            // defaultValue={navigateViewDefaultValue?.screen?.id}
                            onChange={e => handleNavigateView({ screenId: e.target.value })}
                          >
                            <option value="">Select One</option>
                            {activeComponentData &&
                              appScreens &&
                              appScreens.length > 0 &&
                              appScreens.map(screen => {
                                return <option value={screen.id}>{screen.title}</option>;
                              })}
                          </ValidatedField>
                        )}
                      </Col>
                    )}
                  </Row>
                )}
              </Col>
              <Col md={12}>
                <Label> Navigate Edit Screen : </Label>
                {activeComponentData && (
                  <Row>
                    <ValidatedField
                      name={`navigate-${activeComponentData.name}`}
                      type="select"
                      style={fieldsStyle}
                      // defaultValue={activeComponentData?.appScreenEditType}
                      value={activeComponentData?.appScreenEditType ? activeComponentData?.appScreenEditType : ''}
                      onChange={e => {
                        if (e.target.value != '') handleNavigateEdit({ type: e.target.value });
                      }}
                    >
                      <option value="">Select One</option>
                      <option value={AppScreenEditType.Component}>On Screen</option>
                      <option value={AppScreenEditType.Screen}>To Screen</option>
                    </ValidatedField>
                    {activeComponentData?.appScreenEditType == AppScreenEditType.Component && appScreenComponents ? (
                      <ValidatedField
                        name={`navigate-${activeComponentData?.entity_id}`}
                        type="select"
                        style={fieldsStyle}
                        className="mb-0"
                        value={activeComponentData?.screenComponentEdit?.id}
                        // defaultValue={activeComponentData?.screenComponentEdit?.id}
                        onChange={e => handleNavigateEdit({ componentId: e.target.value })}
                      >
                        <option value="">Select One</option>
                        {appScreenComponents &&
                          appScreenComponents.length > 0 &&
                          appScreenComponents.map(component => {
                            return (
                              <option value={component.id} key={component.id}>
                                {component.name},{component.layout}
                              </option>
                            );
                          })}
                      </ValidatedField>
                    ) : (
                      <Col md="12">
                        {activeComponentData?.appScreenEditType == AppScreenEditType.Screen && appScreens && (
                          <ValidatedField
                            name={`navigate-${activeComponentData.name}`}
                            type="select"
                            className="mb-0"
                            style={fieldsStyle}
                            value={activeComponentData?.appScreenviewEdit?.id}
                            onChange={e => handleNavigateEdit({ screenId: e.target.value })}
                          >
                            <option value="">Select One</option>
                            {appScreens &&
                              appScreens.length > 0 &&
                              appScreens.map(screen => {
                                return <option value={screen.id}>{screen.title}</option>;
                              })}
                          </ValidatedField>
                        )}
                      </Col>
                    )}
                  </Row>
                )}
              </Col>
              <Col md={12}>
                <ActionButtons
                  relatedEntity={activeComponentData}
                  buttonActionTypeValues={buttonActionTypeValues}
                  actionAdded={actionAdded}
                  appScreenId={appScreenId}
                  appId={appId}
                  activeComponentData={activeComponentData}
                  appScreens={appScreens}
                  endPointList={endPointList}
                />
              </Col>
            </Row>
          )}{' '}
          {/* {activeComponentData.layout && activeComponentData.layout == 'list' && (
                        <Row>
                            <Label> Actions : </Label>
                            <Col md={6}>
                                {activeComponentData && multipleActions?.length == 0 &&
                                    // multipleActions.map(actions => {
                                        // return (
                                            <Row>
                                                <Col>
                                                    <ValidatedField
                                                        name={`actions-${activeComponentData.name}`}
                                                        type="select"
                                                                style={{padding: '2px', minHeight: '36px'}}
                                                        value={actions.screenComponentActions}
                                                    // onChange={e => {
                                                    //     setScreenComponentAction({
                                                    //         entity_id: entity.entity_id,
                                                    //         actionType: e.target.value,
                                                    //         screen_id: entity.screen_id,
                                                    //         actionIndex: actions.index,
                                                    //     });
                                                    // }}
                                                    >
                                                        <option value="">Select One</option>
                                                        <option value="REDIRECT">Redirect</option>
                                                    </ValidatedField>
                                                </Col>
                                                <Col>
                                                    {appScreenComponents &&
                                                        // (
                                                        <>
                                                            <ValidatedField
                                                                name={`redirect-screen-${activeComponentData.appEntity.id}`}
                                                                type="select"
                                style={{padding: '2px', minHeight: '36px'}}
                                                                value={actions.redirectActionScreenId}
                                                            // onChange={e => {
                                                            //     setRedirectActionScreen({
                                                            //         entity_id: entity.entity_id,
                                                            //         screenId: e.target.value,
                                                            //         screen_id: entity.screen_id,
                                                            //         actionIndex: actions.index,
                                                            //     });
                                                            // }}
                                                            >
                                                                <option value="">Select One</option>
                                                                {appScreenComponents && appScreenComponents.length > 0 && appScreenComponents.map(component => {
                                                                        return (
                                                                            <option value={component.id} key={component.id}>
                                                                                {component.title}
                                                                            </option>
                                                                        );
                                                                    })}
                                                            </ValidatedField>
                                                        </>
                                                        // ) : actions.screenComponentActions ? (
                                                        //     <Spinner size="sm" color="primary">
                                                        //         Loading...
                                                        //     </Spinner>
                                                        // ) : (
                                                        //     ''
                                                        // )
                                                    }
                                                </Col>
                                            </Row>
                                        // );
                                    // })
                                    }
                            </Col>
                            <Col md={6}>
                                <Row>
                                    {activeComponentData && multipleActions.length > 0 &&
                                        <Col className="screen-access-roles">
                                            <Button
                                                color="primary"
                                                outline
                                            // onClick={() => {
                                            //     handleMultipleAction(entity.entity_id, entity.screen_id, entity.multipleActions);
                                            // }}
                                            >
                                                <FontAwesomeIcon icon={faPlus} /> Add More Actions
                                            </Button>
                                        </Col>
                                    }
                                </Row>
                            </Col>
                        </Row>
                    )} */}
          {activeComponentData.layout && activeComponentData?.layout == 'form' && (
            <Row className="layoutDesign-sction field-sction bordr-section">
              <Col md="12">
                <Label>Form Action: </Label>
                {
                  <ValidatedField
                    name={`form-action-${activeComponentData.name}`}
                    type="select"
                    style={fieldsStyle}
                    defaultValue={activeComponentData.formAction}
                    onChange={e => {
                      if (e.target.value != '') setFormAction({ action: e.target.value });
                    }}
                  >
                    <option value="">Select One</option>
                    <option value={FormActionType.REDIRECT}>Redirect</option>
                    <option value={FormActionType.MESSAGE}>Show Message</option>
                    <option value={FormActionType.ENDPOINT}>Custom Endpoint</option>
                  </ValidatedField>
                }
              </Col>

              <Col md="12">
                {activeComponentData && activeComponentData?.formAction == FormActionType.REDIRECT && (
                  <>
                    <Label>Screen: </Label>
                    {activeComponentData && appScreens?.length > 0 ? (
                      <ValidatedField
                        name={`redirect-screen-${activeComponentData.name}`}
                        type="select"
                        className="mb-0 space-remove"
                        style={fieldsStyle}
                        defaultValue={activeComponentData?.appScreenformSuccessRedirectScreen?.id}
                        onChange={e => {
                          if (e.target.value != '') setRedirectScreen({ id: e.target.value });
                        }}
                      >
                        <option value="">Select One</option>
                        {appScreens &&
                          appScreens.length > 0 &&
                          appScreens.map(screen => {
                            return <option value={screen.id}>{screen.title}</option>;
                          })}
                      </ValidatedField>
                    ) : (
                      <Spinner size="sm" color="primary">
                        Loading...
                      </Spinner>
                    )}
                  </>
                )}
                {activeComponentData && activeComponentData?.formAction == FormActionType.MESSAGE && (
                  <>
                    <Label>Message: </Label>

                    <ValidatedField
                      name="form-action-message"
                      type="text"
                      className="mb-0"
                      style={fieldsStyle}
                      // value={activeComponentData.formSuccess_message}
                      defaultValue={activeComponentData?.formSuccessMessage}
                      onChange={e => {
                        setFormSuccessMessage({
                          message: e.target.value,
                        });
                      }}
                    ></ValidatedField>
                  </>
                )}

                {activeComponentData && activeComponentData?.formAction == FormActionType.ENDPOINT && (
                  <>
                    <Label>Select Endpoint: </Label>

                    <ValidatedField
                      name="form-action-endpoint"
                      type="select"
                      className="mb-0"
                      style={fieldsStyle}
                      value={activeComponentData?.formCustomEndpoint}
                      onChange={e => {
                        setFormCustomEndpoint(e.target.value);
                      }}
                    >
                      <option value="">Select One</option>
                      {endPointList &&
                        endPointList.length > 0 &&
                        endPointList.map(endpoint => {
                          return <option value={endpoint.id}>{endpoint.path}</option>;
                        })}
                    </ValidatedField>
                  </>
                )}
              </Col>
            </Row>
          )}
          {activeComponentData.layout != 'map' && (
            <Row className="layoutDesign-sction bordr-section">
              {!loadingComponent && relatedEntitiesList && relatedEntitiesList.length > 0 && (
                <ValidatedField
                  className="col-md-12 mb-0"
                  style={fieldsStyle}
                  name=""
                  type="select"
                  // value={activeComponentData}
                  label="Add Related Entity"
                  onChange={e => {
                    if (e.target.value != 'default') {
                      setSelectedRelatedEntityId({ id: e.target.value });
                    }
                  }}
                >
                  <option value="default">Select related Entity</option>
                  {relatedEntitiesList.map(realtedEntity => {
                    return realtedEntity.appEntityTo?.type != 'Internal' && realtedEntity.appEntityFrom.id == appEntityId ? (
                      <option value={realtedEntity?.appEntityTo?.id}>{realtedEntity.appEntityTo?.name}</option>
                    ) : realtedEntity.appEntityFrom?.type != 'Internal' && realtedEntity.appEntityTo.id == appEntityId ? (
                      <option value={realtedEntity?.appEntityFrom?.id}>{realtedEntity.appEntityFrom?.name}</option>
                    ) : (
                      ''
                    );
                  })}
                </ValidatedField>
              )}
              {relatedEntitiesList?.length == 0 && <p>No Related Entity</p>}
            </Row>
          )}
          {relatedScreenComponents?.length > 0 && (
            <div className="relatedEntities">
              {!appEntitiesLoading &&
                appEntityList &&
                relatedScreenComponents?.length > 0 &&
                relatedScreenComponents?.map(related_entity => {
                  return (
                    <>
                      {related_entity ? (
                        <div
                          className="bg-light border hide layout-padding"
                          style={{
                            paddingTop: '6px',
                            paddingBottom: '6px',
                          }}
                        >
                          <h5 className="entityTitle">
                            {' '}
                            Related Entity Name : {related_entity.appEntity.name}{' '}
                            <Button
                              className="del-entityScreen"
                              id={`deleteapp-${related_entity.entity_id}`}
                              onClick={e => {
                                removeRelatedEntity(related_entity.id);
                              }}
                            >
                              <DeleteIcon />
                            </Button>
                          </h5>
                          <Row style={{ marginTop: '0.2rem', justifyContent: 'center' }}>
                            {activeComponentData.layout != 'form' ||
                            (activeComponentData.layout == 'form' &&
                              relatedEntitiesList.find(
                                it =>
                                  it.appEntityTo.id == related_entity.appEntity.id &&
                                  it.appEntityFrom.id != related_entity.appEntity.id &&
                                  (it.type == 'OneToMany' || it.type == 'ManyToMany')
                              )) ? (
                              <>
                                <Row className="layoutDesign-sction bordr-section related-entity-border">
                                  <Col md="12">
                                    {related_entity.appEntity &&
                                      appEntityList?.filter(it => it.id.toString() == related_entity?.appEntity?.id.toString())[0]
                                        .appEntityFields.length > 0 && <Label>Select Fields : </Label>}

                                    {related_entity.appEntity &&
                                      appEntityList?.filter(it => it.id.toString() == related_entity?.appEntity?.id.toString())[0]
                                        .appEntityFields.length > 0 &&
                                      appEntityList
                                        ?.filter(it => it.id.toString() == related_entity?.appEntity?.id.toString())[0]
                                        .appEntityFields?.map(field => (
                                          <>
                                            <Input
                                              type="checkbox"
                                              style={{ marginRight: '5px' }}
                                              defaultChecked={related_entity?.entityFields
                                                ?.split(',')
                                                .includes(`${field.name}:${field.type}`)}
                                              onChange={e => {
                                                let selectedFieldsArr = [];
                                                if (related_entity?.entityFields?.length > 0) {
                                                  selectedFieldsArr = related_entity?.entityFields?.split(',');
                                                }
                                                if (e.target.checked == true) {
                                                  const existingItem = selectedFieldsArr.includes(`${field.name}:${field.type}`);
                                                  if (!existingItem) selectedFieldsArr.push(`${field.name}:${field.type}`);
                                                  setSelectRelatedEntityField(selectedFieldsArr);
                                                } else if (e.target.checked == false) {
                                                  const existingItem = selectedFieldsArr.includes(`${field.name}:${field.type}`);
                                                  if (existingItem) {
                                                    const filteredArr = selectedFieldsArr.filter(it => it != `${field.name}:${field.type}`);
                                                    setSelectRelatedEntityField(filteredArr);
                                                  }
                                                }
                                                setRelatedComponent(related_entity);
                                              }}
                                            />
                                            <Label className="fw-500 fs-12 mb-1" check style={{ marginRight: '10px' }}>
                                              {field.name}
                                            </Label>
                                          </>
                                        ))}
                                    {related_entity.appEntity &&
                                      appEntityList?.filter(it => it.id.toString() == related_entity?.appEntity?.id.toString())[0]
                                        .appEntityFields.length == 0 && (
                                        <Col className="noFieldsCol">
                                          <p>No fields in the entity</p>
                                          <Link to={`/product/${productId}/app/${appId}/app-entity/${related_entity?.appEntity?.id}/view`}>
                                            Add fields
                                          </Link>
                                        </Col>
                                      )}
                                  </Col>
                                </Row>
                                {activeComponentData?.layout == 'details' && related_entity && (
                                  <Row className="layoutDesign-sction bordr-section related-entity-border">
                                    {/*<Col md="12">
                                      <Input
                                        type="checkbox"
                                        style={{ marginRight: '5px' }}
                                        checked={related_entity?.createButtonEnable == true ? true : false}
                                        onChange={(e) => {
                                          handleAddCreateButton(e, related_entity);
                                        }}
                                      />
                                      <Label className="fw-500 fs-12 mb-1" check style={{ marginRight: '10px' }}>
                                        Add Button
                                      </Label>
                                    </Col> */}
                                    <ActionButtons
                                      relatedEntity={related_entity}
                                      buttonActionTypeValues={buttonActionTypeValues}
                                      actionAdded={actionAdded}
                                      appScreenId={appScreenId}
                                      appId={appId}
                                      activeComponentData={activeComponentData}
                                      appScreens={appScreens}
                                      endPointList={endPointList}
                                    />
                                  </Row>
                                )}
                              </>
                            ) : (
                              <Col>
                                {' '}
                                {activeComponentData.layout == 'form' &&
                                  relatedEntitiesList.find(
                                    it =>
                                      it.appEntityTo.id == related_entity.appEntity.id &&
                                      it.appEntityFrom.id != related_entity.appEntity.id &&
                                      (it.type == 'ManyToOne' || it.type == 'OneToOne')
                                  ) && (
                                    <>
                                      <Label>Field to display in dropdown</Label>
                                      <FormGroup
                                      // key={entityLayout ? entityLayout.screen_id : activeComponentData?.appScreen?.id}
                                      >
                                        {related_entity.appEntity &&
                                          appEntityList?.filter(it => it.id.toString() == related_entity?.appEntity?.id.toString())[0]
                                            .appEntityFields.length > 0 &&
                                          appEntityList
                                            ?.filter(it => it.id.toString() == related_entity?.appEntity?.id.toString())[0]
                                            .appEntityFields?.map((field, index) => {
                                              return (
                                                <>
                                                  <Input
                                                    key={index}
                                                    name={field.name}
                                                    type="radio"
                                                    className="me-1"
                                                    checked={related_entity?.entityFields
                                                      ?.split(',')
                                                      .includes(`${field.name}:${field.type}`)}
                                                    defaultChecked={related_entity?.entityFields
                                                      ?.split(',')
                                                      .includes(`${field.name}:${field.type}`)}
                                                    // value={field}
                                                    id={`field-${field.name}`}
                                                    onChange={e => {
                                                      setSelectRelatedEntityField([`${field.name}:${field.type}`]);
                                                      setRelatedComponent(related_entity);
                                                    }}
                                                  />
                                                  <Label check className="me-2 fs-12 mb-1 fw-500">
                                                    {field.name}
                                                  </Label>
                                                </>
                                              );
                                            })}
                                      </FormGroup>
                                    </>
                                  )}
                              </Col>
                            )}
                            {activeComponentData.layout != 'form' ||
                              (activeComponentData.layout == 'form' &&
                                relatedEntitiesList.find(
                                  it =>
                                    it.appEntityFrom.id == related_entity.appEntity.id && it.appEntityTo.id != related_entity.appEntity.id
                                ) && (
                                  <>
                                    <Row className="layoutDesign-sction bordr-section related-entity-border">
                                      <Col md="12">
                                        {related_entity.appEntity &&
                                          appEntityList?.filter(it => it.id.toString() == related_entity?.appEntity?.id.toString())[0]
                                            .appEntityFields.length > 0 && <Label>Select Fields : </Label>}

                                        {related_entity.appEntity &&
                                          appEntityList?.filter(it => it.id.toString() == related_entity?.appEntity?.id.toString())[0]
                                            .appEntityFields.length > 0 &&
                                          appEntityList
                                            ?.filter(it => it.id.toString() == related_entity?.appEntity?.id.toString())[0]
                                            .appEntityFields?.map(field => (
                                              <>
                                                <Input
                                                  type="radio"
                                                  style={{ marginRight: '5px' }}
                                                  checked={related_entity?.entityFields?.split(',').includes(`${field.name}:${field.type}`)}
                                                  defaultChecked={related_entity?.entityFields
                                                    ?.split(',')
                                                    .includes(`${field.name}:${field.type}`)}
                                                  onChange={e => {
                                                    setSelectRelatedEntityField([`${field.name}:${field.type}`]);
                                                    setRelatedComponent(related_entity);
                                                  }}
                                                />
                                                <Label className="fw-500 fs-12 mb-1" check style={{ marginRight: '10px' }}>
                                                  {field.name}
                                                </Label>
                                              </>
                                            ))}
                                        {related_entity.appEntity &&
                                          appEntityList?.filter(it => it.id.toString() == related_entity?.appEntity?.id.toString())[0]
                                            .appEntityFields.length == 0 && (
                                            <Col className="noFieldsCol">
                                              <p>No fields in the entity</p>
                                              <Link
                                                to={`/product/${productId}/app/${appId}/app-entity/${related_entity?.appEntity?.id}/view`}
                                              >
                                                Add fields
                                              </Link>
                                            </Col>
                                          )}
                                      </Col>
                                    </Row>
                                    {activeComponentData?.layout == 'details' && related_entity && (
                                      <Row className="layoutDesign-sction bordr-section related-entity-border">
                                        <Col md="12">
                                          <Input
                                            type="checkbox"
                                            style={{ marginRight: '5px' }}
                                            checked={related_entity?.createButtonEnable == true ? true : false}
                                            onChange={e => {
                                              handleAddCreateButton(e, related_entity);
                                            }}
                                          />
                                          <Label className="fw-500 fs-12 mb-1" check style={{ marginRight: '10px' }}>
                                            Add Create Button {related_entity?.createButtonEnable == true ? '{true}' : '{false}'}
                                          </Label>
                                        </Col>
                                        {related_entity?.createButtonEnable && (
                                          <>
                                            <Col md="12" style={{ display: 'flex', marginTop: '4px' }}>
                                              <Label className="fw-500 fs-12 mb-1" check style={{ marginRight: '10px' }}>
                                                Button Text:
                                              </Label>
                                              <Input
                                                type="text"
                                                style={{ marginRight: '5px', width: '60%' }}
                                                defaultValue={related_entity?.createButtonText ? related_entity?.createButtonText : ''}
                                                onBlur={e => {
                                                  // console.log("e.target.value", e.target.value);
                                                  // handleButtonText(e, related_entity);
                                                  // setRelatedComponent(related_entity);
                                                  // setCreateButtonText(e.target.value)
                                                }}
                                              />
                                            </Col>
                                            <Col md="12" style={{ display: 'flex', marginTop: '4px' }}>
                                              <Label>Redirect To Screen: </Label>
                                              {activeComponentData && appScreens?.length > 0 ? (
                                                <ValidatedField
                                                  name={`redirect-screen-${activeComponentData.name}`}
                                                  type="select"
                                                  className="mb-0 space-remove"
                                                  style={fieldsStyle}
                                                  value={
                                                    related_entity?.createButtonRedirect
                                                      ? JSON.parse(related_entity?.createButtonRedirect).id
                                                      : ''
                                                  }
                                                  onChange={e => {
                                                    if (e.target.value != '') {
                                                      handleButtonRedirect({ id: e.target.value }, related_entity);
                                                      // setRelatedComponent(related_entity);
                                                      // setButtonRedirect({ id: e.target.value });
                                                    }
                                                  }}
                                                >
                                                  <option value="">Select One</option>
                                                  {appScreens &&
                                                    appScreens.length > 0 &&
                                                    appScreens.map(screen => {
                                                      return <option value={screen.id}>{screen.title}</option>;
                                                    })}
                                                </ValidatedField>
                                              ) : (
                                                <Spinner size="sm" color="primary">
                                                  Loading...
                                                </Spinner>
                                              )}
                                            </Col>
                                          </>
                                        )}
                                      </Row>
                                    )}
                                  </>
                                ))}
                          </Row>
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  );
                })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ComponentUpdate;
