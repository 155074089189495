import { IAppEntityField } from 'app/shared/model/app-entity-field.model';
import { IAppFile } from 'app/shared/model/app-file.model';
import { IEntityRelationship } from 'app/shared/model/entity-relationship.model';
import { IAppEntity } from 'app/shared/model/app-entity.model';
import { IApp } from 'app/shared/model/app.model';
import { DomainEventType } from 'app/shared/model/enumerations/domain-event-type.model';
import { DomainListenerType } from 'app/shared/model/enumerations/domain-listener-type.model';

export interface IAppDomainListener {
  id?: number;
  domainEventType?: DomainEventType | null;
  description?: string | null;
  updateFieldValue?: string | null;
  listenerClassName?: string | null;
  type?: DomainListenerType | null;
  preQueryListener?: boolean | null;
  appEntityField?: IAppEntityField | null;
  appFile?: IAppFile | null;
  entityRelationships?: IEntityRelationship[] | null;
  appEntity?: IAppEntity | null;
  app?: IApp | null;
}

export const defaultValue: Readonly<IAppDomainListener> = {
  preQueryListener: false,
};
