import { ICoreFunctionality } from '../../shared/model/core-functionality.model';

export interface ICoreAccessConfig {
  id?: number;
  pathPattern?: string | null;
  reason?: string | null;
  accessRoles?: string | null;
  enabled?: boolean | null;
  userId?: number | null;
  coreFunctionality?: ICoreFunctionality | null;
}

export const defaultValue: Readonly<ICoreAccessConfig> = {
  enabled: false,
};
