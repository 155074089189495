import { faSquareRootVariable } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../config/store';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getEntity } from '../app/app.reducer';
import Deployment from '../deployment/deployment';
import DeploymentTab from './deployTab/DeploymentTab';
import EntityRelationship from '../entity-relationship/entity-relationship';
import AppFeature from './AppFeature';
import Relationship from './relationshipForm/Relationship';
import AppEntity from './editEntityFunctionality/AppEntity';
import '../../app.scss';
import { Breadcrumb, BreadcrumbItem, Button, Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import AppScreen from '../app-screen/app-screen';
import Screentab from './screentab/Screentab';
import Dependency from '../../entities/dependency/dependency';
import { AppStatus } from '../../../../../app/shared/model/enumerations/app-status.model';
import AppDependency from '../../entities/app-dependency/app-dependency';
import Job from '../../entities/job/job';
import Endpoints from '../../entities/app-end-point-controller/app-end-point-controller';
import AppDomainListener from '../../entities/app-domain-listener/app-domain-listener';
import ProductJournalEntry from '../product-journal-entry/product-journal-entry';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate } from 'react-jhipster';
import { BackArrowIcon, ScreenIcon } from '../../shared/assets/svg-icon/icon';
import AppScreenUpdate from '../app-screen/app-screen-update';
import AppFile from "../app-file/app-file";
import AppAiService from '../app-ai-service/app-ai-service';

function ProductAllFeatures() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { appId } = useParams<'appId'>();
  const { productId } = useParams<'productId'>();
  const [showTab, setShowTab] = useState(localStorage.getItem('selectedTab') || 'AppFeature');
  const appEntity = useAppSelector(state => state);
  const appEntities = useAppSelector(state => state.app.entity);
  const productEntity = useAppSelector(state => state.product.entity);
  const [screenWrap, setScreenWrap] = useState(false);
  // const [screenWrapClassName, setScreenWrapClassName] = useState('inner-content-wrap');

  useEffect(() => {
    if (appId !== undefined) {
      dispatch(getEntity(appId));
    }
    if (showTab == 'AppScreen' && document.getElementsByClassName('app-sidebar')[0]?.className.indexOf('open-sidebar') > -1) {
      document.getElementsByClassName('sidebar-toggle')[0].click();
      setScreenWrap(true);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedTab', showTab);
  }, [showTab]);

  useEffect(() => {
    if (appEntity?.app?.entity?.status == AppStatus.Deleted) navigate(`/product/${productId}`);
  }, [appEntity]);

  const sideBarClick = () => {
    if (document.getElementsByClassName('app-sidebar')[0]?.className.indexOf('open-sidebar') > -1) {
      document.getElementsByClassName('sidebar-toggle')[0].click();
      setScreenWrap(false);
    }
    if (document.getElementsByClassName('app-sidebar')[0].className.indexOf('open-sidebar') == -1) {
      document.getElementsByClassName('sidebar-toggle')[0].click();
      setScreenWrap(false);
    }
  };

  useEffect(() => {
    if (showTab == 'AppScreen' && document.getElementsByClassName('app-sidebar')[0]?.className.indexOf('open-sidebar') > -1) {
      document.getElementsByClassName('sidebar-toggle')[0].click();
      setScreenWrap(true);
    } else if (showTab != 'AppScreen' && document.getElementsByClassName('app-sidebar')[0].className.indexOf('open-sidebar') == -1) {
      document.getElementsByClassName('sidebar-toggle')[0].click();
      setScreenWrap(false);
    }
  }, [showTab]);

  useEffect(() => {
    return () => {
      if (document.getElementsByClassName('app-sidebar')[0].className.indexOf('open-sidebar') == -1) {
        document.getElementsByClassName('sidebar-toggle')[0].click();
        setScreenWrap(false);
      }
      console.log('Component is unmounting');
    };
  }, []);

  return (
    <>
      <div
        className={!screenWrap && showTab == 'AppScreen' ? 'inner-content-wrap' : 'inner-content-wrap adding_spc'}
        style={
          screenWrap == true
            ? {
                margin: '4px 0 0',
                padding: '0px 5px 0px 5px',
                overflow: 'hidden',
                background: 'white',
                borderRadius: '0px !important',
                boxShadow: 'none',
                minHeight: 'calc(100vh - 85px)',
              }
            : {}
        }
      >
        <Row className={!screenWrap ? 'mb-4' : 'm-0'} style={screenWrap ? { marginBottom: '0 !important' } : {}}>
          {screenWrap == false && (
            <Col md={screenWrap ? '12' : '8'}>
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/product">Products</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link to={`/product/${productEntity.id}`}>{productEntity.name}</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>{appEntities.name}</BreadcrumbItem>
              </Breadcrumb>
            </Col>
          )}
          <Col md={screenWrap ? '12' : '4'} className="righttext">
            {screenWrap ? (
              <Row className="fullScreenBar">
                <Col md={8}>
                  <Link to="#" onClick={sideBarClick}>
                    <p>
                      {' '}
                      <ScreenIcon /> Exit Full Screen
                    </p>
                  </Link>
                </Col>
                <Col md={4} className="righttext">
                  <Link className="feature-back" to={`/product/${productEntity.id}`} replace color="back" data-cy="entityDetailsBackButton">
                    <BackArrowIcon />
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </Link>
                </Col>
              </Row>
            ) : (
              <Link
                // href=""
                className="feature-back"
                to={`/product/${productEntity.id}`}
                replace
                color="back"
                data-cy="entityDetailsBackButton"
              >
                <BackArrowIcon />
                <Translate contentKey="entity.action.back">Back</Translate>
              </Link>
            )}
          </Col>
        </Row>

        <Nav tabs className="tab-design">
          <NavItem>
            <NavLink herf="#" onClick={() => setShowTab('AppFeature')} active={showTab == 'AppFeature'}>
              Features
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={() => setShowTab('AppEntity')} active={showTab == 'AppEntity'}>
              Entities{' '}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={() => setShowTab('EntityRelationship')} active={showTab == 'EntityRelationship'}>
              Relationships{' '}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={() => setShowTab('AppScreen')} active={showTab == 'AppScreen'}>
              Screens{' '}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={() => setShowTab('Deployment')} active={showTab == 'Deployment'}>
              Deployments
            </NavLink>
          </NavItem>
          {/*<NavItem>*/}
          {/*  <NavLink onClick={() => setShowTab('Dependency')} active={showTab == 'Dependency'}>*/}
          {/*    Dependencies*/}
          {/*  </NavLink>*/}
          {/*</NavItem>*/}
          <NavItem>
            <NavLink onClick={() => setShowTab('AppDependency')} active={showTab == 'AppDependency'}>
              Dependencies
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={() => setShowTab('Jobs')} active={showTab == 'Jobs'}>
              Jobs
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={() => setShowTab('Endpoints')} active={showTab == 'Endpoints'}>
              Endpoints
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={() => setShowTab('Listeners')} active={showTab == 'Listeners'}>
              Listeners
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={() => setShowTab('Journal')} active={showTab == 'Journal'}>
              Journal
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={() => setShowTab('AppFile')} active={showTab == 'AppFile'}>
              App Files
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={() => setShowTab('AISetup')} active={showTab == 'AISetup'}>
              AI Setup
            </NavLink>
          </NavItem>
        </Nav>

        {showTab == 'AppFeature' && <AppFeature />}
        {showTab == 'AppEntity' && <AppEntity />}
        {showTab == 'EntityRelationship' && <Relationship />}
        {showTab == 'AppScreen' && <AppScreenUpdate />}
        {showTab == 'Deployment' && <DeploymentTab />}
        {/*{showTab == 'Dependency' && <Dependency />}*/}
        {showTab == 'AppDependency' && <AppDependency />}
        {showTab == 'Jobs' && <Job />}
        {showTab == 'Endpoints' && <Endpoints />}
        {showTab == 'Listeners' && <AppDomainListener />}
        {showTab == 'Journal' && <ProductJournalEntry />}
        {showTab == 'AppFile' && <AppFile />}
        {showTab == 'AISetup' && <AppAiService />}
      </div>
    </>
  );
}

export default ProductAllFeatures;
