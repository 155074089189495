import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from '../../../../../app/shared/error/error-boundary-routes';

import Deployment from './deployment';
import DeploymentDetail from './deployment-detail';
import DeploymentUpdate from './deployment-update';
import DeploymentDeleteDialog from './deployment-delete-dialog';

const DeploymentRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Deployment />} />
    <Route path="/:appId/new" element={<DeploymentUpdate />} />
    <Route path=":appId/:id">
      <Route index element={<DeploymentDetail />} />
      <Route path="edit" element={<DeploymentUpdate />} />
      <Route path="view" element={<DeploymentDetail />} />
    </Route>
    <Route path=":appId">
      <Route path="" element={<DeploymentDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default DeploymentRoutes;
