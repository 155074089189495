import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, Label, ButtonGroup, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '../../../config/store';
import { getEntities as getApps, getEntity as getApp } from '../../../entities/app/app.reducer';
import { EntityType } from '../../../../../../app/shared/model/enumerations/entity-type.model';
import { getEntity, updateEntity, createEntity, reset } from '../../../entities/app-entity/app-entity.reducer';
import { getEntities as getFeatures, getEntity as getFeature } from '../../../entities/feature/feature.reducer';
// import {
//   getEntities as getEnumMembers,
//   getEntity as getEnumMember,
//   createEntity as createEnumMember,
//   updateEntity as updateEnumMember,
//   reset as resetEnumMember,
// } from 'app/entities/enum-member/enum-member.reducer';
// import {
//   getEntities as getProductUserRoleAccesses,
//   getEntity as getProductUserRoleAccess,
//   createEntity as createProductUserRoleAccess,
//   updateEntity as updateProductUserRoleAccess,
//   reset as resetProductUserRoleAccess,
// } from 'app/entities/product-user-role-access/product-user-role-access.reducer';

export const AppEntityUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { appEntityId } = useParams<'appEntityId'>();

  const isNew = appEntityId === undefined;

  const app = useAppSelector(state => state.app.entity);
  const appEntity = useAppSelector(state => state.appEntity.entity);
  // const productUserRoleAccess = useAppSelector(state => state.productUserRoleAccess.entity);
  // const productUserRoleAccesses = useAppSelector(state => state.productUserRoleAccess.entities);
  const loading = useAppSelector(state => state.appEntity.loading);
  const updating = useAppSelector(state => state.appEntity.updating);
  const updateSuccess = useAppSelector(state => state.appEntity.updateSuccess);
  const [type, setType] = useState(EntityType.Standard);
  const entityTypeValues = Object.keys(EntityType);
  const featureEntities = useAppSelector(state => state.feature.entities);
  const featureEntity = useAppSelector(state => state.feature.entity);
  const [selectFeatureId, setSelectFeatureId] = useState(0);
  const [appEntityName, setAppEntityName] = useState('');
  const chatGptSearchData = useAppSelector(state => state.chatGptSearch);
  const [appEntityDescription, setAppEntityDescription] = useState('');
  const productEntity = useAppSelector(state => state.product.entity);
  // const enumMembers = useAppSelector(state => state.enumMember.entities);
  // const enumMember = useAppSelector(state => state.enumMember.entity);
  // const [selectEnumMemberId, setSelectEnumMemberId] = useState(0);
  // const [enumMemberDescription, setEnumMemberDescription] = useState('');
  // const [selectProductUserRoleAccessId, setSelectProductUserRoleAccessId] = useState(0);
  // const [canReadValue, setCanReadValue] = useState(false);
  // const [canCreateValue, setCanCreateValue] = useState(false);
  // const [canUpdateValue, setCanUpdateValue] = useState(false);
  // const [canDeleteValue, setCanDeleteValue] = useState(false);
  // const [whenCreatedByUserValue, setWhenCreatedByUserValue] = useState(false);
  // const [whenCreatedByGroupValue, setWhenCreatedByGroupValue] = useState(false);

  const handleClose = () => {
    navigate(`/product/${productId}/app/${appId}` + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
      // dispatch(resetProductUserRoleAccess());
      // dispatch(resetEnumMember());
    } else {
      dispatch(getEntity(appEntityId));
      // dispatch(getProductUserRoleAccesses({query: `appEntityId.equals=${appEntityId}`}));
      // dispatch(getEnumMembers({query: `appEntityId.equals=${appEntityId}`}));
    }
    if (appId !== undefined) {
      dispatch(getApp(appId));
    }
    dispatch(getFeatures({ query: `appId.equals=${appId}` }));
  }, []);

  useEffect(() => {
    if (!isNew && appEntity && appEntity.id) {
      setType(appEntity.type);
    }
    if (!isNew && appEntity && appEntity.feature && appEntity.feature.id) {
      setSelectFeatureId(appEntity.feature.id);
      dispatch(getFeature(appEntity.feature.id));
    }
    if (!isNew && appEntity && appEntity.name) {
      setAppEntityName(appEntity.name);
    }
    if (!isNew && appEntity && appEntity.description) {
      setAppEntityDescription(appEntity.description);
    }
    // if (!isNew && enumMember && enumMember.description) {
    //   setEnumMemberDescription(enumMember.description);
    // }
    // if (!isNew && productUserRoleAccess) {
    //   setCanReadValue(productUserRoleAccess.canRead);
    //   setCanCreateValue(productUserRoleAccess.canCreate);
    //   setCanUpdateValue(productUserRoleAccess.canUpdate);
    //   setCanDeleteValue(productUserRoleAccess.canDelete);
    //   setWhenCreatedByUserValue(productUserRoleAccess.whenCreatedByUser);
    //   setWhenCreatedByGroupValue(productUserRoleAccess.whenCreatedByGroup);
    // }
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess, appEntity]);

  const saveEntity = values => {
    const entity = {
      ...appEntity,
      ...values,
      type,
      app: app,
      feature: featureEntity && featureEntity.id ? featureEntity : null,
      name: appEntityName,
      description: appEntityDescription,
    };
    // const productUserRoleAccessEntity = {
    //   ...productUserRoleAccess,
    //   ...values,
    //   canRead: canReadValue,
    //   canCreate: canCreateValue,
    //   canUpdate: canUpdateValue,
    //   canDelete: canDeleteValue,
    //   whenCreatedByUser: whenCreatedByUserValue,
    //   whenCreatedByGroup: whenCreatedByGroupValue,
    // };
    // const enumMemberEntity = {
    //   ...enumMember,
    //   ...values,
    //   description: enumMemberDescription,
    // };

    if (isNew) {
      dispatch(createEntity(entity));
      // dispatch(createProductUserRoleAccess(productUserRoleAccessEntity));
      // dispatch(createEnumMember(enumMemberEntity));
    } else {
      dispatch(updateEntity(entity));
      // dispatch(updateProductUserRoleAccess(productUserRoleAccessEntity));
      // dispatch(updateEnumMember(enumMemberEntity));
    }
  };

  const defaultValues = () => {
    if (isNew) {
      return chatGptSearchData?.entity?.identifier ? { appEntityName: chatGptSearchData.entity.identifier } : {};
    } else {
      return {
        type: appEntity?.app?.type,
        ...appEntity,
        app: appEntity?.app?.id,
      };
    }
  };

  const handleFeatureChange = e => {
    setSelectFeatureId(e.target.value);
    dispatch(getFeature(e.target.value));
  };

  const handleAppEntityNameChange = e => {
    let entityNameCaptial = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    setAppEntityName(entityNameCaptial);
  };

  const handleAppEntityDescriptionChange = e => {
    setAppEntityDescription(e.target.value);
  };

  // const handleEnumMemberChange = (e) => {
  //   setSelectEnumMemberId(e.target.value);
  //   dispatch(getEnumMember(e.target.value));
  // }

  // const handleEnumMemberDescriptionChange = (e) => {
  //   setEnumMemberDescription(e.target.value);
  // }

  // const handleProductUserRoleChange = (e) => {
  //   setSelectProductUserRoleAccessId(e.target.value);
  //   dispatch(getProductUserRoleAccess(e.target.value));
  // }

  // const handleCanReadValueChange = (e) => {
  //   setCanReadValue(e.target.checked);
  // }

  // const handleCanCreateValueChange = (e) => {
  //   setCanCreateValue(e.target.checked);
  // }

  // const handleCanUpdateValueChange = (e) => {
  //   setCanUpdateValue(e.target.checked);
  // }

  // const handleCanDeleteValueChange = (e) => {
  //   setCanDeleteValue(e.target.checked);
  // }

  // const handleWhenCreatedByUserValueChange = (e) => {
  //   setWhenCreatedByUserValue(e.target.checked);
  // }

  // const handleWhenCreatedByGroupValueChange = (e) => {
  //   setWhenCreatedByGroupValue(e.target.checked);
  // }

  return (
    <div className="inner-content-wrap">
      <Row className="justify-content-center">
        {/* <Col md="12">
          <h2 className="row  page-title" id="codeconductorApp.appEntity.home.createOrEditLabel" data-cy="AppEntityCreateUpdateHeading">
            <Label>{isNew ? `Add` : ` Manage`} App Entity</Label>
          </h2>
        </Col> */}
        <Col md="12">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/product">Products</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={`/product/${productEntity.id}`}>{productEntity.name}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={`/product/${productEntity.id}/app/${app.id}`}>{app.name}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>{isNew ? `Add` : `Manage`} App Entity</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity} className="row">
              <ValidatedField
                className="col-md-12 col-lg-6"
                label={translate('codeconductorApp.appEntity.name')}
                id="app-entity-name"
                name="app-entity-name"
                data-cy="appEntityName"
                type="text"
                value={appEntityName}
                onChange={handleAppEntityNameChange}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <div className="col-md-12 col-lg-6 screen-access-roles">
                <Label>Type</Label>
                <ButtonGroup style={{ width: '100%' }}>
                  {entityTypeValues.map(entityType => (
                    <Button
                      color="primary"
                      name="type"
                      data-cy="type"
                      id="app-entity-type"
                      outline
                      onClick={() => setType(EntityType[entityType])}
                      active={type === entityType}
                      value={EntityType[entityType]}
                      key={EntityType[entityType]}
                    >
                      {translate('codeconductorApp.EntityType.' + EntityType[entityType])}
                    </Button>
                  ))}
                </ButtonGroup>
              </div>
              {/* {type != EntityType.Enum && ( */}
              <ValidatedField
                onChange={handleFeatureChange}
                className="col-md-12 col-lg-6"
                id="app-entity-feature-name"
                name="selectFeatureId"
                data-cy="selectFeatureId"
                label={translate('codeconductorApp.feature.detail.title')}
                type="select"
                value={selectFeatureId}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0">
                  Select One
                </option>
                {featureEntities
                  ? featureEntities.map(feature => (
                      <option value={feature.id} key={feature.id} selected={true}>
                        {feature.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              {/* )} */}
              <ValidatedField
                className="col-md-12 col-lg-6"
                label={translate('codeconductorApp.appEntity.description')}
                id="app-entity-description"
                name="appEntityDescription"
                data-cy="appEntityDescription"
                type="text"
                value={appEntityDescription}
                onChange={handleAppEntityDescriptionChange}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              {/* <ValidatedField
                onChange={handleEnumMemberChange}
                className="col-md-6"
                id="enum-member-name"
                name="selectEnumMemberId"
                data-cy="selectEnumMemberId"
                label={translate('codeconductorApp.enumMember.detail.title')}
                type="select"
                value={selectEnumMemberId}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {enumMembers
                  ? enumMembers.map(enumMember => (
                    <option value={enumMember.id} key={enumMember.id} selected={true}>
                      {enumMember.name}
                    </option>
                  ))
                  : null}
              </ValidatedField>
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.enumMember.description')}
                id="enum-member-description"
                name="enumMemberDescription"
                data-cy="enumMemberDescription"
                type="text"
                value={enumMemberDescription}
                onChange={handleEnumMemberDescriptionChange}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              /> */}
              {/* <ValidatedField
                id="product-user-role-name"
                name="selectProductUserRoleAccessId"
                data-cy="selectProductUserRoleAccessId"
                label={translate('codeconductorApp.productUserRoleAccess.productUserRole')}
                type="select"
                value={selectProductUserRoleAccessId}
                onChange={handleProductUserRoleChange}
                // validate={{
                //   required: { value: true, message: translate('entity.validation.required') },
                // }}
              >
                <option value="" key="0" />
                {productUserRoleAccesses
                  ? productUserRoleAccesses.map(productUserRoleAccess => (
                      <option value={productUserRoleAccess.id} key={productUserRoleAccess.id}>
                        {productUserRoleAccess?.productUserRole?.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                label={translate('codeconductorApp.productUserRoleAccess.canRead')}
                id="product-user-role-access-canRead"
                name="canReadValue"
                data-cy="canReadValue"
                check
                type="checkbox"
                checked={canReadValue}
                onChange={handleCanReadValueChange}
              />
              <ValidatedField
                label={translate('codeconductorApp.productUserRoleAccess.canCreate')}
                id="product-user-role-access-canCreate"
                name="canCreateValue"
                data-cy="canCreateValue"
                check
                type="checkbox"
                checked={canCreateValue}
                onChange={handleCanCreateValueChange}
              />
              <ValidatedField
                label={translate('codeconductorApp.productUserRoleAccess.canUpdate')}
                id="product-user-role-access-canUpdate"
                name="canUpdateValue"
                data-cy="canUpdateValue"
                check
                type="checkbox"
                checked={canUpdateValue}
                onChange={handleCanUpdateValueChange}
              />
              <ValidatedField
                label={translate('codeconductorApp.productUserRoleAccess.canDelete')}
                id="product-user-role-access-canDelete"
                name="canDeleteValue"
                data-cy="canDeleteValue"
                check
                type="checkbox"
                checked={canDeleteValue}
                onChange={handleCanDeleteValueChange}
              />
              <ValidatedField
                label={translate('codeconductorApp.productUserRoleAccess.whenCreatedByUser')}
                id="product-user-role-access-whenCreatedByUser"
                name="whenCreatedByUserValue"
                data-cy="whenCreatedByUserValue"
                check
                type="checkbox"
                checked={whenCreatedByUserValue}
                onChange={handleWhenCreatedByUserValueChange}
              />
              <ValidatedField
                label={translate('codeconductorApp.productUserRoleAccess.whenCreatedByGroup')}
                id="product-user-role-access-whenCreatedByGroup"
                name="whenCreatedByGroupValue"
                data-cy="whenCreatedByGroupValue"
                check
                type="checkbox"
                checked={whenCreatedByGroupValue}
                onChange={handleWhenCreatedByGroupValueChange}
              /> */}
              <Col md={'12'}>
                <Button
                  tag={Link}
                  id="cancel-save"
                  data-cy="entityCreateCancelButton"
                  to={`/product/${productId}/app/${appId}`}
                  replace
                  color="back"
                >
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button
                  color="save"
                  className="btn-save"
                  id="save-entity"
                  data-cy="entityCreateSaveButton"
                  type="submit"
                  disabled={updating}
                >
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </Col>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AppEntityUpdate;
