import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from '../../config/store';
import { getEntities, deleteEntity } from './app-screen.reducer';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { DESC, ITEMS_PER_PAGE } from '../../../../../app/shared/util/pagination.constants';

export const AppScreenDeleteDialog = ({ id, showModal, setShowActiveScreenData, setShowActiveScreen, setSelectionBuilder }) => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  // const { id } = useParams<'id'>();
  const { appId } = useParams<'appId'>();

  const [loadModal, setLoadModal] = useState(false);

  const appScreenEntity = useAppSelector(state => state.appScreen.entities);
  const updateSuccess = useAppSelector(state => state.appScreen.updateSuccess);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', DESC), location.search)
  );

  const handleClose = () => {
    showModal(loadModal);
  };

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: 100,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: `appId.equals=${appId}`,
      })
    );
  };

  useEffect(() => {
    if (updateSuccess) {
      showModal(loadModal);
      getAllEntities();
      setShowActiveScreen(undefined);
      setShowActiveScreenData(undefined);
    }
  }, [updateSuccess]);

  const confirmDelete = () => {
    dispatch(deleteEntity(id));
  };

  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose} data-cy="appScreenDeleteDialogHeading">
        <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
      </ModalHeader>
      <ModalBody id="codeconductorApp.appScreen.delete.question">
        <Translate contentKey="codeconductorApp.appScreen.delete.question" interpolate={{ id: id }}>
          Are you sure you want to delete this AppScreen?
        </Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button id="jhi-confirm-delete-appScreen" data-cy="entityConfirmDeleteButton" color="danger" onClick={confirmDelete}>
          <FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AppScreenDeleteDialog;
