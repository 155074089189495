import React, { useState } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const AiSuggestEntitiesModal = props => {
  const {
    aiSuggestLoading,
    aiSuggestEntitiesList,
    handleOnSaveForSuggested,
    handleCloseAiEntitiesModal,
    handleSuggestedDelete,
    featureName,
  } = props;
  //   const location = useLocation();
  //   const { productId } = useParams<'productId'>();
  //   const { appId } = useParams<'appId'>();
  //   const { appEntityId } = useParams<'appEntityId'>();
  const titleUpperCase = str => {
    let splitStr = str.split(' ').map(item => item.charAt(0).toUpperCase() + item.substring(1));

    return splitStr.join(' ');
  };
  const [open, setOpen] = useState('0');
  const [selectedEntities, setSelectedEntities] = useState([]);
  console.log('aiSuggestEntitiesList', aiSuggestEntitiesList);
  const toggle = id => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const handleCheckboxChange = (e, appEntity) => {
    console.log('appEntity', appEntity);
    let isChecked = e.target.checked;
    if (isChecked == true) {
      let tempArr = selectedEntities;
      tempArr.push(appEntity);
      setSelectedEntities(tempArr);
    }
    if (isChecked == false) {
      let tempArr = selectedEntities.filter(obj => obj.featureEntityName != appEntity.featureEntityName);
      setSelectedEntities(tempArr);
    }
  };
  return (
    <>
      <Modal
        isOpen
        className="modal-lg"
        toggle={() => !aiSuggestLoading && handleCloseAiEntitiesModal()}
        className="modalDesignNew"
        centered
      >
        <ModalHeader
          //  toggle={handleClose}
          data-cy="appEntityFeatureHeading"
        >
          {!aiSuggestLoading && <>AI suggested entities for selected features</>}
        </ModalHeader>

        <ModalBody id="codeconductorApp.appEntity.feature.select">
          {aiSuggestEntitiesList.length > 0 && !aiSuggestLoading ? (
            <div className="coll-design">
              <div className="table-responsive">
                <Accordion open={open} toggle={toggle}>
                  {aiSuggestEntitiesList.length > 0 &&
                    aiSuggestEntitiesList.map((appEntity, index) => (
                      <>
                        {/* <td>{appEntity.featureEntityName}</td>
                      <td>{appEntity.name}</td>
                      <td className="text-end">
                        <div className="btn-group flex-btn-group-container">
                          <Button
                            onClick={e => {
                              handleOnSaveForSuggested(appEntity);
                            }}
                            color="primary"
                            size="sm"
                            data-cy="entityEditButton"
                          >
                            <FontAwesomeIcon icon="save" />
                          </Button>
                          <Button onClick={() => handleSuggestedDelete(appEntity)} color="danger" size="sm" data-cy="entityDeleteButton">
                            <FontAwesomeIcon icon="trash" />{' '}
                            <span className="d-none">
                              <Translate contentKey="entity.action.delete">Delete</Translate>
                            </span>
                          </Button>
                        </div>
                      </td> */}
                        <AccordionItem key={index}>
                          <AccordionHeader targetId={index}>
                            <>
                              <input
                                type="checkbox"
                                onChange={e => {
                                  handleCheckboxChange(e, appEntity);
                                }}
                              />
                              {appEntity.featureEntityName}
                            </>
                          </AccordionHeader>
                          <AccordionBody accordionId={index}>
                            <div>{appEntity.featureEntityDescription}</div>
                          </AccordionBody>
                        </AccordionItem>
                      </>
                    ))}
                </Accordion>
              </div>
            </div>
          ) : aiSuggestLoading ? (
            <div className="justify-content-center">
              <>
                <h2 className="wizard-heading gif_heading">Thank you!! Your input helps</h2>
                <img className="modalIimg-dgn" src="themeContent/entitiesloader.gif" />
              </>
            </div>
          ) : (
            <div className="alert alert-warning">No AI suggested Entities Found</div>
          )}
        </ModalBody>
        <ModalFooter>
          <>
            {!aiSuggestLoading && (
              <Button
                onClick={e => {
                  handleOnSaveForSuggested(selectedEntities);
                }}
                disabled={selectedEntities.length <= 0 ? true : false}
                color="save"
                data-cy="entityEditButton"
              >
                Next
              </Button>
            )}
            {/* <Button color="secondary" onClick={handleCloseAiEntitiesModal}>
              Close
            </Button> */}
          </>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AiSuggestEntitiesModal;
