import dayjs from 'dayjs';
import { IEntityRelationship } from '../../shared/model/entity-relationship.model';
import { IAppEntity } from '../../shared/model/app-entity.model';

export interface IAppEntityField {
  id?: number;
  name?: string;
  type?: string;
  isRequired?: boolean | null;
  description?: string | null;
  lastSyncTimestamp?: string | null;
  userId?: number | null;
  defaultValue?: string | null;
  entityRelationshipFroms?: IEntityRelationship[] | null;
  entityRelationshipTos?: IEntityRelationship[] | null;
  appEntity?: IAppEntity | null;
}

export const defaultValue: Readonly<IAppEntityField> = {
  isRequired: false,
};
