import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from '../../../../../app/shared/error/error-boundary-routes';

import AppDomainListener from './app-domain-listener';
import AppDomainListenerDetail from './app-domain-listener-detail';
import AppDomainListenerUpdate from './app-domain-listener-update';
import AppDomainListenerDeleteDialog from './app-domain-listener-delete-dialog';

const AppDomainListenerRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<AppDomainListener />} />
    <Route path="new" element={<AppDomainListenerUpdate />} />
    <Route path=":id">
      <Route index element={<AppDomainListenerDetail />} />
      <Route path="edit" element={<AppDomainListenerUpdate />} />
      <Route path="delete" element={<AppDomainListenerDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default AppDomainListenerRoutes;
