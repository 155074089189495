import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Translate, byteSize } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from '../../../../../app/config/constants';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';

import { getEntity } from './app-ai-service.reducer';

export const AppAiServiceDetail = () => {
  const dispatch = useAppDispatch();

  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { appAiServiceId } = useParams<'appAiServiceId'>();
  const productEntity = useAppSelector(state => state.product.entity);
  const app = useAppSelector(state => state.app.entity);

  useEffect(() => {
    dispatch(getEntity(appAiServiceId));
  }, []);

  const appAiServiceEntity = useAppSelector(state => state.appAiService.entity);
  return (
    <div className="inner-content-wrap">
      <Row>
        <Col md="8">
          <h2 data-cy="appAiServiceDetailsHeading">
            {/* <Translate contentKey="codeconductorApp.appAiService.detail.title">AppAiService</Translate> */}
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/product">Products</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link to={`/product/${productEntity.id}`}>{productEntity.name}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link to={`/product/${productEntity.id}/app/${app.id}`}>{app.name}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active> AI Services</BreadcrumbItem>
            </Breadcrumb>
          </h2>
          <dl className="jh-entity-details">
            {/* <dt>
              <span id="id">
                <Translate contentKey="global.field.id">ID</Translate>
              </span>
            </dt>
            <dd>{appAiServiceEntity.id}</dd> */}
            <dt>
              <span id="name">
                <Translate contentKey="codeconductorApp.appAiService.name">Name</Translate>
              </span>
            </dt>
            <dd>{appAiServiceEntity.name}</dd>
            <dt>
              <span id="className">
                <Translate contentKey="codeconductorApp.appAiService.className">Class Name</Translate>
              </span>
            </dt>
            <dd>{appAiServiceEntity.className}</dd>
            <dt>
              <span id="systemMessage">
                <Translate contentKey="codeconductorApp.appAiService.systemMessage">System Message</Translate>
              </span>
            </dt>
            <dd>{appAiServiceEntity.systemMessage}</dd>
            <dt>
              <span id="returnType">
                <Translate contentKey="codeconductorApp.appAiService.returnType">Return Type</Translate>
              </span>
            </dt>
            <dd>{appAiServiceEntity.returnType}</dd>
            <dt>
              <span id="parameters">
                <Translate contentKey="codeconductorApp.appAiService.parameters">Parameters</Translate>
              </span>
            </dt>
            <dd>{appAiServiceEntity.parameters}</dd>
            <dt>
              <Translate contentKey="codeconductorApp.appAiService.appFile">App File</Translate>
            </dt>
            <dd>{appAiServiceEntity.appFile ? appAiServiceEntity.appFile.id : ''}</dd>
            <dt>
              <Translate contentKey="codeconductorApp.appAiService.appChatLanguageModel">App Chat Language Model</Translate>
            </dt>
            <dd>{appAiServiceEntity.appChatLanguageModel ? appAiServiceEntity.appChatLanguageModel.id : ''}</dd>
            <dt>
              <Translate contentKey="codeconductorApp.appAiService.appEmbeddingStore">App Embedding Store</Translate>
            </dt>
            <dd>{appAiServiceEntity.appEmbeddingStore ? appAiServiceEntity.appEmbeddingStore.id : ''}</dd>
            <dt>
              <Translate contentKey="codeconductorApp.appAiService.app">App</Translate>
            </dt>
            <dd>{appAiServiceEntity.app ? appAiServiceEntity.app.id : ''}</dd>
          </dl>
          <Button tag={Link} to={`/product/${productId}/app/${appId}`} replace color="back" data-cy="entityDetailsBackButton">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/product/${productId}/app/${appId}/app-ai-service/${appAiServiceEntity.id}/edit`} replace color="edit">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default AppAiServiceDetail;
