import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '../../../../../app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../config/store';

// import { getEntity, deleteEntity, getEntities } from '../../../../../app/entities/app-end-point/app-end-point.reducer';
import { getEntity, deleteEntity, getEntities } from './app-end-point.reducer';

export const AppEndPointDeleteDialog = props => {
  const { appEndPointId, showModal } = props;

  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const { appId } = useParams<'appId'>();
  const { endpointControllerId } = useParams<'endpointControllerId'>();
  const { id } = useParams<'id'>();
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', DESC), location.search)
  );
  const [loadModal, setLoadModal] = useState(false);

  const appEndPointEntity = useAppSelector(state => state.appEndPoint.entity);
  const updateSuccess = useAppSelector(state => state.appEndPoint.updateSuccess);

  const handleClose = () => {
    // navigate('/app-end-point' + location.search);
    showModal(loadModal);
  };

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}&appEndPointControllerId.equals=${endpointControllerId}`,
        // query: `appEndPointControllerId.equals=${endpointControllerId}`,
      })
    );
  };

  useEffect(() => {
    if (updateSuccess) {
      showModal(loadModal);
      getAllEntities();
    }
  }, [updateSuccess]);

  const confirmDelete = () => {
    dispatch(deleteEntity(appEndPointId));
  };

  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader data-cy="appEndPointDeleteDialogHeading">
        <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
      </ModalHeader>
      <ModalBody id="codeconductorApp.appEndPoint.delete.question">
        <Translate contentKey="codeconductorApp.appEndPoint.delete.question" interpolate={{ id: appEndPointId }}>
          Are you sure you want to delete this AppEndPoint?
        </Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button id="jhi-confirm-delete-appEndPoint" data-cy="entityConfirmDeleteButton" color="danger" onClick={confirmDelete}>
          <FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AppEndPointDeleteDialog;
