import React, { useEffect, useState, useRef } from 'react';
import randomColor from 'randomcolor';
import { Button } from 'reactstrap';
import { MinusIcon, PlusIcon, ResetIcon } from '../../assets/svg-icon/icon';

const GraphComponent = props => {
  const [lib, setLib] = useState({});
  const [libg, setLibg] = useState({});
  const [loading, setLoading] = useState(true);
  const [canvas, setCanvas] = useState();
  const [source, setSource] = useState();
  const [scale, setScale] = useState(1);
  const [fitToScreen, setFitToScreen] = useState();

  const getRandomColor = () => {
    let color;
    do {
      color = randomColor({ luminosity: 'light', hue: 'blue' });
    } while (color == '#000' || color == '#000000');
    return color;
  };

  useEffect(() => {
    if (fitToScreen) {
      setScale(fitToScreen);
      lib['nomnoml']?.draw(canvas, source, fitToScreen);
    }
  }, [fitToScreen]);

  function transformObjectForRelationships(data) {
    let transformed = '';
    data.forEach(item => {
      const fromName = item?.appEntityFrom?.name;
      const toName = item?.appEntityTo?.name;

      if (fromName != '' && fromName != undefined && toName != '' && toName != undefined) {
        const fromColor = getRandomColor();
        const toColor = getRandomColor();

        transformed += `#.${fromName.toLowerCase()}: fill=${fromColor}\n`;
        transformed += `#.${toName.toLowerCase()}: fill=${toColor}\n`;
        transformed += `[<${fromName.toLowerCase()}> ${fromName}] -> [<${toName.toLowerCase()}> ${toName}]\n`;
      }
    });
    return transformed;
  }

  function transformObjectForScreens(data) {
    let screens = data.screen_list;
    let componentList = data.component_list;
    if (screens.length < 0 || componentList.length < 1) return '';

    let frameName = screens[0].app.name; // get frame name
    let components = screens.map(item => item.title); // get frame components
    let transformed = '';
    let sub_transformed = '';
    let newComponentsArray = [];
    let newArrayKeys = [];

    transformed += `#direction: right \n`;
    transformed += `#background: #f1f5f9 \n`;
    transformed += `#.screenframe: stroke=#f1f5f9 fill=#14329d visual=frame title=left \n`;
    transformed += `#.screen: stroke=#f1f5f9 fill=#4d5eec \n`;
    transformed += `#.subscreen: fill=#f1f5f9 stroke=#14329d \n`;
    transformed += `[<screenframe> ${frameName} \n`;

    components.forEach(item => {
      let newArrayIn = componentList.filter(compItem => compItem.appScreen.title == item);
      let index = newArrayKeys.indexOf(item);
      let componentObj = {};
      if (index === -1) {
        componentObj = {
          component: item,
          componentDetails: newArrayIn,
        };
        newComponentsArray.push(componentObj);
      } else {
        let addNewVal = newComponentsArray.filter(i => i.component == item);
        addNewVal[0].componentDetails.push(newArrayIn[0]);
      }
      transformed += `| [<screen> ${item}] \n`;
    });
    transformed += `] \n`;

    newComponentsArray.forEach(item => {
      if (item.componentDetails.length > 0) {
        transformed += `[${frameName}] -> [<screen> ${item.component}`;

        item.componentDetails.forEach(component => {
          if (!component) return;

          transformed += ` | [<subscreen> ${component.name}]`;

          if (component.appScreenviewEdit !== null && component.appScreenviewDetail !== null) {
            if (component.appScreenviewEdit.title == component.appScreenviewDetail.title) {
              sub_transformed += `[<screen> ${item.component} | [<subscreen> ${component.name}]] edit/detail-> [${component.appScreenviewEdit.title}]\n`;
            } else {
              sub_transformed += `[${item.component} | [<subscreen>  ${component.name}]] edit-> [${component.appScreenviewEdit.title}]\n`;
              sub_transformed += `[<screen> ${item.component} | [<subscreen> ${component.name}]] detail-> [${component.appScreenviewDetail.title}]\n`;
            }
          } else {
            if (component.appScreenviewEdit !== null) {
              sub_transformed += `[<screen> ${item.component} | [<subscreen> ${component.name}]] edit-> [${component.appScreenviewEdit.title}]\n`;
            }
            if (component.appScreenviewDetail !== null) {
              sub_transformed += `[<screen> ${item.component} | [<subscreen> ${component.name}]] detail-> [${component.appScreenviewDetail.title}]\n`;
            }
          }

          if (
            component?.layout == 'form' &&
            component?.formAction?.toLowerCase() == 'redirect' &&
            component?.appScreenformSuccessRedirectScreen !== null
          ) {
            let redirectedVal = componentList.find(val => val.appScreen.id === component.appScreenformSuccessRedirectScreen)?.appScreen
              .title;
            sub_transformed += `[<screen> ${item.component} | [<subscreen> ${component.name}]] redirect-> [${redirectedVal}]\n`;
          }
        });
        transformed += `]\n`;
      }
    });
    transformed += sub_transformed;
    return transformed;
  }

  const loadSrcipt = scriptName => {
    let url = scriptName == 'nomnoml' ? 'https://unpkg.com/nomnoml/dist/nomnoml.js' : 'https://unpkg.com/graphre/dist/graphre.js';
    let script = document.createElement('script');
    script.src = url;
    script.type = 'text/javascript';
    script.async = false;
    document.body.appendChild(script);
    script.onload = () => (scriptName == 'nomnoml' ? setLib({ nomnoml: window['nomnoml'] }) : setLibg({ graphre: window['graphre'] }));
  };

  useEffect(() => {
    if (window.document && lib['nomnoml'] != undefined && libg['graphre'] != undefined) {
      if (props.entityRelationships) {
        let source = transformObjectForRelationships(props.entityRelationships);
        setSource(source);

        let canvas = window.document.getElementById('erd-canvas');
        setCanvas(canvas);
        lib['nomnoml']?.draw(canvas, source, 1);

        setFitToScreen(document.getElementById('erd-canvas-main').offsetWidth / canvas.offsetWidth);
        setLoading(false);
      }
    } else {
      if (!loading) {
        setLoading(true);
      }
    }
  }, [props.entityRelationships, lib, libg]);

  useEffect(() => {
    if (window.document && lib['nomnoml'] != undefined && libg['graphre'] != undefined) {
      debugger;
      if (props.screensData) {
        let source = transformObjectForScreens(props.screensData);
        setSource(source);

        let canvas = window.document.getElementById('erd-canvas');
        setCanvas(canvas);
        lib['nomnoml']?.draw(canvas, source, 2);

        setFitToScreen(document.getElementById('erd-canvas-main').offsetWidth / canvas.offsetWidth);
        setLoading(false);
      }
    } else {
      if (!loading) {
        setLoading(true);
      }
    }
  }, [props.screensData, lib, libg]);

  useEffect(() => {
    if (window.document) {
      loadSrcipt('graphre');
      loadSrcipt('nomnoml');
    }
  }, []);

  // let scale = 1.0;
  // fitCanvasSize()
  const zoomIn = () => {
    setScale(scale + 0.2);
    lib['nomnoml']?.draw(canvas, source, scale + 0.2);
  };
  const zoomOut = () => {
    setScale(scale - 0.2);
    lib['nomnoml']?.draw(canvas, source, scale - 0.2);
  };
  const reset = () => {
    setScale(fitToScreen);

    lib['nomnoml']?.draw(canvas, source, fitToScreen);
  };
  return (
    <>
      <div className="canvas-btn">
        <Button onClick={() => zoomIn()}>
          <PlusIcon />
        </Button>
        <Button onClick={() => reset()}>
          <ResetIcon />
        </Button>
        <Button onClick={() => zoomOut()}>
          <MinusIcon />
        </Button>
      </div>
      <div id="erd-canvas-main">
        {loading && <div className="conductor_loader"></div>}

        <canvas id="erd-canvas"></canvas>
      </div>
    </>
  );
};

export default GraphComponent;
