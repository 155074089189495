import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from '../../../../../app/config/constants';
import { useAppDispatch, useAppSelector } from '../../config/store';

import { getEntity } from '../../../../../app/entities/app-access-config/app-access-config.reducer';

export const AppAccessConfigDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getEntity(id));
    }
  }, []);

  const appAccessConfigEntity = useAppSelector(state => state.appAccessConfig.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="appAccessConfigDetailsHeading">
          <Translate contentKey="codeconductorApp.appAccessConfig.detail.title">AppAccessConfig</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{appAccessConfigEntity.id}</dd>
          <dt>
            <span id="pathPattern">
              <Translate contentKey="codeconductorApp.appAccessConfig.pathPattern">Path Pattern</Translate>
            </span>
          </dt>
          <dd>{appAccessConfigEntity.pathPattern}</dd>
          <dt>
            <span id="reason">
              <Translate contentKey="codeconductorApp.appAccessConfig.reason">Reason</Translate>
            </span>
          </dt>
          <dd>{appAccessConfigEntity.reason}</dd>
          <dt>
            <span id="accessRoles">
              <Translate contentKey="codeconductorApp.appAccessConfig.accessRoles">Access Roles</Translate>
            </span>
          </dt>
          <dd>{appAccessConfigEntity.accessRoles}</dd>
          <dt>
            <span id="enabled">
              <Translate contentKey="codeconductorApp.appAccessConfig.enabled">Enabled</Translate>
            </span>
          </dt>
          <dd>{appAccessConfigEntity.enabled ? 'true' : 'false'}</dd>
          <dt>
            <Translate contentKey="codeconductorApp.appAccessConfig.functionality">Functionality</Translate>
          </dt>
          <dd>{appAccessConfigEntity.functionality ? appAccessConfigEntity.functionality.name : ''}</dd>
          <dt>
            <Translate contentKey="codeconductorApp.appAccessConfig.app">App</Translate>
          </dt>
          <dd>{appAccessConfigEntity.app ? appAccessConfigEntity.app.name : ''}</dd>
        </dl>
        <Button tag={Link} to="/app-access-config" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/app-access-config/${appAccessConfigEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default AppAccessConfigDetail;
