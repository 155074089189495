import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import AppScreen from './app-screen';
import AppScreenDetail from './app-screen-detail';
import AppScreenUpdate from './app-screen-update';
import AppScreenDeleteDialog from './app-screen-delete-dialog';

const AppScreenRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<AppScreen />} />
    <Route path="new" element={<AppScreenUpdate />} />
    <Route path=":id">
      <Route index element={<AppScreenDetail />} />
      <Route path="edit" element={<AppScreenUpdate />} />
      <Route path="delete" element={<AppScreenDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default AppScreenRoutes;
