import { IProductUserRoleAccess } from '../../shared/model/product-user-role-access.model';

export interface IProductUserRole {
  id?: number;
  name?: string;
  description?: string | null;
  accessMap?: string | null;
  userId?: number | null;
  productUserRoleAccesses?: IProductUserRoleAccess[] | null;
}

export const defaultValue: Readonly<IProductUserRole> = {};
