import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Col } from 'reactstrap';
import { Translate, ValidatedForm, ValidatedField, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from '../../config/store';
import { getEntities, deleteEntity, getEntity as getAppScreenEntity } from './app-screen.reducer';
import { getEntities as getScreenComponents, createEntity, updateEntity, getEntity } from '../screen-component/screen-component.reducer';
import DynamicSelect from '../../modules/dynamicSelectComponent/DynamicSelect';

export const AddComponentModal = props => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const { appId } = useParams<'appId'>();
  const { appScreenId, showModal, componentId, screenComponent } = props;
  const isNew = screenComponent?.id === undefined;

  const [loadModal, setLoadModal] = useState(false);

  const appScreenEntity = useAppSelector(state => state.appScreen.entities);
  const appEntityList = useAppSelector(state => state.appEntity.entities);
  const appScreen = useAppSelector(state => state.appScreen.entity);
  const updateSuccess = useAppSelector(state => state.screenComponent.updateSuccess);
  const appScreenComponent = useAppSelector(state => state.screenComponent.entities);
  // const screenComponent = useAppSelector(state => state.screenComponent.entity);
  const updating = useAppSelector(state => state.screenComponent.updating);
  const [nameValue, setNameValue] = useState();
  const [appEntityValue, setAppEntityValue] = useState();
  const [appEntities, setAppEntities] = useState();

  useEffect(() => {
    dispatch(getAppScreenEntity(appScreenId));
    {
      componentId != undefined ? dispatch(getEntity(componentId)) : '';
    }
    dispatch(getScreenComponents({ query: 'appScreenId.equals=' + appScreenId }));
  }, []);

  useEffect(() => {
    if (appEntityList?.length > 0) {
      const filteredEntities = appEntityList.filter(it => it.type == 'Standard');
      setAppEntities(filteredEntities);
    }
  }, [appEntityList]);

  const handleClose = () => {
    showModal(false);
  };

  const getAllEntities = () => {
    dispatch(
      getEntities({
        query: `appId.equals=${appId}`,
      })
    );
    dispatch(getScreenComponents({ query: 'appScreenId.equals=' + appScreenId }));
  };

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
      showModal(false);
      getAllEntities();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...screenComponent,
      ...values,
      // id: !isNew ? screenComponent.id : '',
      name: nameValue,
      layout: isNew ? 'list' : screenComponent.layout,
      appScreen: appScreenEntity.find(it => it.id.toString() === appScreenId.toString()),
      appEntity: isNew ? appEntities.find(it => it.id.toString() === appEntityValue?.toString()) : screenComponent?.appEntity,
      entityFields: !isNew ? screenComponent.entityFields : null,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          screenComponent,
        };

  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose} data-cy="appScreenDeleteDialogHeading">
        <Label>{isNew ? 'Add new component' : 'Rename component'}</Label>
      </ModalHeader>
      <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
        <ModalBody>
          <ValidatedField
            label={translate('codeconductorApp.screenComponent.name')}
            id="screen-component-name"
            name="name"
            data-cy="name"
            type="text"
            required
            defaultValue={screenComponent?.name ? screenComponent?.name : ''}
            onChange={e => setNameValue(e.target.value)}
          />
          {isNew && (
            <ValidatedField
              id="screen-component-appEntity"
              name="appEntity"
              data-cy="appEntity"
              label={translate('codeconductorApp.screenComponent.appEntity')}
              type="select"
              required
              onChange={e => {
                setAppEntityValue(e.target.value);
              }}
            >
              <option value="" key="0" />
              {appEntities
                ? appEntities.map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.name}
                    </option>
                  ))
                : null}
            </ValidatedField>
          )}
        </ModalBody>
        <ModalFooter>
          <Col md={'12'} style={{ padding: '0px 6px' }}>
            <Button color="secondary" onClick={handleClose}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            <Button
              color="primary"
              id="save-entity"
              data-cy="entityCreateSaveButton"
              type="submit"
              disabled={updating}
              style={{ marginLeft: '4px', padding: '8px !important' }}
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="entity.action.save">Save</Translate>
            </Button>
          </Col>
        </ModalFooter>
      </ValidatedForm>
    </Modal>
  );
};

export default AddComponentModal;
