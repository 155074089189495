import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col, Label, FormGroup, Input, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from '../../../../../app/config/constants';
import { useAppDispatch, useAppSelector } from '../../config/store';

import { getEntity } from './app-screen.reducer';
import { getEntities as getScreenComponents } from '../../../../../app/entities/screen-component/screen-component.reducer';
import { BackArrowIcon } from '../../shared/assets/svg-icon/icon';

export const AppScreenDetail = () => {
  const dispatch = useAppDispatch();

  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { appScreenId } = useParams<'appScreenId'>();
  const productEntity = useAppSelector(state => state.product.entity);
  const appEntity = useAppSelector(state => state.app.entity);
  useEffect(() => {
    if (appScreenId !== undefined) {
      dispatch(getEntity(appScreenId));
    }
    dispatch(getScreenComponents({ query: 'appScreenId.equals=' + appScreenId }));
  }, []);

  const appScreenEntity = useAppSelector(state => state.appScreen.entity);
  const appScreenComponent = useAppSelector(state => state.screenComponent.entities);
  console.log('appScreenEntities', appScreenComponent);
  return (
    <div className="inner-content-wrap">
      <Row>
        <Col md="12">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/product">Products</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={`/product/${productEntity.id}`}>{productEntity.name}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={`/product/${productEntity.id}/app/${appEntity.id}`}>{appEntity.name}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active> App Screen</BreadcrumbItem>
          </Breadcrumb>
        </Col>
        {/* <Col md="6" className="justify-content-end d-flex">
            <Link
              // href=""
              className="feature-back"
              to={`/product/${productEntity.id}/app/${appEntity.id}`}
              replace
              color="back"
              data-cy="entityDetailsBackButton"
            >
              <BackArrowIcon />
              <Translate contentKey="entity.action.back">Back</Translate>
            </Link>
          </Col> */}
        {/* <h2 className="row  page-title" data-cy="appScreenDetailsHeading">
            <Translate contentKey="codeconductorApp.appScreen.detail.title">AppScreen</Translate>
          </h2> */}
        <dl className="jh-entity-details">
          {/* <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{appScreenEntity.id}</dd> */}
          <Row>
            <Col md="12" col lg="6">
              <FormGroup>
                <span id="title">
                  <Label contentKey="codeconductorApp.appScreen.title">Title</Label>
                </span>
                <Input value={appScreenEntity.title} />
              </FormGroup>
            </Col>
            <Col md="12" col lg="6">
              <FormGroup>
                <span id="path">
                  <Label contentKey="codeconductorApp.appScreen.path">Path</Label>
                </span>
                <Input value={appScreenEntity.path} />
              </FormGroup>
            </Col>
            {/* <Col md ={6}>
          <FormGroup>
            <span id="metadata">
              <Translate contentKey="codeconductorApp.appScreen.metadata">Metadata</Translate>
            </span>
            <UncontrolledTooltip target="metadata">
              <Label contentKey="codeconductorApp.appScreen.help.metadata" />
            </UncontrolledTooltip>
            <Input value = {appScreenEntity.metadata}/>
            </FormGroup>
          </Col> */}
            <Col md="12" col lg="6">
              <FormGroup>
                <span id="template">
                  <Label contentKey="codeconductorApp.appScreen.template">Template</Label>
                </span>
                <Input value={appScreenEntity.template} />
              </FormGroup>
            </Col>
            <Col md="12" col lg="6">
              <FormGroup>
                <span id="accessRoles">
                  <Label contentKey="codeconductorApp.appScreen.accessRoles">Access Roles</Label>
                </span>
                <UncontrolledTooltip target="accessRoles">
                  <Translate contentKey="codeconductorApp.appScreen.help.accessRoles" />
                </UncontrolledTooltip>
                <Input value={appScreenEntity.accessRoles} />
              </FormGroup>
            </Col>
            {/* <Col md ={6}>
          <FormGroup>
            <Label contentKey="codeconductorApp.appScreen.app">App</Label>
            <Input value = {appScreenEntity.app ? appScreenEntity.app.name : ''}/>
            </FormGroup>
          </Col> */}
            {/* <Col md ={6}>
          <FormGroup>
            <Translate contentKey="codeconductorApp.appScreen.functionality">Functionality</Translate>
            <Input value = {appScreenEntity.functionality ? appScreenEntity.functionality.name : ''}/>
            </FormGroup>
          </Col> */}
          </Row>
        </dl>
        <Col md="12" col lg="6">
          <Button tag={Link} to={`/product/${productId}/app/${appId}`} replace color="back" data-cy="entityDetailsBackButton">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
        </Col>
        &nbsp;
        {/* <Button tag={Link} to={`/app-screen/${appScreenEntity.id}/edit`} replace color="save">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button> */}
      </Row>
    </div>
  );
};

export default AppScreenDetail;
