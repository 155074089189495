import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { translate, Translate, ValidatedField } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from './config/store';
import { toast } from 'react-toastify';
import {
  createEntity as createProduct,
  copyCoreFunctionality,
  updateEntity as updateProduct,
} from '../app/entities/product/product.reducer';
import { createEntity as createApp, updateEntity as updateApp } from '../../../app/entities/app/app.reducer';
import { createDefaultDeploymentCreds } from './entities/deployment/deployment.reducer';
import { useNavigate } from 'react-router-dom';
import { Authentication } from '../../../app/shared/model/enumerations/authentication.model';
import { ApplicationType } from '../../../app/shared/model/enumerations/application-type.model';
import { AppStatus } from '../../../app/shared/model/enumerations/app-status.model';

export const AiConfirmationDialog = ({ productContext, coreFunctionalities, apps, selectApp, closeModal, userPrompt }) => {
  const dispatch = useAppDispatch();
  const productEntity = useAppSelector(state => state.product.entity);
  const [appName, setAppName] = useState(productContext && productContext.appName ? productContext.appName : productContext.identifier);
  const [selectedAppId, setSelectedAppId] = useState(undefined);
  const [functionalities, setFunctionalities] = useState(
    productContext && productContext.functionalities ? productContext.functionalities : []
  );
  const navigate = useNavigate();

  const handleClose = () => {
    closeModal(false);
  };

  interface ActionUrlObject {
    action: string;
    url: string;
    label?: string;
  }

  // Action and url mapping array
  const actionUrlMappingArray: ActionUrlObject[] = [
    { action: 'create_app', url: '/app/new' },
    { action: 'update_app', url: '/app/:id/edit', label: 'Please select an app you want update' },
    { action: 'add_functionality', url: '/functionality/:id/new', label: 'Confirm or Select an app for which Functionality to be added' },
    { action: 'add_entity', url: '/app-entity/:id/new', label: 'Confirm or Select an app for which Entity to be added' },
    // { action: "update_entity", url: "app-entity/:id/:entity_id/edit" },
    // { action: "add_entity_field", url: "/product-feature/:id/app-entity-view/:entity_id/new" },
    // { action: "update_entity_field", url: "/product-feature/:id/app-entity-view/:entity_id/edit/:entity_field_id" },
    { action: 'create_screen', url: '/app-screen/:id/new', label: 'Confirm or Select an app for which Screen to be added' },
  ];

  const getRediectURL = (action: string, id: number): string => {
    let dynamicUrl = '';
    const foundObject = actionUrlMappingArray.find(obj => obj.action === action);
    if (foundObject) {
      dynamicUrl = foundObject.url.replace(':id', id.toString());
    }

    return dynamicUrl;
  };

  const onSubmit = async () => {
    const action = productContext.action;
    let redirectUrl = undefined;
    if (action) {
      handleClose();
      switch (action) {
        case 'create_app':
          toast(`Prompt processed successfully, creating ${action.split('_').pop()}...`);
          addProduct();
          userPrompt('');
          break;
        case 'update_app':
        case 'add_functionality':
        case 'add_entity':
        case 'create_screen':
          let id = selectedAppId ? selectedAppId : apps.find(app => app.name.match(new RegExp(appName, 'i')))?.id;
          let selectedAppObj = apps.find(app => app.id == id);
          dispatch(() => {
            state: {
              app: {
                entity: selectedAppObj;
              }
            }
          });
          redirectUrl = getRediectURL(action, id);
          console.log('redirect', redirectUrl);
          if (redirectUrl && redirectUrl !== '') {
            navigate(redirectUrl);
          }
          userPrompt('');
          break;
        default:
          toast('Hey!! I am not able to understand what you want to do, can you please explain a bit more...');
      }
    }

    // if (action) {
    //     handleClose();
    //     if (action.includes("create") || action.includes("add")) {
    //         toast(`Prompt processed successfully, creating ${action.split('_').pop()}...`);
    //         if (action.includes("app")) {
    //             addProduct();
    //         }
    //     } else if (action.includes("update")) {
    //       //  toast(`Prompt processed successfully, updating ${action.split('_').pop()}...`);
    //         if (action.includes("app")) {
    //            // modifyApp();
    //            navigate("/app/"+selectedAppId+"/edit");
    //         }
    //     } else {
    //         toast("Hey!! I am not able to understand what you want to do, can you please explain a bit more...");
    //     }
    // }
  };

  const addProduct = async () => {
    console.log({ appName });
    let product;
    try {
      if (appName) {
        //create product
        var productValues = {
          name: appName,
          authenticationType: Authentication.Jwt,
          gitGroupPath: appName,
          status: AppStatus.New,
        };
        product = await dispatch(createProduct({ ...productValues }));
      } else {
        toast('Please enter a valid app name!!');
      }
    } catch (error) {
    } finally {
      console.log({ product });
      if (product && product.payload && product.payload.data) {
        //create app
        var appValues = {
          name: product?.payload?.data?.name,
          baseName: product?.payload?.data?.name,
          applicationType: ApplicationType.Monolith,
          product: product?.payload?.data,
          status: AppStatus.New,
        };
        await dispatch(createApp(appValues));

        //add default deployement config
        await dispatch(createDefaultDeploymentCreds(product.payload.data.id));

        //add functionality
        if (coreFunctionalities && coreFunctionalities.length > 0) {
          var selectedCoreFunctionalityIds = [];
          productContext.functionalities.map(f => {
            coreFunctionalities.filter(c => {
              if (c.name.toLowerCase() === f.name.toLowerCase()) {
                selectedCoreFunctionalityIds.push(c.id);
              }
            });
          });
          console.log({ selectedCoreFunctionalityIds });
          await dispatch(copyCoreFunctionality({ productId: product.payload.data.id, coreFunctionalityId: selectedCoreFunctionalityIds }));
        }
      }
    }
  };

  const modifyApp = async () => {
    if (selectedAppId) {
      var app = apps && apps.find(it => it.id.toString() === selectedAppId.toString());
      var appValues = {
        name:
          productContext &&
          (productContext.appName
            ? productContext.appName
            : productContext.columnValue
            ? productContext.columnValue
            : productContext.identifier),
      };
      await dispatch(
        updateApp({
          ...app,
          ...appValues,
        })
      );
      // Navigate({ to: '/product/' + app.product.id });
    }
  };

  const onAppNameChange = e => {
    setAppName(e.target.value);
  };

  const onAppChange = e => {
    setSelectedAppId(e.target.value);
  };

  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose} data-cy="aiConfirmationDialogHeading">
        {!selectApp ? `Confirm App Name` : `Select an app`}
      </ModalHeader>
      <ModalBody id="codeconductorApp.app.delete.question">
        {!selectApp ? (
          <>
            We are going to create an app with name '
            {productContext && productContext.identifier ? productContext.identifier : productContext.appName}'{' '}
            {functionalities.length == 0
              ? ''
              : functionalities.length == 1
              ? 'with functionality: ' + functionalities.map(f => f.name).join(', ')
              : 'with functionalities: ' + functionalities.map(f => f.name).join(', ')}
            . Do you want to change?
            <ValidatedField
              className="col-md-12"
              id="name"
              name="name"
              data-cy="name"
              onChange={onAppNameChange}
              defaultValue={appName}
              validate={{
                required: { value: true, message: 'Please enter a valid name!!' },
              }}
            />
          </>
        ) : (
          <>
            {productContext && productContext.action ? actionUrlMappingArray.find(obj => obj.action === productContext.action)?.label : ''}
            <ValidatedField
              className="col-md-6"
              id="appId"
              name="appId"
              data-cy="appId"
              type="select"
              onChange={onAppChange}
              validate={{
                required: { value: true, message: translate('entity.validation.required') },
              }}
            >
              <option value={0} key={0}>
                {'Select App'}
              </option>
              {apps.map(app =>
                app.name.match(new RegExp(appName, 'i')) ? (
                  <option value={app.id} key={app.id} selected>
                    {app.name}
                  </option>
                ) : (
                  <option value={app.id} key={app.id}>
                    {app.name}
                  </option>
                )
              )}
            </ValidatedField>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={e => handleClose()}>
          {/* <FontAwesomeIcon icon="ban" /> */}
          {/* &nbsp; */}
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button id="jhi-confirm-delete-app" data-cy="entityConfirmDeleteButton" color="danger" onClick={onSubmit}>
          {/* <FontAwesomeIcon icon="trash" /> */}
          {/* &nbsp; */}
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AiConfirmationDialog;
