import product from '../entities/product/product.reducer';
import productUserRole from '../../../../app/entities/product-user-role/product-user-role.reducer';
import app from '../entities/app/app.reducer';
import codeBlueprint from '../../../../app/entities/code-blueprint/code-blueprint.reducer';
import appCodeBlueprint from '../../../../app/entities/app-code-blueprint/app-code-blueprint.reducer';
import appScreen from '../entities/app-screen/app-screen.reducer';
import appAccessConfig from '../../../../app/entities/app-access-config/app-access-config.reducer';
import functionality from '../entities/functionality/functionality.reducer';
import appEntity from '../entities/app-entity/app-entity.reducer';
import appEntityField from '../entities/app-entity-field/app-entity-field.reducer';
import entityRelationship from '../entities/entity-relationship/entity-relationship.reducer';
import deployment from '../entities/deployment/deployment.reducer';
import coreScreen from '../../../../app/entities/core-screen/core-screen.reducer';
import coreAccessConfig from '../../../../app/entities/core-access-config/core-access-config.reducer';
import coreFunctionality from '../../../../app/entities/core-functionality/core-functionality.reducer';
import coreEntity from '../../../../app/entities/core-entity/core-entity.reducer';
import coreEntityField from '../../../../app/entities/core-entity-field/core-entity-field.reducer';
import coreEntityRelationship from '../../../../app/entities/core-entity-relationship/core-entity-relationship.reducer';
import emailConfiguration from '../../../../app/entities/email-configuration/email-configuration.reducer';
import productUser from '../../../../app/entities/product-user/product-user.reducer';
import enumMember from '../entities/enum-member/enum-member.reducer';
import type from '../../../../app/entities/type/type.reducer';
import teamMember from '../../../../app/entities/team-member/team-member.reducer';
import screenComponent from '../entities/screen-component/screen-component.reducer';
import vaultConfig from '../entities/vault-config/vault-config.reducer';
import dependency from '../entities/dependency/dependency.reducer';
import chatGptSearch from './chatgpt/chatgpt-search.reducer';
import appDependency from '../entities/app-dependency/app-dependency.reducer';
import productUserRoleAccess from '../entities/product-user-role-access/product-user-role-access.reducer';
import feature from '../entities/feature/feature.reducer';
import job from '../../../../app/entities/job/job.reducer';
import aiSuggestor from './ai-suggestor/ai-suggestor.reducer';
import appEndPointController from '../../../../app/entities/app-end-point-controller/app-end-point-controller.reducer';
//import appEndPoint from '../../entities/app-end-point/app-end-point.reducer'
import appEndPoint from '../../../../app/entities/app-end-point/app-end-point.reducer';
import coreScreenComponent from '../../../../app/entities/core-screen-component/core-screen-component.reducer';
import appScreenLayout from '../../../../app/entities/app-screen-layout/app-screen-layout.reducer';
import menuItem from '../../../../app/entities/menu-item/menu-item.reducer';
import layoutConfig from '../../../../app/entities/layout-config/layout-config.reducer';
import appLayout from '../../../../app/entities/app-layout/app-layout.reducer';
import screenContent from '../../../../app/entities/screen-content/screen-content.reducer';
import jobTask from '../../../../app/entities/job-task/job-task.reducer';
import coreEnumMember from '../../../../app/entities/core-enum-member/core-enum-member.reducer';
import coreFunctionalityDependency from './core-functionality-dependency/core-functionality-dependency.reducer';
import coreAppEndPoint from './core-app-end-point/core-app-end-point.reducer';
import coreAppEndPointController from './core-app-end-point-controller/core-app-end-point-controller.reducer';
import coreJob from './core-job/core-job.reducer';
import appDomainListener from './app-domain-listener/app-domain-listener.reducer';
import productJournalEntry from './product-journal-entry/product-journal-entry.reducer';
import appFile from './app-file/app-file.reducer';
import appAiTool from './app-ai-tool/app-ai-tool.reducer';
import appAiService from './app-ai-service/app-ai-service.reducer';
import appChatLanguageModel from './app-chat-language-model/app-chat-language-model.reducer';
import appEmbeddingStore from './app-embedding-store/app-embedding-store.reducer';
import appAiServiceTool from './app-ai-service-tool/app-ai-service-tool.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */
import actionComponent from '../../../../app/entities/action-component/action-component.reducer';
const entitiesReducers = {
  product,
  productUserRole,
  app,
  codeBlueprint,
  appCodeBlueprint,
  appScreen,
  appAccessConfig,
  functionality,
  appEntity,
  appEntityField,
  entityRelationship,
  deployment,
  coreScreen,
  coreAccessConfig,
  coreFunctionality,
  coreEntity,
  coreEntityField,
  coreEntityRelationship,
  emailConfiguration,
  productUser,
  enumMember,
  type,
  teamMember,
  screenComponent,
  vaultConfig,
  coreScreenComponent,
  dependency,
  chatGptSearch,
  appScreenLayout,
  menuItem,
  layoutConfig,
  appLayout,
  screenContent,
  jobTask,
  job,
  appDependency,
  productUserRoleAccess,
  feature,
  aiSuggestor,
  coreEnumMember,
  appEndPoint,
  appEndPointController,
  coreFunctionalityDependency,
  coreAppEndPoint,
  coreAppEndPointController,
  coreJob,
  productJournalEntry,
  appDomainListener,
  appFile,
  appAiTool,
  appAiService,
  appChatLanguageModel,
  appEmbeddingStore,
  appAiServiceTool,
  actionComponent,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
