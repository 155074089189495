import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from '../../../../../app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '../../../../../app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';

import { IAppFile } from '../../../../../app/shared/model/app-file.model';
import { getEntities } from './app-file.reducer';

export const AppFile = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const appFileList = useAppSelector(state => state.appFile.entities);
  const loading = useAppSelector(state => state.appFile.loading);
  const totalItems = useAppSelector(state => state.appFile.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}&appId.equals=${appId}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    // if (location.search !== endURL) {
    //   navigate(`${location.pathname}${endURL}`);
    // }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  return (
    <div className="inner-content-wrap" id="app-file-heading">
      <h2 className=" mb-4 mt-3" id="app-file-heading" data-cy="AppFileHeading">
        {/* <Translate contentKey="codeconductorApp.appFile.home.title"></Translate> */}
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="codeconductorApp.appFile.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link
            to={`/product/${productId}/app/${appId}/app-file/new`}
            className="btn btn-edit jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="codeconductorApp.appFile.home.createLabel">Create new App File</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {!loading && appFileList && appFileList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                {/* <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="codeconductorApp.appFile.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th> */}
                <th className="hand" onClick={sort('extension')}>
                  <Translate contentKey="codeconductorApp.appFile.extension">Extension</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('fileName')}>
                  <Translate contentKey="codeconductorApp.appFile.fileName">File Name</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('fileContents')}>
                  <Translate contentKey="codeconductorApp.appFile.fileContents">File Contents</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('filePath')}>
                  <Translate contentKey="codeconductorApp.appFile.filePath">File Path</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('lastCommitHash')}>
                  <Translate contentKey="codeconductorApp.appFile.lastCommitHash">Last Commit Hash</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                {/*<th>*/}
                {/*  <Translate contentKey="codeconductorApp.appFile.app">App</Translate> <FontAwesomeIcon icon="sort" />*/}
                {/*</th>*/}
                {/*<th>*/}
                {/*  <Translate contentKey="codeconductorApp.appFile.feature">Feature</Translate> <FontAwesomeIcon icon="sort" />*/}
                {/*</th>*/}
                {/*<th>*/}
                {/*  <Translate contentKey="codeconductorApp.appFile.functionality">Functionality</Translate> <FontAwesomeIcon icon="sort" />*/}
                {/*</th>*/}
                <th />
              </tr>
            </thead>
            <tbody>
              {appFileList.map((appFile, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  {/* <td>
                    <Button tag={Link} to={`/product/${productId}/app/${appId}/app-file/${appFile.id}/view`} color="link" size="sm">
                      {appFile.id}
                    </Button>
                  </td> */}
                  <td>
                    <Translate contentKey={`codeconductorApp.FileExtension.${appFile.extension}`} />
                  </td>
                  <td>{appFile.fileName}</td>
                  <td>{appFile.fileContents}</td>
                  <td>{appFile.filePath}</td>
                  <td>{appFile.lastCommitHash}</td>
                  {/*<td>{appFile.app ? <Link to={`/product/${productId}/app/${appFile.app.id}`}>{appFile.app.id}</Link> : ''}</td>*/}
                  {/*<td>{appFile.feature ? <Link to={`/product/${productId}/app/${appId}/feature/${appFile.feature.id}`}>{appFile.feature.id}</Link> : ''}</td>*/}
                  {/*<td>*/}
                  {/*  {appFile.functionality ? <Link to={`/product/${productId}/app/${appId}/functionality/${appFile.functionality.id}`}>{appFile.functionality.id}</Link> : ''}*/}
                  {/*</td>*/}
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/product/${productId}/app/${appId}/app-file/${appFile.id}/view`}
                        color="primary"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{' '}
                      </Button>
                      <Button
                        tag={Link}
                        to={`/product/${productId}/app/${appId}/app-file/${appFile.id}/edit`}
                        color="warning"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                      </Button>
                      <Button
                        tag={Link}
                        to={`/product/${productId}/app/${appId}/app-file/${appFile.id}/delete`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : loading ? (
          <div className="conductor_loader"></div>
        ) : (
          <div className="alert alert-warning">
            <Translate contentKey="codeconductorApp.appFile.home.notFound">No App Files found</Translate>
          </div>
        )}
      </div>
      {totalItems ? (
        <div className={appFileList && appFileList.length > 0 ? 'footer-pagination' : 'd-none'}>
          <div className="justify-content-end d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-end d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default AppFile;
