import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import './product-inner.scss';
import './modal.scss';
import './screen.scss';
import 'app/config/dayjs.ts';

import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { BrowserRouter, Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { ValidatedForm, ValidatedField, getSortState } from 'react-jhipster';

import { useAppDispatch, useAppSelector } from '../app/config/store';
import { getSession } from '../app/shared/reducers/authentication';
import { getProfile } from '../../../app/shared/reducers/application-profile';
import Header from './shared/layout/header/header';
import { hasAnyAuthority } from '../../../app/shared/auth/private-route';
import ErrorBoundary from '../../../app/shared/error/error-boundary';
import { AUTHORITIES } from '../../../app/config/constants';
import AppRoutes from './routes';
import Sidebar from '../app/shared/layout/sidebar/Sidebar';
import { extractProductContext, extractUserIntentContext } from './entities/product/product.reducer';
import { overridePaginationStateWithQueryParams } from '../../../app/shared/util/entity-utils';
import { DESC, ITEMS_PER_PAGE } from '../../../app/shared/util/pagination.constants';
import { getEntities as getCoreFuntionalities } from '../../../app/entities/core-functionality/core-functionality.reducer';
import { getEntities as getApps, updateEntity as updateApp } from '../app/entities/app/app.reducer';
import AiConfirmationDialog from '../app/ai-confirmation-dialog';
import ChatGptSearch from '../app/entities/chatgpt/chatgpt-search';
import { ToggleButtonIcon } from './shared/assets/svg-icon/icon';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSession());
    dispatch(getProfile());
  }, []);

  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);
  const coreFunctionalities = useAppSelector(state => state.coreFunctionality.entities);
  const apps = useAppSelector(state => state.app.entities);
  const productsEntity = useAppSelector(state => state.product.entities);
  const [showModal, setShowModal] = useState(false);
  const [selectApp, setSelectApp] = useState(false);
  const [updateReq, setUpdateReq] = useState(false);
  const [userPrompt, setUserPropmt] = useState('');
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    console.log('Sidebar Open');
    setSidebarOpen(!sidebarIsOpen);
    console.log('sidebarIsOpen', sidebarIsOpen);
  };
  const [productContext, setProductContext] = useState({
    appName: undefined,
    action: undefined,
    identifier: undefined,
    columnValue: undefined,
  });
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', DESC), location.search)
  );

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(
        getCoreFuntionalities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `id,desc`,
        })
      );
    }
  }, [isAuthenticated, paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    if (updateReq && apps && apps.length > 0) {
      if (apps.length === 1 && productContext.action) {
        //app update
        console.log('inside if of apps');
        toast(`Prompt processed successfully, Please update ${productContext.action.split('_').pop()}...`);
        var app = apps && apps[0];
        var appValues = {
          name: productContext.appName
            ? productContext.appName
            : productContext.columnValue
            ? productContext.columnValue
            : productContext.identifier,
        };
        dispatch(
          updateApp({
            ...app,
            ...appValues,
          })
        );
        // Navigate({ to: '/product/' + app.product.id });
      } else {
        console.log('inside else of apps');
        setShowModal(true);
        setSelectApp(true);
      }
    }
    setUpdateReq(false);
  }, [updateReq, apps]);

  const saveEntity = async values => {
    console.log({ values });
    let productContext;
    var count = 0;
    try {
      // productContext = await dispatch(extractProductContext(values.userPrompt));
      productContext = await dispatch(extractUserIntentContext(values.userPrompt));
      count = count + 1;
    } catch (error) {
    } finally {
      console.log({ productContext });
      if (
        productContext &&
        productContext.payload &&
        productContext.payload.action &&
        (productContext.payload.identifier || productContext.payload.appName)
      ) {
        handleAiResponse(productContext);
      } else if (count === 1) {
        console.log('Calling chatgpt again...');
        productContext = await dispatch(extractUserIntentContext(values.userPrompt));
        console.log({ productContext });
        if (
          productContext &&
          productContext.payload &&
          productContext.payload.action &&
          (productContext.payload.identifier || productContext.payload.appName)
        ) {
          handleAiResponse(productContext);
        } else {
          toast('Hey!! I am not able to understand what you want to do, can you please explain a bit more...');
        }
      } else {
        toast('Hey!! I am not able to understand what you want to do, can you please explain a bit more...');
      }
    }
  };

  const handleAiResponse = async productContext => {
    setProductContext(productContext.payload);
    var action = productContext.payload.action;
    if (action) {
      if (action.includes('create') || action.includes('add')) {
        if (action.includes('app')) {
          setShowModal(true);
        } else {
          toast(`Hey! Please tell me to which app you want to add ${action.split('_').pop()}`);
        }
      } else if (action.includes('update')) {
        let productId;
        let appId;
        console.log({ location });
        if (location && location.pathname) {
          var splitPath = location.pathname.split('/');
          if (location.pathname.includes('product') && splitPath[2] && typeof Number(splitPath[2]) === 'number') {
            productId = Number(splitPath[2]);
          }
          if (location.pathname.includes('product-feature') && splitPath[2] && typeof Number(splitPath[2]) === 'number') {
            appId = Number(splitPath[2]);
          }
        }
        console.log({ appId });
        console.log({ productId });
        if (action.includes('app')) {
          await dispatch(
            getApps({
              page: paginationState.activePage - 1,
              size: paginationState.itemsPerPage,
              sort: `id,desc`,
              query: `${appId ? `id.equals=${appId}` : productId ? `productId.equals=${productId}` : ''}`,
            })
          );
          setUpdateReq(true);
        } else {
          toast(`Hey!! You can't update ${action.split('_').pop()}, we are working on this...`);
        }
      }
    }
  };

  const closeModal = val => {
    setShowModal(val);
  };

  return (
    <BrowserRouter basename={baseHref}>
      <div className="app-container">
        <ToastContainer position={toast.POSITION.TOP_RIGHT} className="toastify-container" toastClassName="toastify-toast" />
        <ErrorBoundary>
          <div className="wrap">
            <Row className="g-0">
              {!!isAuthenticated && (
                <Col
                  md={sidebarIsOpen ? '3' : ''}
                  xl={sidebarIsOpen ? '2' : ''}
                  className={`app-sidebar ${sidebarIsOpen ? 'open-sidebar' : ''}`}
                >
                  <Sidebar toggle={toggleSidebar} />
                </Col>
              )}
              <Col
                className={`full-view ${sidebarIsOpen && isAuthenticated ? 'is-open' : ''}`}
                md={`${!!isAuthenticated && sidebarIsOpen ? 9 : 12}`}
                xl={`${!!isAuthenticated && sidebarIsOpen ? 10 : 12}`}
              >
                <div className="container-fluid view-container" id="app-view-container">
                  {/* {isAuthenticated &&
                    <div className="test" style={{ marginLeft: '20px', marginRight: '20px' }}>
                      <ValidatedForm defaultValues={{}} onSubmit={saveEntity}>
                        <ValidatedField
                          className="col-md-12"
                          id="userPrompt"
                          name="userPrompt"
                          data-cy="userPrompt"
                          placeholder="Let me know what you want to build..."
                          validate={{
                            required: { value: true, message: "Please enter valid prompt!!" },
                          }}
                        />
                        <Button
                          className="md-1"
                          color="save"
                          id="save-entity"
                          data-cy="entityCreateSaveButton"
                          type="submit"
                        >
                          Submit
                        </Button>
                      </ValidatedForm>
                    </div>
                  } */}
                  <Header
                    isAuthenticated={isAuthenticated}
                    isAdmin={isAdmin}
                    currentLocale={currentLocale}
                    ribbonEnv={ribbonEnv}
                    isInProduction={isInProduction}
                    isOpenAPIEnabled={isOpenAPIEnabled}
                    onToggleClick={toggleSidebar}
                  />
                  <ErrorBoundary>
                    <AppRoutes />
                  </ErrorBoundary>
                  {/* <Footer /> */}
                </div>
              </Col>
            </Row>
          </div>
        </ErrorBoundary>
      </div>
      {showModal && (
        <AiConfirmationDialog
          productContext={productContext}
          userPrompt={userPrompt}
          coreFunctionalities={coreFunctionalities}
          apps={apps}
          selectApp={selectApp}
          closeModal={closeModal}
        />
      )}
    </BrowserRouter>
  );
};

export default App;
