import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from '../../config/store';
import { getEntities, deleteEntity } from './enum-member.reducer';

export const EnumMemberDeleteDialog = (itemId, showModal) => {
  const dispatch = useAppDispatch();
  const { id } = useParams<'id'>();
  const { appEntityId } = useParams<'appEntityId'>();
  const [loadModal, setLoadModal] = useState(false);

  const enumMemberEntity = useAppSelector(state => state.enumMember.entities);
  const updateSuccess = useAppSelector(state => state.enumMember.updateSuccess);

  const handleClose = () => {
    itemId.showModal(loadModal);
  };

  const getAllEntities = () => {
    dispatch(
      getEntities({
        query: `appEntityId.equals=${appEntityId}`,
      })
    );
  };

  useEffect(() => {
    if (updateSuccess) {
      itemId.showModal(loadModal);
      getAllEntities();
    }
  }, [updateSuccess]);

  const confirmDelete = () => {
    dispatch(deleteEntity(itemId.itemId));
  };

  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose} data-cy="enumMemberDeleteDialogHeading">
        <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
      </ModalHeader>
      <ModalBody id="codeconductorApp.enumMember.delete.question">
        <Translate contentKey="codeconductorApp.enumMember.delete.question" interpolate={{ id: enumMemberEntity.id }}>
          Are you sure you want to delete this EnumMember?
        </Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button id="jhi-confirm-delete-enumMember" data-cy="entityConfirmDeleteButton" color="danger" onClick={confirmDelete}>
          <FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EnumMemberDeleteDialog;
