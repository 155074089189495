import { IAppFile } from 'app/shared/model/app-file.model';
import { IAppEndPoint } from 'app/shared/model/app-end-point.model';
import { IApp } from 'app/shared/model/app.model';
import { IFunctionality } from 'app/shared/model/functionality.model';
import { IFeature } from 'app/shared/model/feature.model';

export interface IAppEndPointController {
  id?: number;
  packageName?: string | null;
  className?: string | null;
  description?: string | null;
  baseApiPath?: string | null;
  appFile?: IAppFile | null;
  appEndPoints?: IAppEndPoint[] | null;
  app?: IApp | null;
  functionality?: IFunctionality | null;
  feature?: IFeature | null;
}

export const defaultValue: Readonly<IAppEndPointController> = {};
