import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getSortState, isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from '../../../../../app/shared/util/date-utils';
import { mapIdList, overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';

import { IAppEndPointController } from '../../../../../app/shared/model/app-end-point-controller.model';
import { getEntities as getAppEndPointControllers } from '../../../../../app/entities/app-end-point-controller/app-end-point-controller.reducer';
import { IAppDomainListener } from '../../../../../app/shared/model/app-domain-listener.model';
import { getEntities as getAppDomainListeners } from '../../../../../app/entities/app-domain-listener/app-domain-listener.reducer';
import { IAppAiTool } from '../../../../../app/shared/model/app-ai-tool.model';
import { getEntities as getAppAiTools } from '../../../../../app/entities/app-ai-tool/app-ai-tool.reducer';
import { IAppAiService } from '../../../../../app/shared/model/app-ai-service.model';
import { getEntities as getAppAiServices } from '../../../../../app/entities/app-ai-service/app-ai-service.reducer';
import { IApp } from '../../../../../app/shared/model/app.model';
import { getEntities as getApps } from '../../../../../app/entities/app/app.reducer';
import { IFeature } from '../../../../../app/shared/model/feature.model';
import { getEntities as getFeatures } from '../../../../../app/entities/feature/feature.reducer';
import { IFunctionality } from '../../../../../app/shared/model/functionality.model';
import { getEntities as getFunctionalities } from '../../../../../app/entities/functionality/functionality.reducer';
import { IAppFile } from '../../../../../app/shared/model/app-file.model';
import { FileExtension } from '../../../../../app/shared/model/enumerations/file-extension.model';
import { getEntity, updateEntity, createEntity, reset } from './app-file.reducer';
import { ITEMS_PER_PAGE } from '../../../../../app/shared/util/pagination.constants';

export const AppFileUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { appFileId } = useParams<'appFileId'>();
  const isNew = appFileId === undefined;

  // const appEndPointControllers = useAppSelector(state => state.appEndPointController.entities);
  // const appDomainListeners = useAppSelector(state => state.appDomainListener.entities);
  // const appAiTools = useAppSelector(state => state.appAiTool.entities);
  // const appAiServices = useAppSelector(state => state.appAiService.entities);
  // const apps = useAppSelector(state => state.app.entities);
  // const functionalities = useAppSelector(state => state.functionality.entities);
  const productEntity = useAppSelector(state => state.product.entity);
  const app = useAppSelector(state => state.app.entity);
  const features = useAppSelector(state => state.feature.entities);
  const appFileEntity = useAppSelector(state => state.appFile.entity);
  const loading = useAppSelector(state => state.appFile.loading);
  const updating = useAppSelector(state => state.appFile.updating);
  const updateSuccess = useAppSelector(state => state.appFile.updateSuccess);
  const fileExtensionValues = Object.keys(FileExtension);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const handleClose = () => {
    navigate(`/product/${productId}/app/${appId}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(appFileId));
    }

    // dispatch(getAppEndPointControllers({}));
    // dispatch(getAppDomainListeners({}));
    // dispatch(getAppAiTools({}));
    // dispatch(getAppAiServices({}));
    // dispatch(getApps({}));
    dispatch(
      getFeatures({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}&appId.equals=${appId}`,
      })
    );
    // dispatch(getFunctionalities({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...appFileEntity,
      ...values,
      app: app,
      feature: features.find(it => it.id.toString() === values.feature.toString()),
      // functionality: functionalities.find(it => it.id.toString() === values.functionality.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          extension: 'JAVA',
          ...appFileEntity,
          app: appFileEntity?.app?.id,
          feature: appFileEntity?.feature?.id,
          functionality: appFileEntity?.functionality?.id,
        };

  return (
    <div className="inner-content-wrap">
      <Row className="justify-content-center">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/product">Products</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={`/product/${productEntity.id}`}>{productEntity.name}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={`/product/${productEntity.id}/app/${app.id}`}>{app.name}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active> App Files</BreadcrumbItem>
        </Breadcrumb>
        {/* <Col md="8">
          <h2 id="codeconductorApp.appFile.home.createOrEditLabel" data-cy="AppFileCreateUpdateHeading">
            <Translate contentKey="codeconductorApp.appFile.home.createOrEditLabel">Create or edit a AppFile</Translate>
          </h2>
        </Col> */}
      </Row>
      <Row className="justify-content-center">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity} className="row">
              {/* {!isNew ? (
                <ValidatedField
                  className="col=md-6"
                  name="id"
                  required
                  readOnly
                  id="app-file-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null} */}
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appFile.extension')}
                id="app-file-extension"
                name="extension"
                data-cy="extension"
                type="select"
              >
                {fileExtensionValues.map(fileExtension => (
                  <option value={fileExtension} key={fileExtension}>
                    {translate('codeconductorApp.FileExtension.' + fileExtension)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appFile.fileName')}
                id="app-file-fileName"
                name="fileName"
                data-cy="fileName"
                type="text"
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appFile.fileContents')}
                id="app-file-fileContents"
                name="fileContents"
                data-cy="fileContents"
                type="text"
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appFile.filePath')}
                id="app-file-filePath"
                name="filePath"
                data-cy="filePath"
                type="text"
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appFile.lastCommitHash')}
                id="app-file-lastCommitHash"
                name="lastCommitHash"
                data-cy="lastCommitHash"
                type="text"
              />
              {/* <ValidatedField
                className="col=md-6"
                id="app-file-app" name="app" data-cy="app" label={translate('codeconductorApp.appFile.app')} type="select">
                <option value="" key="0" />
                {apps
                  ? apps.map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.id}
                    </option>
                  ))
                  : null}
              </ValidatedField> */}
              <ValidatedField
                className="col-md-6"
                id="app-file-feature"
                name="feature"
                data-cy="feature"
                label={translate('codeconductorApp.appFile.feature')}
                type="select"
              >
                <option value="" key="0" />
                {features
                  ? features.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              {/* <ValidatedField
                className="col=md-6"
                id="app-file-functionality"
                name="functionality"
                data-cy="functionality"
                label={translate('codeconductorApp.appFile.functionality')}
                type="select"
              >
                <option value="" key="0" />
                {functionalities
                  ? functionalities.map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.id}
                    </option>
                  ))
                  : null}
              </ValidatedField> */}
              <Col md="12">
                <Button
                  tag={Link}
                  id="cancel-save"
                  data-cy="entityCreateCancelButton"
                  to={`/product/${productId}/app/${appId}`}
                  replace
                  color="back"
                >
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="save" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </Col>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AppFileUpdate;
