import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from '../../../../../app/config/constants';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';

import { getEntity } from './app-domain-listener.reducer';

export const AppDomainListenerDetail = () => {
  const dispatch = useAppDispatch();

  const { listenerId } = useParams<'listenerId'>();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();

  useEffect(() => {
    dispatch(getEntity(listenerId));
  }, []);

  const appDomainListenerEntity = useAppSelector(state => state.appDomainListener.entity);
  const productEntity = useAppSelector(state => state.product.entity);

  return (
    <div className="inner-content-wrap">
      <Row>
        <Col md="8">
          <h2 className="fs-5" data-cy="appDomainListenerDetailsHeading">
            <Translate contentKey="codeconductorApp.appDomainListener.detail.title">AppDomainListener</Translate>
          </h2>
          <dl className="jh-entity-details" style={{ marginTop: '20px' }}>
            <dt>
              <span id="id">
                <Translate contentKey="global.field.id">ID</Translate>
              </span>
            </dt>
            <dd>{appDomainListenerEntity.id}</dd>
            <dt>
              <span id="domainEventType">
                <Translate contentKey="codeconductorApp.appDomainListener.domainEventType">Domain Event Type</Translate>
              </span>
            </dt>
            <dd>{appDomainListenerEntity.domainEventType}</dd>
            <dt>
              <span id="description">
                <Translate contentKey="codeconductorApp.appDomainListener.description">Description</Translate>
              </span>
            </dt>
            <dd>{appDomainListenerEntity.description}</dd>
            <dt>
              <span id="updateFieldValue">
                <Translate contentKey="codeconductorApp.appDomainListener.updateFieldValue">Update Field Value</Translate>
              </span>
            </dt>
            <dd>{appDomainListenerEntity.updateFieldValue}</dd>
            <dt>
              <span id="listenerClassName">
                <Translate contentKey="codeconductorApp.appDomainListener.listenerClassName">Listener Class Name</Translate>
              </span>
            </dt>
            <dd>{appDomainListenerEntity.listenerClassName}</dd>
            <dt>
              <span id="type">
                <Translate contentKey="codeconductorApp.appDomainListener.type">Type</Translate>
              </span>
            </dt>
            <dd>{appDomainListenerEntity.type}</dd>
            <dt>
              <Translate contentKey="codeconductorApp.appDomainListener.appEntityField">App Entity Field</Translate>
            </dt>
            <dd>{appDomainListenerEntity.appEntityField ? appDomainListenerEntity.appEntityField.id : ''}</dd>
            <dt>
              <Translate contentKey="codeconductorApp.appDomainListener.app">App</Translate>
            </dt>
            <dd>{appDomainListenerEntity.app ? appDomainListenerEntity.app.id : ''}</dd>
          </dl>
          <Button tag={Link} to={`/product/${productId}/app/${appId}`} replace color="back" data-cy="entityDetailsBackButton">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/product/${productId}/app/${appId}/app-domain-listener/${appDomainListenerEntity.id}/edit`}
            replace
            color="edit"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default AppDomainListenerDetail;
