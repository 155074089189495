import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
  Accordion,
  Button,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { Translate, translate, getSortState, JhiPagination, JhiItemCount, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '../../../../../../app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from '../../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../../config/store';
import { getEntities } from '../../../entities/app-entity/app-entity.reducer';
import AppEntityDeleteDialog from '../../../entities/app-entity/app-entity-delete-dialog';
import SearchModule from '../../../shared/component/searchModule/SearchModule';
import { getEntities as getFeatures, getEntity as getFeature } from '../../../entities/feature/feature.reducer';
import { suggestFeatureEntities } from '../../../entities/ai-suggestor/ai-suggestor.reducer';
import { getEntity as getProduct } from '../../../entities/product/product.reducer';
import { getEntity, updateEntity, createEntity, reset } from '../../../entities/app-entity/app-entity.reducer';
import AiSuggestEntitiesModal from './AiSuggestEntitiesModal';
import { AiGeneratedImage } from '../../../shared/assets/svg-icon/icon';
import ProductModal from '../../product/productModal';

export const AppEntity = props => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { appEntityId } = useParams<'appEntityId'>();
  var [deleteId, setDeleteId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', DESC), location.search)
  );
  const [query, setQuery] = useState('');

  const app = useAppSelector(state => state.app.entity);
  const appEntities = useAppSelector(state => state.appEntity.entities);
  const appEntity = useAppSelector(state => state.appEntity.entity);
  const productEntity = useAppSelector(state => state.product.entity);
  const featureEntities = useAppSelector(state => state.feature.entities);
  const featureEntity = useAppSelector(state => state.feature.entity);
  const loading = useAppSelector(state => state.appEntity.loading);
  const totalItems = useAppSelector(state => state.appEntity.totalItems);
  const [aiSuggestLoading, setAiSuggestLoading] = useState<boolean>(false);
  const [showAiWizard, setShowAiWizard] = useState(false);
  const [showFeatureModel, setShowFeatureModel] = useState(false);
  const [selectFeatureId, setSelectFeatureId] = useState(0);
  const [inputValue, setInputValue] = useState({
    productName: '',
    productDescription: '',
    featureName: '',
    featureDescription: '',
    appId: '',
  });
  const [selectedFeature, setSelectedFeature] = useState({});
  const [aiSuggestEntitiesList, setAiSuggestEntitiesList] = useState([]);
  const [aiSuggestEntitiesModal, setAiSuggestEntitiesModal] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [open, setOpen] = useState(false);
  const [userPrompt, setUserPrompt] = useState('');
  const [savedEntities, setSavedEntities] = useState([]);

  useEffect(() => {
    // dispatch(getEntity(appEntityId));
    dispatch(getFeatures({ query: `appId.equals=${appId}` }));
    if (productId !== undefined) {
      dispatch(getProduct(productId));
    }
  }, []);

  const getAllEntities = (emptyQuery?: any) => {
    const queryValue = emptyQuery != undefined ? emptyQuery : query;
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        // query: `appId.equals=${appId}`,
        query: `appId.equals=${appId}${queryValue ? `&name.contains=${queryValue}` : ''}`,
      })
    );
  };

  const handleCheckboxChange = (e, feature) => {
    let isChecked = e.target.checked;
    if (isChecked == true) {
      let tempArr = selectedFeatures;
      tempArr.push(feature);
      setSelectedFeatures(tempArr);
    }
    if (isChecked == false) {
      let tempArr = selectedFeatures.filter(obj => obj.id != feature.id);
      setSelectedFeatures(tempArr);
    }
  };

  const sortEntities = () => {
    getAllEntities();
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };
  const toggle = id => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const toggleModal = () => {
    setShowFeatureModel(!showFeatureModel);
    setUserPrompt('');
  };
  const toggleProductPopup = () => {
    console.log('modalVisible', modalVisible);
    setModalVisible(!modalVisible);
  };

  const handlePagination = currentPage => {
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });
  };
  const handleSyncList = () => {
    sortEntities();
  };

  const handleDelete = appEntity_id => {
    setShowModal(true);
    setDeleteId(appEntity_id);
    deleteId = appEntity_id;
  };

  const viewModal = modal => {
    setShowModal(modal);
  };

  const viewFeatureListModal = () => {
    setAiSuggestEntitiesModal(false);
    setShowFeatureModel(true);
    setInputValue({
      productName: productEntity?.name,
      productDescription: productEntity?.description,
      featureName: featureEntities[0].name,
      featureDescription: featureEntities[0].description,
      appId: appId,
    });
    setSelectFeatureId(featureEntities[0].id);
    setSelectedFeature(featureEntities[0]);

    setShowAiWizard(false);
  };

  const handleCloseFeatureModal = () => {
    setShowFeatureModel(false);
  };
  const handleCloseAiEntitiesModal = () => {
    setAiSuggestEntitiesModal(false);
    setAiSuggestEntitiesList([]);
  };

  // const propss;
  const handleFeautreSelect = e => {
    e.preventDefault();
    const filteredFeature = featureEntities.find(feature => feature.id == e.target.value);
    setInputValue({
      productName: productEntity?.name,
      productDescription: productEntity?.description,
      featureName: filteredFeature?.name,
      featureDescription: filteredFeature?.description,
      appId: appId,
    });
    setSelectFeatureId(e.target.value);
    setSelectedFeature(filteredFeature);
  };

  const handleUserPrompt = event => {
    if (event.target) {
      setUserPrompt(event.target.value);
    } else {
      setUserPrompt('');
    }
  };

  const handleAiSuggest = async e => {
    setShowFeatureModel(false);
    setAiSuggestLoading(true);
    setAiSuggestEntitiesModal(true);
    let filterFeatures = [];
    for (const selectedAppFeature of selectedFeatures) {
      let featureDescription = userPrompt ? selectedAppFeature?.description + ' ' + userPrompt : selectedAppFeature?.description;
      const { payload } = await dispatch(
        suggestFeatureEntities({
          productName: productEntity?.name,
          productDescription: productEntity?.description,
          featureName: selectedAppFeature?.name,
          featureDescription: featureDescription,
          appId: appId,
        })
      );
      let result = payload['data'];
      let temp_result = [];
      result.map(ele => {
        let temp_obj = { ...ele, key: `${ele.featureEntityName}-${Date.now()}`, feature: selectedAppFeature };
        temp_result.push(temp_obj);
      });

      let temp_AiSuggestEntities = temp_result.filter(function (array_el) {
        return (
          appEntities.filter(function (anotherOne_el) {
            return anotherOne_el.name == array_el.featureEntityName;
          }).length == 0 &&
          filterFeatures.filter(function (feature_el) {
            return feature_el.featureEntityName == array_el.featureEntityName;
          }).length == 0
        );
      });
      filterFeatures.push(...temp_AiSuggestEntities);
      setUserPrompt('');
    }

    setAiSuggestLoading(false);
    setAiSuggestEntitiesList(filterFeatures);
    setSelectedFeatures([]);
  };

  const handleOnSaveForSuggested = async selectedEntities => {
    let savedEntities = [];
    setShowAiWizard(true);
    setAiSuggestLoading(true);
    setModalVisible(!modalVisible);
    for (const selectedAppEntity of selectedEntities) {
      const entity = {
        ...selectedAppEntity,
        type: 'Standard',
        app: app,
        feature: featureEntities.find(feature => feature.id == selectedAppEntity.feature.id),
        name: selectedAppEntity?.featureEntityName,
        description: selectedAppEntity?.featureEntityDescription,
      };
      const { payload } = await dispatch(createEntity(entity));

      let temp_AiSuggestEntities = aiSuggestEntitiesList.filter(item => item.featureEntityName != payload['data'].name);

      if (payload['data']) {
        sortEntities();
        setAiSuggestEntitiesList(temp_AiSuggestEntities);
        savedEntities.push(payload['data']?.id);
      }
      if (temp_AiSuggestEntities.length == 0) {
        setAiSuggestEntitiesModal(false);
      }
    }
    setAiSuggestLoading(false);
    setSavedEntities(savedEntities);
  };

  const handleSuggestedDelete = data => {
    let filterAiSuggestEntities = aiSuggestEntitiesList.filter(item => item.featureEntityName != data.featureEntityName);
    setAiSuggestEntitiesList(filterAiSuggestEntities);
  };

  return (
    <>
      <div className="inner-content-wrap" id="app-entity">
        <h2 id="app-entity-heading" data-cy="AppEntityHeading" className=" mb-4 mt-3">
          <Row>
            <Col md="7">
              <SearchModule setQuery={setQuery} fetchFunction={getAllEntities} query={query} placeHolder="Entity Name" />
            </Col>
            <Col md="5">
              <div className="d-flex justify-content-end">
                {/* <Button className="me-2" color="refresh" onClick={handleSyncList} disabled={loading}>
                <FontAwesomeIcon icon="sync" spin={loading} />{' '}
                <Translate contentKey="codeconductorApp.appEntity.home.refreshListLabel">Refresh List</Translate>
              </Button> */}
                <Button className="me-2" color="refresh" onClick={viewFeatureListModal} disabled={loading}>
                  <AiGeneratedImage /> Wizard
                </Button>
                <Link
                  to={`/product/${productId}/app/${appId}/app-entity/new`}
                  className="btn btn-save jh-create-entity"
                  id="jh-create-entity"
                  data-cy="entityCreateButton"
                >
                  <FontAwesomeIcon icon="plus" />
                  &nbsp;
                  <Translate contentKey="codeconductorApp.appEntity.home.createLabel">Create new App Entity</Translate>
                </Link>
              </div>
            </Col>
          </Row>
        </h2>

        {!loading && appEntities.length > 0 ? (
          <div className="table-responsive">
            <Table>
              <thead className="table-primary">
                <tr>
                  <th className="hand" onClick={sort('name')}>
                    <Translate contentKey="codeconductorApp.appEntity.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    <Translate contentKey="codeconductorApp.appEntity.feature">Feature</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    <Translate contentKey="codeconductorApp.appEntity.description">Description</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {appEntities.map((appEntity, i) => (
                  <tr key={`entity-${i}`} id={`entity-key-${appEntity.id || appEntity.key}`} data-cy="entityTable">
                    <td>{appEntity.name}</td>
                    <td>
                      {appEntity.feature ? (
                        <Link
                          style={{ cursor: 'auto', textDecoration: 'none', fontWeight: 'normal', color: '#000' }}
                          to={`/product/${productId}/app/${appId}`}
                        >
                          {appEntity.feature.name}
                        </Link>
                      ) : (
                        '--'
                      )}
                    </td>
                    <td>{appEntity.description ? appEntity.description : '--'}</td>
                    <td className="text-end">
                      <div className="btn-group flex-btn-group-container">
                        <Button
                          tag={Link}
                          to={`/product/${productId}/app/${appId}/app-entity/${appEntity.id}/view`}
                          color="primary"
                          size="sm"
                          data-cy="entityDetailsButton"
                        >
                          <FontAwesomeIcon icon="eye" />{' '}
                        </Button>
                        <Button
                          tag={Link}
                          to={`/product/${productId}/app/${appId}/app-entity/${appEntity.id}/edit`}
                          color="warning"
                          size="sm"
                          data-cy="entityEditButton"
                        >
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                        </Button>
                        <Button onClick={() => handleDelete(appEntity.id)} color="danger" size="sm" data-cy="entityDeleteButton">
                          <FontAwesomeIcon icon="trash" />{' '}
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : loading ? (
          <div className="conductor_loader"></div>
        ) : (
          <div className="alert alert-warning">
            <Translate contentKey="codeconductorApp.appEntity.home.notFound">No App Entities found</Translate>
          </div>
        )}
        {totalItems ? (
          <div className={appEntities && appEntities.length > 0 ? 'footer-pagination' : 'd-none'}>
            <div className="justify-content-end d-flex pagination-pstn">
              <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
            </div>
            <div className="justify-content-end d-flex pagination-pstn">
              <JhiPagination
                activePage={paginationState.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={paginationState.itemsPerPage}
                totalItems={totalItems}
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {showFeatureModel && (
        // <AppEntityDeleteDialog/>
        <Modal isOpen={showFeatureModel} toggle={toggleModal} className="modalDesignNew">
          <ModalHeader className="pt-0">Select Features</ModalHeader>
          <ModalBody className="stepmodal">
            <div className="coll-design">
              <Accordion open={open} toggle={toggle}>
                {featureEntities &&
                  featureEntities.length > 0 &&
                  featureEntities.map((feature, index) => (
                    <AccordionItem key={index}>
                      <AccordionHeader targetId={index}>
                        <>
                          <input
                            type="checkbox"
                            onChange={e => {
                              handleCheckboxChange(e, feature);
                            }}
                          />
                          {feature.name}
                        </>
                      </AccordionHeader>
                      <AccordionBody accordionId={index}>
                        <div>{feature.description}</div>
                      </AccordionBody>
                    </AccordionItem>
                  ))}
              </Accordion>
            </div>
            <h5 className="pt-4 pb-2">How would you like to modify the features?</h5>
            <ValidatedField
              className="col-md-12 mb-0 modal-input"
              id="userPrompt"
              name="userPrompt"
              type="textarea"
              data-cy="userPrompt"
              value={userPrompt}
              onChange={handleUserPrompt}
              placeholder="What are you looking for? (Optional)"
              validate={{
                required: { value: true, message: 'Please enter valid prompt!!' },
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              id="jhi-appEntity-feature-select"
              color="save"
              disabled={selectedFeatures.length <= 0 ? true : false}
              data-cy="entityFeatureSelectButton"
              onClick={handleAiSuggest}
            >
              Next
            </Button>
          </ModalFooter>
        </Modal>
      )}

      {showModal && <AppEntityDeleteDialog itemId={deleteId} showModal={viewModal} />}
      {(aiSuggestLoading || aiSuggestEntitiesModal) && !showAiWizard && (
        <AiSuggestEntitiesModal
          featureName={selectedFeature}
          aiSuggestLoading={aiSuggestLoading}
          aiSuggestEntitiesList={aiSuggestEntitiesList}
          handleAiSuggest={handleAiSuggest}
          handleCloseAiEntitiesModal={handleCloseAiEntitiesModal}
          handleOnSaveForSuggested={handleOnSaveForSuggested}
          handleSuggestedDelete={handleSuggestedDelete}
        />
      )}

      {showAiWizard && (
        <>
          <ProductModal
            visible={modalVisible}
            toggleProductPopup={toggleProductPopup}
            activeStep={3}
            activeTab="Entities"
            app={app}
            aiSuggestLoading={aiSuggestLoading}
            productEntity={productEntity}
            savedEntities={savedEntities}
          />
        </>
      )}
    </>
  );
};

export default AppEntity;
