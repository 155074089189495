import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Accordion,
  Button,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from 'reactstrap';
import { getSortState, ValidatedField, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile, faCheckDouble, faTv } from '@fortawesome/free-solid-svg-icons';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../config/store';
import { getEntities as getScreens } from './app-screen.reducer';
// import { deleteEntity as deleteScreenComponent } from '../../entities/screen-component/screen-component.reducer';
import { getEntities as getAppEntities } from '../../entities/app-entity/app-entity.reducer';
import {
  getEntities as getScreenComponents,
  getEntity as getComponent,
  deleteEntity as deleteScreenComponent,
  getComponentsByAppId,
} from '../../entities/screen-component/screen-component.reducer';
import { getEntities as getRelationships } from '../entity-relationship/entity-relationship.reducer';
// import DynamicSelect from '../../modules/dynamicSelectComponent/DynamicSelect';

import { ITEMS_PER_PAGE, DESC } from '../../../../../app/shared/util/pagination.constants';

import GrapeEditor from '../../../app/shared/component/grapeJS/GrapeEditor';
import { AiGeneratedImage, DotsIcon } from '../../shared/assets/svg-icon/icon';
// import { getEntities as getRelatedEntities } from '../entity-relationship/entity-relationship.reducer';
import AddScreenModal from './add-screen-modal';
import AddComponentModal from './add-component-modal';
import ComponentUpdate from './component-update-form';
import AddTabsForm from './add-tabs-form';
import ScreenListSkeleton from './screen-list-skeleton';
import AppScreenDeleteDialog from './app-screen-delete-dialog';
import GraphComponent from '../../shared/component/erDiagrams/nomnol';
import ProductModal from '../product/productModal';
import AiWizardScreenSuggestor from './ai-suggestor-modal';

type Item = string;

const AppScreenUpdate = props => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { appScreenId } = useParams<'appScreenId'>();
  const isNew = appScreenId === undefined;
  // const { appScreenList } = props;
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', DESC), location.search)
  );

  const apps = useAppSelector(state => state.app.entities);
  const appEntitiesLoading = useAppSelector(state => state.appEntity.loading);
  const productEntity = useAppSelector(state => state.product.appEntity);
  const appScreenComponents = useAppSelector(state => state.screenComponent.entities);
  const activeComponent = useAppSelector(state => state.screenComponent.entity);
  const appScreenComponentUpdate = useAppSelector(state => state.screenComponent.updateSuccess);
  const appScreenUpdate = useAppSelector(state => state.appScreen.updateSuccess);
  const appScreenComponentLoading = useAppSelector(state => state.screenComponent.loading);
  const appScreens = useAppSelector(state => state.appScreen.entities);
  const loading = useAppSelector(state => state.appScreen.loading);
  const loadingScreens = useAppSelector(state => state.appScreen.loadingScreens);
  const relationshipList = useAppSelector(state => state.entityRelationship.entities);

  const [selectionBuilder, setSelectionBuilder] = useState([]);
  const [appScreenList, setAppScreenList] = useState(appScreens);
  const [appScreenComponentList, setAppScreenComponentList] = useState([]);
  const [screensData, setScreensData] = useState({});
  const [screenComponent, setScreenComponent] = useState(appScreenComponents);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [showAddScreenModal, setShowAddScreenModal] = useState(false);
  const [showComponentModal, setShowComponentModal] = useState(false);
  const [screenEditId, setScreenEditId] = useState('');
  // const [showModal, setShowModal] = useState(false);
  const [showActiveScreen, setShowActiveScreen] = useState();
  const [showActiveScreenData, setShowActiveScreenData] = useState();
  const [componentEdit, setComponentEdit] = useState(false);
  const [activeComponentData, setActiveComponentData] = useState<any>();
  // const [activeComponentId, setActiveComponentId] = useState();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownId, setShowDropdownId] = useState();
  const [showDeleteDialog, setShowDeleteDialog] = useState();
  const [previewMessage, setPreviewMessage] = useState(undefined);
  const [showGraph, setShowGraph] = useState(false);
  const [componentsEr, setComponentsEr] = useState();
  const [showProductModal, setShowProductModal] = useState(false);
  const [showAiWizardModal, setShowAiWizardModal] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [showScreenModel, setShowScreenModel] = useState(false);
  const [open, setOpen] = useState(false);
  const [screenType, setScreenType] = useState('ALL');
  const [editComponent, setEditComponent] = useState();

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [userPrompt, setUserPrompt] = useState('');
  const [step, setStep] = useState(1);
  const toggleAccordion = id => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  useEffect(() => {
    if (appScreenComponentLoading == true) {
      setSelectionBuilder([]);
    }
  }, [appScreenComponentLoading]);

  const getComponentsEr = async () => {
    const { payload } = await dispatch(getComponentsByAppId(appId));
    setAppScreenComponentList(payload?.data || []);
  };

  const toggleProductPopup = () => {
    setModalVisible(!modalVisible);
  };

  const toggleModal = () => {
    setShowScreenModel(!showScreenModel);
    setStep(1);
  };

  const handleUserPrompt = event => {
    if (event.target) {
      setUserPrompt(event.target.value);
    } else {
      setUserPrompt('');
    }
  };

  useEffect(() => {
    dispatch(
      getScreens({
        page: 0,
        size: 100,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: `appId.equals=${appId}`,
      })
    );
    dispatch(
      getAppEntities({
        page: paginationState.activePage - 1,
        size: 100,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: `appId.equals=${appId}`,
      })
    );
    dispatch(
      getRelationships({
        page: paginationState.activePage - 1,
        size: 100,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: `appId.equals=${appId}`,
      })
    );
    getComponentsEr();
  }, []);

  useEffect(() => {
    return () => {
      if (document.getElementsByClassName('app-sidebar')[0].className.indexOf('open-sidebar') == -1) {
        document.getElementsByClassName('sidebar-toggle')[0].click();
      }
    };
  }, []);

  useEffect(() => {
    {
      showActiveScreen != undefined ? dispatch(getScreenComponents({ query: 'appScreenId.equals=' + showActiveScreen })) : '';
    }
    setComponentEdit(false);
  }, [showActiveScreen]);

  useEffect(() => {
    if (screenType !== undefined && screenType !== 'ALL') {
      setAppScreenList(appScreens.filter(it => it.screenType == screenType));
    } else if (screenType == 'ALL') {
      setAppScreenList(appScreens);
    }
  }, [screenType, appScreens]);

  // console.log("appScreens", appScreens);

  useEffect(() => {
    if (appScreens && appScreenComponentList) {
      let obj = { screen_list: appScreens, component_list: appScreenComponentList };
      setScreensData(obj);
    }
  }, [appScreens, appScreenComponentList]);

  const handleClose = () => {
    navigate(`/product/${productId}/app/${appId}` + location.search);
  };

  useEffect(() => {
    return () => {
      let arr = [];
      setScreenComponent(arr);
    };
  }, []);

  useEffect(() => {
    if (appScreenComponentUpdate) {
      getScreenComponents({ query: 'appScreenId.equals=' + appScreenId });
    }
  }, [appScreenComponentUpdate]);
  const removeScreenComponent = async screenId => {
    await dispatch(deleteScreenComponent(screenId));
    await dispatch(getScreenComponents({ query: 'appScreenId.equals=' + appScreenId }));
  };

  useEffect(() => {
    setScreenComponent(appScreenComponents);
  }, [appScreenComponents]);

  const handleAddScreen = id => {
    setShowAddScreenModal(true);
    setScreenEditId(id);
  };

  const handleDeleteScreen = id => {
    setShowDeleteDialog(true);
    setScreenEditId(id);
  };

  const handleCreateComponent = () => {
    setShowComponentModal(true);
    setEditComponent(undefined);
  };

  const viewModal = modal => {
    setShowAddScreenModal(modal);
    setShowDeleteDialog(modal);
  };

  const viewAiWizard = () => {
    // console.log("Hiiiii");
    setShowAiWizardModal(true);
    setShowScreenModel(!showScreenModel);
  };

  const viewComponentModal = modal => {
    setShowComponentModal(modal);
  };

  let dataArray = [];
  let filteredItem = [];
  useEffect(() => {
    screenComponent &&
      !appScreenComponentLoading &&
      screenComponent.length > 0 &&
      screenComponent.map(item => {
        const existingItem = selectionBuilder?.filter(it => it.screen_id == item.id);
        const compareExistingItem = JSON.stringify(existingItem[0]) == JSON.stringify(item);
        let relatedEntities = screenComponent?.filter(it => it.screenComponentParent?.id == item?.id);
        let relatedEntitiesData = [];
        let relationshipData;
        let relationshipFromData;
        relatedEntities?.length > 0 &&
          relatedEntities?.map(item => {
            const id = screenComponent.find(it => it.id == item.screenComponentParent?.id)?.appEntity?.id;
            relationshipData = relationshipList.find(
              it => it.appEntityFrom.id.toString() == id?.toString() && it.appEntityTo.id.toString() == item.appEntity.id.toString()
            );
            relationshipFromData = relationshipList.find(
              it => it.appEntityTo.id.toString() == id?.toString() && it.appEntityFrom.id.toString() == item.appEntity.id.toString()
            );
            if (item.entityFields != (undefined || null))
              relatedEntitiesData.push({
                fields: item.entityFields,
                relationshipData: relationshipData != undefined ? relationshipData : [],
                createButtonEnable: item.createButtonEnable,
                createButtonText: item.createButtonText,
                relationshipFromData: relationshipFromData,
                actionComponents: item.actionComponents,
              });
          });
        if ((existingItem != undefined || existingItem.length == 0) && item.screenComponentParent == null && item.appEntity != null) {
          const data = {
            screen_id: item.id,
            entityfields: item.entityFields,
            layout: item.layout,
            layoutTemplate: item.layoutTemplate,
            entity: item.appEntity?.id,
            name: item.name,
            appScreenViewType: item.appScreenViewType,
            screenComponentDetails: item.screenComponentDetails,
            appScreenviewDetail: item.appScreenviewDetail,
            appScreenEditType: item.appScreenEditType,
            screenComponentEdit: item.screenComponentEdit,
            appScreenviewEdit: item.appScreenviewEdit,
            relatedEntitiesData: relatedEntitiesData,
            mapW: item.mapW,
            mapH: item.mapH,
            lat: item.lat,
            lang: item.lang,
            actionComponents: item.actionComponents,
          };
          dataArray?.push(data);
          setSelectionBuilder(dataArray);
          setPreviewMessage(undefined);
        } else if (existingItem?.length > 0 && !compareExistingItem && item.screenComponentParent == null && item.appEntity != null) {
          const data = {
            screen_id: item.id,
            entityfields: item.entityFields,
            layout: item.layout,
            layoutTemplate: item.layoutTemplate,
            entity: item.appEntity?.id,
            name: item.name,
            appScreenViewType: item.appScreenViewType,
            screenComponentDetails: item.screenComponentDetails,
            appScreenviewDetail: item.appScreenviewDetail,
            appScreenEditType: item.appScreenEditType,
            screenComponentEdit: item.screenComponentEdit,
            appScreenviewEdit: item.appScreenviewEdit,
            relatedEntitiesData: relatedEntitiesData,
            mapW: item.mapW,
            mapH: item.mapH,
            lat: item.lat,
            lang: item.lang,
            actionComponents: item.actionComponents,
          };
          filteredItem = dataArray.filter(it => it.id == existingItem.id);
          filteredItem.push(data);
          setSelectionBuilder(filteredItem);
          setPreviewMessage(undefined);
        }
      });
    if (showActiveScreen == undefined) {
      setSelectionBuilder([]);
      setPreviewMessage('Select Screen');
    }
  }, [screenComponent, showActiveScreen]);

  const handleAiSuggest = () => {
    setShowProductModal(!showProductModal);
    setModalVisible(!modalVisible);
  };

  useEffect(() => {
    if (Object.entries(activeComponent).length === 0) {
      console.log('This object is empty');
    }
    if (
      Object.entries(activeComponent).length !== 0 &&
      activeComponent != (null && undefined) &&
      activeComponent?.screenComponentParent == null
    )
      setActiveComponentData(activeComponent);
  }, [activeComponent]);

  const handleEditComponent = component => {
    setComponentEdit(component);
  };

  const dropdownToggle = id => {
    setShowDropdown(!showDropdown);
    setShowDropdownId(id);
  };

  const handleComponentSwitch = component => {
    setComponentEdit(true);
    // setActiveComponentId(component.id);
    setActiveComponentData(component);
  };

  return (
    <div className="screen-wrap">
      <Row className="justify-content-center fullScreenRow">
        <Col md="2" className="addPageCol">
          <div>
            <Row className="addScreenRow fix-screenbtn">
              <Col md={7} className="fix-screenbtn" style={{ justifyContent: 'flex-start' }}>
                {/* <h4>Screens</h4> */}
                <Button className="addScreenButton" onClick={() => setScreenType('ALL')}>
                  <FontAwesomeIcon icon={faCheckDouble} />
                </Button>
                <Button className="addScreenButton" onClick={() => setScreenType('WEB')}>
                  <FontAwesomeIcon icon={faTv} />
                </Button>
                <Button className="addScreenButton" onClick={() => setScreenType('MOBILE')}>
                  <FontAwesomeIcon icon={faMobile} className="screenTypeIcons" />
                </Button>
              </Col>
              <Col md={5} className="fix-screenbtn">
                <Button className="addScreenButton" id="ai-wizard" disabled={loading} onClick={() => viewAiWizard()}>
                  <AiGeneratedImage />
                </Button>
                <UncontrolledTooltip placement="top" target={`ai-wizard`}>
                  AI Wizard to suggest AI Generated Screens.
                </UncontrolledTooltip>
                <Button className="addScreenButton" id="add-new-screen" onClick={() => handleAddScreen(undefined)}>
                  <FontAwesomeIcon style={{ width: '16px', height: '16px' }} icon="plus" />
                </Button>
                <UncontrolledTooltip placement="top" target={`add-new-screen`}>
                  Add New Screen.
                </UncontrolledTooltip>
              </Col>
              {/* <Col md={2}>
                  <Button className="addScreenButton" onClick={() => handleAddScreen(undefined)}>
                    <FontAwesomeIcon style={{ width: '16px', height: '16px' }} icon="plus" />
                  </Button>
                </Col> */}
            </Row>
            {!loadingScreens && appScreenList?.length > 0 ? (
              <div className="screen-list">
                <Nav tabs className="tab-design screenTab row">
                  {appScreenList &&
                    appScreenList.length > 0 &&
                    appScreenList.map(screen => (
                      <NavItem style={{ background: 'white !important' }} className="screenItem">
                        <NavLink
                          herf="#"
                          onClick={() => {
                            setShowActiveScreen(screen.id);
                            setShowActiveScreenData(screen);
                          }}
                          active={showActiveScreen == screen.id}
                          style={showActiveScreen == screen.id ? { background: '#eee' } : {}}
                        >
                          {screen.title}
                          {showActiveScreen == screen.id && (
                            <UncontrolledDropdown
                              className="addLayoutButton"
                              toggle={() => dropdownToggle(screen.id)}
                              isOpen={showDropdown}
                            >
                              <DropdownToggle data-toggle="dropdown" tag="span">
                                <DotsIcon />
                              </DropdownToggle>
                              <DropdownMenu container="body">
                                <DropdownItem id="dropdownEdit" onClick={e => handleAddScreen(screen.id)}>
                                  Edit
                                </DropdownItem>
                                <DropdownItem id="dropdownDelete" onClick={e => handleDeleteScreen(screen.id)}>
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          )}
                        </NavLink>
                      </NavItem>
                    ))}
                </Nav>
              </div>
            ) : (
              loadingScreens && <ScreenListSkeleton />
            )}
            <Row className="addComponentRow">
              <Col md={10}>
                <h4># Components</h4>
              </Col>
              <Col md={2} style={{ paddingLeft: '0px' }}>
                <Button
                  className="addScreenButton addComponentButton"
                  onClick={handleCreateComponent}
                  disabled={showActiveScreen == undefined}
                >
                  <FontAwesomeIcon style={{ width: '16px', height: '16px' }} icon="plus" />
                </Button>
              </Col>
            </Row>
            <div className="component-list">
              {showActiveScreen != undefined &&
                screenComponent &&
                screenComponent.length > 0 &&
                screenComponent.map(
                  component =>
                    component?.screenComponentParent == null &&
                    component.appEntity != null && (
                      <Link to="#" className="componentLink" onClick={() => handleComponentSwitch(component)}>
                        {component.name}
                        {activeComponentData?.id == component.id ? (
                          <Button
                            className="addLayoutButton"
                            onClick={() => {
                              setShowComponentModal(true);
                              setEditComponent(activeComponentData);
                            }}
                          >
                            <DotsIcon />
                          </Button>
                        ) : (
                          ''
                        )}
                      </Link>
                    )
                )}
            </div>
          </div>
        </Col>
        {!showGraph && selectionBuilder && (
          <Col className="col-md-8 mb-6 editorCol">
            <GrapeEditor selectionBuilder={selectionBuilder} selectionLoading={appScreenComponentLoading} previewMessage={previewMessage} />
          </Col>
        )}
        {showGraph && screensData && (
          <Col className="col-md-8 mb-6 editorCol">
            <div className="er-diagram" style={{ height: '100%', width: '100%' }}>
              {/* {entityRelationshipList && entityRelationshipList.length > 0 ? */}
              <GraphComponent screensData={screensData} />
              {/* : ''} */}
            </div>
          </Col>
        )}
        <Col className="col-md-2 configCol">
          {/* <Col md={12} className="d-flex justify-content-end align-items-center" style={{ fontSize: '17px' }}> */}
          {/* <FormGroup switch style={{ margin: '0' }}>
            <Input
              type="switch"
              checked={showGraph}
              onClick={() => {
                setShowGraph(!showGraph);
              }}
            />
            <Label check style={{ marginTop: '2px' }}>
              Show Screens Diagram
            </Label>
          </FormGroup> */}
          {/* </Col> */}
          {showActiveScreenData != undefined && !componentEdit && (
            <Row className="m-0">
              <h5 className="entityTitle">
                Screen Details{' '}
                <Button className="editScreenBtn" tag={Link} to="#" onClick={e => handleAddScreen(showActiveScreenData.id)}>
                  <FontAwesomeIcon icon="pencil-alt" />{' '}
                </Button>
              </h5>
              <p>Title: {showActiveScreenData.title}</p>
              <p>Description: {showActiveScreenData.description}</p>
              <p>Path: {showActiveScreenData.path}</p>
              <p>Template: {showActiveScreenData.template}</p>
              <p>Access Role: {showActiveScreenData.accessRoles}</p>
            </Row>
          )}
          {componentEdit && showActiveScreenData?.template != 'tabs' && (
            <ComponentUpdate
              componentData={activeComponentData}
              componentId={activeComponentData?.id}
              appScreenId={showActiveScreen}
              showModal={handleEditComponent}
            />
          )}

          {componentEdit && showActiveScreenData?.template == 'tabs' && (
            <AddTabsForm activeComponentData={activeComponentData} activeScreenData={showActiveScreenData} screenType={screenType} />
          )}
        </Col>

        {showAiWizardModal && (
          <>
            <AiWizardScreenSuggestor
              app={apps.find(it => it.id == appId)}
              productEntity={productEntity}
              toggleModal={toggleModal}
              step={step}
              showScreenModel={showScreenModel}
            />
          </>
        )}

        {showProductModal && (
          <ProductModal
            visible={modalVisible}
            toggleProductPopup={toggleProductPopup}
            activeStep={4}
            activeTab="Screen"
            app={apps.find(it => it.id == appId)}
            productEntity={productEntity}
          />
        )}
        {showAddScreenModal && (
          <AddScreenModal
            itemId={screenEditId}
            showModal={viewModal}
            selectionBuilder={selectionBuilder}
            screenComponent={screenComponent}
            screenType={screenType}
            setScreenType={setScreenType}
          />
        )}

        {showComponentModal && (
          <AddComponentModal appScreenId={showActiveScreen} showModal={viewComponentModal} screenComponent={editComponent} />
        )}
        {showDeleteDialog && (
          <AppScreenDeleteDialog
            id={screenEditId}
            showModal={viewModal}
            setShowActiveScreenData={setShowActiveScreenData}
            setShowActiveScreen={setShowActiveScreen}
          />
        )}
      </Row>
    </div>
  );
};

export default AppScreenUpdate;
