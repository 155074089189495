import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import { ProductCreatedIcon } from '../../shared/assets/svg-icon/icon';
import { useAppDispatch, useAppSelector } from '../../config/store';
import { suggestAppScreens } from '../ai-suggestor/ai-suggestor.reducer';
import { createEntity as createScreenComponent } from '../screen-component/screen-component.reducer';
import { createEntity as createAppScreen, getEntities as getScreenEntities } from '../../entities/app-screen/app-screen.reducer';
import { getEntities as getAppEntities } from '../../entities/app-entity/app-entity.reducer';
import { getEntities as getAppEntityFields } from '../app-entity-field/app-entity-field.reducer';
import { ValidatedField, Translate, getSortState } from 'react-jhipster';
import { useParams } from 'react-router-dom';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '../../../../../app/shared/util/pagination.constants';

const AiWizardScreenSuggestor = props => {
  const product = useAppSelector(state => state.product.entity);
  const dispatch = useAppDispatch();
  const [loadingText, setLoadingText] = useState('');
  const currentApp = useAppSelector(state => state.app.entity);
  const [currentProduct, setCurrentProduct] = useState(props.productEntity);
  // const [showModal, setShowModal] = useState(props.showScreenModel);
  const [showModal, setShowModal] = useState(true);
  const [userPrompt, setUserPrompt] = useState('');
  const [aiSuggestedScreens, setAiSuggestedScreens] = useState([]);
  const [aiSuggestLoading, setAiSuggestLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [selectedScreens, setSelectedScreens] = useState([]);
  const { appId } = useParams<'appId'>();
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const getAllEntities = () => {
    dispatch(
      getScreenEntities({
        page: 0,
        size: 100,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: `appId.equals=${appId}`,
      })
    );
  };

  const handleUserPrompt = event => {
    if (event.target) {
      setUserPrompt(event.target.value);
    } else {
      setUserPrompt('');
    }
  };

  console.log('selectedScreens', selectedScreens);

  useEffect(() => {
    setShowModal(props.showScreenModel);
  }, [props.showScreenModel]);

  // console.log(props.productEntity,"props.productEntity");
  const handleAppScreens = async currentProduct => {
    setAiSuggestLoading(true);
    setLoadingText('Finalizing the App Screens');
    setStep(prevStep => prevStep + 1);
    const productDetails = {
      productName: product?.name,
      productDescription: product?.description,
      appId: appId,
      userPrompt: userPrompt,
    };
    const appScreensResult = await dispatch(suggestAppScreens(productDetails));
    if (appScreensResult?.payload?.data) {
      const tempArr = appScreensResult?.payload?.data.reduce((acc, current) => {
        const isDuplicate = acc.some(
          item =>
            item.title === current.title &&
            item.path === current.path &&
            item.template === current.template &&
            item.accessRole === current.accessRole
        );

        if (!isDuplicate) {
          acc.push(current);
        }

        return acc;
      }, []);
      setAiSuggestedScreens(tempArr);
    }

    setAiSuggestLoading(false);
  };

  const handleCheckboxChange = (e, screen) => {
    let isChecked = e.target.checked;
    if (isChecked == true) {
      setSelectedScreens(prevScreens => [...prevScreens, screen]);
    }
    if (isChecked == false) {
      let tempArr = selectedScreens.filter(obj => obj?.title !== screen.title);
      setSelectedScreens(tempArr);
    }
  };

  const modalToggle = () => {
    props.toggleModal();
    setStep(1);
    setUserPrompt('');
  };

  const saveAppScreens = async () => {
    setLoadingText('Adding the App Screens');
    setAiSuggestLoading(true);
    for (const appScreen of selectedScreens) {
      let appScreenPayload = {
        ...appScreen,
        app: currentApp,
        accessRoles: appScreen?.accessRole,
      };
      const appScreenEntity = await dispatch(createAppScreen(appScreenPayload));
      if (appScreenEntity?.payload?.data) {
        let appEntities = await dispatch(getAppEntities({ query: `appId.equals=${currentApp.id}` }));
        if (appEntities?.payload?.data) {
          let suggestedAppEntity = appEntities.payload.data.find(it => it.name == appScreen?.screenComponentSuggestion?.entity);
          let appEntityFields;
          if (suggestedAppEntity?.id) {
            appEntityFields = await dispatch(getAppEntityFields({ query: `appEntityId.equals=${suggestedAppEntity?.id}` }));
          }
          let suggestedAppEntityFieldsString = [];
          appEntityFields?.payload?.data.map((field, index) => {
            const existingItem = suggestedAppEntityFieldsString.includes(`${field.name}:${field.type}`);
            if (!existingItem) suggestedAppEntityFieldsString.push(`${field.name}:${field.type}`);
          });
          let screenComponentPayload = {
            name: appScreen?.title,
            layout: appScreen?.screenComponentSuggestion?.layout,
            layoutTemplate: appScreen?.screenComponentSuggestion?.layoutTemplate,
            appEntity: suggestedAppEntity,
            appScreen: appScreenEntity?.payload?.data,
            entityFields: suggestedAppEntityFieldsString.toString(),
          };
          await dispatch(createScreenComponent(screenComponentPayload));
        }
      }
    }
    getAllEntities();
    setAiSuggestLoading(false);
    setSelectedScreens([]);
    setStep(prevStep => prevStep + 1);
  };

  return (
    <>
      <Modal isOpen={showModal} toggle={() => !aiSuggestLoading && modalToggle()} className="modalDesignNew">
        {/* {step == 1 && ( */}
        <ModalHeader
          //  toggle={handleClose}
          data-cy="appEntityRelationshipHeading"
        >
          {/* AI suggested entity relationship for {titleUpperCase(productEntity?.name)} */}
          {step == 1 && <>How would you like to modify the Screens?</>}
          {step == 2 && aiSuggestedScreens.length > 0 && !aiSuggestLoading && <>which Screens would you like to create :</>}
        </ModalHeader>
        {/* )} */}
        <ModalBody>
          {step == 1 && (
            <>
              <ValidatedField
                className="col-md-12 mb-0 modal-input"
                id="userPrompt"
                name="userPrompt"
                type="textarea"
                data-cy="userPrompt"
                value={userPrompt}
                onChange={handleUserPrompt}
                placeholder="What are you looking for? (Optional)"
                validate={{
                  required: { value: true, message: 'Please enter valid prompt!!' },
                }}
              />
            </>
          )}
          {step == 2 && (
            <>
              {aiSuggestedScreens.length > 0 && !aiSuggestLoading ? (
                <div className="table-responsive coll-design">
                  <div className="table-scrolling">
                    <Table>
                      <thead className="table-primary">
                        <tr>
                          <th></th>
                          <th className="hand">Title</th>
                          <th>Template</th>
                          <th>Layout</th>
                        </tr>
                      </thead>
                      <tbody>
                        {aiSuggestedScreens.map((screen, i) => (
                          <tr key={`entity-${i}`} id={`entity-key-${screen.id || screen.key}`} data-cy="entityTable">
                            <td>
                              <input
                                type="checkbox"
                                onChange={e => {
                                  handleCheckboxChange(e, screen);
                                }}
                              />
                            </td>
                            <td>{screen?.title}</td>
                            <td>{screen?.template}</td>
                            <td>{screen?.screenComponentSuggestion?.layout}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              ) : aiSuggestLoading ? (
                <div className="justify-content-center">
                  <h2 className="wizard-heading gif_heading">{loadingText}</h2>
                  <div style={{ textAlign: 'center' }}>
                    <img className="modalIimg-dgn" src="themeContent/entitiesloader.gif" />
                  </div>
                </div>
              ) : (
                <div className="alert alert-warning">No AI suggested Screens Found.</div>
              )}
            </>
          )}

          {step == 3 &&
            (aiSuggestLoading ? (
              <div className="justify-content-center">
                <h2 className="wizard-heading gif_heading">{loadingText}</h2>
                <div style={{ textAlign: 'center' }}>
                  <img className="modalIimg-dgn" src="themeContent/entitiesloader.gif" />
                </div>
              </div>
            ) : (
              <>
                <div className="modal-success">
                  <ProductCreatedIcon />
                  <h2>Your Screens has been added Successfully.</h2>
                  <Button color="save" onClick={() => modalToggle()}>
                    Let's Review
                  </Button>
                </div>
              </>
            ))}
        </ModalBody>
        <ModalFooter>
          {step !== 3 && selectedScreens && !aiSuggestLoading && (
            <Button
              id="jhi-appEntity-feature-select"
              color="save"
              data-cy="entityFeatureSelectButton"
              disabled={(step == 2 && selectedScreens.length == 0) || aiSuggestLoading}
              onClick={() => {
                step == 1 ? handleAppScreens(product) : step == 2 ? saveAppScreens() : '';
              }}
            >
              Next
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AiWizardScreenSuggestor;
