import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Table, Label } from 'reactstrap';
import { byteSize, Translate, TextFormat, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from '../../../../../app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '../../../../../app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';

import { IProductJournalEntry } from '../../../../../app/shared/model/product-journal-entry.model';
import { getEntities } from './product-journal-entry.reducer.ts';

export const ProductJournalEntry = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'entryTimestamp'), location.search)
  );

  const productJournalEntryList = useAppSelector(state => state.productJournalEntry.entities);
  const loading = useAppSelector(state => state.productJournalEntry.loading);
  const totalItems = useAppSelector(state => state.productJournalEntry.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}&appId.equals=${appId}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${DESC}`;
    // if (location.search !== endURL) {
    //   navigate(`${location.pathname}${endURL}`);
    // }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  return (
    <div>
      <h2 id="product-journal-entry-heading" data-cy="ProductJournalEntryHeading" style={{ marginTop: '25px' }}>
        {/* <Translate contentKey="codeconductorApp.productJournalEntry.home.title">Product Journal Entries</Translate> */}
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="codeconductorApp.productJournalEntry.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link
            to={`/product/${productId}/app/${appId}/product-journal-entry/new`}
            className="btn btn-edit jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            {/* <Translate contentKey="codeconductorApp.productJournalEntry.home.createLabel">Create new Product Journal Entry</Translate> */}
            <Label style={{ marginBottom: '0px' }}>Create Journal</Label>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {!loading && productJournalEntryList && productJournalEntryList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('entryType')}>
                  <Translate contentKey="codeconductorApp.productJournalEntry.entryType">Entry Type</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('title')}>
                  <Translate contentKey="codeconductorApp.productJournalEntry.title">Title</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('details')}>
                  <Translate contentKey="codeconductorApp.productJournalEntry.details">Details</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('verifiedComplete')}>
                  <Translate contentKey="codeconductorApp.productJournalEntry.verifiedComplete">Verified Complete</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('verifiable')}>
                  <Translate contentKey="codeconductorApp.productJournalEntry.verifiable">Verifiable</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('entryTimestamp')}>
                  <Translate contentKey="codeconductorApp.productJournalEntry.entryTimestamp">Entry Timestamp</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('verificationTimestamp')}>
                  <Translate contentKey="codeconductorApp.productJournalEntry.verificationTimestamp">Verification Timestamp</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                {/* <th className="hand" onClick={sort('relatedAppEntityId')}>
                  <Translate contentKey="codeconductorApp.productJournalEntry.relatedAppEntityId">Related App Entity Id</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('relatedAppEntityRecordId')}>
                  <Translate contentKey="codeconductorApp.productJournalEntry.relatedAppEntityRecordId">
                    Related App Entity Record Id
                  </Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('ticketIdentifier')}>
                  <Translate contentKey="codeconductorApp.productJournalEntry.ticketIdentifier">Ticket Identifier</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('commitIdentifier')}>
                  <Translate contentKey="codeconductorApp.productJournalEntry.commitIdentifier">Commit Identifier</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('deletedOrRemoved')}>
                  <Translate contentKey="codeconductorApp.productJournalEntry.deletedOrRemoved">Deleted Or Removed</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('manualEffortRequired')}>
                  <Translate contentKey="codeconductorApp.productJournalEntry.manualEffortRequired">Manual Effort Required</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="codeconductorApp.productJournalEntry.app">App</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="codeconductorApp.productJournalEntry.product">Product</Translate> <FontAwesomeIcon icon="sort" />
                </th> */}
                <th>
                  <Translate contentKey="codeconductorApp.productJournalEntry.appEntity">App Entity</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {productJournalEntryList.map((productJournalEntry, i) => (
                <tr
                  key={`entity-${i}`}
                  data-cy="entityTable"
                  style={productJournalEntry.manualEffortRequired ? { background: 'rgb(0 205 236 / 12%)' } : {}}
                >
                  <td>
                    <Translate contentKey={`codeconductorApp.ProductJournalEntryType.${productJournalEntry.entryType}`} />
                  </td>
                  <td>{productJournalEntry.title}</td>
                  <td>{productJournalEntry.details}</td>
                  <td>{productJournalEntry.verifiedComplete ? 'true' : 'false'}</td>
                  <td>{productJournalEntry.verifiable ? 'true' : 'false'}</td>
                  <td>
                    {productJournalEntry.entryTimestamp ? (
                      <TextFormat type="date" value={productJournalEntry.entryTimestamp} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>
                    {productJournalEntry.verificationTimestamp ? (
                      <TextFormat type="date" value={productJournalEntry.verificationTimestamp} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  {/* <td>{productJournalEntry.relatedAppEntityId}</td>
                  <td>{productJournalEntry.relatedAppEntityRecordId}</td>
                  <td>{productJournalEntry.ticketIdentifier}</td>
                  <td>{productJournalEntry.commitIdentifier}</td>
                  <td>{productJournalEntry.deletedOrRemoved ? 'true' : 'false'}</td>
                  <td>{productJournalEntry.manualEffortRequired ? 'true' : 'false'}</td>
                  <td>
                    {productJournalEntry.app ? <Link to={`/app/${productJournalEntry.app.id}`}>{productJournalEntry.app.id}</Link> : ''}
                  </td>
                  <td>
                    {productJournalEntry.product ? (
                      <Link to={`/product/${productJournalEntry.product.id}`}>{productJournalEntry.product.id}</Link>
                    ) : (
                      ''
                    )}
                  </td> */}
                  <td>
                    {productJournalEntry.appEntity ? (
                      <Link to={`/product/${productId}/appId/${appId}/app-entity/${productJournalEntry.appEntity.id}`}>
                        {productJournalEntry.appEntity.name}
                      </Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/product/${productId}/app/${appId}/product-journal-entry/${productJournalEntry.id}/view`}
                        color="primary"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{' '}
                        {/* <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span> */}
                      </Button>
                      <Button
                        tag={Link}
                        to={`/product/${productId}/app/${appId}/product-journal-entry/${productJournalEntry.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="warning"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        {/* <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span> */}
                      </Button>
                      <Button
                        tag={Link}
                        to={`/product/${productId}/app/${appId}/product-journal-entry/${productJournalEntry.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        {/* <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span> */}
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : loading ? (
          <div className="conductor_loader"></div>
        ) : (
          <div className="alert alert-warning">
            <Translate contentKey="codeconductorApp.productJournalEntry.home.notFound">No Product Journal Entries found</Translate>
          </div>
        )}
      </div>
      {totalItems ? (
        <div className={productJournalEntryList && productJournalEntryList.length > 0 ? 'footer-pagination' : 'd-none'}>
          <div className="justify-content-end d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-end d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ProductJournalEntry;
