import React, { useEffect, useState } from 'react';
import { Col, Input, Label, Spinner, Button } from 'reactstrap';
import { ValidatedField, getSortState, translate } from 'react-jhipster'; // Assuming this is your custom component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '../../config/store';
import {
  createEntity as createActionComponent,
  deleteEntity as deleteActionComponent,
  updateEntity as updateActionComponent,
} from '../../../../../app/entities/action-component/action-component.reducer';
import { getEntities as getScreenComponents } from '../screen-component/screen-component.reducer';
import { getEntities } from './app-screen.reducer';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { DESC, ITEMS_PER_PAGE } from '../../../../../app/shared/util/pagination.constants';

export const actionButtons = ({
  relatedEntity,
  buttonActionTypeValues,
  actionAdded,
  appScreenId,
  appId,
  activeComponentData,
  appScreens,
  endPointList,
}) => {
  const dispatch = useAppDispatch();
  const [fields, setFields] = useState(relatedEntity.actionComponents);
  const updateSuccess = useAppSelector(state => state.actionComponent.updateSuccess);
  const [redirectTo, setRedirectTo] = useState('');
  const [loading, setLoading] = useState(false);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', DESC), location.search)
  );

  const handleAddField = () => {
    const newFields = [...fields, { value: '' }];
    setFields(newFields);
  };

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: 0,
        size: 100,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: `appId.equals=${appId}`,
      })
    );
  };

  const refreshFields = async () => {
    const result = await dispatch(getScreenComponents({ query: 'appScreenId.equals=' + appScreenId }));
    console.log('result', result);
    console.log('relatedEntity', relatedEntity);

    setFields(result.payload.data.find(item => item.id == relatedEntity.id).actionComponents);
    setLoading(false);
  };

  useEffect(() => {
    if (updateSuccess === true) {
      refreshFields();
      // getAllEntities();
    }
  }, [updateSuccess]);

  const handleButtonText = async (index, event) => {
    const newFields = fields.map((field, i) => (i === index ? { ...field, createButtonText: event.target.value } : field));
    setFields(newFields);
    let field = fields[index];
    await saveButton(field, index);
  };

  const saveButton = async (field, index) => {
    console.log('fieldsssssss', field);
    setLoading(true);
    if (field?.id) {
      await dispatch(
        updateActionComponent({
          id: field?.id,
          title: field?.title,
          description: '',
          redirectTo: field.redirectTo,
          appEndPoint: field?.appEndPoint,
          screenComponent: relatedEntity,
          appScreen: field?.appScreen,
          redirectUrl: field?.ExternalUrl,
        })
      );
    } else {
      await dispatch(
        createActionComponent({
          title: field?.title,
          description: '',
          redirectTo: field?.redirectTo,
          appEndPoint: field?.appEndPoint,
          screenComponent: relatedEntity,
          appScreen: field?.appScreen,
          redirectUrl: field?.ExternalUrl,
        })
      );
    }
    // await actionAdded();
  };

  const handleButtonActionType = async (value, related_entity, index) => {
    const newFields = fields.map((field, i) => (i === index ? { ...field, redirectTo: value } : field));
    setFields(newFields);
    let field = newFields[index];
    await saveButton(field, index);
  };

  const handleRemoveField = async index => {
    const field = fields.filter((_, i) => i === index);
    await dispatch(deleteActionComponent(field[0].id));
    const newFields = fields.filter((_, i) => i !== index);
    setFields(newFields);
  };

  const handleChange = (index, event) => {
    const newFields = fields.map((field, i) => (i === index ? { ...field, title: event.target.value } : field));
    setFields(newFields);
  };

  const handleButtonAction = async (event, index) => {
    let field = fields[index];
    let Redirect;
    let currentField;
    console.log('event.target.value', event.target.value);
    console.log('field.redirectTo', field.redirectTo);
    console.log('appScreens', appScreens);

    console.log('endPointList', endPointList);

    if (field.redirectTo === 'Redirect') {
      Redirect = appScreens.find(item => item.id == event.target.value);
      currentField = { ...field, appScreen: Redirect };
      console.log('Redirect', Redirect);
    } else if (field.redirectTo === 'Endpoint') {
      Redirect = endPointList.find(item => item.id == event.target.value);
      currentField = { ...field, appEndPoint: Redirect };
      console.log('Redirect', Redirect);
    } else if (field.redirectTo === 'externalUrl') {
      Redirect = event.target.value;
      currentField = { ...field, ExternalUrl: Redirect };
      console.log('Redirect', Redirect);
    }

    const newFields = fields.map((field, i) => (i === index ? currentField : field));
    let newField = newFields[index];
    await saveButton(newField, index);
  };

  const handleExternalUrl = async (index, event) => {
    const newFields = fields.map((field, i) => (i === index ? { ...field, ExternalUrl: event.target.value } : field));
    setFields(newFields);
  };

  const handleExternalUrlChange = async (index, event) => {
    const newFields = fields.map((field, i) => (i === index ? { ...field, ExternalUrl: event.target.value } : field));
    setFields(newFields);
    let field = newFields[index];
    await saveButton(field, index);
  };

  return (
    <fieldset disabled={loading}>
      {fields?.map((field, index) => (
        <div key={index}>
          <Col md="12" style={{ display: 'flex', marginTop: '4px' }}>
            <Label className="fw-500 fs-12 mb-1" check style={{ marginRight: '10px' }}>
              Text:
            </Label>
            <Input
              type="text"
              style={{ marginRight: '5px', width: '60%' }}
              maxLength="20"
              value={field.title || ''}
              onChange={event => handleChange(index, event)}
              onBlur={event => handleButtonText(index, event)}
            />
            <Button type="button" color="danger" onClick={() => handleRemoveField(index)}>
              Remove
            </Button>
          </Col>

          <Col md="12" style={{ display: 'flex', marginTop: '4px' }}>
            <ValidatedField
              label="Action Type:"
              id={`screen-component-buttonActionType-${index}`}
              name={`buttonActionType-${index}`}
              data-cy="buttonActionType"
              type="select"
              defaultValue={field?.redirectTo || ''}
              onClick={e => handleButtonActionType(e.target.value, relatedEntity, index)}
            >
              {buttonActionTypeValues.map(buttonActionType => (
                <option value={buttonActionType} key={buttonActionType}>
                  {translate('codeconductorApp.ButtonActionType.' + buttonActionType)}
                </option>
              ))}
            </ValidatedField>
          </Col>

          {field?.redirectTo == 'Redirect' && (
            <Col md="12" style={{ display: 'flex', marginTop: '4px' }}>
              <Label>Redirect To Screen: </Label>
              {activeComponentData && appScreens?.length > 0 ? (
                <ValidatedField
                  name={`redirect-screen-${activeComponentData.name}-${index}`}
                  type="select"
                  className="mb-0 space-remove"
                  style={{ marginRight: '5px' }}
                  defaultValue={field?.appScreen?.id}
                  onChange={e => handleButtonAction(e, index)}
                >
                  <option value="">Select One</option>
                  {appScreens.map(screen => (
                    <option value={screen.id} key={screen.id}>
                      {screen.title}
                    </option>
                  ))}
                </ValidatedField>
              ) : (
                <Spinner size="sm" color="primary">
                  Loading...
                </Spinner>
              )}
            </Col>
          )}

          {field?.redirectTo == 'Endpoint' && endPointList && (
            <Col md="12">
              <Label>Select Endpoint: </Label>
              {activeComponentData && endPointList?.length > 0 ? (
                <ValidatedField
                  name={`endpoint-${activeComponentData.name}-${index}`}
                  type="select"
                  className="mb-0 space-remove"
                  style={{ marginRight: '5px' }}
                  value={field?.appEndPoint?.id}
                  onChange={e => handleButtonAction(e, index)}
                >
                  <option value="">Select One</option>
                  {endPointList.map(endpoint => (
                    <option value={endpoint.id} key={endpoint.id}>
                      {endpoint.path}
                    </option>
                  ))}
                </ValidatedField>
              ) : (
                <Spinner size="sm" color="primary">
                  Loading...
                </Spinner>
              )}
            </Col>
          )}

          {field?.redirectTo == 'ExternalUrl' && (
            <Col md="12">
              <Label>Enter URL: </Label>
              {activeComponentData && (
                <ValidatedField
                  name={`endpoint-${activeComponentData.name}-${index}`}
                  type="text"
                  className="mb-0 space-remove"
                  style={{ marginRight: '5px' }}
                  value={field?.redirectUrl}
                  onChange={event => handleExternalUrl(index, event)}
                  onBlur={event => handleExternalUrlChange(index, event)}
                ></ValidatedField>
              )}
            </Col>
          )}
        </div>
      ))}
      {loading && (
        <Spinner size="sm" color="primary">
          Loading...
        </Spinner>
      )}
      <Button type="button" color="secondary" onClick={handleAddField}>
        <FontAwesomeIcon style={{ width: '16px', height: '16px' }} icon="plus" /> Add Action
      </Button>
    </fieldset>
  );
};

export default actionButtons;
