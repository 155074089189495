import { IAppFile } from 'app/shared/model/app-file.model';
import { IAppAiServiceTool } from 'app/shared/model/app-ai-service-tool.model';
import { IApp } from 'app/shared/model/app.model';

export interface IAppAiTool {
  id?: number;
  name?: string;
  className?: string;
  description?: string | null;
  returnType?: string | null;
  isGenerated?: boolean | null;
  appFile?: IAppFile | null;
  appAiServiceTools?: IAppAiServiceTool[] | null;
  app?: IApp | null;
}

export const defaultValue: Readonly<IAppAiTool> = {
  isGenerated: false,
};
