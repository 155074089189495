import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from '../../../../../app/config/constants';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';

import { getEntity } from './app-ai-tool.reducer';

export const AppAiToolDetail = () => {
  const dispatch = useAppDispatch();

  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { appAiToolId } = useParams<'appAiToolId'>();
  const productEntity = useAppSelector(state => state.product.entity);
  const app = useAppSelector(state => state.app.entity);

  useEffect(() => {
    dispatch(getEntity(appAiToolId));
  }, []);

  const appAiToolEntity = useAppSelector(state => state.appAiTool.entity);
  return (
    <div className="inner-content-wrap">
      <Row>
        <Col md="8">
          <h2 data-cy="appAiToolDetailsHeading">
            {/* <Translate contentKey="codeconductorApp.appAiTool.detail.title">AppAiTool</Translate> */}
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/product">Products</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link to={`/product/${productEntity.id}`}>{productEntity.name}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link to={`/product/${productEntity.id}/app/${app.id}`}>{app.name}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active> AI Tools</BreadcrumbItem>
            </Breadcrumb>
          </h2>
          <dl className="jh-entity-details">
            {/* <dt>
              <span id="id">
                <Translate contentKey="global.field.id">ID</Translate>
              </span>
            </dt>
            <dd>{appAiToolEntity.id}</dd> */}
            <dt>
              <span id="name">
                <Translate contentKey="codeconductorApp.appAiTool.name">Name</Translate>
              </span>
            </dt>
            <dd>{appAiToolEntity.name}</dd>
            <dt>
              <span id="className">
                <Translate contentKey="codeconductorApp.appAiTool.className">Class Name</Translate>
              </span>
            </dt>
            <dd>{appAiToolEntity.className}</dd>
            <dt>
              <span id="description">
                <Translate contentKey="codeconductorApp.appAiTool.description">Description</Translate>
              </span>
            </dt>
            <dd>{appAiToolEntity.description}</dd>
            <dt>
              <span id="returnType">
                <Translate contentKey="codeconductorApp.appAiTool.returnType">Return Type</Translate>
              </span>
            </dt>
            <dd>{appAiToolEntity.returnType}</dd>
            <dt>
              <Translate contentKey="codeconductorApp.appAiTool.appFile">App File</Translate>
            </dt>
            <dd>{appAiToolEntity.appFile ? appAiToolEntity.appFile.id : ''}</dd>
            <dt>
              <Translate contentKey="codeconductorApp.appAiTool.app">App</Translate>
            </dt>
            <dd>{appAiToolEntity.app ? appAiToolEntity.app.id : ''}</dd>
          </dl>
          <Button tag={Link} to={`/product/${productId}/app/${appId}`} replace color="back" data-cy="entityDetailsBackButton">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/product/${productId}/app/${appId}/app-ai-tool/${appAiToolEntity.id}/edit`} replace color="edit">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default AppAiToolDetail;
