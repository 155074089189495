import { IAppFile } from 'app/shared/model/app-file.model';
import { IAppAiServiceTool } from 'app/shared/model/app-ai-service-tool.model';
import { IAppChatLanguageModel } from 'app/shared/model/app-chat-language-model.model';
import { IAppEmbeddingStore } from 'app/shared/model/app-embedding-store.model';
import { IApp } from 'app/shared/model/app.model';

export interface IAppAiService {
  id?: number;
  name?: string | null;
  className?: string | null;
  systemMessage?: string | null;
  returnType?: string | null;
  parameters?: string | null;
  isGenerated?: boolean | null;
  appFile?: IAppFile | null;
  appAiServiceTools?: IAppAiServiceTool[] | null;
  appChatLanguageModel?: IAppChatLanguageModel | null;
  appEmbeddingStore?: IAppEmbeddingStore | null;
  app?: IApp | null;
}

export const defaultValue: Readonly<IAppAiService> = {
  isGenerated: false,
};
