import { IFeature } from '../../shared/model/feature.model';
import { IFunctionality } from '../../shared/model/functionality.model';
import { ICoreAccessConfig } from '../../shared/model/core-access-config.model';
import { ICoreEntityRelationship } from '../../shared/model/core-entity-relationship.model';
import { ICoreScreen } from '../../shared/model/core-screen.model';
import { ICoreEntity } from '../../shared/model/core-entity.model';

export interface ICoreFunctionality {
  id?: number;
  name?: string;
  description?: string | null;
  userId?: number | null;
  features?: IFeature[] | null;
  functionalities?: IFunctionality[] | null;
  coreAccessConfigs?: ICoreAccessConfig[] | null;
  coreEntityRelationships?: ICoreEntityRelationship[] | null;
  coreScreens?: ICoreScreen[] | null;
  coreEntities?: ICoreEntity[] | null;
}

export const defaultValue: Readonly<ICoreFunctionality> = {};
