import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  ButtonGroup,
  Col,
  Row,
  Container,
  FormGroup,
  Input,
  Spinner,
} from 'reactstrap';
import { Translate, ValidatedForm, ValidatedField, translate, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '../../config/store';
import { getEntities, deleteEntity } from './app-screen.reducer';
import { faXmark, faPlus } from '@fortawesome/free-solid-svg-icons';
import { getEntity, updateEntity, createEntity, reset, getEntities as getScreens } from './app-screen.reducer';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { DESC, ITEMS_PER_PAGE } from '../../../../../app/shared/util/pagination.constants';
import { createEntity as createScreenComponent, getEntities as getScreenComponents } from '../screen-component/screen-component.reducer';
import { getEntities as getEntityFields } from '../app-entity-field/app-entity-field.reducer';
import { ScreenType } from '../../../../../app/shared/model/enumerations/screen-type.model';
const screenTypeValues = Object.keys(ScreenType);

export const AddScreenModal = props => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  // const { id } = useParams<'id'>();
  const { appId } = useParams<'appId'>();
  const { itemId, showModal, updateScreenComponents, selectionBuilder, screenComponent, screenType, setScreenType } = props;
  const isNew = itemId === undefined;

  const [loadModal, setLoadModal] = useState(false);

  const appScreenEntity = useAppSelector(state => state.appScreen.entity);
  const updateSuccess = useAppSelector(state => state.appScreen.updateSuccess);
  const componentUpdateSuccess = useAppSelector(state => state.screenComponent.updateSuccess);

  const chatGptSearchData = useAppSelector(state => state.chatGptSearch);
  const updating = useAppSelector(state => state.appScreen.updating);

  const appEntityList = useAppSelector(state => state.appEntity.entities);
  const appEntityFieldEntities = useAppSelector(state => state.appEntityField.entities);

  const [rSelected, setRSelected] = useState('ADMIN');
  const [template, setTemplate] = useState('col');
  const [appScreenTitle, setAppScreenTitle] = useState('');
  const [appScreenDescription, setAppScreenDescription] = useState('');
  const [appScreenPath, setAppScreenPath] = useState('');
  const [fields, setFields] = useState([]);
  const [appEntityValue, setAppEntityValue] = useState();
  const [appEntities, setAppEntities] = useState();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', DESC), location.search)
  );

  const handleClose = () => {
    showModal(false);
  };

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: 0,
        size: 100,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: `appId.equals=${appId}`,
      })
    );
  };

  useEffect(() => {
    if (appEntityList?.length > 0) {
      const filteredEntities = appEntityList.filter(it => it.type == 'Standard');
      setAppEntities(filteredEntities);
    }
  }, [appEntityList]);

  useEffect(() => {
    if (appEntities && appEntities.length > 0) {
      dispatch(getEntityFields({ query: `appEntityId.equals=${appEntityValue}` }));
    }
  }, [appEntityValue]);

  useEffect(() => {
    if (updateSuccess) {
      showModal(false);
      getAllEntities();
    }
  }, [updateSuccess]);

  useEffect(() => {
    let arr = [];
    if (appEntityFieldEntities && appEntityFieldEntities.length > 0) {
      appEntityFieldEntities.map(item => {
        const existingField = fields.includes(`${item.name}:${item.type}`);
        if (!existingField) {
          arr.push(`${item.name}:${item.type}`);
          setFields(arr);
        }
      });
    }
  }, [appEntityFieldEntities]);
  // console.log("fields", fields);

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(itemId));
    }
  }, []);

  useEffect(() => {
    if (componentUpdateSuccess) {
      showModal(false);
      getAllEntities();
    }
  }, [componentUpdateSuccess]);

  useEffect(() => {
    if (Object.entries(appScreenEntity).length > 0) {
      setTemplate(appScreenEntity?.template);
      setRSelected(appScreenEntity?.accessRoles);
    }
    if (!isNew && appScreenEntity && appScreenEntity.title) {
      setAppScreenTitle(appScreenEntity.title);
    }
    if (!isNew && appScreenEntity && appScreenEntity.description) {
      setAppScreenDescription(appScreenEntity.description);
    } else {
      setAppScreenDescription('');
    }
    if (!isNew && appScreenEntity && appScreenEntity.path) {
      setAppScreenPath(appScreenEntity.path);
    }
    if (!isNew && appScreenEntity && appScreenEntity.screenType) {
      setScreenType(appScreenEntity.screenType);
    }
  }, [appScreenEntity]);

  const handleAppScreenTitleChange = e => {
    setAppScreenTitle(e.target.value);
  };

  const handleAppScreenPathChange = e => {
    setAppScreenPath(e.target.value);
  };

  const handleTemplateLayout = async event => {
    setTemplate(event.target.value);
    if (event.target.value == 'tabs') {
      await dispatch(
        getScreens({ page: 0, size: 100, sort: `${paginationState.sort},${paginationState.order}`, query: `appId.equals=${appId}` })
      );
    }
  };

  useEffect(() => {
    if (appEntityValue != undefined) {
      const values = appEntities.find(it => it.id.toString() == appEntityValue?.toString());
      setAppScreenTitle(`${values.name}`);
      setAppScreenPath(`/${values.name.toLowerCase()}`);
    }
  }, [appEntityValue]);

  // console.log("appEntities", appEntities);

  const saveEntity = async values => {
    // debugger
    // console.log("valuesScreen", values);
    let entity = {
      ...appScreenEntity,
      ...values,
      id: !isNew ? appScreenEntity.id : '',
      app: { id: appId },
      accessRoles: rSelected,
      template: template ? template : values?.templates,
      title: appScreenTitle,
      path: appScreenPath,
      screenType: screenType,
      description: appScreenDescription,
    };

    if (isNew) {
      console.log('InnIsNew');
      const { payload } = await dispatch(createEntity(entity));
      let screen_id = payload['data'];
      // console.log('🚀 ~ saveEntity ~ screen_id:', screen_id);

      let screenEntity = {
        name: `${appScreenTitle} List`,
        appEntity: appEntities.find(it => it.id.toString() === appEntityValue?.toString()),
        appScreen: screen_id,
      };
      if (screen_id && screen_id.template != 'tabs') {
        screenEntity = {
          ...screenEntity,
          layout: 'list',
          layoutTemplate: 'table',
          entityFields: fields.toString(),
        };

        // console.log("screenEntityINN IFF", screenEntity);
        dispatch(createScreenComponent(screenEntity));
      } else if (screen_id && screen_id.template == 'tabs') {
        // console.log("screenEntity INN IFF 22", screenEntity);
        dispatch(createScreenComponent(screenEntity));
      }
    } else {
      // entity = { ...entity };
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? chatGptSearchData?.entity?.identifier
        ? { title: chatGptSearchData.entity.identifier }
        : {}
      : {
          ...appScreenEntity,
          app: appScreenEntity?.app?.id,
          // functionality: appScreenEntity?.functionality?.id,
        };

  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose} data-cy="appScreenDeleteDialogHeading" style={{ padding: '20px' }} className="addScreenHeader">
        <Label>Add Screen</Label>
        {screenType && (
          <FormGroup className="mb-0">
            {screenTypeValues.map((type, index) => {
              return (
                <>
                  <Input
                    key={index}
                    name={type}
                    type="radio"
                    className="mx-1"
                    // defaultChecked={screenTypeValue == type}
                    checked={type === screenType}
                    id={`type`}
                    onChange={e => {
                      setScreenType(e?.target?.name);
                    }}
                  />
                  <Label check className="me-2 fs-12 fw-500">
                    {type}
                  </Label>
                </>
              );
            })}
          </FormGroup>
        )}
      </ModalHeader>
      <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity} className="row" style={{ padding: '0px 20px' }}>
        <ModalBody id="codeconductorApp.appScreen.delete.question">
          {isNew ? (
            <ValidatedField
              id="screen-component-appEntity"
              name="appEntity"
              data-cy="appEntity"
              label={translate('codeconductorApp.screenComponent.appEntity')}
              type="select"
              onChange={e => {
                setAppEntityValue(e.target.value);
              }}
              required
            >
              <option value="" key="0" />
              {appEntities
                ? appEntities.map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.name}
                    </option>
                  ))
                : null}
            </ValidatedField>
          ) : (
            ''
          )}
          <ValidatedField
            // className="col-md-6"
            label={translate('codeconductorApp.appScreen.title')}
            id="app-screen-title"
            name="appScreenTitle"
            data-cy="appScreenTitle"
            type="text"
            value={appScreenTitle}
            onChange={handleAppScreenTitleChange}
            required
            // validate={{
            //   required: { value: true, message: translate('entity.validation.required') },
            // }}
          />
          {!isNew ? (
            <ValidatedField
              label={translate('codeconductorApp.appScreen.description')}
              id="app-screen-description"
              name="description"
              data-cy="description"
              type="text"
              value={appScreenDescription}
              onChange={e => setAppScreenDescription(e.target.value)}
            />
          ) : (
            ''
          )}
          <ValidatedField
            // className="col-md-6"
            label={translate('codeconductorApp.appScreen.path')}
            id="app-screen-path"
            name="appScreenPath"
            data-cy="appScreenPath"
            type="text"
            value={appScreenPath}
            onChange={handleAppScreenPathChange}
            required
            // validate={{
            //   required: { value: true, message: translate('entity.validation.required') },
            // }}
          />
          {/* convert temaplate field to dropdown with values "Single column (col)" and "Two Column (2-col)"
              if selected is template 2 column then sidebar show */}
          {/* if sidebar is selected for one entity then it will not be selected for second entity */}
          <ValidatedField
            // className="col-md-6"
            label={translate('codeconductorApp.appScreen.template')}
            id="app-screen-template"
            name="templates"
            data-cy="template"
            value={template}
            type="select"
            onChange={handleTemplateLayout}
            required
          >
            <option value="col">Single Column</option>
            <option value="2-col">Double Column</option>
            <option value="tabs">{screenType == 'MOBILE' ? 'Bottom Bar' : 'Tabs'}</option>
          </ValidatedField>
          <Col md="12" className="mb-3 screen-access-roles">
            <Label>{translate('codeconductorApp.appScreen.accessRoles')}</Label>
            <ButtonGroup style={{ width: '100%' }}>
              <Button color="primary" outline onClick={() => setRSelected('ADMIN')} active={rSelected === 'ADMIN'}>
                Admin
              </Button>
              <Button color="primary" outline onClick={() => setRSelected('USER')} active={rSelected === 'USER'}>
                User
              </Button>
              <Button color="primary" outline onClick={() => setRSelected('PUBLIC')} active={rSelected === 'PUBLIC'}>
                Public
              </Button>
            </ButtonGroup>
          </Col>
          {/* <Col>
          </Col> */}
        </ModalBody>
        <ModalFooter>
          <Col md={'12'} style={{ padding: '0px 6px' }}>
            <Button color="secondary" onClick={handleClose}>
              <FontAwesomeIcon icon="ban" />
              &nbsp;
              <Translate contentKey="entity.action.cancel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button
              color="save"
              id="save-entity"
              data-cy="entityCreateSaveButton"
              type="submit"
              disabled={updating}
              className="saveScreenBtn"
              style={{ padding: '6px !important' }}
            >
              <FontAwesomeIcon icon="save" />
              &nbsp;
              <Translate contentKey="entity.action.save">Save</Translate>
            </Button>
          </Col>
        </ModalFooter>
      </ValidatedForm>
    </Modal>
  );
};

export default AddScreenModal;
