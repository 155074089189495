import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, Label, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from '../../../../../app/shared/util/date-utils';
import { mapIdList } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../config/store';
import { DependencyType } from '../../../../../app/shared/model/enumerations/dependency-type.model';

import { IDependency } from '../../../../../app/shared/model/dependency.model';
import { getEntity, updateEntity, createEntity, reset } from './dependency.reducer';
import { getEntity as getApp } from '../app/app.reducer';

export const DependencyUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { dependencyId } = useParams<'dependencyId'>();
  const isNew = dependencyId === undefined;
  const app = useAppSelector(state => state.app.entity);
  const productEntity = useAppSelector(state => state.product.entity);
  const dependencyTypeValues = Object.keys(DependencyType);

  const dependencyEntity = useAppSelector(state => state.dependency.entity);
  const loading = useAppSelector(state => state.dependency.loading);
  const updating = useAppSelector(state => state.dependency.updating);
  const updateSuccess = useAppSelector(state => state.dependency.updateSuccess);
  const [dependencyDeclaration, setDependencyDeclaration] = useState('');

  const handleClose = () => {
    navigate(`/product/${productId}/app/${appId}` + location.search);
  };

  useEffect(() => {
    if (isNew) {
      if (appId !== undefined) {
        dispatch(getApp(appId));
      }
      // dispatch(reset());
    } else {
      dispatch(getEntity(dependencyId));
    }
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (!isNew && dependencyEntity && dependencyEntity.id) {
      setDependencyDeclaration(dependencyEntity.declarations);
    }
  }, [dependencyEntity]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...dependencyEntity,
      ...values,
      declarations: dependencyDeclaration,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...dependencyEntity,
        };

  return (
    <div className="inner-content-wrap">
      <Row className="justify-content-center">
        {/* <Col md="12">
          <h2 id="codeconductorApp.dependency.home.createOrEditLabel" data-cy="DependencyCreateUpdateHeading">
            <Label>{isNew ? `Add` : `Manage`} Dependency</Label>
          </h2>
        </Col> */}
        <Col md="12">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/product">Products</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={`/product/${productEntity.id}`}>{productEntity.name}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={`/product/${productEntity.id}/app/${app.id}`}>{app.name}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>{isNew ? `Add` : `Manage`} Dependency</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  className="col-md-6"
                  name="id"
                  required
                  readOnly
                  id="dependency-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.dependency.packageName')}
                id="dependency-packageName"
                name="packageName"
                data-cy="packageName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.dependency.declarations')}
                id="dependency-declarations"
                name="dependencyDeclaration"
                data-cy="dependencyDeclaration"
                type="textarea"
                value={dependencyDeclaration}
                onChange={e => setDependencyDeclaration(e.target.value)}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.dependency.minVersion')}
                id="dependency-minVersion"
                name="minVersion"
                data-cy="minVersion"
                type="text"
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.dependency.maxVersion')}
                id="dependency-maxVersion"
                name="maxVersion"
                data-cy="maxVersion"
                type="text"
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.dependency.type')}
                id="dependency-type"
                name="type"
                data-cy="type"
                type="select"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              >
                {dependencyTypeValues.map(dependencyType => (
                  <option value={DependencyType[dependencyType]} key={DependencyType[dependencyType]}>
                    {translate('codeconductorApp.DependencyType.' + DependencyType[dependencyType])}
                  </option>
                ))}
              </ValidatedField>
              <Button
                tag={Link}
                className="btn-back"
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to={`/product/${productId}/app/${appId}`}
                replace
                color="back"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button className="btn-save" color="save" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default DependencyUpdate;
