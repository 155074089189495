import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col, FormGroup, Input, Label } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from '../../../../../app/config/constants';
import { useAppDispatch, useAppSelector } from '../../config/store';

import { getEntity } from './deployment.reducer';

export const DeploymentDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { deploymentId } = useParams<'deploymentId'>();

  useEffect(() => {
    if (deploymentId !== undefined) {
      dispatch(getEntity(deploymentId));
    }
  }, []);

  const deploymentEntity = useAppSelector(state => state.deployment.entity);
  return (
    <div className="inner-content-wrap">
      <Row>
        <Col md="12">
          <h2 className="row  page-title" data-cy="deploymentDetailsHeading">
            <Translate contentKey="codeconductorApp.deployment.detail.title">Deployment</Translate>
          </h2>
          <dl className="jh-entity-details">
            {/* <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt> */}
            {/* <dd>{deploymentEntity.id}</dd> */}
            <Col md={4}>
              <FormGroup className="row">
                <span id="deploymentType">
                  <Label contentKey="codeconductorApp.deployment.deploymentType">Deployment Type</Label>
                </span>
                <Input value={deploymentEntity.deploymentType} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup className="row">
                <span id="deploymentCloud">
                  <Label contentKey="codeconductorApp.deployment.deploymentCloud">Deployment Cloud</Label>
                </span>
                <Input value={deploymentEntity.deploymentCloud} />
              </FormGroup>

              <FormGroup className="row">
                <span id="cloudToken">
                  <Label contentKey="codeconductorApp.deployment.cloudToken">Cloud Token</Label>
                </span>
                <Input value={deploymentEntity.cloudToken} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup className="row">
                <span id="enabled">
                  <Label contentKey="codeconductorApp.deployment.enabled">Enabled</Label>
                </span>
                <dt>
                  <span id="domainName">
                    <Translate contentKey="codeconductorApp.deployment.domainName">Domain Name</Translate>
                  </span>
                </dt>
                <Input value={deploymentEntity.enabled ? 'true' : 'false'} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup className="row">
                <span id="envName">
                  <Label contentKey="codeconductorApp.deployment.envName">Env Name</Label>
                </span>
                <Input value={deploymentEntity.envName} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup className="row">
                <span id="credentials">
                  <Label contentKey="codeconductorApp.deployment.credentials">Credentials</Label>
                </span>
                <Input value={deploymentEntity.credentials} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup className="row">
                <span id="resourceName">
                  <Label contentKey="codeconductorApp.deployment.resourceName">Resource Name</Label>
                </span>
                <Input value={deploymentEntity.resourceName} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup className="row">
                <span id="resourcePath">
                  <Label contentKey="codeconductorApp.deployment.resourcePath">Resource Path</Label>
                </span>
                <Input value={deploymentEntity.resourcePath} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup className="row">
                <span id="appsFolders">
                  <Label contentKey="codeconductorApp.deployment.appsFolders">Apps Folders</Label>
                </span>
                <Input value={deploymentEntity.appsFolders} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup className="row">
                <span id="dockerRepositoryName">
                  <Label contentKey="codeconductorApp.deployment.dockerRepositoryName">Docker Repository Name</Label>
                </span>
                <Input value={deploymentEntity.dockerRepositoryName} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup className="row">
                <Label contentKey="codeconductorApp.deployment.product">Product</Label>
                <Input value={deploymentEntity.product ? deploymentEntity.product.name : ''} />
              </FormGroup>
            </Col>
          </dl>
          <Button tag={Link} to={`/product/${productId}/app/${appId}`} replace color="back" data-cy="entityDetailsBackButton">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          {/* <Button tag={Link} to={`/deployment/${deploymentEntity.id}/edit`} replace color="save">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button> */}
        </Col>
      </Row>
    </div>
  );
};

export default DeploymentDetail;
