import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from '../../../../../app/config/constants';

import { useAppDispatch, useAppSelector } from '../../config/store';

import { getEntity } from '../../../../../app/entities/app-end-point/app-end-point.reducer';

export const AppEndPointDetail = () => {
  const dispatch = useAppDispatch();

  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { endpointControllerId } = useParams<'endpointControllerId'>();
  const { endpointId } = useParams<'endpointId'>();
  const id = endpointId;

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getEntity(id));
    }
  }, []);

  const appEndPointEntity = useAppSelector(state => state.appEndPoint.entity);
  return (
    <div className="inner-content-wrap">
      <Row>
        <Col md="12">
          <h2 data-cy="appEndPointDetailsHeading" className="d-flex justify-content-between align-items-center fs-5">
            <Translate contentKey="codeconductorApp.appEndPoint.detail.title">AppEndPoint</Translate>
          </h2>
          <dl className="jh-entity-details" style={{ marginTop: '20px' }}>
            <Col md="6">
              <dt>
                <span id="id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </span>
              </dt>
              <dd>{appEndPointEntity.id}</dd>
            </Col>
            <Col md="6">
              <dt>
                <span id="path">
                  <Translate contentKey="codeconductorApp.appEndPoint.path">Path</Translate>
                </span>
              </dt>
              <dd>{appEndPointEntity.path}</dd>
            </Col>
            <Col md="6">
              <dt>
                <span id="method">
                  <Translate contentKey="codeconductorApp.appEndPoint.method">Method</Translate>
                </span>
              </dt>
              <dd>{appEndPointEntity.method}</dd>
            </Col>
            {appEndPointEntity?.description ? (
              <Col md="6">
                <dt>
                  <span id="description">
                    <Translate contentKey="codeconductorApp.appEndPoint.description">Description</Translate>
                  </span>
                </dt>
                <dd>{appEndPointEntity.description}</dd>
              </Col>
            ) : (
              ''
            )}
            {appEndPointEntity?.pseudoCode ? (
              <Col md="6">
                <dt>
                  <span id="pseudoCode">
                    <Translate contentKey="codeconductorApp.appEndPoint.pseudoCode">Pseudo Code</Translate>
                  </span>
                </dt>
                <dd>{appEndPointEntity.pseudoCode}</dd>
              </Col>
            ) : (
              ''
            )}
            <Col md="6">
              <dt>
                <span id="outputsList">
                  <Translate contentKey="codeconductorApp.appEndPoint.outputsList">Outputs List</Translate>
                </span>
              </dt>
              <dd>{appEndPointEntity.outputsList ? 'true' : 'false'}</dd>
            </Col>
            <Col md="6">
              <dt>
                <Translate contentKey="codeconductorApp.appEndPoint.appEndPointController">App End Point Controller</Translate>
              </dt>
              <dd>{appEndPointEntity.appEndPointController ? appEndPointEntity.appEndPointController.id : ''}</dd>
            </Col>
          </dl>
          <Button
            tag={Link}
            to={`/product/${productId}/app/${appId}/controller/${endpointControllerId}/view`}
            replace
            color="back"
            data-cy="entityDetailsBackButton"
          >
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/product/${productId}/app/${appId}/controller/${endpointControllerId}/endpoint/${appEndPointEntity.id}/edit`}
            replace
            color="edit"
          >
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default AppEndPointDetail;
