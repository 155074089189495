import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from '../../../../../app/shared/util/date-utils';
import { mapIdList } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';

import { IApp } from '../../../../../app/shared/model/app.model';
import { getEntities as getApps } from '../../../../../app/entities/app/app.reducer';
import { IAppChatLanguageModel } from '../../../../../app/shared/model/app-chat-language-model.model';
import { ChatLanguageModelType } from '../../../../../app/shared/model/enumerations/chat-language-model-type.model';
import { getEntity, updateEntity, createEntity, reset } from './app-chat-language-model.reducer';

export const AppChatLanguageModelUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { appChatLanguageModelId } = useParams<'appChatLanguageModelId'>();

  const isNew = appChatLanguageModelId === undefined;

  const apps = useAppSelector(state => state.app.entities);
  const productEntity = useAppSelector(state => state.product.entity);
  const app = useAppSelector(state => state.app.entity);
  const appChatLanguageModelEntity = useAppSelector(state => state.appChatLanguageModel.entity);
  const loading = useAppSelector(state => state.appChatLanguageModel.loading);
  const updating = useAppSelector(state => state.appChatLanguageModel.updating);
  const updateSuccess = useAppSelector(state => state.appChatLanguageModel.updateSuccess);
  const chatLanguageModelTypeValues = Object.keys(ChatLanguageModelType);

  const handleClose = () => {
    navigate(`/product/${productId}/app/${appId}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(appChatLanguageModelId));
    }

    // dispatch(getApps({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...appChatLanguageModelEntity,
      ...values,
      app: app,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          type: 'OpenAi',
          ...appChatLanguageModelEntity,
          app: appChatLanguageModelEntity?.app?.id,
        };

  return (
    <div className="inner-content-wrap">
      <Row className="justify-content-center">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/product">Products</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={`/product/${productEntity.id}`}>{productEntity.name}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={`/product/${productEntity.id}/app/${app.id}`}>{app.name}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active> Chat Language Model</BreadcrumbItem>
        </Breadcrumb>
        {/* <Col md="8">
          <h2 id="codeconductorApp.appChatLanguageModel.home.createOrEditLabel" data-cy="AppChatLanguageModelCreateUpdateHeading">
            <Translate contentKey="codeconductorApp.appChatLanguageModel.home.createOrEditLabel">
              Create or edit a AppChatLanguageModel
            </Translate>
          </h2>
        </Col> */}
      </Row>
      <Row className="justify-content-center">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity} className="row">
              {/* {!isNew ? (
                <ValidatedField
                  className="col-md-6"
                  name="id"
                  required
                  readOnly
                  id="app-chat-language-model-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null} */}
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appChatLanguageModel.type')}
                id="app-chat-language-model-type"
                name="type"
                data-cy="type"
                type="select"
              >
                {chatLanguageModelTypeValues.map(chatLanguageModelType => (
                  <option value={chatLanguageModelType} key={chatLanguageModelType}>
                    {translate('codeconductorApp.ChatLanguageModelType.' + chatLanguageModelType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appChatLanguageModel.modelName')}
                id="app-chat-language-model-modelName"
                name="modelName"
                data-cy="modelName"
                type="text"
              />
              <ValidatedField
                className="col-md-6"
                label={translate('codeconductorApp.appChatLanguageModel.modelVersion')}
                id="app-chat-language-model-modelVersion"
                name="modelVersion"
                data-cy="modelVersion"
                type="text"
              />
              {/* <ValidatedField
                id="app-chat-language-model-app"
                name="app"
                data-cy="app"
                label={translate('codeconductorApp.appChatLanguageModel.app')}
                type="select"
              >
                <option value="" key="0" />
                {apps
                  ? apps.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField> */}
              <Col md={12}>
                <Button
                  tag={Link}
                  id="cancel-save"
                  data-cy="entityCreateCancelButton"
                  to={`/product/${productId}/app/${appId}`}
                  replace
                  color="back"
                >
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="save" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </Col>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AppChatLanguageModelUpdate;
