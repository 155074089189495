import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from '../../../../app/shared/error/error-boundary-routes';

import Product from '../../../../app/entities/product';
import ProductUserRole from '../../../../app/entities/product-user-role';
import App from './app';
import CodeBlueprint from '../../../../app/entities/code-blueprint';
import AppCodeBlueprint from '../../../../app/entities/app-code-blueprint';
import AppScreen from '../../../../app/entities/app-screen';
import AppAccessConfig from '../../../../app/entities/app-access-config';
import Functionality from './functionality';
import AppEntity from './app-entity';
import AppEntityField from './app-entity-field';
import EntityRelationship from './entity-relationship';
import Deployment from './deployment';
import CoreScreen from '../../../../app/entities/core-screen';
import CoreAccessConfig from '../../../../app/entities/core-access-config';
import CoreFunctionality from '../../../../app/entities/core-functionality';
import CoreEntity from '../../../../app/entities/core-entity';
import CoreEntityField from '../../../../app/entities/core-entity-field';
import CoreEntityRelationship from '../../../../app/entities/core-entity-relationship';
import ProductAllFeatureRoutes from './productAllFeatures';
// import AppScreenEntity from './app-screen-entity/app-screen-entity';
import EmailConfiguration from '../../../../app/entities/email-configuration';
import ProductUser from '../../../../app/entities/product-user';
import EnumMember from './enum-member';
import Type from '../../../../app/entities/type';
import TeamMember from '../../../../app/entities/team-member';
import ScreenComponent from '../../../../app/entities/screen-component';
import VaultConfig from '../../../../app/entities/vault-config';
import Dependency from '../entities/dependency';
import AppUpdate from './app/app-update';
import ProductUpdate from './product/product-update';
import ProductDetail from './product/product-detail';
import CoreScreenComponent from './core-screen-component/core-screen-component';
import AppScreenLayout from './app-screen-layout/app-screen-layout';
import LayoutConfig from './layout-config/layout-config';
import MenuItem from './menu-item/menu-item';
import AppLayout from './app-layout/app-layout';
import ScreenContent from './screen-content/screen-content';
import JobTask from './job-task/job-task';
import Job from './job/job';
import AppDependency from './app-dependency/app-dependency';
import ProductUserRoleAccess from './product-user-role-access/product-user-role-access';
import Feature from './feature/feature';
import CoreEnumMember from './core-enum-member/core-enum-member';
import AppEndPoint from './app-end-point/app-end-point';
import AppEndPointController from './app-end-point-controller/app-end-point-controller';
import CoreFunctionalityDependency from './core-functionality-dependency/core-functionality-dependency';
import CoreAppEndPoint from './core-app-end-point/core-app-end-point';
import CoreAppEndPointController from './core-app-end-point-controller/core-app-end-point-controller';
import CoreJob from './core-job/core-job';
import AppDomainListener from './app-domain-listener';
import ProductJournalEntry from './product-journal-entry/product-journal-entry';
import AppAiService from './app-ai-service/app-ai-service';
import ActionComponent from '../../../../app/entities/action-component';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="product">
          <Route index element={<Product />} />
          <Route path="new" element={<ProductUpdate />} />
          <Route path=":id/*">
            <Route index element={<ProductDetail />} />
            <Route path="edit" element={<ProductUpdate />} />
          </Route>
            <Route path=":productId/app">
              <Route path="new" element={<AppUpdate />} />
              <Route path=":appId/*" element={<ProductAllFeatureRoutes/>}/>
            </Route>
        </Route>
        <Route path="product-user-role/*" element={<ProductUserRole />} />
        <Route path="app/*" element={<App />} />
        <Route path="code-blueprint/*" element={<CodeBlueprint />} />
        <Route path="app-code-blueprint/*" element={<AppCodeBlueprint />} />
        <Route path="app-screen/*" element={<AppScreen />} />
        <Route path="app-access-config/*" element={<AppAccessConfig />} />
        <Route path="functionality/*" element={<Functionality />} />
        <Route path="app-entity/*" element={<AppEntity />} />
        <Route path="app-entity-field/*" element={<AppEntityField />} />
        <Route path="entity-relationship/*" element={<EntityRelationship />} />
        <Route path="deployment/*" element={<Deployment />} />
        <Route path="core-screen/*" element={<CoreScreen />} />
        <Route path="core-access-config/*" element={<CoreAccessConfig />} />
        <Route path="core-functionality/*" element={<CoreFunctionality />} />
        <Route path="core-entity/*" element={<CoreEntity />} />
        <Route path="core-entity-field/*" element={<CoreEntityField />} />
        <Route path="core-entity-relationship/*" element={<CoreEntityRelationship />} />
        <Route path="email-configuration/*" element={<EmailConfiguration />} />
        <Route path="product-user/*" element={<ProductUser />} />
        <Route path="enum-member/*" element={<EnumMember />} />
        <Route path="type/*" element={<Type />} />
        <Route path="team-member/*" element={<TeamMember />} />
        <Route path="screen-component/*" element={<ScreenComponent />} />
        <Route path="vault-config/*" element={<VaultConfig />} />
        <Route path="core-screen-component/*" element={<CoreScreenComponent />} />
        <Route path="dependency/*" element={<Dependency />} />
        <Route path="app-screen-layout/*" element={<AppScreenLayout />} />
        <Route path="menu-item/*" element={<MenuItem />} />
        <Route path="layout-config/*" element={<LayoutConfig />} />
        <Route path="app-layout/*" element={<AppLayout />} />
        <Route path="screen-content/*" element={<ScreenContent />} />
        <Route path="job-task/*" element={<JobTask />} />
        <Route path="job/*" element={<Job />} />
        <Route path="app-dependency/*" element={<AppDependency />} />
        <Route path="product-user-role-access/*" element={<ProductUserRoleAccess />} />
        <Route path="feature/*" element={<Feature />} />
        <Route path="core-enum-member/*" element={<CoreEnumMember />} />
        <Route path="app-end-point/*" element={<AppEndPoint />} />
        <Route path="app-end-point-controller/*" element={<AppEndPointController />} />
        <Route path="core-functionality-dependency/*" element={<CoreFunctionalityDependency />} />
        <Route path="core-app-end-point/*" element={<CoreAppEndPoint />} />
        <Route path="core-app-end-point-controller/*" element={<CoreAppEndPointController />} />
        <Route path="core-job/*" element={<CoreJob />} />
        <Route path="app-domain-listener/*" element={<AppDomainListener />} />
        <Route path="product-journal-entry/*" element={<ProductJournalEntry />} />
        <Route path="app-ai-service/*" element={<AppAiService />} />
        <Route path="action-component/*" element={<ActionComponent />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
