import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from '../../../config/store';
import ProductAllFeatures from '../../../entities/productAllFeatures/ProductAllFeatures';
import {
  AppEntityFieldSvgIcon,
  AppEntitySvgIcon,
  AppSvgIcon,
  DeploymentSvgIcon,
  DropdownSvgIcon,
  EntityRelationshipSvgIcon,
  FunctionalitySvgIcon,
  ProductDIcon,
  ProductDAppIcon,
  ProductSvgIcon,
  ToggleButtonIcon,
} from '../../../shared/assets/svg-icon/icon';
import { ConductorLogo } from '../../../shared/assets/svg-icon/logo';
import React, { useEffect, useMemo, useState } from 'react';
import { Route, useLocation, useNavigate } from 'react-router-dom';
import { Nav, NavLink, NavItem, Spinner, CardImg, Button } from 'reactstrap';
import { Brand } from '../header/header-components';
import './sidebar.scss';
import './sidebarnew.scss';
function Sidebar({ toggle }) {
  const productEntity = useAppSelector(state => state.product.entity);
  const appEntity = useAppSelector(state => state.app.entity);
  const [isProductVisited, setIsProductVisited] = useState(false);
  const [isAppVisited, setIsAppVisited] = useState(false);
  const [productId, setProductId] = useState('');
  const [appId, setAppId] = useState('');

  const location = useLocation();
  const navigate = useNavigate();
  const isActive = regexPattern => {
    return new RegExp(regexPattern).test(location.pathname);
  };

  useEffect(() => {
    const appMatch = location.pathname.match(/^\/product\/(\d+)\/app\/(\d+).*?$/);
    const productMatch = location.pathname.match(/^\/product\/(\d).*?$/);
    setIsProductVisited(productMatch !== null);
    // console.log('appMatch', appMatch);
    setIsAppVisited(appMatch !== null);
    const path = location.pathname.split('/');
    // console.log('path', path);
    if (path.length >= 3) {
      setProductId(path[2]);
    }
    if (path.length >= 5) {
      setAppId(path[4]);
    }
  }, [location]);

  const handleProductClick = () => {
    if (productId != '' && appId != '') {
      navigate(`/product/${productId}/app/${appId}`);
    } else {
      navigate(`/product`);
    }
  };

  const handleAppClick = () => {
    if (productId != '') {
      navigate(`/product/${productId}`);
    } else {
      navigate(`/product`);
    }
  };

  // useEffect(() => {
  //   let loc_Arr = location.pathname.split('/');
  //   if (loc_Arr[1] == 'product-feature' && !isNaN(parseInt(loc_Arr[2]))) {
  //     setIsProductVisited(true);
  //   }
  // }, [location]);

  // // Navigation for app sidebar
  // const url = window.location.href;
  // const id = url.substring(url.lastIndexOf('/') + 1);

  // const handleAppClick = () => {
  //   const loc_Arr = location.pathname.split('/');
  //   const id = loc_Arr[2];
  //   const newUrl = `/product-feature/${id}`;
  //   navigate(newUrl);
  // };

  return (
    <div className="sidemenu col-md-3 col-xl-2">
      <NavLink href="./product" className="Logo">
        <ConductorLogo />
        {/* <span className="navbar-version">{VERSION}</span> */}
      </NavLink>
      <Nav vertical>
        <NavItem key="sideproductsitem">
          <NavLink key="sideproducts" className={isProductVisited || isProductVisited ? '' : 'active'} onClick={() => navigate(`/product`)}>
            <ProductSvgIcon color={'#fff'} /> Products
          </NavLink>
        </NavItem>
        {/*<NavItem isOpen={isProductVisited} disabled={!isProductVisited}>*/}
        {/*  <NavLink*/}
        {/*    onClick={isProductVisited ? handleAppClick : null}*/}
        {/*    style={{ color: isProductVisited ? '#1764AF' : '#111111', cursor: isProductVisited ? 'pointer' : 'not-allowed' }}*/}
        {/*  >*/}
        {/*    <AppSvgIcon color={isProductVisited ? '#1764AF' : '#111111'} /> App*/}
        {/*    {isProductVisited*/}
        {/*      ? appEntity && appEntity.name*/}
        {/*        ? ` - ${appEntity.name}`*/}
        {/*        : [<span>&nbsp;</span>,<Spinner size={'sm'}>Loading...</Spinner>]*/}
        {/*      : ''}*/}
        {/*  </NavLink>*/}
        {/*</NavItem>*/}
        {isProductVisited ? (
          <NavItem isopen="true" key="sideproductNameitem">
            <NavLink key="sideproductName" onClick={handleAppClick} className={isProductVisited && !isAppVisited ? 'active' : ''}>
              <ProductDIcon />
              {productEntity && productEntity.name
                ? ` ${productEntity.name}`
                : [<span>&nbsp;</span>, <Spinner size={'sm'}>Loading...</Spinner>]}
            </NavLink>
            {isAppVisited ? (
              <Nav vertical>
                <NavItem isopen="true" key="sideproductAppNameitem">
                  <NavLink key="sideproductAppName" onClick={handleProductClick} className={isAppVisited ? 'active' : ''}>
                    <ProductDAppIcon />
                    {appEntity && appEntity.name ? ` ${appEntity.name}` : [<span>&nbsp;</span>, <Spinner size={'sm'}>Loading...</Spinner>]}
                  </NavLink>
                </NavItem>
              </Nav>
            ) : null}
          </NavItem>
        ) : null}
      </Nav>
    </div>
  );
}

export default Sidebar;
