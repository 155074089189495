import { IAppSetting } from 'app/shared/model/app-setting.model';
import { IApp } from 'app/shared/model/app.model';
import { IAppAiService } from 'app/shared/model/app-ai-service.model';
import { ChatLanguageModelType } from 'app/shared/model/enumerations/chat-language-model-type.model';

export interface IAppChatLanguageModel {
  id?: number;
  type?: ChatLanguageModelType | null;
  modelName?: string | null;
  modelVersion?: string | null;
  appSettings?: IAppSetting[] | null;
  app?: IApp | null;
  appAiServices?: IAppAiService[] | null;
}

export const defaultValue: Readonly<IAppChatLanguageModel> = {};
