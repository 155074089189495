import { ReducersMapObject, combineReducers } from '@reduxjs/toolkit';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import locale from '../../../../../app/shared/reducers/locale';
import authentication from './authentication';
import applicationProfile from '../../../../../app/shared/reducers/application-profile';
import administration from '../../modules/administration/administration.reducer';
import userManagement from '../../modules/administration/user-management/user-management.reducer';
import register from '../../../app/modules/account/register/register.reducer';
import activate from '../../../app/modules/account/activate/activate.reducer';
import password from '../../../app/modules/account/password/password.reducer';
import settings from '../../../app/modules/account/settings/settings.reducer';
import passwordReset from '../../../app/modules/account/password-reset/password-reset.reducer';
import entitiesReducers from '../../../app/entities/reducers';
import CustomReducer from '../../entities/customreducers/ustomReducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const rootReducer: ReducersMapObject = {
  authentication,
  locale,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  loadingBar,
  CustomReducer,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  ...entitiesReducers,
};

export default rootReducer;
