import React, { useState } from 'react';
import { Button, Table, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { Translate, ValidatedField } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const AiSuggestEntityRelationshipModal = props => {
  const {
    aiSuggestLoading,
    aiSuggestEntityRelationshipList,
    handleOnSaveForSuggested,
    handleCloseAiEntityRelationshipModal,
    handleSuggestedDelete,
    handleAiSuggest,
    productEntity,
    modalVisible,
    closeRelationshipModal,
  } = props;
  //   const location = useLocation();
  //   const { productId } = useParams<'productId'>();
  //   const { appId } = useParams<'appId'>();
  //   const { appEntityId } = useParams<'appEntityId'>();
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [step, setStep] = useState(1);
  const [userPropmt, setUserPropmt] = useState('');
  const [aiRelationshipLoading, setAiRelationshipLoading] = useState(false);
  const titleUpperCase = str => {
    let splitStr = str.split(' ').map(item => item.charAt(0).toUpperCase() + item.substring(1));

    return splitStr.join(' ');
  };

  const [showModal, setShowModal] = useState(modalVisible);
  const toggle = () => {
    setShowModal(!showModal);
    closeRelationshipModal();
  };
  const handleUserPrompt = event => {
    if (event.target) {
      setUserPropmt(event.target.value);
    } else {
      setUserPropmt('');
    }
  };

  const handleAiPrompt = async () => {
    setAiRelationshipLoading(true);
    setStep(step => step + 1);
    await handleAiSuggest(userPropmt);
    setUserPropmt('');
    setAiRelationshipLoading(false);
  };

  const handleCheckboxChange = (e, relationship) => {
    let isChecked = e.target.checked;
    if (isChecked == true) {
      setSelectedFeatures(prevFeatures => [...prevFeatures, relationship]);
    }
    if (isChecked == false) {
      let tempArr = selectedFeatures.filter(
        obj =>
          obj.relationshipType !== relationship.relationshipType ||
          obj.fromEntity !== relationship.fromEntity ||
          obj.toEntity !== relationship.toEntity
      );
      setSelectedFeatures(tempArr);
    }
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={() => !aiRelationshipLoading && !aiSuggestLoading && toggle()}
        className="modal-lg"
        className="modalDesignNew"
        centered
      >
        {/* {step == 1 && ( */}
        <ModalHeader
          //  toggle={handleClose}
          data-cy="appEntityRelationshipHeading"
        >
          {step == 1 && <>How would you like to modify the relationships?</>}
          {step == 2 && aiSuggestEntityRelationshipList.length > 0 && !aiRelationshipLoading && (
            <>which relationship would you like to create :</>
          )}
        </ModalHeader>
        {/* )} */}
        <ModalBody id="codeconductorApp.appEntity.feature.select" className="stepmodal">
          {step == 1 && (
            <>
              <ValidatedField
                className="col-md-12 mb-0 modal-input"
                id="userPrompt"
                name="userPrompt"
                type="textarea"
                data-cy="userPrompt"
                value={userPropmt}
                onChange={handleUserPrompt}
                placeholder="What are you looking for? (Optional)"
                validate={{
                  required: { value: true, message: 'Please enter valid prompt!!' },
                }}
              />
            </>
          )}
          {step == 2 && (
            <>
              {aiSuggestEntityRelationshipList.length > 0 && !aiRelationshipLoading ? (
                <div className="table-responsive coll-design">
                  <div className="table-scrolling">
                    <Table>
                      <thead className="table-primary">
                        <tr>
                          <th></th>
                          <th className="hand">
                            <Translate contentKey="codeconductorApp.entityRelationship.type">Type</Translate>
                          </th>
                          <th>
                            <Translate contentKey="codeconductorApp.entityRelationship.appEntityFrom">Entity from</Translate>
                          </th>
                          <th>
                            <Translate contentKey="codeconductorApp.entityRelationship.appEntityTo">Entity To</Translate>
                          </th>
                          {/* <th>
                      <Translate contentKey="codeconductorApp.entityRelationship.appEntityFieldFrom">Entity Field From</Translate>
                    </th>
                    <th>
                      <Translate contentKey="codeconductorApp.entityRelationship.appEntityFieldTo">Entity Field To</Translate>
                    </th> */}
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {aiSuggestEntityRelationshipList.map((entityRelationship, i) => (
                          <tr
                            key={`entity-${i}`}
                            id={`entity-key-${entityRelationship.id || entityRelationship.key}`}
                            data-cy="entityTable"
                          >
                            <td>
                              <input
                                type="checkbox"
                                onChange={e => {
                                  handleCheckboxChange(e, entityRelationship);
                                }}
                              />
                            </td>
                            <td>{entityRelationship?.relationshipType}</td>
                            <td>{entityRelationship?.fromEntity}</td>
                            <td>{entityRelationship?.toEntity}</td>
                            {/* <td>{entityRelationship?.appEntityFieldFrom?.name}</td>
                      <td>{entityRelationship?.appEntityFieldTo?.name}</td> */}
                            <td className="text-end">
                              {/* <div className="btn-group flex-btn-group-container">
                          <Button
                            onClick={e => {
                              handleOnSaveForSuggested(entityRelationship);
                            }}
                            color="primary"
                            size="sm"
                            data-cy="entityEditButton"
                          >
                            <FontAwesomeIcon icon="save" />
                          </Button>
                          <Button
                            onClick={() => handleSuggestedDelete(entityRelationship)}
                            color="danger"
                            size="sm"
                            data-cy="entityDeleteButton"
                          >
                            <FontAwesomeIcon icon="trash" />{' '}
                            <span className="d-none">
                              <Translate contentKey="entity.action.delete">Delete</Translate>
                            </span>
                          </Button>
                        </div> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              ) : aiSuggestLoading || aiRelationshipLoading ? (
                <div className="d-flex justify-content-center">
                  <div style={{ textAlign: 'center' }}>
                    <h2 className="wizard-heading gif_heading">Working On Relationships</h2>
                    <img className="modalIimg-dgn" src="themeContent/entitiesloader.gif" />
                  </div>
                </div>
              ) : (
                <div className="alert alert-warning">No AI suggested Entity Relationship Found</div>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {!aiSuggestLoading && (
            <Button
              color="save"
              disabled={step == 2 && selectedFeatures.length == 0}
              onClick={() => {
                step == 1 ? handleAiPrompt() : handleOnSaveForSuggested(selectedFeatures);
              }}
            >
              Next
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AiSuggestEntityRelationshipModal;
