import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, TextFormat, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from '../../../../../app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '../../../../../app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from '../../config/store';

import { IEnumMember } from '../../../../../app/shared/model/enum-member.model';
import { getEntities } from './enum-member.reducer';
import EnumMemberDeleteDialog from './enum-member-delete-dialog';

export const EnumMember = () => {
  const dispatch = useAppDispatch();
  const { productId } = useParams<'productId'>();
  const { appId } = useParams<'appId'>();
  const { appEntityId } = useParams<'appEntityId'>();

  const location = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );
  var [deleteId, setDeleteId] = useState('');
  const [showModal, setShowModal] = useState(false);

  const enumMemberList = useAppSelector(state => state.enumMember.entities);
  const loading = useAppSelector(state => state.enumMember.loading);
  const totalItems = useAppSelector(state => state.enumMember.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: `appEntityId.equals=${appEntityId}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    // const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    // if (location.search !== endURL) {
    //   navigate(`${location.pathname}${endURL}`);
    // }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const handleDelete = functionality_id => {
    setShowModal(true);
    setDeleteId(functionality_id);
    deleteId = functionality_id;
  };

  const viewModal = modal => {
    setShowModal(modal);
  };

  return (
    <>
      <div className="inner-content-wrap">
        <h2 className="row mb-4 mt-3" id="enum-member-heading" data-cy="EnumMemberHeading">
          <Translate contentKey="codeconductorApp.enumMember.home.title">Enum Members</Translate>
          <div className="d-flex justify-content-end">
            <Button className="me-2" color="refresh" onClick={handleSyncList} disabled={loading}>
              <FontAwesomeIcon icon="sync" spin={loading} />{' '}
              <Translate contentKey="codeconductorApp.enumMember.home.refreshListLabel">Refresh List</Translate>
            </Button>
            <Link
              to={`/product/${productId}/app/${appId}/app-entity/${appEntityId}/view/app-entity-enum/new`}
              className="btn btn-save jh-create-entity"
              id="jh-create-entity"
              data-cy="entityCreateButton"
            >
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="codeconductorApp.enumMember.home.createLabel">Add Enum Member</Translate>
            </Link>
          </div>
        </h2>
        {enumMemberList && enumMemberList.length > 0 ? (
          <div className="table-responsive">
            <Table responsive>
              <thead>
                <tr>
                  {/* <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="codeconductorApp.enumMember.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th> */}
                  <th className="hand" onClick={sort('name')}>
                    <Translate contentKey="codeconductorApp.enumMember.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    <Translate contentKey="codeconductorApp.enumMember.description">Description</Translate>
                  </th>
                  {/* <th>
                  <Translate contentKey="codeconductorApp.enumMember.appEntity">App Entity</Translate> <FontAwesomeIcon icon="sort" />
                </th> */}
                  <th />
                </tr>
              </thead>
              <tbody>
                {enumMemberList.map((enumMember, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td>{enumMember.name}</td>
                    <td>{enumMember.description}</td>
                    <td className="text-end">
                      <div className="btn-group flex-btn-group-container">
                        {/* <Button tag={Link} to={`/product-feature/${id}/app-entity-view/${appEntityId}/${enumMember.id}/view`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button> */}
                        <Button
                          tag={Link}
                          to={`/product/${productId}/app/${appId}/app-entity/${appEntityId}/view/app-entity-enum/${enumMember.id}/edit`}
                          color="primary"
                          size="sm"
                          data-cy="entityEditButton"
                        >
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">{/* <Translate contentKey="entity.action.edit">Edit</Translate> */}</span>
                        </Button>
                        <Button onClick={() => handleDelete(enumMember.id)} color="danger" size="sm" data-cy="entityDeleteButton">
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            {/* <Translate contentKey="entity.action.delete">Delete</Translate> */}
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : loading ? (
          <div className="conductor_loader"></div>
        ) : (
          <div className="alert alert-warning">
            <Translate contentKey="codeconductorApp.enumMember.home.notFound">No Enum Members found</Translate>
          </div>
        )}
        {totalItems ? (
          <div className={enumMemberList && enumMemberList.length > 0 ? 'footer-pagination' : 'd-none'}>
            <div className="justify-content-end d-flex">
              <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
            </div>
            <div className="justify-content-end d-flex">
              <JhiPagination
                activePage={paginationState.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={paginationState.itemsPerPage}
                totalItems={totalItems}
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {showModal && <EnumMemberDeleteDialog itemId={deleteId} showModal={viewModal} />}
    </>
  );
};

export default EnumMember;
