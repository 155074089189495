import './header.scss';

import React, { useState } from 'react';
import { Translate, Storage } from 'react-jhipster';
import { Navbar, Nav, NavbarToggler, Collapse, Button } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';

import { Home, Brand, Demo } from './header-components';
import { AdminMenu, EntitiesMenu, AccountMenu, LocaleMenu } from '../../layout/menus';
import { useAppDispatch, useAppSelector } from '../../../config/store';
import { setLocale } from '../../../../../../app/shared/reducers/locale';
import ChatGptSearch from '../../../entities/chatgpt/chatgpt-search';
import { ToggleButtonIcon } from '../../assets/svg-icon/icon';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  currentLocale: string;
  onToggleClick: () => void;
}

const Header = (props: IHeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);

  const handleLocaleChange = event => {
    const langKey = event.target.value;
    Storage.session.set('locale', langKey);
    dispatch(setLocale(langKey));
  };

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">
          <Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
        </a>
      </div>
    ) : null;
  // const toggleSidebar = ()=>{
  //   props.onToggleClick();
  // }
  const toggleMenu = () => setMenuOpen(!menuOpen);

  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */

  return (
    <div id="app-header">
      {/* {renderDevRibbon()} */}
      <LoadingBar className="loading-bar" />
      <Navbar data-cy="navbar" dark expand="md" className="jh-navbar row shadow-sm top-nav">
        {/* <NavbarToggler aria-label="Menu" onClick={toggleMenu} /> */}
        {/* <Brand /> */}
        <Collapse isOpen={menuOpen} navbar>
          <Nav id="header-tabs" className=" d-flex justify-content-between w-100 align-items-center" navbar>
            {/* <Home /> */}
            {/* {props.isAuthenticated && <Demo />}
            {props.isAuthenticated && <EntitiesMenu />} */}
            {/* {props.isAuthenticated && props.isAdmin && (
              <AdminMenu showOpenAPI={props.isOpenAPIEnabled} showDatabase={!props.isInProduction} />
            )} */}

            <LocaleMenu currentLocale={props.currentLocale} onClick={handleLocaleChange} />
            <Button className="sidebar-toggle" onClick={props.onToggleClick}>
              <ToggleButtonIcon />
            </Button>
            {isAuthenticated && <ChatGptSearch />}
            <AccountMenu isAuthenticated={props.isAuthenticated} />
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
